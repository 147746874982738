import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Angulartics2 } from 'angulartics2';

import { FsMessage } from '@firestitch/message';
import { FsPrompt } from '@firestitch/prompt';

import { Item } from '../../models';
import { ItemService } from '@app/core/services';
import { AddToListService } from '../../services';
import { ActionKebubMenuItem } from '../../interfaces';
import { ItemAddNotesComponent } from '../your-notes';
import { ShareMenuDialogService } from '@app/taster/modules/share-menu';


@Component({
  selector: 'app-item-action-kebub-menu',
  templateUrl: './action-kebub-menu.component.html',
  styleUrls: ['./action-kebub-menu.component.scss']
})
export class ActionKebubMenuComponent implements OnInit, OnDestroy {

  @Input() public customActions: ActionKebubMenuItem[] = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  @Output()
  public ratingChanged = new EventEmitter<void>();

  // @Output()
  // public triedRemoved = new EventEmitter<void>();

  public get item() {
    return this._item;
  }

  private _item: Item = null;

  // private _confirmMessage =
  //   `Are you sure you want to remove this item from the "Things I've Tried" in your Cellar?`;

  // private _triedTemplateMessage =
  //   `<div class="heading">Added to My Cellar</div>Things I've Tried`;

  private _destroy$ = new Subject();

  constructor(
    private _itemService: ItemService,
    private _fsMessage: FsMessage,
    private _fsPrompt: FsPrompt,
    private _addToListService: AddToListService,
    private _angulartics2: Angulartics2,
    private _dialog: MatDialog,
    private _shareDialog: ShareMenuDialogService,
  ) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public share() {
    this._shareDialog.openMenu(this.item);
  }

  // public toggleTryIt() {
  //   if (!this.item.accountItem.triedIt) {
  //     this.submitTryIt();
  //   } else {
  //     this.removeTryIt();
  //   }
  // }

  // private removeTryIt() {
  //   this._fsPrompt.confirm({
  //     title: 'Confirm',
  //     template: this._confirmMessage
  //   }).subscribe(() => {
  //     this.submitTryIt(() => {
  //       this.triedRemoved.emit();
  //     });
  //   })
  // }

  // private submitTryIt(cb = () => {}) {
  //   this.item.accountItem.triedIt = !this.item.accountItem.triedIt;
  //   this._itemService.tried(this.item.id, { triedIt: this.item.accountItem.triedIt})
  //     .pipe(
  //       takeUntil(this._destroy$)
  //     )
  //     .subscribe((response) => {
  //       if (response.triedIt) {

  //         this._angulartics2.eventTrack.next({
  //           action: 'tried_it',
  //           properties: { objectId: this.item.id }
  //         });

  //         this._fsMessage.success(this._triedTemplateMessage);
  //       } else {
  //         this._angulartics2.eventTrack.next({
  //           action: 'untried_it',
  //           properties: { objectId: this.item.id }
  //         });
  //       }
  //       cb();
  //     });
  // }

  // public addToList() {

  //   this._addToListService.openDialog(this.item)
  //     .afterClosed()
  //     .pipe(
  //       takeUntil(this._destroy$)
  //     )
  //     .subscribe(response => { });
  // }

  public rate() {
    const dialogRef = this._dialog.open(ItemAddNotesComponent, {
      data: { item: this.item }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        if (response) {
          this._item.notes.push(response);
          this.item.accountItem.rate = !this.item.accountItem.rate; // @TODO
        }

        this.ratingChanged.emit();
      });
  }

  public customAction(item: ActionKebubMenuItem) {
    item.action(this.item);
  }

}
