/**
 * Main module can be named as Pages i.e.
 * So, Main module contains site layout base elements menus/sidebars/header/footer and other static components
 * Also it can contain static pages, but if you will need lot's of static pages would be nice to create special PagesModule
 */
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FsSidenavModule } from '@firestitch/sidenav';

import { LogoModule } from '@libs/logo';

import { RatingComponent } from '@app/labs/components';
import { LabsModule } from '@app/labs';

// Modules
import { SharedModule } from '../shared';
import { TastingDialogModule } from '@app/taster/modules/tastings/modules/tasting-dialog';
import { WhereToBuyPaneModule } from '@app/taster/modules/wine/modules/where-to-buy-pane';
import { WelcomeActionsPaneModule } from '@app/taster/modules/welcome/modules/welcome-actions-pane';
import { QuizCompleteActionPaneModule } from '@app/taster/modules/tastetest/modules/complete-action-pane';
import { QuizStepPaneModule } from '@app/taster/modules/tastetest/modules/quiz-step-pane';
import { TastingRoomPaneModule } from '@app/taster/modules/organization/modules/tasting-room';
import { OrganizationActionPaneModule } from '@app/taster/modules/organization/modules/action';
import { ExperienceActionPaneModule } from '@app/taster/modules/experience/modules/action';
// import { MapPaneModule } from '@app/taster/modules/map/modules/pane';
import { TasterBannerModule } from '@app/taster/modules/banner';

import { ContentComponent } from './content';
import { HomeComponent } from './home';
import { ShareClickComponent } from './share-click';
import { EnvironmentEmptyComponent } from './environment-empty';

import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import {
  EnvironmentMenuComponent,
  EnvironmentSwitchComponent,
  SearchOrganizationsComponent,
  SideNavComponent,
} from './side-nav';


@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        TastingDialogModule,
        FsSidenavModule,
        LabsModule,
        LogoModule,
        TasterBannerModule,
        // Panes
        WhereToBuyPaneModule,
        WelcomeActionsPaneModule,
        QuizCompleteActionPaneModule,
        TastingRoomPaneModule,
        OrganizationActionPaneModule,
        ExperienceActionPaneModule,
        QuizStepPaneModule,
    ],
    declarations: [
        ContentComponent,
        HomeComponent,
        ShareClickComponent,
        EnvironmentEmptyComponent,
        EnvironmentMenuComponent,
        EnvironmentSwitchComponent,
        SideNavComponent,
        SearchOrganizationsComponent,
    ],
    exports: [
        ContentComponent,
        HomeComponent,
        ShareClickComponent,
        EnvironmentEmptyComponent
    ]
})
export class MainModule {
  constructor(@Optional() @SkipSelf() parentModule: MainModule) {
    throwIfAlreadyLoaded(parentModule, 'MainModule');
  }
}
