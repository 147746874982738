import { Component, Input } from '@angular/core';


@Component({
  selector: 'item-headings',
  templateUrl: './headings.component.html',
  styleUrls: ['./headings.component.scss']
})
export class HeadingsComponent {

  @Input() public item;
  @Input() public headingType = '2';
  @Input() public hideOrganizationName = false;
  @Input() public showRating = true;

}
