import { CurateListListType } from '../enums';


export const CuratedListListTypes = [
  {
    name: 'Filtered',
    title: 'Filtered',
    value: CurateListListType.Filtered,
    hint: 'Based on Filters on the right, ordered by Relevance.',
  },
  {
    name: 'Rotating',
    title: 'Weekly Top Wines for You',
    value: CurateListListType.Rotating,
    hint: `User's current Top 10 Wines overall. Ensure it is on the Wines page.`,
  },
];
