import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import { LabsRoutingModule } from './labs-routing.module';
import {  DialogNavComponent,
          VerifyComponent,
          RatingComponent,
          FullComponent
         } from './components';
import { LayersComponent } from './views/layers';
import { StylesComponent } from './views/styles';


@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        LabsRoutingModule
    ],
    declarations: [
        DialogNavComponent,
        VerifyComponent,
        LayersComponent,
        RatingComponent,
        FullComponent,
        StylesComponent,
    ],
    exports: [
        RatingComponent
    ]
})
export class LabsModule { }
