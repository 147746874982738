import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { takeUntil } from 'rxjs/operators';
import { Attribute } from 'app/shared/interfaces';
import { Subject } from 'rxjs';
import { ObjectAttributeSelectorComponent } from 'app/shared/components/object-attribute-selector';
import { AttributeService } from 'app/core/services';

@Component({
  selector: 'object-attribute-field',
  templateUrl: './object-attribute-field.component.html',
  styleUrls: ['./object-attribute-field.component.scss']
})
export class ObjectAttributeFieldComponent implements OnDestroy, OnInit {

  @Input() attributes: Attribute[] = [];
  @Input() class = null;
  @Input() public objectId;
  @Input() public projectId;
  @Output() public changed = new EventEmitter<Attribute[]>();

  public selected = {};
  public label;
  private _destroy = new Subject();

  constructor(private _dialog: MatDialog,
              private _attributeService: AttributeService) {}

  public ngOnInit() {
    this.label = this._attributeService.getClass(this.class).pluralName;
  }

  public ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  public openSelector() {
    const dialogRef = this._dialog.open(ObjectAttributeSelectorComponent, {
      width: '30%',
      minWidth: '400px',
      disableClose: true,
      data: { objectId: this.objectId,
              projectId: this.projectId,
              attributes: this.attributes,
              class: this.class }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe((attributes: Attribute[]) => {
        this.changed.emit(attributes);
      });
  }
}
