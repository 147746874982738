<div class="item-availability subheading-1" fxLayout="column" *ngIf="!hideAvailability">
  <div fxLayout="row" fxLayoutAlign="start center">

    <i class="material-icons small-icon">access_time</i>

    <ng-container [ngSwitch]="item.class" *ngIf="!displaySchedule">
      <div *ngIf="item.status !== 'available' && item.status !== 'open'" class="availability-status" fxFlex>
        <ng-container [ngSwitch]="item.class">
          <app-organization-status-pill
            *ngSwitchCase="'organization'"
            [status]="$any(item.status)">
          </app-organization-status-pill>

          <app-experience-status-pill
            *ngSwitchCase="'experience'"
            [status]="$any(item.status)">
          </app-experience-status-pill>

          <app-wine-status-pill
            *ngSwitchCase="'wine'"
            [status]="$any(item.status)">
          </app-wine-status-pill>
        </ng-container>
      </div>
    </ng-container>
    <span class="availability-description" *ngIf="item.organization?.availabilityLabel && !displaySchedule" fxFlex="grow">

      <fs-truncate [lines]="1" [more]=true *ngIf="item.isExperience && item.status === 'open'; else fullContent">
          {{ item.organization.availabilityLabel }}
      </fs-truncate>

      <ng-template #fullContent>{{ item.organization.availabilityLabel }}</ng-template>

    </span>

    <ng-container *ngIf="item.isOrganization">
      <span class="availability-description" *ngIf="displaySchedule" fxFlex="grow">
        {{ item.organization.activeSeasonRange }}
      </span>

      <mat-icon
        *ngIf="item.organization.activeSeasonSchedule"
        class="schedule-toggle-icon"
        (click)="toggleSchedule()">
        {{ displaySchedule ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>

    </ng-container>

  </div>

  <ng-container *ngIf="item.isOrganization">
    <div class="item-availability-schedule" fxLayout="column" *ngIf="displaySchedule && item.organization.activeSeasonSchedule">
      <div *ngFor="let dailySchedule of item.organization.activeSeasonSchedule" class="schedule-row">
        <span class="schedule-day">{{ dailySchedule.day }}</span>
        <span class="schedule-hours">{{ dailySchedule.hours }}</span>
      </div>
    </div>
  </ng-container>
</div>
