import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Build } from '../../../build/interfaces/build.interface';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AppService } from '@app/core';


@Component({
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss']
})
export class UpdateDialogComponent {

  public build: Build;
  public installUrl: string;

  constructor(
    private _splashscreen: SplashScreen,
    private _appService: AppService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.build = this._data.build as Build;

    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      this.installUrl = this.build.androidInstallUrl;
    } else {
      this.installUrl = this.build.iosInstallUrl;
    }

    this._appService.ready$.subscribe(() => {
      this._splashscreen.hide();
    })

  }


  public install() {
    if ((window as any).cordova && (window as any).cordova.InAppBrowser) {
      (window as any).cordova.InAppBrowser.open(this.installUrl, '_system');
    } else {
      window.open(this.installUrl, '_system');
    }

  }

}
