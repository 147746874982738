import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { NotePreviewComponent } from './components/note-preview.component';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        NotePreviewComponent,
    ],
    exports: [
        NotePreviewComponent
    ],
    providers: []
})
export class NotePreviewModule {
}
