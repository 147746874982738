import { Component, Input } from '@angular/core';

@Component({
  selector: 'last-sign-in',
  templateUrl: './last-sign-in.component.html',
  styleUrls: ['./last-sign-in.component.scss']
})
export class LastSignInComponent {
  @Input() public date: Date = null;

  constructor() {

  }


}
