import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

import { filter } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { ObjectAward, AwardOrganization } from 'app/shared/interfaces';
import { ObjectService, AwardOrganizationService } from 'app/core/services';
import { ObjectAwardParent } from 'app/shared/types';
import { AwardOrganizationDialogComponent } from '../award-organization-dialog';


@Component({
  selector: 'app-award-won-dialog',
  templateUrl: './award-won-dialog.component.html',
  styleUrls: ['./award-won-dialog.component.scss']
})
export class AwardWonDialogComponent implements OnInit {

  public objectAward: ObjectAward = null;
  public parentObject: ObjectAwardParent = null;

  @ViewChild('form') public form: NgForm = null;

  public fetch = keyword => {
    return this._awardOrganizationService.gets({
      keyword: keyword,
    });
  }

  constructor(
    private _dialogRef: MatDialogRef<AwardWonDialogComponent>,
    private _dialog: MatDialog,
    private _objectService: ObjectService,
    private _fsMessage: FsMessage,
    private _awardOrganizationService: AwardOrganizationService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.objectAward = data.objectAward;
    this.parentObject = data.parentObject;
  }

  public ngOnInit() { }

  public displayWith = (data) => {
    return data.name;
  }

  public awardOrganizationChange(awardOrganization:AwardOrganization) {
    this.objectAward.awardOrganization = awardOrganization;
    this.objectAward.awardOrganizationId = awardOrganization.id;
  }


  public openCreateAwardOrganizationDialog() {
    const dialogRef = this._dialog.open(AwardOrganizationDialogComponent, {
      data: { awardOrganization: { id: null } }
    });

    dialogRef.afterClosed()
    .pipe(
      filter(response => !!response)
    )
    .subscribe(response => {
      if (response.id) {
        this.objectAward.awardOrganization = response;
        this.objectAward.awardOrganizationId = response.id;
      }
    });

  }

  public save() {
    this._objectService.saveObjectAward(this.parentObject.id, this.objectAward)
      .subscribe(response => {
        this._fsMessage.success('Changes Saved');
        this.close(response);
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
