import { Workflow } from './workflow';
import { Account } from './account';
import { Type } from './type';


export interface WorkflowTask {
  id: number;
  workflowId?: number;
  workflow?: Workflow;
  name?: string;
  configs?: number[];
  nameTemplate?: string;
  assignAccountId?: number;
  assignAccount?: Account;
  typeId?: number;
  type?: Type;
  state?: string;
  subscribers?: Account[];
}

