<ng-container *ngIf="item.meta?.ratingCount; else unrated">
  <fs-rating
    [ngModel]="item.meta.itemRating"
    [showValue]="false">
  </fs-rating>

  <div class="label rating-count"> {{ item.meta.ratingCount }} </div>
</ng-container>

<ng-template #unrated>
  <span class="unrated-label">No ratings yet</span>
</ng-template>

<mat-icon class="award" svgIcon="award-circle" *ngIf="item.awardsWonCount"></mat-icon>
