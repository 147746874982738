import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService, SessionService, AuthService } from 'app/core/services';
import { Account } from 'app/shared/interfaces';


@Component({
  templateUrl: './environment-switch.component.html',
  styleUrls: ['./environment-switch.component.scss']
})
export class EnvironmentSwitchComponent implements OnInit, OnDestroy {

  public environments: any[] = [];
  public account: Account = null;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<EnvironmentSwitchComponent>,
    private _accountService: AccountService,
    private _sessionService: SessionService,
    private _authService: AuthService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.environments = this.data.environments;

    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(store => this.account = store.value);
  }

  public switch(environment) {
    this._accountService.switchEnvironment(this.account.id, environment.id)
      .subscribe(response => {
        this._sessionService.environment(response);
        this.close();
        this._accountService.proceedHome();
      });
  }

  public close() {
    this._dialogRef.close();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
