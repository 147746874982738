import { Component, Inject, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';

import { PANE_DATA } from '@firestitch/pane';

import { Item } from '@app/taster/modules/item/models';

import { WhereToBuyDialogComponent } from '../../../where-to-buy-dialog/components';


@Component({
  templateUrl: './where-to-buy-pane.component.html',
  styleUrls: ['./where-to-buy-pane.component.scss']
})
export class WhereToBuyPaneComponent implements OnInit {

  public item: Item = null;

  constructor(
    private _dialog: MatDialog,
    private _angulartics2: Angulartics2,
    @Inject(PANE_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
  }

  public whereToBuyDialog() {
    this._angulartics2.eventTrack.next({
      action: 'click_action_button',
      properties: { objectId: this.item.id, buttonLabel: 'Where To Buy' }
    });

    const dialogRef = this._dialog.open(WhereToBuyDialogComponent, {
      data: {
        item: this.item
      }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => {  });
  }

}
