import { Component, Inject, Input, OnInit } from '@angular/core';

import { list } from '@firestitch/common';
import { COUNTRIES } from '@firestitch/address';

import { Subregion } from 'app/shared/interfaces';


@Component({
  selector: 'app-subregion-view',
  templateUrl: './subregion-view.component.html',
  styleUrls: ['./subregion-view.component.scss']
})
export class SubregionViewComponent implements OnInit {

  @Input() public subregion: Subregion = null;

  public countriesFlatten: any = {};

  constructor(
    @Inject(COUNTRIES) private _countries
  ) { }

  public ngOnInit() {
    this.countriesFlatten = list(this._countries, 'name', 'code');
  }

}
