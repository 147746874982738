import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ThemingService } from '@libs/theming';

@Component({
  templateUrl: './share-click.component.html',
  styleUrls: ['./share-click.component.scss']
})
export class ShareClickComponent implements OnInit, OnDestroy {

  private url = '';
  private path;
  private _destroy$ = new Subject();

  private timeout;

  constructor(
    public branding: ThemingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit() {
    this.route.queryParams
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(params => {
        this.url = params.url || '/';
        this.path = params.path;
      })
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public goWeb() {
    sessionStorage.setItem('proceed_web', 'true');

    if (this.path) {
      this.router.navigate([this.path]);
    } else {
      this.router.navigate([this.url]);
    }
  }

  public openApp() {
    const customUrl = environment.urlScheme + '://' + this.url;

    (<any>window).location = customUrl;
  }

  public openAppstore() {
    const appstoreUrl = navigator.userAgent.toLowerCase().indexOf('android') > -1 ? environment.androidAppstoreUrl : environment.iosAppstoreUrl;

    (<any>window).location = appstoreUrl;

  }
}
