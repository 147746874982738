import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';

import { FsStore } from '@firestitch/store';



@Injectable()
export class SigninService {

  constructor(
    private router: Router,
    private fsStore: FsStore
  ) {}

  redirectThroughSignin(state: RouterStateSnapshot) {
    this.setRedirectUrl(state);
    this.router.navigateByUrl('/signin');
  }

  redirectThroughSignUp(state: RouterStateSnapshot | string) {
    this.setRedirectUrl(state);
    this.router.navigateByUrl('/signup');
  }


  createRedirectUrlTree(state: RouterStateSnapshot) {
    this.setRedirectUrl(state);
    // Looks like an Angular bug - Route needs queryParams to redirect
    // properly even if its not being used in our redirect after logic
    return this.router.createUrlTree(['/signin'], { queryParams: { redirect: state.url } });
  }

  private setRedirectUrl(state) {
    let url: string;

    if (typeof state === 'string') {
      url = state;
    } else {
      url = state.url
    }

    if (url === '/signin') {
      return;
    }

    this.fsStore.set('redirect_url', url);
  }

  public clearRedirectUrl() {
    this.fsStore.remove('redirect_url');
  }
}
