import { Component, OnInit, Inject } from '@angular/core';
import {
  FS_ATTRIBUTE_CONFIG,
  FS_ATTRIBUTE_FIELD_DATA,
  FsAttributeConfig
} from '@firestitch/attribute';


@Component({
  selector: 'app-attribute-edit-visible',
  templateUrl: 'edit-visible.component.html',
  styleUrls: ['edit-visible.component.scss']
})
export class AttributeEditVisibleComponent implements OnInit {

  constructor(
    @Inject(FS_ATTRIBUTE_FIELD_DATA) public data,
    @Inject(FS_ATTRIBUTE_CONFIG) private attributeConfig: FsAttributeConfig
  ) {}

  ngOnInit() {
    if (!this.data.attribute.configs) {
      this.data.attribute.configs = {};
    }
  }
}
