import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AclQueryService } from '../../core/services';
import { FsMessage, MessageMode } from '@firestitch/message';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private aclQueryService: AclQueryService,
              private router: Router,
              private fsMessage: FsMessage) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.aclQueryService.hasApp()) {
      return true;
    }

    this.router.navigateByUrl('/');
    this.fsMessage.error('Sorry, but you haven\'t permission for this page', { mode: MessageMode.Toast });
    return false;
  }
}
