import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'app-item-action-list-element',
  templateUrl: './action-list-element.component.html',
  styleUrls: ['./action-list-element.component.scss']
})
export class ActionListElement {
  @Input()
  public icon: string = null;

  @Input()
  public title: string = null;

  @Input()
  public value: number = null;

  constructor() {

  }
}
