import { Component, Input } from '@angular/core';

import { Attribute } from 'app/shared/interfaces';


@Component({
  selector: 'app-attribute-badge',
  templateUrl: './attribute-badge.component.html',
  styleUrls: ['./attribute-badge.component.scss']
})
export class AttributeBadgeComponent {
  @Input() public attribute: Attribute = null;

}
