<mat-form-field class="mat-fullwidth">
  <mat-label>Country</mat-label>
        <input
    matInput
    name="country"
    #selectedCountryRef="ngModel"
    [(ngModel)]="selectedCountry"
    (ngModelChange)="loadCountries($event)"
    [matAutocomplete]="countryInput"
    [fsFormFunction]="validationFunction"
    />
  <mat-autocomplete #countryInput="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of countries" [value]="item">
      <span>{{ item.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field *ngIf="countryRegions.length" class="mat-fullwidth">
  <mat-label>Province/State</mat-label>
        <input
    matInput
    name="region"
    #selectedRegionRef="ngModel"
    [(ngModel)]="selectedRegion"
    (ngModelChange)="loadRegions($event)"
    [matAutocomplete]="regionInput"
    />
  <mat-autocomplete #regionInput="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of regions" [value]="item">
      <span>{{ item.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field *ngIf="!countryRegions.length" class="mat-fullwidth">
  <mat-label>Province/State</mat-label>
<input matInput [(ngModel)]="data.province" name="province">
</mat-form-field>
