import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { forEach } from 'lodash';

import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FsMessage } from '@firestitch/message';
import { list } from '@firestitch/common';

import { AccountService, AclRoleService } from '../../../core/services';
import { AccountRequest, Account, AclRole, Project } from '../../../shared/interfaces';


@Component({
  selector: 'app-account-request',
  templateUrl: './account-request.component.html',
  styleUrls: ['./account-request.component.scss']
})
export class AccountRequestComponent implements OnInit {

  public accountRequest: AccountRequest = { id: null };
  public selectedAccounts: Account[] = [];

  public account: Account = {};


  public globalRoles: AclRole[] = null;
  public projectRoles: AclRole[] = null;
  public selectedRoles: AclRole[] = [];

  public projects: Project[] = null;
  public selectedProjects: Project[] = [];

  public mode: 'global'|'project' = 'global';
  public type: 'invite'|'full' = 'invite';
  public action: 'invite'|'create'|'activate' = 'invite';

  public sendActivationEmail = false;

  public search = query => {
    return this.accountService.gets(query);
  }

  constructor(
    private dialogRef: MatDialogRef<AccountRequestComponent>,
    private fsMessage: FsMessage,
    private accountService: AccountService,
    private aclRoleService: AclRoleService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.accountRequest = data.accountRequest ? data.accountRequest : this.accountRequest;
    this.type = data.type ? data.type : 'invite';
  }

  public ngOnInit() {

    this.mode = 'global';
    this.aclRoleService.gets().subscribe(response => this.globalRoles = response);
  }

  public changeMode() {
    this.selectedRoles = [];
    this.selectedProjects = [];
  }

  public save() {

    const aclRoleIds = list(this.selectedRoles, 'id');
    const requestRolesArray = [];

    if (this.selectedProjects.length) {

      const requestedProjects = [];

      forEach(this.selectedProjects, project => {
        requestedProjects.push({ aclRoleIds: aclRoleIds, projectId: project.id });
      });

      requestRolesArray['projects'] = requestedProjects;

    } else {
      requestRolesArray['acl_role_ids'] = aclRoleIds;
    }

    if (this.action === 'invite') {

      if (!this.selectedAccounts.length) {
        return;
      }

      let source$ = of(true);

      forEach(this.selectedAccounts, account => {
        const query = this.accountService
          .postInvites(Object.assign(
            { email: account.email },
            this.accountRequest,
            requestRolesArray
          ));

        // Subscription fires 1 time. Replace switchMap with concat to fire for all queries
        source$ = source$.pipe(
          switchMap(() => query)
        );
      });

      source$
        .subscribe(response => {
          this.fsMessage.success('Successfully sent invitations');
          this.close(response);
        });
    } else if (this.action === 'create' || this.action === 'activate') {

      const query = {
        firstName: this.account.firstName,
        lastName: this.account.lastName,
        email: this.account.email,
        sendActivationEmail: this.sendActivationEmail && this.action === 'create',
        activate: this.action === 'activate'
      };

      Object.assign(query, requestRolesArray);

      this.accountService.createAccount(query)
        .subscribe(response => {
          this.fsMessage.success('Successfully created account');
          this.close(response);
        });
    }
  }

  public close(data = null) {
    this.dialogRef.close(data);
  }

}
