import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';
import { FsStore } from '@firestitch/store';

import { Observable } from 'rxjs';

import { AclRole } from 'app/shared';
import { StateBase } from 'app/shared/types';


@Injectable()
export class EnvironmentService {

  public environment$: Observable<any> = this._fsStore.observe('environment');

  public states = [
    { name: 'Active', value: StateBase.Active },
    { name: 'Deleted', value: StateBase.Deleted }
  ];

  constructor(
    private fsApi: FsApi,
    private _fsStore: FsStore
    ) { }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(environmentId, data: any = {}): Observable<any> {
    return this.fsApi.get(`environments/${environmentId}`, data, { key: 'environment' });
  }

  public gets(data: any = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `environments`, data, Object.assign({ key: 'environments' }, config));
  }

  public put(environment, config = {}): Observable<any> {
    return this.fsApi.put(`environments/${environment.id}`, environment, Object.assign({ key: 'environment' }, config));
  }

  public post(environment): Observable<any> {
    return this.fsApi.post(`environments`, environment, { key: 'environment' });
  }

  public delete(environment): Observable<any> {
    return this.fsApi.delete(`environments/${environment.id}`, environment, { key: 'environment' });
  }

  public switch(accountId: number, environmentId: number): Observable<any> {
    return this.fsApi.put(`accounts/${accountId}/environments/${environmentId}/switch`, null, Object.assign({ key: 'environment' }));
  }

  public image(environment, file): Observable<any> {
    return this.fsApi.post(`environments/${environment.id}/image`, { file: file.file || file }, { key: 'environment' });
  }

  public getAccounts(environmentId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `environments/${environmentId}/accounts`, data,
      Object.assign({ key: 'environmentAccounts' }, config));
  }

  public getAccount(environmentId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `environments/${environmentId}/accounts/${data.accountId}`, data,
      Object.assign({ key: 'environmentAccount' }, config));
  }

  public putAccounts(environmentId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.put(`environments/${environmentId}/accounts/${data.accountId}`,
      data, Object.assign({ key: 'objectAccount' }, config));
  }

  public deleteAccounts(environmentId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.delete(`environments/${environmentId}/accounts/${data.accountId}`,
      data, Object.assign({ key: 'objectAccount' }, config));
  }

  public getRole(environmentId: number, roleId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `environments/${environmentId}/roles/${roleId}`, data, Object.assign({ key: 'aclRole' }, config));
  }

  public getRoles(environmentId: number, data: any = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `environments/${environmentId}/roles`, data, Object.assign({ key: 'aclRoles' }, config));
  }

  public deleteRole(environmentId: number, role: AclRole): Observable<any> {
    return this.fsApi.delete(`environments/${environmentId}/roles/${role.id}`, role, { key: 'aclRole' });
  }

  public updateRole(environmentId: number, role: AclRole): Observable<any> {
    return this.fsApi.put(`environments/${environmentId}/roles/${role.id}`, role, { key: 'aclRole' });
  }

  public postRole(environmentId: number, role: AclRole): Observable<any> {
    return this.fsApi.post(`environments/${environmentId}/roles`, role, { key: 'aclRole' });
  }

  public saveRole(environmentId: number, data): Observable<any> {
    if (data.id) {
      return this.updateRole(environmentId, data);
    }
    return this.postRole(environmentId, data);
  }

  public assignRoles(environmentId, accountId, data: any = {}, config = {}): Observable<any> {
    data.accountId = accountId;
    return this.fsApi.put(`environments/${environmentId}/entries/bulk`,
      data, Object.assign({ key: null }, config));
  }

}
