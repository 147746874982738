<footer *ngIf="items">
  <div class="button-group">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.hide">
        <button
          *ngIf="item.link"
          mat-icon-button
          [disableRipple]="true"
          [routerLink]="item.link"
          routerLinkActive
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: item.exact }"
          [ngClass]="{ active: rla.isActive }"
          (click)="item.click()">
            <ng-container *ngTemplateOutlet="buttonTemplate;context: { item: item, rla: rla }"></ng-container>
        </button>
        <button
          *ngIf="!item.link"
          mat-icon-button
          [disableRipple]="true"
          (click)="item.click()">
            <ng-container *ngTemplateOutlet="buttonTemplate;context: { item: item }"></ng-container>
        </button>
      </ng-container>
    </ng-container>
  </div>
</footer>

<ng-template #buttonTemplate let-item="item" let-rla="rla">
  <div class="button-template">
    <img *ngIf="item.image; else svgIcon" src="{{ item.image }}">

    <ng-template #svgIcon>
      <div
        *ngIf="item.iconSvg; else matSvgIcon"
        class="svg-icon"
        [innerHTML]="item.iconSvg">
      </div>
    </ng-template>

    <ng-template #matSvgIcon>
      <mat-icon [svgIcon]="rla && rla.isActive ? item.iconActive : item.icon"></mat-icon>
    </ng-template>
    
    <span>{{ item.title }}</span>
  </div>
</ng-template>
