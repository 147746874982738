import { CollectionOwnerType } from '@app/shared/types';
import { Organization } from '@app/shared/interfaces';

import { ItemOrganizationModel } from './item-organization.model';

export class ItemCollectionModel {

  public description: string;
  public itemsCount: number;
  public ownerType: string;
  public ownerObjectId: number;
  public ownerObject: any;
  public organization: ItemOrganizationModel;

  constructor(data: any = {}) {
    this._init(data);
  }

  public get isOwnerTaster() {
    return this.ownerType === CollectionOwnerType.Taster;
  }

  public get isOwnerOrganization() {
    return this.ownerType === CollectionOwnerType.Organization;
  }

  private _init(data) {
    this.description = data.description || null;
    this.itemsCount = data.itemsCount || 0;
    this.ownerType = data.ownerType || null;
    this.ownerObjectId = data.ownerObjectId || null;
    this.ownerObject = data.ownerObject || null;
    this.organization = data.organization ? new ItemOrganizationModel(data.organization) : null;
  }

}
