import { Image } from './image';


export interface Environment {
  readonly id?: number;
  name?: string;
  createDate?: string;
  state?: string;
  guid?: string;
  imageTime?: number;
  imageType?: string;
  image?: Image;
}
