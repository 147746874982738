<h2> Styling Headers Guid </h2>

<div fxLayout="column">
  <div fxLayout="row">
    <div fxFlex="50%"> jumbo-heading </div>
    <div fxFlex class="jumbo-heading"> Sample Text </div>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> super-heading </div>
    <div fxFlex class="super-heading"> Sample Text </div>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> h1</div>
    <h1 fxFlex> Sample Text </h1>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> h2</div>
    <h2 fxFlex> Sample Text </h2>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> subheading-1</div>
    <div fxFlex class="subheading-1"> Sample Text </div>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> subheading-2</div>
    <div fxFlex class="subheading-2"> Sample Text </div>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> b</div>
    <b fxFlex> Sample Text </b>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> b-light </div>
    <b fxFlex class="light"> Sample Text </b>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> small </div>
    <small fxFlex> Sample Text </small>
  </div>

  <div fxLayout="row">
    <div fxFlex="50%"> a </div>
    <a fxFlex> Sample Text </a>
  </div>
</div>

