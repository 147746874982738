import { Component, Input, OnInit } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'app-item-detail-heading',
  templateUrl: './detail-heading.component.html',
  styleUrls: ['./detail-heading.component.scss']
})
export class DetailHeadingComponent implements OnInit {

  private _item: Item = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public get item() {
    return this._item;
  }

  @Input() public showRating = true;

  constructor() {

  }

  public ngOnInit() {

  }

}
