import { AwardOrganization } from './award-organization';

export interface Award {
  readonly id: number;
  awardOrganizationId?: number;
  awardOrganization?: AwardOrganization;
  name?: string;
  state?: string;
  class?: string;
}
