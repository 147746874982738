import { NgModule } from '@angular/core';

import { Angulartics2Module } from 'angulartics2';

import { SharedModule } from '@app/shared';
import { ItemModule } from '@app/taster/modules/item/item.module';

import {
  ExperienceActionPaneComponent
} from './components';


@NgModule({
    imports: [
        Angulartics2Module,
        SharedModule,
        ItemModule
    ],
    declarations: [
        ExperienceActionPaneComponent
    ],
    exports: [
        ExperienceActionPaneComponent
    ]
})
export class ExperienceActionPaneModule { }
