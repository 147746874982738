import { Component } from '@angular/core';


@Component({
  templateUrl: './wide.component.html',
  styleUrls: ['./wide.component.css']
})
export class WideComponent {

}
