import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoComponent } from './components';


@NgModule({
  imports: [
    CommonModule,

  ],
  declarations: [
    LogoComponent,
  ],
  exports: [LogoComponent]
})
export class LogoModule { }
