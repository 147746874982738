import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ShareMenuDialogComponent } from '../components';
import { ShareMenuService } from '../../share-menu/services/share-menu.service';


@Injectable({
  providedIn: 'root',
})
export class ShareMenuDialogService {

  constructor(
    private _shareMenu: ShareMenuService,
    private _dialog: MatDialog,
  ) {
  }

  public openMenu(item) {
    this._dialog.open(ShareMenuDialogComponent, {data: {item: item}, width: '350px'});
  }
}
