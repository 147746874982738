import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';
import { AuthService } from './auth.service';
import { FsStore } from '@firestitch/store';


@Injectable()
export class SignupService {

  constructor(private _fsApi: FsApi,
              private _authService: AuthService,
              private _fsStore: FsStore) { }

  public postEmail(data, query = {}): Observable<any> {

    const visit_id = this._fsStore.get('visit_id');
    if (visit_id) {
      data.visitId = visit_id;
    }

    const taste_test_id = this._fsStore.get('taste_test_id');
    if (taste_test_id) {
      data.tasteTestId = taste_test_id;
    }

    return this._authService.processResponse(this._fsApi.post(`signup/email`, data));
  }

  public validateEmail(data, query = {}): Observable<any> {
    return this._fsApi.post(`signup/email/validate`, data);
  }

  public postFacebook(access_token, data: any = {}): Observable<any> {

    const visit_id = this._fsStore.get('visit_id');
    if (visit_id) {
      data.visitId = visit_id;
    }

    const taste_test_id = this._fsStore.get('taste_test_id');
    if (taste_test_id) {
      data.tasteTestId = taste_test_id;
    }

    data.accessToken = access_token;

    return this._authService.processResponse(this._fsApi.post(`signup/facebook`, data));
  }

  public postGoogle(id_token, access_token, data: any= {}): Observable<any> {

    const visit_id = this._fsStore.get('visit_id');
    if (visit_id) {
      data.visitId = visit_id;
    }

    data.idToken = id_token;
    data.accessToken = access_token;

    const taste_test_id = this._fsStore.get('taste_test_id');
    if (taste_test_id) {
      data.tasteTestId = taste_test_id;
    }

    return this._authService.processResponse(this._fsApi.post(`signup/google`, data));
  }

  public postApple(id_token, access_token, data: any= {}): Observable<any> {

    const visit_id = this._fsStore.get('visit_id');
    if (visit_id) {
      data.visitId = visit_id;
    }

    data.idToken = id_token;
    data.accessToken = access_token;

    const taste_test_id = this._fsStore.get('taste_test_id');
    if (taste_test_id) {
      data.tasteTestId = taste_test_id;
    }

    return this._authService.processResponse(this._fsApi.post(`signup/apple`, data));
  }

}
