<ng-container *ngIf="experience">
  <div *ngIf="experience.pricingModel === 'free'">
    <b> {{ pricingModelsFlatten['free'] }} </b>
  </div>
  <div *ngIf="experience.pricingModel === 'flat'">
    <b> {{ experience.priceFrom | fsFormatCurrency: 2 }} </b>
  </div>
  <div *ngIf="experience.pricingModel === 'range' && experience.priceFrom && experience.priceTo">
    <b> {{ experience.priceFrom | fsFormatCurrency: 2 }} - {{ experience.priceTo | fsFormatCurrency: 2 }} </b>
  </div>
</ng-container>
