import {
  AttributeColor,
  AttributeImage,
  AttributeOrder,
  FsAttributeConfig
} from '@firestitch/attribute';
import { FlatItemNode } from '@firestitch/tree';
import { list } from '@firestitch/common';
import { FsPrompt } from '@firestitch/prompt';
import { FsMessage } from '@firestitch/message';

import { concat, of, Observable } from 'rxjs';
import { concatAll, map, switchMap } from 'rxjs/operators';

import { AttributeService } from '../services';
import { sortBy } from 'lodash-es'

// Fields
import { AttributeListVisibleComponent } from '../../attribute-fields/list-visible';
import { AttributeEditVisibleComponent } from '../../attribute-fields/edit-visible';
import { AttributeEditMonthsComponent } from '../../attribute-fields/edit-months';
import { AttributeListUseSparklingComponent } from '../../attribute-fields/list-use-sparkling';
import { AttributeEditUseSparklingComponent } from '../../attribute-fields/edit-use-sparkling';
import { AttributeEditFlavourProfileImageComponent } from '@app/attribute-fields/edit-flavour-profile-image';
import { AttributeEditFlavourProfileDescriptionComponent } from '@app/attribute-fields/edit-flavour-profile-description';
import { AttributeEditRegionalReportingComponent } from '@app/attribute-fields/edit-regional-reporting';
import { AttributeListRegionalReportingComponent } from '@app/attribute-fields/list-regional-reporting';

// @TODO this config need some love <3
export function attributeConfigFactory(attrService: AttributeService, prompt: FsPrompt, message: FsMessage): FsAttributeConfig {

  const wineTypeClass = attrService.getClass('wine_type');
  const wineVarietyClass = attrService.getClass('wine_variety');
  const wineClassification = attrService.getClass('wine_classification');
  const wineFlavourProfileClass = attrService.getClass('wine_flavour_profile');
  const wineFlavourClass = attrService.getClass('wine_flavour');
  const wineFinishesClass = attrService.getClass('wine_finish');
  const wineColorTypeClass = attrService.getClass('wine_color_type');
  const wineColorClass = attrService.getClass('wine_color');
  const wineAgingClass = attrService.getClass('wine_aging');
  const winePairingClass = attrService.getClass('wine_pairing');
  const wineStylesClass = attrService.getClass('wine_style');
  const languageClass = attrService.getClass('language');
  const featureClass = attrService.getClass('feature');
  const organisationClassificationClass = attrService.getClass('organization_classification');
  const popularNoteClass = attrService.getClass('popular_note');


  const config = {
    configs: [
      {
        class: wineTypeClass.value,
        name: wineTypeClass.name,
        pluralName: wineTypeClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
        childClass: wineVarietyClass.value,
        fields: [
          {
            label: 'Use Sparkling Sweetness Scale for these Wines',
            listComponent: AttributeListUseSparklingComponent,
            editComponent: AttributeEditUseSparklingComponent
          },
          {
            label: 'Only available for regional reporting',
            listComponent: AttributeListRegionalReportingComponent,
            editComponent: AttributeEditRegionalReportingComponent
          }
        ],
      },
      {
        class: wineVarietyClass.value,
        name: wineVarietyClass.name,
        pluralName: wineVarietyClass.pluralName,
        order: AttributeOrder.Alphabetical,
        fields: [],
      },
      {
        class: languageClass.value,
        name: languageClass.name,
        pluralName: languageClass.pluralName,
        order: AttributeOrder.Custom,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Disabled,
        fields: [],
      },
      {
        class: wineClassification.value,
        name: wineClassification.name,
        pluralName: wineClassification.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
        fields: [
          {
            label: 'Show on List Item in Taster Portal',
            listComponent: AttributeListVisibleComponent,
            editComponent: AttributeEditVisibleComponent
          }
        ],
      },

      // Flavours & Profiles
      {
        class: wineFlavourProfileClass.value,
        name: wineFlavourProfileClass.name,
        pluralName: wineFlavourProfileClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
        childClass: wineFlavourClass.value,
        fields: [
          {
            label: 'Description',
            editComponent: AttributeEditFlavourProfileDescriptionComponent,
          },
          {
            label: 'Small Image',
            editComponent: AttributeEditFlavourProfileImageComponent
          },
        ],
      },
      {
        class: wineFlavourClass.value,
        name: wineFlavourClass.name,
        pluralName: wineFlavourClass.pluralName,
        order: AttributeOrder.Alphabetical,
        image: AttributeImage.Parent,
        fields: []
      },

      // Finishes
      {
        class: wineFinishesClass.value,
        name: wineFinishesClass.name,
        pluralName: wineFinishesClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Alphabetical,
        fields: [],
      },

      // Colors
      {
        class: wineColorTypeClass.value,
        name: wineColorTypeClass.name,
        pluralName: wineColorTypeClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Enabled,
        order: AttributeOrder.Custom,
        childClass: wineColorClass.value,
        fields: [],
      },
      {
        class: wineColorClass.value,
        name: wineColorClass.name,
        pluralName: wineColorClass.pluralName,
        order: AttributeOrder.Custom,
        fields: [],
      },

      // Aging
      {
        class: wineAgingClass.value,
        name: wineAgingClass.name,
        pluralName: wineAgingClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Alphabetical,
        selectedOrder: true,
        fields: [
          {
            label: 'Months',
            editComponent: AttributeEditMonthsComponent,
          }
        ],
      },
      // Pairings
      {
        class: winePairingClass.value,
        name: winePairingClass.name,
        pluralName: winePairingClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
      },
      // Styles
      {
        class: wineStylesClass.value,
        name: wineStylesClass.name,
        pluralName: wineStylesClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
      },

      // Feature
      {
        class: featureClass.value,
        name: featureClass.name,
        pluralName: featureClass.pluralName,
        image: AttributeImage.Enabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Alphabetical,
      },


      // Organisation Classification
      {
        class: organisationClassificationClass.value,
        name: organisationClassificationClass.name,
        pluralName: organisationClassificationClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
        fields: [
          {
            label: 'Show on List Item in Taster Portal',
            listComponent: AttributeListVisibleComponent,
            editComponent: AttributeEditVisibleComponent
          }
        ],
      },

      // Popular Notes
      {
        class: popularNoteClass.value,
        name: popularNoteClass.name,
        pluralName: popularNoteClass.pluralName,
        image: AttributeImage.Disabled,
        backgroundColor: AttributeColor.Disabled,
        color: AttributeColor.Disabled,
        order: AttributeOrder.Custom,
      },
      /*
      {
          class: 'everything', // type
          image: AttributeImage.Enabled,
          backgroundColor: AttributeColor.Enabled,
          color: AttributeColor.Enabled,
          name: 'Person',
          pluralName: 'People',
          order: AttributeOrder.Custom,
          fields: [
          {
              label: 'Visible on List',
              listComponent: ListVisibleComponent,
              editComponent: EditVisibleComponent
          }
          ]
      },
      {
          class: 'aroma_type',
          image: AttributeImage.Enabled,
          backgroundColor: AttributeColor.Enabled,
          // color: AttributeColor.Disabled,
          order: AttributeOrder.Custom,
          name: 'Aroma Type',
          pluralName: 'Aroma Types',
          childClass: 'aroma',
          fields: [
          {
              label: 'Visible on List',
              listComponent: ListVisibleComponent,
              editComponent: EditVisibleComponent
          }
          ],
      },
      {
          class: 'aroma',
          // image: AttributeColor.Disabled,
          // backgroundColor: AttributeColor.Parent,
          // color: AttributeColor.Disabled,
          order: AttributeOrder.Alphabetical,
          name: 'Aroma',
          pluralName: 'Aromas',
      },
      {
          class: 'background-color',
          backgroundColor: AttributeColor.Enabled,
          name: 'Background Color',
          pluralName: 'Background Colors',
      }
      */
    ],
    mapping: {
      id: 'id',
      name: 'name',
      image: 'image.small',
      backgroundColor: 'backgroundColor',
      childAttributes: 'childAttributes',
      parentAttribute: 'parentAttribute',
      configs: 'configs',
    },
    getAttributeTree: (e) => {

      const query = {
        class: e.class,
        childAttributes: true,
        order: 'order'
      };

      const attrConfig = config.getAttributeConfig(e.class);
      if (attrConfig.order === AttributeOrder.Alphabetical) {
        query.order = 'name,asc';
      } else if (attrConfig.order === AttributeOrder.Custom) {
        query.order = 'order,asc';
      }

      return attrService.gets(query).pipe(
        switchMap((data) => {
          return of({attributes: data})
        })
      );
    },
    reorderAttributeTree: (event: any) => {

      const requests: any = [];

      if (event.toParent) {
        const moveRequest = attrService.put({
          ...event.attribute,
          parentAttributeId: event.toParent,
        });

        const orderRequest = attrService.order({
          class: event.class,
          parentAttributeId: event.toParent,
          attributeIds: event.childIds,
        });

        requests.push(moveRequest, orderRequest);
      } else {
        const orderRequest = attrService.order({
          class: event.class,
          attributeIds: event.parentIds,
        });

        requests.push(orderRequest);
      }

      return concat(requests).pipe(concatAll());
    },
    canDropTreeAttribute: (
      node?: FlatItemNode,
      fromParent?: FlatItemNode,
      toParent?: FlatItemNode,
      dropPosition?: any,
      prevElement?: FlatItemNode,
      nextElement?: FlatItemNode
    ) => {

      // Sorting Rule
      const prevElSortCoimplied = prevElement && prevElement.data.name < node.data.name || !prevElement;
      const nextElSortCoimplied = nextElement && node.data.name < nextElement.data.name || !nextElement;
      const compliedWithSort = prevElSortCoimplied && nextElSortCoimplied;

      return (!fromParent && !toParent) ||
        (fromParent && toParent && fromParent.data.class === toParent.data.class && compliedWithSort);
    },
    sortByAttributeTree: (data, parent) => {

      if (data[0]) {
        data = config.sortAttributes(data[0].class, data);
        data.forEach((element, idx) => {
          if (data[idx].childAttributes && data[idx].childAttributes[0]) {
            data[idx].childAttributes = config.sortAttributes(data[idx].childAttributes[0].class, data[idx].childAttributes);
          }
        });
      }


      if (!parent) { return data; }

      return data.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (b.name < b.name) { return 1; }

        return 0;
      });
    },
    saveAttribute: (e, draft = false) => {

      const state = draft ? 'draft' : 'active';

      const attribute = e.attribute;
      attribute.class = e.class;

      switch (e.class) {
        case attrService.ClassWineVariety: {
          if (e.parent) {
            attribute.parentAttributeId = e.parent.id;
          }
        } break;

        case attrService.ClassWineFlavour: {
          if (e.parent) {
            attribute.parentAttributeId = e.parent.id;
          }
        } break;

        case attrService.ClassWineColor: {
          if (e.parent) {
            attribute.parentAttributeId = e.parent.id;
          }
        } break;


      }

      attribute.state = state;

      if (!e.attribute.id) {
        return attrService.post(attribute).pipe(
          switchMap((data) => {
            return of({ attribute: data })
          })
        );
      } else {
        return attrService.put(attribute).pipe(
          switchMap((data) => {
            return of({ attribute: data })
          })
        )
      }
    },
    saveAttributeImage: (e) => {
      if (e.attribute.id) {
        return attrService
          .image({ id: e.attribute.id }, e.file.file);
      } else {
        e.attribute.state = 'draft';

        return config.saveAttribute(e, !e.attribute.id)
          .pipe(
            switchMap((response: any) => {
              const attribute = response.attribute;

              return attrService
                .image({ id: attribute.id }, e.file.file);
            })
          );
      }
    },
    getAttributes: (e) => {

      const query = {
        ...e.query,
        class: e.class,
        queryConfigs: e.queryConfigs
      };


      const attrConfig = config.getAttributeConfig(e.class);
      if (attrConfig.order === AttributeOrder.Alphabetical) {
        query.order = 'name,asc';
      } else if (attrConfig.order === AttributeOrder.Custom) {
        query.order = 'order,asc';
      }


      if (e.keyword) {
        query.keyword = e.keyword;
      }

      if (e.data && e.data.childAttributes) {
        query.childAttributes = !!e.data.childAttributes


        const childAttrConfig = config.getAttributeConfig(attrConfig.childClass);
        if (childAttrConfig) {
          if (childAttrConfig.order === AttributeOrder.Alphabetical) {
            query.childOrder = 'name,asc';
          } else if (childAttrConfig.order === AttributeOrder.Custom) {
            query.childOrder = 'order,asc';
          }
        }

      }

      if (e.data && e.data.excludeEmpty) {
        query.excludeEmptyParentAttributes = !!e.data.excludeEmpty
      }

      if (e.data && e.data.parentAttributes) {
        query.parentAttributes = !!e.data.parentAttributes;
      }

      return attrService.gets(query,
      {
        key: null
      }).pipe(
        map((response) => {
          return { data: response.attributes, paging: response.paging };
        })
      );

      /*return of({ data: filteredAttributes, paging: { records: attributesStore.length, limit: 10 } });
      *!/
      return of({ data: null, paging: { records: null, limit: null } });*/
    },
    getSelectedAttributes: (e) => {

      const query = {
        ...e.query,
        class: e.class,
        parentAttributes: !!e.parentClass,
      };


      // there is probally a better way to add this order param to query.
      const attrConfig = config.configs.find(eachConfig => eachConfig.class === e.class);

      if (attrConfig.selectedOrder) {
        query.order = 'order,asc';
      } else {
        if (attrConfig.order === AttributeOrder.Alphabetical) {
          query.order = 'name,asc';
        } else if (attrConfig.order === AttributeOrder.Custom) {
          query.order = 'order,asc';
        }
      }


      if (e.data && e.data.childAttributes) {
        query.childAttributes = !!e.data.childAttributes
      }

      if (e.data && e.data.parentAttributes) {
        query.parentAttributes = !!e.data.parentAttributes;
      }

      return attrService.getObjectAttributes(e.data.objectId, query,
      {
        key: null
      }).pipe(
        map((response) => {
          return { data: response.attributes, paging: response.paging };
        })
      );
    },
    reorderAttributes: (e) => {

      return attrService.order({
        class: e.class,
        attributeIds: list(e.attributes, 'id')
      });
      /*
      attributesStore = e.attributes;
      return of({ attributes: e.attributes });
      */
      // return of({ attributes: null });
    },
    attributeSelectionChanged: (e) => {
      if (e.data.disableAutoSave) {
        return of({ attribute: e.attribute })
      }

      const attribute = e.value;
      return new Observable(observer => {

        if (e.selected) {
          attrService.assignObject(attribute.id, e.data.objectId)
            .subscribe(response => {
              message.success('Saved Changes');
              observer.next({ attribute: attribute });
              observer.complete();
            });
          return;
        }

        attrService.deleteObject(attribute.id, e.data.objectId)
          .subscribe(response => {
            message.success('Saved Changes');
            observer.next({ attribute: attribute });
            observer.complete();
          });
      });

      // return of({ attribute: e.attribute });
    },
    deleteAttribute: (e) => {

      return attrService.delete({ id: e.id })
        .pipe(
          switchMap((data) => {
            return of({ attribute: data })
          }),
        );
    },
    deleteConfirmation: (event) => {

      let templateName;

      switch (event.class) {
        case attrService.ClassWineType: {
          templateName = 'Deleting this Wine Type will also delete all of the Wine Varieties that belong to it.\n\n' +
          'Are you sure you would like to delete this Wine Type?';
        } break;

        case attrService.ClassWineFlavourProfile: {
          templateName = 'Deleting this Flavour Profile will also delete all of the Flavours that belong to it.\n\n' +
          'Are you sure you would like to delete this Flavour Profile?';
        } break;

        case attrService.ClassWineColorType: {
          templateName = 'Deleting this Colour Profile will also delete all of the Colours that belong to it.\n\n' +
          'Are you sure you would like to delete this Flavour Colour?';
        } break;

        default: {
          const klass = attrService.getClass(event.class);
          templateName = `Are you sure you would like to delete this ${klass ? klass.name : 'Undefined'}?`
        }
      }

      return prompt.confirm({
        title: 'Confirm',
        template: templateName
      })
    },
    compareAttributes(o1, o2) {
      return o1 && o2 && o1.id === o2.id;
    },
    getAttributeConfig(klass) {
      const attributeConfig = config.configs.find(eachConfig => {
        return eachConfig.class === klass;
      });
      return attributeConfig;
    },
    sortAttributes(klass, attributes) {
      const attrConfig = this.getAttributeConfig(klass);
      if (attrConfig.order === AttributeOrder.Alphabetical) {
        return sortBy(attributes, (o) => {
          return o.name.toLowerCase ? o.name.toLowerCase() : o.name.toString().toLowerCase();
        });
      } else {
        return attributes;
      }
    }

  };

  return config;
}
