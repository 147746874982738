import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';
import { guid } from '@firestitch/common';
import { environment } from 'environments/environment';
import { DynamicLinkService } from 'app/core/services/dynamic-link.service';

@Injectable()
export class ShareService {

  constructor(
    private _fsApi: FsApi,
    private _dynamicLinkService: DynamicLinkService) { }

  public post(data, options: any = {}): Observable<any> {
    return this._fsApi.post('share', data, Object.assign({ key: '' }, options));
  }


  public getShareData(object_id, path) {

    const code = guid();
    const opengraphUrl = 'https://' + environment.apiDomain + environment.apiPath.concat('opengraph/' + object_id) + '?path=' + path + '&tc=' + code;

    const dynamicUrl = this._dynamicLinkService.url(opengraphUrl);
    return {
      code: code,
      url: dynamicUrl
    }
  }
}
