import { FsAnalyticsConfig } from '@firestitch/analytics';

import { Injector } from '@angular/core';
import { SettingService } from '../services';


export function appAnalyticsConfig(injector: Injector): FsAnalyticsConfig {
  const config: FsAnalyticsConfig = { providers: {} };
  const settingService = injector.get(SettingService);
  if(settingService.settingsData['other'].googleAnalyticsMeasurementId) {
    config.providers = {
      googleTagManager: {
        containerId: settingService.settingsData['other'].googleAnalyticsMeasurementId,
      }
    };
  }

  return config;
}
