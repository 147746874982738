import { Project } from './project';
import { Account } from './account';


export interface Object {
  readonly id: number;
  class?: string;
  state?: string;
  className?: string;
  name?: string;
  description?: string;
  imageUrl?: string;
  content?: string;
  projectId?: number;
  project?: Project;
  modifierAccount?: Account;
  identifier?: string;
  primaryKeyword?: string;
  secondaryKeyword?: string;
  modifyDate?: string;
  createDate?: string;
  number?: any;
  meta?: any;
  objects?: Object[];
  relatedObjectHighlight?: number;
}
