import {
  isAfter,
  isBefore,
  format,
} from 'date-fns';
import { date } from '@firestitch/date';
import { Address, Organization } from '@app/shared';

import { ItemOrganizationModel } from './item-organization.model';


export class ItemExperienceModel {
  public pricingModel: string;
  public priceFrom: number;
  public priceTo: number;
  public location: string;
  public address: Address;
  public phone: string;
  public email: string;
  public website: string;
  public reservationRequired: number;
  public actionButtonUrl: string;
  public actionButtonLabel: string;

  public organization: ItemOrganizationModel;
  public availabilityLabel: string;

  private _dateFormat = 'MMM d, Y';

  constructor(data: any = {}) {
    this._init(data);
  }

  public get isAddressEmpty() {
    if (!this.address
      || !this.address.city
      && !this.address.street
      && !this.address.region
      && !this.address.zip) {
      return true;
    }

    return false;
  }

  private _init(data) {
    this.pricingModel = data.pricingModel || null;
    this.priceFrom = data.priceFrom || null;
    this.priceTo = data.priceTo || null;
    this.address = data.address || null;
    this.phone = data.organization.phone || null;
    this.email = data.organization.email || null;
    this.website = data.organization.website || null;
    this.reservationRequired = data.reservationRequired || null;
    this.actionButtonUrl = data.actionButtonUrl || null;
    this.actionButtonLabel = data.actionButtonLabel || null;
    this.organization = data.organization ? new ItemOrganizationModel(data.organization) : null;

    this._getExperienceAvailability(data);
    this._setLocation();
  }

  private _getExperienceAvailability(data) {

    const openDate = date(data.openDate);
    const closedDate = date(data.closedDate);
    const curentDate = date(new Date());

    if (isAfter(openDate, curentDate)) {
      this.availabilityLabel = 'Opening ' + format(openDate, this._dateFormat);
    } else if (isBefore(closedDate, curentDate)) {
      this.availabilityLabel = 'Closed ' + format(closedDate, this._dateFormat);
    } else {
      this.availabilityLabel = data.availability;
    }
  }

  private _setLocation() {
    this.location = '';

    if (!this.address) {
      return;
    }

    if (this.address.city) {
      this.location += this.address.city;
      this.location += this.address.region ? ', ' : '';
    }

    if (this.address.region) {
      this.location += this.address.region;
      this.location += this.address.country ? ', ' : '';
    }

    if (this.address.country) {
      this.location += this.address.country;
    }
  }
}
