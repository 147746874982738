import { Component, Input, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MapService } from '@app/taster/modules/map/services';
import { SettingService } from '@app/core/services';
import { Mode as MapMode } from '@app/taster/modules/map/enums';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @HostBinding('class.show') classShow = true;

  public items = null;
  public liteMode: boolean;

  @Input('show') set show(value) {
    this.classShow = value;
  }

  private _destroy$ = new Subject();
  
  constructor(
    private _router: Router,
    private _mapService: MapService,
    private _settingService: SettingService,
    private _sanitizer: DomSanitizer,
  ) { }

  public ngOnInit() {
    this._init();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _init(): void {
    forkJoin([
      this._settingService.gets('other', {}, false),
      this._settingService.gets('branding', {}, false)
    ])
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(([otherSettings, brandingSettings]) => {
        this.liteMode = !!+otherSettings.liteMode;

        this.items = [
          {
            title: 'Wines',
            icon: 'tastings',
            iconActive: 'tastings-burgundy',
            exact: false,
            hide: this.liteMode,
            link: '/wines',
            click: () => { },
          },
          {
            title: brandingSettings.brandingOrganizationPageTitle || 'Wineries',
            // icon: 'wineries-grey',
            // iconActive: 'wineries-primary',
            // image: brandingSettings.brandingOrganizationPageLogo,
            iconSvg: this._sanitizer.bypassSecurityTrustHtml(brandingSettings.logos.brandingOrganizationPageLogo),
            exact: false,
            link: '/wineries',
            click: () => { },
          },
          {
            title: 'Map',
            icon: 'map',
            iconActive: 'map',
            exact: false,
            link: '/map',
            click: () => { },
          },
          {
            title: brandingSettings.brandingActivitiesPageTitle || 'To Do',
            // icon: 'to-do-grey',
            // iconActive: 'to-do-primary',
            // image: brandingSettings.brandingActivitiesPageLogo,
            iconSvg: this._sanitizer.bypassSecurityTrustHtml(brandingSettings.logos.brandingActivitiesPageLogo),
            exact: false,
            link: '/todo',
            click: () => { },
          },
          {
            title: brandingSettings.brandingCollectionPageTitle || 'My Cellar',
            // icon: 'my-cellar',
            // iconActive: 'my-cellar-burgundy',
            // image: brandingSettings.brandingCollectionPageLogo,
            iconSvg: this._sanitizer.bypassSecurityTrustHtml(brandingSettings.logos.brandingCollectionPageLogo),
            exact: false,
            link: '/cellar',
            click: () => { },
          }
        ];
      });
  }

}
