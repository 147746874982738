<div class="type-menu" *ngIf="types">

  <ng-container *ngIf="type; else noType">
    <button mat-icon-button [matMenuTriggerFor]="typeMenu" [matTooltip]="type.name">
      <mat-icon [ngStyle]="{ color: type.color }">{{ type.icon }}</mat-icon>
    </button>
  </ng-container>

  <ng-template #noType>
    <div class="empty" [matMenuTriggerFor]="typeMenu"></div>
  </ng-template>

  <mat-menu #typeMenu="matMenu">
    <button mat-menu-item *ngFor="let item of types" (click)="changeType(item)">
      <mat-icon [ngStyle]="{color: item.color}">{{ item.icon }}</mat-icon>
      <span>{{ item.name }}</span>
    </button>
  </mat-menu>
</div>
