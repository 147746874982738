import { Component, Input } from '@angular/core';

import { Item } from '../../models/item.model';


@Component({
  selector: 'app-item-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss']
})
export class CharacteristicsComponent {
  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public get item() {
    return this._item;
  }

  private _item: Item = null;

  constructor() {

  }
}
