import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { of } from 'rxjs';

import { FsMessage } from '@firestitch/message';

import { AwardOrganization } from 'app/shared/interfaces';
import { AwardOrganizationService } from 'app/core/services';


@Component({
  selector: 'app-award-organization-dialog',
  templateUrl: './award-organization-dialog.component.html',
  styleUrls: ['./award-organization-dialog.component.scss']
})
export class AwardOrganizationDialogComponent implements OnInit {

  public awardOrganization: AwardOrganization = null;

  constructor(
    private _dialogRef: MatDialogRef<AwardOrganizationDialogComponent>,
    private _fsMessage: FsMessage,
    private _awardOrganizationService: AwardOrganizationService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {

    (this.data.awardOrganization.id ? this._awardOrganizationService.get(this.data.awardOrganization.id) : of(this.data.awardOrganization))
      .subscribe(response => {
        this.awardOrganization = Object.assign({}, this._awardOrganizationService.create(response));
      });
  }

  public save() {
    this._awardOrganizationService.save(this.awardOrganization)
      .subscribe(response => {
        this._fsMessage.success('Saved Changes');
        this.close(response);
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
