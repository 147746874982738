import { ActivatedRoute } from '@angular/router';

export function routeFullTreeParams(route: ActivatedRoute, params = {}) {
  if (route) {
    params = {...params, ...route.snapshot.params};
  }
  return route.parent
    ? routeFullTreeParams(route.parent, params)
    : params;
}
