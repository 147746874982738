<ng-container *ngIf="item">
  <div class="menu">
    <a authorizedClick [fsMenuTriggerFor]="menu">
      <mat-icon>more_horiz</mat-icon>
      <span>More</span>
    </a>
  </div>

  <fs-menu #menu class="action-kebab-menu">
    <!-- <ng-template fs-menu-item (click)="rate()">
      <mat-icon class="raiting-icon">star</mat-icon>
      Rate
    </ng-template>
    <ng-template fs-menu-item (click)="toggleTryIt()">
      <mat-icon class="tried-icon">check</mat-icon>
      Tried It
    </ng-template>
    <ng-template fs-menu-item (click)="addToList()" *ngIf="!item.isCollection">
      <mat-icon class="added-icon">add</mat-icon>
      Add to List
    </ng-template> -->
    <ng-template fs-menu-item (click)="share()">
      <mat-icon svgIcon="item-share"></mat-icon>
      Share
    </ng-template>
    <ng-template fs-menu-item (click)="rate()">
      <mat-icon class="notes-icon">speaker_notes</mat-icon>
      Add Notes
    </ng-template>
    <ng-container *ngIf="customActions && customActions.length">
      <ng-container *ngFor="let action of customActions">
        <ng-template *ngIf="action.show" fs-menu-item (click)="customAction(action)">
          <mat-icon *ngIf="action.icon" class="notes-icon">{{ action.icon }}</mat-icon>
          <mat-icon *ngIf="action.svgIcon" [svgIcon]="action.svgIcon" class="notes-icon"></mat-icon>
          <span>{{ action.title }}</span>
        </ng-template>
      </ng-container>
    </ng-container>
  </fs-menu>
</ng-container>
