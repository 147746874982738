import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs';
import { Note } from '../../interfaces';


@Injectable({
  providedIn: 'root',
})
export class NotesDataService {

  constructor(private _fsApi: FsApi) {}

  public get(itemId: number): Observable<Note> {
    return this._fsApi.get(`items/${itemId}/notes`, {}, { key: 'note' });
  }

  public put(itemId: number, note: any, config = {}): Observable<Note> {
    return this._fsApi.put(`items/${itemId}/notes/${note.id}`, note, Object.assign({ key: 'note' }, config));
  }

  public post(itemId: number, note: any): Observable<Note> {
    return this._fsApi.post(`items/${itemId}/notes`, note, { key: 'note' });
  }

  public delete(itemId: any): Observable<Note> {
    return this._fsApi.delete(`items/${itemId}`, {}, { key: 'note' });
  }
}
