<fs-dialog>
  <h1 mat-dialog-title>Invites</h1>
  <div mat-dialog-content>
    <fs-list [config]="config" #list>

      <fs-list-column title="Email">
        <ng-template fs-list-cell let-row="row">
          {{ row.email }}
        </ng-template>
      </fs-list-column>

      <fs-list-column title="Invitation Creator" name="creator_name" [sortable]="true">
        <ng-template fs-list-cell let-row="row">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <fs-badge shape="circle" image="{{ row?.creatorAccount?.image?.small }}"></fs-badge>
            <a *ngIf="hasApp" (click)="openSettings(row)">
              {{ row?.creatorAccount?.firstName }} {{ row?.creatorAccount?.lastName }}
            </a>
            <span *ngIf="!hasApp">{{ row?.creatorAccount?.firstName }} {{ row?.creatorAccount?.lastName }}</span>
          </div>
        </ng-template>
      </fs-list-column>

      <fs-list-column title="Organization" name="organization" *ngIf="!organizationId">
        <ng-template fs-list-cell let-row="row">
          <fs-badge
            *ngIf="row.environment"
            [shape]="'circle'"
            [size]="30"
            [tooltip]="row.environment.name"
            [image]="row.environment.image.small">
          </fs-badge>
        </ng-template>
      </fs-list-column>

      <fs-list-column title="Status" name="state" [sortable]="true">
        <ng-template fs-list-cell let-row="row">
          {{ statesFlatten[row.state] }}
        </ng-template>
      </fs-list-column>
      <fs-list-column title="Created" name="create_date" [sortable]="true">
        <ng-template fs-list-cell let-row="row">
          {{ row.createDate | fsDateAgo }}
        </ng-template>
      </fs-list-column>

      <fs-list-column title="Expires">
        <ng-template fs-list-cell let-row="row">

          <div *ngIf="row.expires > 0">
            {{ row.expiryDate | fsDate: 'date-time-yearless' }}
          </div>
          <div *ngIf="row.expires <= 0">Expired</div>
        </ng-template>
      </fs-list-column>

    </fs-list>
  </div>
  <mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close color="primary">Done</button>
  </mat-dialog-actions>
</fs-dialog>
