import { Injectable, Injector } from '@angular/core';

import { isAfter } from 'date-fns';

import { FsAddress } from '@firestitch/address';
import { FsCookie } from '@firestitch/cookie';
import { parse } from '@firestitch/date';
import { FsStore } from '@firestitch/store';

import { TasteTest } from '@app/shared';
import { Account } from '@app/shared/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';


@Injectable()
export class SessionService {

  constructor(
    private fsStore: FsStore,
    private _injector: Injector,
    private _cookie: FsCookie,
  ) { }

  public set(data) {
    this.fsStore.set('account', data.account);
    this.fsStore.set('permissions', data.permissions);
    this.fsStore.set('environment', data.environment);
  }

  public destroy() {
    this.fsStore.remove('account');
    this.fsStore.remove('permissions');
    this.fsStore.remove('environment');
  }

  public signedIn(): boolean {
    const expiry = parse(this._cookie.get('Token-Expiry'));
    const account = this._injector.get(SessionService).account();

    return !!account && expiry && isAfter(expiry, new Date());
  }

  public searchRecentIds(data: number[] = null) {
    if (data) {
      this.fsStore.set('search_recent_ids', data);
    }

    return this.fsStore.get('search_recent_ids');
  }

  public account(data: Account = null) {
    if (data) {
      this.fsStore.set('account', data);
    }

    return this.fsStore.get('account');
  }

  public accountId() {
    return this.account() ? this.account().id : null;
  }

  public lastPromptDate(data = null) {
    if (data) {
      this.fsStore.set('last_prompt_date', data);
    }

    return this.fsStore.get('last_prompt_date');
  }

  public hasSeenTutorial(data=null) {
    if (data) {
      this.fsStore.set('has_seen_tutorial', data);
    }

    return this.fsStore.get('has_seen_tutorial');
  }

  public environment(data = null) {
    if (data) {
      this.fsStore.set('environment', data);
    }

    return this.fsStore.get('environment');
  }

  public clearEnvironment() {
    return this.fsStore.set('environment', null);
  }

  public tasteTest(data: TasteTest) {
    const account = this.fsStore.get('account');

    if (data) {
      account.tasteTestId = data.id;
      account.tasteTest = data;

      this.fsStore.set('account', account)
    }

    return account ? account.tasteTest : null;
  }

  public lastLocationAddress(data: FsAddress = null) {
    if (data) {
      this.fsStore.set('last_location_address', data);
    }

    return this.fsStore.get('last_location_address');
  }

  public accountEmail(): Observable<string> {
    return this._injector.get(AccountService).get(this.accountId())
      .pipe(
        map((account) => (account.email)),
      );
  }

  public clearFilterPersist(urlTree: string[]) {
    const data = this.fsStore.get('fs-filter-persist');
    if (!data) {
      return;
    }
    delete(data[urlTree.join('/')]);
    this.fsStore.set('fs-filter-persist', data);
  }

}
