import { Account } from './account';


export interface Invite {
  id: number;
  inviteeAccountId?: number;
  inviteeAccount?: Account;
  invitedAccountId?: number;
  invitedAccount?: Account;
  state?: string;
  createDate?: string;
  guid?: string;
  ip?: string;
  userAgent?: string;
  activateDate?: string;
  email?: string;
  meta?: string;
}
