import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';

import { AclEntry } from 'app/shared/interfaces';


@Injectable()
export class AclEntryService {

  public create(data: AclEntry = { id: null }) {
    return data;
  }

  constructor(private fsApi: FsApi) { }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(id, data = {}): Observable<any> {
    return this.fsApi.get(`acl/entries/${id}`, data, { key: 'aclEntry' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `acl/entries`, data, Object.assign({ key: 'aclEntries' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this.fsApi.put(`acl/entries/${data.id}`, data, Object.assign({ key: 'aclEntry' }, config));
  }

  public post(data): Observable<any> {
    return this.fsApi.post(`acl/entries`, data, { key: 'aclEntry' });
  }

  public delete(data): Observable<any> {
    return this.fsApi.delete(`acl/entries/${data.id}`, data, { key: 'aclEntry' });
  }
}
