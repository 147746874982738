import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})

export class TagComponent implements OnInit {

  @Input() public backgroundColor: string = null;
  @Input() public color: string = null;
  @Input() public show: boolean = null;
  @Input() public label: string = null;
  @Input() public size = '';

  constructor() { }

  public ngOnInit() {

  }
}
