import { Router } from '@angular/router';

import { FsApiResponseHandler } from '@firestitch/api';
import { FsMessage, MessageMode } from '@firestitch/message';

import { MessageService, SessionService, SigninService } from '../services';


export class ApiHandler extends FsApiResponseHandler {

  constructor(
    private fsMessage: FsMessage,
    private router: Router,
    private sessionService: SessionService,
    private signinService: SigninService,
    private messageService: MessageService
  ) {
    super();
  }

  public success(event, config) {
    if (event.body.code >= 400) {
      this.messageService.showApiErrorMessage({ error: event.body });
    } else {

      // if (event.body.token) {
      //   this.sessionService.setToken(event.body.token);
      // }

      event.body = event.body.data;
      if (config.key) {
        event.body = event.body[config.key];
      }
    }
  }

  public error(event, config) {

    if (event.status === 404) {
      this.router.navigateByUrl('/notfound');
      return;

    } else if (event.status === 401 || event.status === 403 || event.status === 490) {

      this.fsMessage.error('Please signin to access this page', { mode: MessageMode.Toast });
      //this.sessionService.destroy();

      if (event.status === 403) {
        this.signinService.clearRedirectUrl();
        this.router.navigateByUrl('/signin');
      } else {
        this.signinService.redirectThroughSignin(this.router.routerState.snapshot);
      }

    } else {

      if (config.handleError === false) {
        return;
      }

      this.messageService.showApiErrorMessage(event);
    }
  }

}
