import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Platform } from '@ionic/angular';
import { differenceInSeconds } from 'date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AccountService } from './account.service';
import { BackgroundGeolocationService } from './background-geolocation.service';
import { SessionService } from './session.service';

@Injectable()
export class AppUseService {

  private _guid: string;
  private _lastTime: any;
  private _interval: any;

  constructor(
    private _fsApi: FsApi,
    private _platform: Platform,
    private _deviceDetectorService: DeviceDetectorService,
    private _backgroundGeolocationService: BackgroundGeolocationService,
    private _accountService: AccountService,
    private _sessionService: SessionService,
  ) {}

  public start(): void {

    const device = this._deviceDetectorService.getDeviceInfo();

    const data: any = {
      browser: device.browser,
      operatingSystem: device.os,
      deviceMake: device.device,
      deviceModel: device.device,
    };

    this._fsApi.request(`POST`, `app/use`, data, { key: 'appUse' })
    .subscribe((appUse) => {
      this._guid = appUse.guid;
    });


    const account = this._sessionService.account();
    if (account) {
      this._backgroundGeolocationService.getCurrentPermission()
        .subscribe((permission) => {
          this._accountService.put({id: account.id, geolocationPermission: permission})
            .subscribe((updatedAccount) => {});
        });
    }


    clearInterval(this._interval);
    this._lastTime = new Date();

    this._interval = setInterval(() => {
      const diff = differenceInSeconds(new Date(), this._lastTime);
      if (diff > 60) {
        this.start();
      }

      this._lastTime = new Date();
    }, 5000);
  }

  public claim(): void {
    this._fsApi.request(`PUT`, `app/use/claim`, {guid: this._guid}, { key: 'appUse' }).subscribe((appUse) => {

    });
  }

  public end(): void {
    this._fsApi.request(`PUT`, `app/use/end`, {guid: this._guid}, { key: 'appUse' }).subscribe((appUse) => {
      this._guid = null;
    });
  }

  public guid(): string {
    return this._guid;
  }

}
