import { Injectable } from '@angular/core';

import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Injectable()
export class SidenavService {

  private xsmallBreakpoint = 599;
  private smallBreakpoint = 1023;

  public opened: boolean;
  public items = new BehaviorSubject([]);
  public mode: 'over' | 'push' | 'side';
  public topGap = 0;

  constructor() {
    this.updateSettings();

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(50)
      )
      .subscribe((e: any) => {
        this.updateSettings();
      });
  }

  public toggle() {
    this.opened = !this.opened;
  }

  public open() {
    this.opened = true;
  }

  public close() {
    // In other modes menu always opened
    if (this.mode === 'over') {
      this.opened = false;
    }
  }

  public hide() {
    if (window.innerWidth < this.smallBreakpoint) {
      this.opened = false;
    }
  }

  private updateSettings() {
    if (window.innerWidth < this.smallBreakpoint) {
      this.mode = 'over';
      this.opened = false;
    } else {
      this.mode = 'side';
      this.opened = true;
    }
  }
}
