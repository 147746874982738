import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BuildAction } from '../enums';
import { Build } from '../interfaces/build.interface';
import { of, ReplaySubject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BuildService {

  public build: Build = null;

  public build$ = new ReplaySubject(1);

  constructor(
    private _http: HttpClient
  ) {

  }


    public init() {
      return of(true)
        .pipe(
          switchMap(() => {

            if (environment.platform === 'app') {
              return this._http.get('/assets/build.json')
              .pipe(
                catchError((err) => {
                  // didnt find build.json
                  return of(null);
                }),
                switchMap((build: any) => {

                  if (build) {
                    const url = `https://${environment.apiDomain}${environment.apiPath}build?version=${build.version}`;

                    return this._http.get(url)
                    .pipe(
                      tap((resp) => {
                        if (resp['data'].build) {
                          this.build = resp['data'].build as Build;
                          this.build$.next(this.build);
                        }
                      })
                    );
                  } else {
                    return of(null);
                  }
                })
              )
            } else {
              return of(null);
            }
          }),
        )
        .toPromise();
    }

    public hasApiRedirect() {
      return environment.platform === 'app' && this.build && this.build.action === BuildAction.ApiRedirect && this.build.apiUrl;
    }

    public redirectApi(url) {
      return this.build.apiUrl + url;
    }


}
