import { Injectable } from '@angular/core';
import { FsStore } from '@firestitch/store';
import { Intercom } from 'ng-intercom';
import { environment } from 'environments/environment';


@Injectable()
export class IntercomService {

  constructor(
    private _intercom: Intercom,
    private _store: FsStore
    ) {}

  public start() {
    this._intercom.onUnreadCountChange((e: any) => {
      const el = document.querySelector('#intercom-target .badge');
      el.innerHTML = e || '';
    });

    this._store
    .observe('account')
    .subscribe(e => {
      if (e.value) {
        this._intercom.shutdown();
        this._intercom.boot(this.getData(e.value));
      } else {
        this._intercom.shutdown();
        this._intercom.boot(this.getData({ id: null, image: {} }));
      }
    });
  }

  public show() {
    this._intercom.show();
  }

  private getData(account = null) {

    return Object.assign(this.getAccountData(account), {
      appId: environment.intercomAppId,
      customLauncherSelector: '#intercom-target',
      hideDefaultLauncher: true
    });
  }

  private getAccountData(account) {
    if (!account) {
      return {};
    }

    return {
      email: account.email,
      createdAt: account.createDate,
      name: account.firstName + ' ' + account.lastName,
      userId: account.id,
      phone: account.phone,
      avatar: { type: 'avatar', imageUrl: account.image.small }
    };
  }
}
