<navbar>
  <a class="back" navbarBack fsNavBack><mat-icon>keyboard_arrow_left</mat-icon></a>
  <button
    mat-icon-button
    class="menu"
    (click)="sideNavClick()"
    navbarMenu>
    <mat-icon>menu</mat-icon>
  </button>
  <span fsNavComponent="image" type="image" navbarImage></span>
  <span fsNavComponent="logo" type="image" navbarLogo></span>
  <span fsNavSupertitle navbarSupertitle></span>
  <span fsNavTitle navbarTitle></span>
  <span fsNavSubtitle navbarSubtitle></span>
  <span navbarRightActions>
    <div class="menu-actions" fsNavMenu="right-menu"></div>
  </span>
</navbar>


