import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { of } from 'rxjs';

import { FsMessage } from '@firestitch/message';

import { AttributeService } from 'app/core/services';
import { Attribute } from 'app/shared/interfaces';


@Component({
  templateUrl: './attribute-edit.component.html',
  styleUrls: ['./attribute-edit.component.scss']
})
export class AttributeEditComponent implements OnInit {

  public attribute: Attribute = null;
  public title;

  constructor(
    private dialogRef: MatDialogRef<AttributeEditComponent>,
    private fsMessage: FsMessage,
    private attributeService: AttributeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.attribute = this.data.attribute;
    this.title = this.attributeService.getClass(this.attribute.class).name;

    (this.data.attribute.id ? this.attributeService.get(this.data.attribute.id, { projectId: this.data.attribute.projectId }) : of(this.data.attribute))
      .subscribe(response => this.attribute = Object.assign({}, this.attributeService.create(response)));
  }

  public save() {
    this.attributeService.save(this.attribute)
      .subscribe(attribute => {
        this.fsMessage
          .success(`Sucessfully ${this.attribute.id ? 'Saved' : 'Created'}`);
        this.close(attribute);
      });
  }

  public close(data = null) {
    this.dialogRef.close(data);
  }

}
