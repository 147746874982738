import { Component, Input } from '@angular/core';
import { Item } from '../../models';


@Component({
  selector: 'app-item-featured-banner',
  templateUrl: './featured-banner.component.html',
  styleUrls: ['./featured-banner.component.scss']
})
export class FeaturedBannerComponent {
  @Input() public withBorder = true;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public settings = null;

  public get item() {
    return this._item;
  }

  private _item: Item = null;

  constructor() { }

}
