import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { QuizStepPaneComponent } from './components/quiz-step-pane';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        QuizStepPaneComponent
    ],
    exports: [
        QuizStepPaneComponent
    ]
})
export class QuizStepPaneModule { }
