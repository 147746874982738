import { NgModule } from '@angular/core';

import { PercentMatchFlagModule } from '@libs/percent-match-flag';

import { SharedModule } from '@app/shared';

import { WhereToBuyDialogModule } from '../where-to-buy-dialog';
import { ItemModule } from '../../../item/item.module';

import {
  WhereToBuyPaneComponent
} from './components';


@NgModule({
    imports: [
        SharedModule,
        ItemModule,
        WhereToBuyDialogModule,
        PercentMatchFlagModule,
    ],
    declarations: [
        WhereToBuyPaneComponent
    ],
    exports: [
        WhereToBuyPaneComponent
    ]
})
export class WhereToBuyPaneModule { }
