import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { Subscription } from 'app/shared/interfaces';
import { NotesDataService } from '../../../data/notes-data.service';


@Component({
  templateUrl: './custom-note.component.html',
  styleUrls: ['./custom-note.component.scss']
})
export class ItemAddCustomNoteComponent implements OnInit, OnDestroy {

  public subscription: Subscription = null;
  public selectorData;
  public selectedAttributes = [];
  public customNote = '';

  public features = [];

  private _destroy$ = new Subject();

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<ItemAddCustomNoteComponent>,
    private _fsMessage: FsMessage,
    private _notesData: NotesDataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.selectorData = { objectId: this.data.note.id };
    this.selectedAttributes = this.data.note.popularNotes.slice();
    this.customNote = this.data.note.customNote;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public save() {
    this.data.note.customNote = this.customNote;

    if (this.data.note.id) {
      this._notesData.put(
        this.data.itemId,
        {
          id: this.data.note.id,
          customNote: this.customNote,
        }
      )
        .pipe(
          takeUntil(this._destroy$),
        )
        .subscribe(() => {
          this._fsMessage.success('Custom Note Saved');
          this.close();
        });
    } else {
      this._notesData.post(
        this.data.itemId,
        {
          customNote: this.customNote,
        }
      )
        .pipe(
          takeUntil(this._destroy$),
        )
        .subscribe(() => {
          this._fsMessage.success('Custom Note Saved');
          this.close();
        });

    }
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public selectionChanged(event) {
    if (event.selected) {
      this.data.note.popularNotes.push(event.value);
    } else {
      const finishIndex = this.data.note.popularNotes
        .findIndex((finish) => finish.id === event.value.id);

      if (finishIndex > -1) {
        this.data.note.popularNotes.splice(finishIndex, 1);
      }
    }
  }

}
