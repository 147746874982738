import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { AccountService, AuthService, SessionService } from '@app/core/services';
import { Account } from 'app/shared/interfaces';


@Component({
  templateUrl: './take-taste-test.component.html',
  styleUrls: ['./take-taste-test.component.scss']
})
export class TakeTasteTestComponent implements OnInit, OnDestroy {

  public step: 'take' | 'not_now' = 'take';

  public loggedInAccount: Account = null;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _sessionService: SessionService,
    private _dialogRef: MatDialogRef<TakeTasteTestComponent>,
  ) { }

  public ngOnInit() {
    this._authService.loggedInAccount$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(store => this.loggedInAccount = store && store.value ? store.value : null);
  }

  public takeTasteTest() {
    this._router.navigate(['/tastetest']);
    this.close();
  }

  public notNow() {

    if (!this.loggedInAccount) {
      this._sessionService.lastPromptDate(new Date());
      this.step = 'not_now';
      return;
    }

    this.loggedInAccount.lastPromptDate = new Date();
    this._accountService.save(this.loggedInAccount)
      .subscribe((account: Account) => {
        // BE change date
        this.loggedInAccount.lastPromptDate = account.lastPromptDate;
        this._sessionService.account(this.loggedInAccount);
        this.step = 'not_now';
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public ngOnDestroy() { }

}
