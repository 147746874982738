import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';
import { FsStore } from '@firestitch/store';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class TasteTestService {

  constructor(
    private _fsApi: FsApi,
    private _fsStore: FsStore,
  ) { }

  public getTasteTestId() {
    return this._fsStore.get(`taste_test_id`);
  }

  public get(tasteTestId: number = null) {
    const urlData: any[] = ['tastetest'];
    if (tasteTestId) {
      urlData.push(tasteTestId);
    }
    return this._fsApi.get(urlData.join('/'), {}, { key: 'tasteTest' })
      .pipe(
        tap((response) => {
          this._fsStore.set(`taste_test_id`, response.id);
        })
      );
  }

  public post() {
    return this._fsApi.post(`tastetest`, {}, { key: 'tasteTest' })
      .pipe(
        tap((response) => {
          this._fsStore.set(`taste_test_id`, response.id);
        })
      );
  }

  public saveStep(tasteTestId: number, number: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.put(`tastetest/${tasteTestId}/steps/${number}`, data, Object.assign({ key: 'tasteTest' }, config));
  }

  public clear(tasteTestId: number) {
    return this._fsApi.post(`tastetest/${tasteTestId}/clear`, {}, { key: '' });
  }

}
