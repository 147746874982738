/**
 * Shared module must contain modules/components/directives/etc. which must have a possibility to use everywhere in project
 * As example it can be RouterModule, Material Modules, Some components like "Follow" button
 * !!!Shared module CAN NOT CONTAIN SERVICES!!!
 */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { environment } from 'environments/environment';

import { FsAccountPickerModule } from '@firestitch/account-picker';
import { FsAccountStatusModule } from '@firestitch/account-status';
import { FsAddressModule } from '@firestitch/address';
import { FsAttributeModule } from '@firestitch/attribute';
import { FsAutocompleteModule } from '@firestitch/autocomplete';
import { FsAutocompleteChipsModule } from '@firestitch/autocomplete-chips';
import { FsBadgeModule } from '@firestitch/badge';
import { FsBuildModule } from '@firestitch/build';
import { FsCheckboxGroupModule } from '@firestitch/checkboxgroup';
import { FsChipModule } from '@firestitch/chip';
import { FsColorPickerModule } from '@firestitch/colorpicker';
import { FsCommonModule } from '@firestitch/common';
import { FsDateModule } from '@firestitch/date';
import { FsDatePickerModule } from '@firestitch/datepicker';
import { FsDialogModule } from '@firestitch/dialog';
import { FsDrawerModule } from '@firestitch/drawer';
import { FsDurationModule } from '@firestitch/duration';
import { FsFileModule } from '@firestitch/file';
import { FsFilterModule } from '@firestitch/filter';
import { FsFormModule } from '@firestitch/form';
import { FsGalleryModule } from '@firestitch/gallery';
import { FsHtmlEditorModule, FsHtmlRendererModule } from '@firestitch/html-editor';
import { FsIconPickerModule } from '@firestitch/icon-picker';
import { FsImportModule } from '@firestitch/import';
import { FsLabelModule } from '@firestitch/label';
import { FsLinkModule } from '@firestitch/link';
import { FsListModule, PaginationStrategy } from '@firestitch/list';
import { FsMaskModule } from '@firestitch/mask';
import { FsMenuModule } from '@firestitch/menu';
import { FsNavModule } from '@firestitch/nav';
import { FsPaneModule } from '@firestitch/pane';
import { FsPasswordModule } from '@firestitch/password';
import { FsPhoneModule } from '@firestitch/phone';
import { FsPromptModule } from '@firestitch/prompt';
import { FsRadioGroupModule } from '@firestitch/radiogroup';
import { FsRatingModule } from '@firestitch/rating';
import { FsScrollbarModule } from '@firestitch/scrollbar';
import { FsSelectModule } from '@firestitch/select';
import { FsShareModule } from '@firestitch/share';
import { FsSidenavModule } from '@firestitch/sidenav';
import { FsSigninSecurityModule } from '@firestitch/signin-security';
import { FsSkeletonModule } from '@firestitch/skeleton';
import { FsTreeModule } from '@firestitch/tree';
import { FsTruncateModule } from '@firestitch/truncate';
import { FsWizardModule } from '@firestitch/wizard';

import { IntercomModule } from 'ng-intercom';

import { NoteNamePipe } from './pipes';

import { AppMaterialModule } from '../material';

import {
    AccountAssignMenuComponent,
    AccountAssignPillComponent,
    AccountAvatarComponent,
    AccountFormComponent,
    AccountImageComponent,
    AccountMenuComponent,
    AccountRequestComponent,
    AccountSocialComponent,
    ActionsInlineComponent,
    AttributeBadgeComponent,
    AttributeEditComponent,
    AttributeListComponent,
    AttributeManageComponent,
    AttributePillComponent,
    AttributePillsComponent,
    AwardOrganizationDialogComponent,
    AwardWonDialogComponent,
    AwardWonListComponent,
    BaseTileComponent,
    BodyNavbarComponent,
    BouncingIconComponent,
    CardComponent,
    CollectionAssignDialogComponent,
    CollectionImageComponent,
    ColorTileComponent,
    ContentWidgetComponent,
    ContentWidgetDialogComponent,
    CountryComponent,
    DescriptionDialogComponent,
    ExperiencePriceComponent,
    ExperienceStatusPillComponent,
    FooterComponent,
    GlobalSearchComponent,
    HeaderComponent,
    InviteDialogComponent,
    InvitesListDialogComponent,
    LastModifiedComponent,
    LastSignInComponent,
    MediaGalleryComponent,
    NavbarComponent,
    NotFoundComponent,
    ObjectAttributeSelectorComponent,
    OrganizationDialogComponent,
    OrganizationImportDialogComponent,
    OrganizationPickerComponent,
    OrganizationPriceComponent,
    OrganizationStatusPillComponent,
    OrLineComponent,
    PrimaryOrganizationContactComponent,
    PriorityMenuComponent,
    PriorityPillComponent,
    RoleEditComponent,
    SocialButtonComponent,
    SourceObjectImageComponent,
    StatusPillComponent,
    SubregionPickerComponent,
    SubregionViewComponent,
    SubscribersComponent,
    TagComponent,
    TagFeaturedComponent,
    TagShowOnPageComponent,
    TimezoneSelectComponent,
    TypeImageComponent,
    TypeMenuComponent,
    TypePillComponent,
    WideComponent,
    WineNameComponent,
    WineStatusPillComponent,
    WineVintageViewComponent,
} from './components';
import { ObjectAttributeFieldComponent } from './components/object-attribute-field/object-attribute-field.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        FlexLayoutModule,
        FsBadgeModule,
        FsCommonModule,
        AppMaterialModule,
        FsDateModule,
        FsNavModule,
        FsSkeletonModule,
        FsFilterModule,
        FsFileModule,
        FsGalleryModule,
        FsColorPickerModule,
        FsAccountPickerModule,
        FsCheckboxGroupModule,
        FsAccountStatusModule,
        FsSigninSecurityModule.forRoot({ case: 'camel' }),
        FsBuildModule,
        FsPasswordModule,
        FsRadioGroupModule,
        FsMenuModule,
        FsIconPickerModule,
        FsShareModule.forRoot(),
        FsDatePickerModule.forRoot(),
        FsDurationModule,
        FsAddressModule,
        FsPhoneModule,
        FsChipModule,
        FsTruncateModule,
        FsLinkModule,
        FsListModule.forRoot({
            paging: {
                strategy: PaginationStrategy.Offset,
                limit: 25
            },
            chips: true
        }),
        FsPromptModule.forRoot(),
        FsFormModule.forRoot(),
        FsHtmlRendererModule,
        FsDialogModule.forRoot(),
        FsLabelModule,
        FsDrawerModule,
        FsSidenavModule,
        FsAutocompleteChipsModule,
        FsAutocompleteModule,
        FsTreeModule,
        FsAttributeModule,
        FsPaneModule,
        FsSelectModule,
        FsTruncateModule,
        IntercomModule.forRoot({
            appId: environment.intercomAppId,
            updateOnRouterChange: true
        }),
        FsImportModule,
        FsRatingModule,
        FsScrollbarModule,
        FsWizardModule,
        FsHtmlEditorModule,
    ],
    declarations: [
        HeaderComponent,
        AccountMenuComponent,
        AccountAvatarComponent,
        AccountFormComponent,
        NavbarComponent,
        BodyNavbarComponent,
        TypePillComponent,
        AccountAssignMenuComponent,
        AccountAssignPillComponent,
        PriorityPillComponent,
        PriorityMenuComponent,
        AccountRequestComponent,
        AccountImageComponent,
        AccountSocialComponent,
        StatusPillComponent,
        AttributePillComponent,
        LastModifiedComponent,
        BouncingIconComponent,
        RoleEditComponent,
        SocialButtonComponent,
        WideComponent,
        CardComponent,
        TypeMenuComponent,
        SubscribersComponent,
        TimezoneSelectComponent,
        AttributeEditComponent,
        AttributeListComponent,
        AttributePillsComponent,
        AttributeManageComponent,
        ObjectAttributeSelectorComponent,
        ObjectAttributeFieldComponent,
        ObjectAttributeSelectorComponent,
        GlobalSearchComponent,
        NotFoundComponent,
        CountryComponent,
        TypeImageComponent,
        OrganizationStatusPillComponent,
        PrimaryOrganizationContactComponent,
        SubregionPickerComponent,
        LastSignInComponent,
        AwardWonDialogComponent,
        AwardOrganizationDialogComponent,
        AwardWonListComponent,
        OrganizationPickerComponent,
        InvitesListDialogComponent,
        InviteDialogComponent,
        OrganizationDialogComponent,
        OrganizationImportDialogComponent,
        DescriptionDialogComponent,
        CollectionAssignDialogComponent,
        SourceObjectImageComponent,
        SubregionViewComponent,
        WineStatusPillComponent,
        ExperienceStatusPillComponent,
        ContentWidgetDialogComponent,
        ContentWidgetComponent,
        OrLineComponent,
        MediaGalleryComponent,
        CollectionImageComponent,
        ActionsInlineComponent,
        AttributeBadgeComponent,
        WineVintageViewComponent,
        OrganizationPriceComponent,
        ExperiencePriceComponent,
        FooterComponent,
        WineNameComponent,
        BaseTileComponent,
        ColorTileComponent,
        TagComponent,
        TagFeaturedComponent,
        TagShowOnPageComponent,
        NoteNamePipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        FlexLayoutModule,
        FsCommonModule,
        AppMaterialModule,
        FsListModule,
        FsFormModule,
        FsCheckboxGroupModule,
        FsAccountStatusModule,
        FsSigninSecurityModule,
        FsPasswordModule,
        FsRadioGroupModule,
        FsDateModule,
        FsBadgeModule,
        FsDialogModule,
        FsColorPickerModule,
        FsAccountPickerModule,
        FsDatePickerModule,
        FsPaneModule,
        FsFileModule,
        FsGalleryModule,
        FsNavModule,
        FsSkeletonModule,
        FsFilterModule,
        FsMenuModule,
        FsColorPickerModule,
        FsIconPickerModule,
        FsDurationModule,
        FsAddressModule,
        FsPhoneModule,
        FsLabelModule,
        FsDrawerModule,
        FsAutocompleteChipsModule,
        FsAutocompleteModule,
        FsTreeModule,
        FsAttributeModule,
        FsChipModule,
        FsRatingModule,
        FsShareModule,
        FsTruncateModule,
        FsLinkModule,
        FsWizardModule,
        FsMaskModule,
        FsSkeletonModule,
        FsFilterModule,
        FsMenuModule,
        FsColorPickerModule,
        FsIconPickerModule,
        HeaderComponent,
        AccountMenuComponent,
        AccountAvatarComponent,
        AccountFormComponent,
        AccountAssignMenuComponent,
        AccountAssignPillComponent,
        PriorityPillComponent,
        PriorityMenuComponent,
        NavbarComponent,
        BodyNavbarComponent,
        AccountImageComponent,
        AccountSocialComponent,
        StatusPillComponent,
        AttributePillComponent,
        LastModifiedComponent,
        BouncingIconComponent,
        RoleEditComponent,
        SocialButtonComponent,
        WideComponent,
        CardComponent,
        TypePillComponent,
        TypeMenuComponent,
        SubscribersComponent,
        TimezoneSelectComponent,
        AttributeEditComponent,
        AttributeListComponent,
        AttributePillsComponent,
        AttributeManageComponent,
        ObjectAttributeSelectorComponent,
        ObjectAttributeFieldComponent,
        ObjectAttributeSelectorComponent,
        GlobalSearchComponent,
        NotFoundComponent,
        CountryComponent,
        TypeImageComponent,
        OrganizationStatusPillComponent,
        PrimaryOrganizationContactComponent,
        SubregionPickerComponent,
        LastSignInComponent,
        AwardWonDialogComponent,
        AwardOrganizationDialogComponent,
        AwardWonListComponent,
        OrganizationPickerComponent,
        InvitesListDialogComponent,
        InviteDialogComponent,
        OrganizationDialogComponent,
        OrganizationImportDialogComponent,
        DescriptionDialogComponent,
        CollectionAssignDialogComponent,
        SourceObjectImageComponent,
        SubregionViewComponent,
        WineStatusPillComponent,
        ExperienceStatusPillComponent,
        ContentWidgetComponent,
        OrLineComponent,
        MediaGalleryComponent,
        WineNameComponent,
        OrganizationPriceComponent,
        ExperiencePriceComponent,
        FooterComponent,
        CollectionImageComponent,
        BaseTileComponent,
        ColorTileComponent,
        ActionsInlineComponent,
        AttributeBadgeComponent,
        WineVintageViewComponent,
        TagComponent,
        TagFeaturedComponent,
        TagShowOnPageComponent,
        NoteNamePipe,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { float: 'auto' },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { floatLabel: 'auto', appearance: 'outline' },
          },
    ]
})
export class SharedModule { }
