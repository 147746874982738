import { Account } from './account';
import { Project } from './project';
import { Task } from './task';


export interface TimeEntry {
  readonly id?: number;
  accountId?: number;
  taskId?: number;
  task?: Task;
  account?: Account;
  projectId?: number;
  project?: Project;
  state?: string;
  startDate?: string;
  endDate?: string;
  timezone?: string;
  date?: string;
  type?: string;
  minutes?: number;
  description?: string;
  createDate?: string;
}
