<div *fsSkeletonForm="!!objectAward">
  <form  *ngIf="objectAward" (fsForm)="save()" #form="ngForm">
    <fs-dialog>
      <h1 mat-dialog-title>{{ objectAward.id ? 'Edit' : 'Add' }} Award Won</h1>
      <mat-dialog-content>

        <div class="award-organization-field">
          <fs-autocomplete
            [fetch]="fetch"
            [fetchOnFocus]="true"
            placeholder="Award Organization"
            [(ngModel)]="objectAward.awardOrganization"
            (ngModelChange)="awardOrganizationChange($event)"
            [displayWith]="displayWith"
            [required]="true"
            name="award_organization">
            <ng-template fsAutocompleteTemplate let-data="data">
              <span>{{ data.name }}</span>
            </ng-template>
          </fs-autocomplete>

          <div class="create-organization-link"><a (click)="openCreateAwardOrganizationDialog()">Create Award Organization</a></div>
        </div>

        <mat-form-field>
          <mat-label>Award Won</mat-label>
        <textarea
            matInput
            [(ngModel)]="objectAward.award"
            name="award"
            
            [required]="true"
            cdkTextareaAutosize>
          </textarea>
        </mat-form-field>

        <mat-form-field class="mat-fullwidth">
          <mat-label>Year</mat-label>
        <input 
            [(ngModel)]="objectAward.year"
            name="year"
            matInput
            fsFormRequired
            fsFormInteger
            [fsFormMinLength]="4"
            [fsFormMaxLength]="4"
            fsFormIntegerMessage="This is not a valid year"
            fsFormMinlengthMessage="This is not a valid year"
            fsFormMaxlengthMessage="This is not a valid year"
            />
        </mat-form-field>

      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button type="submit" color="primary">{{ objectAward.id ? 'Save' : 'Add' }}</button>
        <button mat-button type="button" mat-dialog-close>CANCEL</button>
      </mat-dialog-actions>
    </fs-dialog>
  </form>
</div>
