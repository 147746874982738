<mat-card>
  <fs-list [config]="config" #list>
    <fs-list-column title="Name">
      <ng-template fs-list-cell let-row="row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <fs-badge *ngIf="row.project?.image.small" shape="circle" [image]="row.project?.image.small"></fs-badge>
          <div fxLayout="column">
            <a (click)="navigateTo(row)">{{ row.name }}</a>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <mat-icon *ngIf="classesIndexed[row.class]" [ngStyle]="{ color: classesIndexed[row.class].color }">{{ classesIndexed[row.class].icon }}</mat-icon>
            </div>
          </div>
        </div>
      </ng-template>
    </fs-list-column>
    <!--
    <fs-list-column title="Type">
      <ng-template fs-list-cell let-row="row">
        {{ classesFlatten[row.class] }}
      </ng-template>
    </fs-list-column>
    -->
    <fs-list-column title="Last Modified">
      <ng-template fs-list-cell let-row="row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <fs-badge [image]="row?.modifierAccount?.image?.small"></fs-badge>
          <span>{{ row.modifyDate | fsDateAgo }}</span>
        </div>
      </ng-template>
    </fs-list-column>
  </fs-list>
</mat-card>
