import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { isObject } from 'lodash-es';

import { RegionService } from '../../../core/services';
import { Subregion } from '../../interfaces';
import { NgForm, ControlContainer } from '@angular/forms';


@Component({
  selector: 'app-subregion-picker',
  templateUrl: './subregion-picker.component.html',
  styleUrls: ['./subregion-picker.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SubregionPickerComponent implements OnInit {

  private _subregion: Subregion = null;
  @Input() public set subregion(value: Subregion) {
    this._subregion = value;
  }
  public get subregion(): Subregion {
    return this._subregion;
  }

  @Output() public subregionChange = new EventEmitter<Subregion>();

  public subregions: Subregion[] = [];

  constructor(
    private _regionService: RegionService
  ) { }

  public ngOnInit() {
  }

  public loadSubregions($event: any): void {
    this.subregions = [];

    const query: any = {
      keyword: $event,
      regions: true,
      order: 'region_name,asc',
      page: 1,
      limit: 10
    };

    if (!$event) {
      this.subregionSelected(null);
      return;
    }

    if (!isObject($event)) {
      this._regionService.getsSubregions(null, query)
        .subscribe(response => this.subregions = response);
    } else {
      this.subregionSelected($event);
    }
  }

  public displayFn(data: any): string {
    return data ? `${data.name}, ${data.region.name}` : '';
  }

  public subregionSelected(data: Subregion): void {
    this.subregionChange.emit(data);
  }

}
