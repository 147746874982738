<h2>Photos</h2>
<fs-gallery
    #gallery
    (previewOpened)="trackOpen()"
    [config]="config">
  <ng-template fsGalleryThumbnail let-item="item" let-template="template">
    <ng-template 
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ item: item }">
    </ng-template>
  </ng-template>
  <ng-template fsGalleryPreview let-item="item">
    <div class="description" *ngIf="item.description">{{ item.description }}</div>
  </ng-template>
</fs-gallery>
<button mat-stroked-button (click)="open()" class="mat-fullwidth">View All Photos</button>
