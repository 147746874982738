import { Injectable } from '@angular/core';

import { Angulartics2 } from 'angulartics2';
import { ActionService } from './action.service';


@Injectable()
export class Angulartics2Action {

  constructor(
    private angulartics2: Angulartics2,
    private actionService: ActionService
  ) {}

  startTracking() {
    this.angulartics2.pageTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe((x) => this.pageTrack(x.path));

    this.angulartics2.eventTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe((x) => this.eventTrack(x.action, x.properties));
  }

  pageTrack(path: string) {
    // try {
    //   console.log('Page Track', path);
    // } catch (e) {
    //   if (!(e instanceof ReferenceError)) {
    //     throw e;
    //   }
    // }
  }

  eventTrack(action: string, properties: any) {

    try {
      //console.log('Event Track', action, properties);

      this.actionService.post(action, properties)
      .subscribe(() => {

      });

    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        throw e;
      }
    }
  }
}