import { Component, Inject, OnInit  } from '@angular/core';
import { NgForm } from '@angular/forms';

import { PANE_DATA } from '@firestitch/pane';


@Component({
  selector: 'app-quiz-step-pane',
  templateUrl: './quiz-step-pane.component.html'
})
export class QuizStepPaneComponent implements OnInit {

  public form: NgForm = null;

  constructor(
    @Inject(PANE_DATA) public data: any
  ) { }

  public ngOnInit() {
    this.form = this.data.form;
  }

  public onSubmit() {
    this.form.ngSubmit.emit();
  }
}
