import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import {
  WhereToBuyDialogComponent,
  WhereToBuyRetailersComponent,
} from './components';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        WhereToBuyDialogComponent,
        WhereToBuyRetailersComponent,
    ],
    exports: [
        WhereToBuyDialogComponent,
        WhereToBuyRetailersComponent,
    ]
})
export class WhereToBuyDialogModule { }
