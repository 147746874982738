import { Component, Optional, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SigninService } from '@app/core';


@Component({
  selector: 'app-anonymous-sign-in',
  templateUrl: './anonymous-sign-in.component.html',
  styleUrls: ['./anonymous-sign-in.component.scss'],
})
export class AnonymousSignInComponent {

  constructor(
    protected _router: Router,
    protected _signInService: SigninService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public proceed(type) {

    const url = this.data && this.data.redirectUrl ? this.data.redirectUrl : null;

    switch (type) {
      case 'signin': {
        if (url) {
          this._signInService.redirectThroughSignin(url);
        } else {
          this._signInService.redirectThroughSignin(this._router.routerState.snapshot);
        }
      } break;

      case 'signup': {
        if (url) {
          this._signInService.redirectThroughSignUp(url);
        } else {
          this._signInService.redirectThroughSignUp(this._router.routerState.snapshot);
        }
      } break;
    }
  }

}
