<fs-dialog>
  <h1 mat-dialog-title>Select {{title}}</h1>
  <mat-dialog-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <fs-filter [filter]="config" #filter></fs-filter>
      </div>

      <app-attribute-pills
        [attributes]="attributes"
        [selected]="attributesSelected"
        (toggle)="toggle($event)">
      </app-attribute-pills>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button color="primary" mat-button (click)="close()">Done</button>
    <button mat-button (click)="createAttribute()">Create {{className}}</button>
    <button mat-button (click)="manageTags()">Manage {{title}}</button>
  </mat-dialog-actions>
</fs-dialog>
