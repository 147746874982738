import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';

import { FsScrollModule } from '@firestitch/scroll';

import { ItemRoutingModule } from './item-routing.module';

// Modules
import { SharedModule } from '@app/shared';
import { ShareMenuModule } from '@app/taster/modules/share-menu';

// Components
import { AboutComponent } from './components/about';
import { ActionBarComponent } from './components/action-bar';
import { ActionKebubMenuComponent } from './components/action-kebub-menu';
import { ActionListElement } from './components/action-list-element';
import { AttributeTilesComponent } from './components/attribute-tile';
import { AvailabilityComponent } from './components/avaliability';
import { CardSectionComponent } from './components/card-section';
import { CardViewComponent } from './components/card-view';
import { CharacteristicsComponent } from './components/characteristics';
import { ItemClassificationPillComponent } from './components/classification-pill';
import { ItemClassificationPillsComponent } from './components/classification-pills';
import { ItemCreatedBy } from './components/created-by';
import { DetailHeadingComponent } from './components/detail-heading';
import { FeaturedBannerComponent } from './components/featured-banner';
import { GroupSizeComponent } from './components/group-size';
import { HeadingsComponent } from './components/headings';
import { ItemImageComponent } from './components/item-image';
import { ItemPriceComponent } from './components/item-price';
import { LanguagesComponent } from './components/languages';
import { ItemListViewActionBarComponent } from './components/list-vew-action-bar';
import { ListViewComponent } from './components/list-view';
import { MapMarkerComponent } from './components/map-marker';
import { PhotosComponent } from './components/photos';
import { RatingComponent } from './components/rating';
import { SectionCollectionComponent } from './components/section-collection';
import { ItemVisitsComponent } from './components/visits';

import {
    ItemAddCustomNoteComponent,
    ItemAddFinishesComponent,
    ItemAddFlavoursComponent,
    ItemAddNotesComponent,
    YourNotesComponent,
} from './components/your-notes';

// Dialogs
import { AddToCollectionDialogComponent } from './components/add-to-collection-dialog';
import { AddToListDialogComponent } from './components/add-to-list-dialog';
import { CollectionDialogComponent } from './components/collection-dialog';

import { BackDialogButtonComponent } from './components/back-dialog-button';

// Resolvers
import { ItemResolve } from './resolves';

// Services
import { AddToListService } from './services';

// Modules
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AnonymousSignInModule } from '@libs/anonymous-sign-in';
import { PercentMatchFlagModule } from '@libs/percent-match-flag';
import { NotePreviewModule } from './modules/note-preview/note-preview.module';


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        SharedModule,
        ItemRoutingModule,
        Angulartics2Module,
        NotePreviewModule,
        ShareMenuModule,
        AnonymousSignInModule,
        PercentMatchFlagModule,
        FsScrollModule,
    ],
    declarations: [
        ListViewComponent,
        CardViewComponent,
        DetailHeadingComponent,
        ActionBarComponent,
        ActionKebubMenuComponent,
        RatingComponent,
        AboutComponent,
        AvailabilityComponent,
        PhotosComponent,
        LanguagesComponent,
        GroupSizeComponent,
        FeaturedBannerComponent,
        AttributeTilesComponent,
        CharacteristicsComponent,
        CardSectionComponent,
        ItemCreatedBy,
        ActionListElement,
        HeadingsComponent,
        ItemImageComponent,
        ItemPriceComponent,
        ItemClassificationPillComponent,
        ItemClassificationPillsComponent,
        SectionCollectionComponent,
        ItemListViewActionBarComponent,
        ItemVisitsComponent,
        MapMarkerComponent,
        // Dialogs
        AddToCollectionDialogComponent,
        AddToListDialogComponent,
        CollectionDialogComponent,
        // Internal
        BackDialogButtonComponent,
        // Notes
        YourNotesComponent,
        ItemAddCustomNoteComponent,
        ItemAddNotesComponent,
        ItemAddFlavoursComponent,
        ItemAddFinishesComponent,
    ],
    exports: [
        ListViewComponent,
        CardViewComponent,
        DetailHeadingComponent,
        ActionBarComponent,
        ActionKebubMenuComponent,
        RatingComponent,
        AboutComponent,
        AvailabilityComponent,
        AddToListDialogComponent,
        PhotosComponent,
        LanguagesComponent,
        GroupSizeComponent,
        FeaturedBannerComponent,
        AttributeTilesComponent,
        CharacteristicsComponent,
        CardSectionComponent,
        ItemCreatedBy,
        ActionListElement,
        HeadingsComponent,
        ItemImageComponent,
        ItemPriceComponent,
        ItemClassificationPillComponent,
        ItemClassificationPillsComponent,
        SectionCollectionComponent,
        ItemVisitsComponent,
        MapMarkerComponent,
        // Internal
        BackDialogButtonComponent,
        // Notes
        YourNotesComponent,
        // Dialogs
        AddToCollectionDialogComponent,
        AddToListDialogComponent,
        CollectionDialogComponent,
    ],
    providers: [
        // Services
        AddToListService,
        // Resolves
        ItemResolve
    ]
})
export class ItemModule {
}
