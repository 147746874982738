import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';

import { Organization } from '@app/shared/interfaces';
import { Item } from '@app/taster/modules/item/models';
import { WhereToBuyRetailersComponent } from './retailers';


@Component({
  templateUrl: './where-to-buy-dialog.component.html',
  styleUrls: ['./where-to-buy-dialog.component.scss']
})
export class WhereToBuyDialogComponent implements OnInit {

  public item: Item = null;

  public listItems: any[] = [];

  constructor(
    private _dialogRef: MatDialogRef<WhereToBuyDialogComponent>,
    private _router: Router,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
    this._initListItems();
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  private _initListItems() {
    const organization: Organization = this.item.organization || { id: null };
    const type = organization.type || null
    const image = organization.image || {};

    this.listItems = [];

    if (organization.id) {
      this.listItems.push({
        title: organization.name,
        subtitle: this._get_subtitle(type),
        image: image.small,
        linkMode: 'organization',
      });
    }

    if (this.item.onlineShopItemUrl || organization.onlineShopUrl) {
      this.listItems.push({
        title: `${organization.name || ''} Wine Shop`,
        subtitle: `Online wine shop ${ this.item.status === 'sold_out' ? ' • Sold Out' : '' }`,
        image: image.small,
        url: this.item.onlineShopItemUrl ? this.item.onlineShopItemUrl : organization.onlineShopUrl,
        linkMode: 'external',
      });
    }

    if (this.item.whereToBuyCount && this.item.whereToBuyCount.length) {
      this.item.whereToBuyCount.forEach(organization => {
        this.listItems.push({
          title: `${organization.count} ${organization.count > 1 ? organization.pluralName : organization.name}`,
          icon: 'where-to-buy',
          linkMode: 'findRetailersDialog',
          data: organization,
        });
      });
    }
  }

  public openOrganizationPage() {
    this._router.navigate(['organizations', this.item.organization.id]);
    this.close();
  }

  public openFindRetailersDialog(listItem) {
    const dialogRef = this._dialog.open(WhereToBuyRetailersComponent, {
      data: {
        item: this.item,
        type: { id: listItem.data.typeId, name: listItem.data.name, pluralName: listItem.data.pluralName },
      }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => this.close());
  }


  public openUrl(url) {
    if ((window as any).cordova && (window as any).cordova.InAppBrowser) {
      (window as any).cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(url, '_system');
    }
  }


  private _get_subtitle(type) {

    if (this.item.status !== 'sold_out') {
      return type ? `For sale at ${type.name}` : null;
    } else {
      return 'Sold Out';
    }

  }

}
