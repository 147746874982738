import { Injectable, OnDestroy } from '@angular/core';
import { DefaultUrlSerializer, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { FsApi } from '@firestitch/api';
import { iso8601, parse } from '@firestitch/date';
import { FsMessage } from '@firestitch/message';
import { FsStore, FsStoreObject } from '@firestitch/store';

import { isAfter } from 'date-fns';

import { Observable, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { AppUseService } from './app-use.service';
import { SessionService } from './session.service';


@Injectable()
export class AuthService implements OnDestroy {

  public loggedInAccount$: Observable<FsStoreObject> = this.fsStore.observe('account');

  private _destroy$ = new Subject();

  constructor(
    private fsApi: FsApi,
    private router: Router,
    private fsMessage: FsMessage,
    private fsStore: FsStore,
    private sessionService: SessionService,
    private appUseService: AppUseService,
    private jwtHelper: JwtHelperService,
  ) { }

  public get isLoggedIn() {
    return !!this.fsStore.get('account');
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public signin(email, password): Observable<any> {
    const data: any = { email: email, password: password };

    const visitId = this.fsStore.get('visit_id');
    if (visitId) {
      data.visitId = visitId;
    }

    const tasteTestId = this.fsStore.get('taste_test_id');
    if (tasteTestId) {
      data.tasteTestId = tasteTestId;
    }

    return this.processResponse(this.fsApi.post('auth/signin', data));
  }

  public siginFacebook(accessToken, config = {}): Observable<any> {
    const data: any = { accessToken: accessToken };

    const visitId = this.fsStore.get('visit_id');
    if (visitId) {
      data.visitId = visitId;
    }

    const tasteTestId = this.fsStore.get('taste_test_id');
    if (tasteTestId) {
      data.tasteTestId = tasteTestId;
    }

    return this.processResponse(this.fsApi.post('auth/facebook', data, config));
  }

  public signinGoogle(idToken, accessToken, config = {}): Observable<any> {
    const data: any = { idToken: idToken, accessToken: accessToken };

    const visitId = this.fsStore.get('visit_id');
    if (visitId) {
      data.visitId = visitId;
    }

    const tasteTestId = this.fsStore.get('taste_test_id');
    if (tasteTestId) {
      data.tasteTestId = tasteTestId;
    }

    return this.processResponse(this.fsApi.post('auth/google', data, config));
  }



  public signinApple(response, config = {}): Observable<any> {
    const data: any = response;

    const payload = this.jwtHelper.decodeToken(response.identityToken);

    if (!data.email && payload.email) {
      data.email = payload.email;
    }

    const visitId = this.fsStore.get('visit_id');
    if (visitId) {
      data.visitId = visitId;
    }

    const tasteTestId = this.fsStore.get('taste_test_id');
    if (tasteTestId) {
      data.tasteTestId = tasteTestId;
    }

    return this.processResponse(this.fsApi.post('auth/apple', data, config));
  }



  public processResponse(observable: Observable<any>): Observable<any> {
    return new Observable(observer => {
      observable
      .subscribe(response => {
        this.sessionService.set(response);
        observer.next(response);
        this.appUseService.claim();
      },
      e => {
        observer.error(e);
      },
      () => {
        observer.complete();
      });
    });
  }

  public processSignin(response, newAccount = false) {

    this._actualizeLastPromptDate();

    const redirectUrl = this.fsStore.get('redirect_url');
    if (redirectUrl && !newAccount) {
      this.router.navigateByUrl(new DefaultUrlSerializer().parse(redirectUrl))
    } else {
      this.router.navigateByUrl('/');
      // newAccount ? this.router.navigateByUrl('/wines') : this.router.navigateByUrl('/wines');
    }

    this.fsStore.remove('redirect_url');


    if (!response.account.passwordChange) {
      if (newAccount) {
        this.fsMessage.success(`Welcome ${response.account.firstName} ${response.account.lastName}`);
      } else if (!response.account.passwordChange) {
        this.fsMessage.success(`Welcome back ${response.account.firstName} ${response.account.lastName}`);
      }
    }
  }

  public destroy() {
    this.fsStore.clear();
    this.sessionService.destroy();
  }

  public signout() {
    this.fsApi.post('auth/signout')
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this.destroy();
        this.router.navigateByUrl('/wines');
      });
  }

  private _actualizeLastPromptDate() {
    const account = this.sessionService.account();
    const lastPromptDate = parse(account.lastPromptDate);
    const anonymousLastPromptDate = parse(this.fsStore.get('last_prompt_date'));

    if (
      (anonymousLastPromptDate && !lastPromptDate) ||
      (anonymousLastPromptDate && lastPromptDate && isAfter(anonymousLastPromptDate, lastPromptDate))
    ) {
      account.lastPromptDate = iso8601(anonymousLastPromptDate);
      this.sessionService.account(account);
    }
  }

}
