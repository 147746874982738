import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { ItemService } from '@app/core';


@Injectable({
  providedIn: 'root',
})
export class MapService {

  public viewCount = 0;

  constructor(
    private _route: ActivatedRoute,
    private _itemService: ItemService,
  ) { }

  public fetchItems(query: any = {}): Observable<any> {
    const queryParams = this._route.snapshot.queryParams;

    return this._itemService.getOrganizations({
      lat: queryParams.lat,
      lng: queryParams.lng,
      ...query,
      order: 'distance',
      types: true,
      typeNotShowOnMap: true,
      organizationNotOnMap: true,
      // public: true,
      // featureCollections: true,
      addedToList: true,
    }, { key: null })
  }
}
