<div class="pane" [ngClass]="{ 'has-tasting-room': item.data['tasting_room'] && item.type.featureTastingRoom }" fxLayout="row" fxLayoutAlign="space-between">
	<ng-container *ngIf="item.data['tasting_room'] && item.type.featureTastingRoom">
		<div>If you're tasting at the winery now, ask the winery to verify your tasting experience.</div>
		<div>
			<button type="button" mat-stroked-button (click)="verifyTastingDialog()">Verify Tasting</button>
		</div>
	</ng-container>
	<ng-container *ngIf="!(item.data['tasting_room'] && item.type.featureTastingRoom)">
		<app-item-rating [item]="item"></app-item-rating>
		<b>No tasting room</b>
	</ng-container>
</div>
