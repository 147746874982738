import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';
import { find } from '@firestitch/common';

import { Subscription } from '../../shared/interfaces';
import { StateBase, SubscriptionFeatures } from 'app/shared/types';


@Injectable()
export class SubscriptionService {

  public features = [
    { name: 'Experiences', field: SubscriptionFeatures.FeatureExperiences },
    { name: 'Lists & Routes', field: SubscriptionFeatures.FeatureCollections },
    { name: 'Inline Analytics', field: SubscriptionFeatures.FeatureInlineAnalytics }
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: Subscription = { id: null }): Subscription {
    data.featureExperiences = data.featureExperiences === false ? false : true; // default featureExperiences = 1;
    data.featureCollections = data.featureCollections === false ? false : true;
    data.featureInlineAnalytics = data.featureInlineAnalytics === false ? false : true;
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`subscriptions/${id}`, query, { key: 'subscription' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'subscriptions', data, Object.assign({ key: 'subscriptions' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`subscriptions/${data.id}`, data, Object.assign({ key: 'subscription' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('subscriptions', data, { key: 'subscription' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`subscriptions/${data.id}`, data, { key: 'subscription' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('subscriptions/order', data, { key: null });
  }

  // Don't check order field. Data with less value can be removed
  public findDefault(subscriptions: Subscription[]): Subscription[] {

    subscriptions = subscriptions || [];

    subscriptions.map(item => item.default = 0);

    const data: Subscription = find(subscriptions, item => {
      return item.state !== StateBase.Deleted;
    });

    if (data) {
      data.default = 1;
    }

    return subscriptions;
  }

}
