import { Component, Input, OnInit } from '@angular/core';

import { ThemingService } from '@libs/theming';


@Component({
  selector: 'item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss']
})
export class ItemImageComponent {

  public item: any;
  public classes = [];
  @Input('item') set setItem(item) {
    this.item = item;
    this.classes = ['item-image', 'item-' + item.class];

    if (item.defaultObject) {
      this.classes.push('item-' + item.defaultObject.class);
    }
  };

  @Input('imageHeight') set setImageHeight(value) {
    this.imageHeight = value + 'px';
  }

  @Input('imageWidth') set setImageWidth(value) {
    this.imageWidth = value + 'px';
  }

  public imageHeight = '';
  public imageWidth = '';
  public settings = null;

  constructor(
    private _themingService: ThemingService,
  ) {}

  public get themeData$() {
    return this._themingService.themeData$
  }

}
