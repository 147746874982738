import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';

import { CurateListListType, CuratedListDigestFrequency } from '@app/shared/enums';
import { CuratedList } from '@app/shared/interfaces';
import { CollectionShowOnPage } from '@app/shared/types';

interface CuratedListsResponse {
  paging: any;
  curatedLists: CuratedList[];
}


@Injectable({
  providedIn: 'root',
})
export class CuratedListService {

  constructor(private _fsApi: FsApi) { }

  public create(data: CuratedList = { id: null }): CuratedList {
    data.listType = data.listType || CurateListListType.Filtered;
    data.digestFrequency = data.digestFrequency || CuratedListDigestFrequency.Daily;
    data.showOnPage = data.showOnPage || CollectionShowOnPage.Wines;
    data.filterModels = Array.isArray(data.filterModels) ? data.filterModels : [];
    data.image = data.image || {};
    data.filterModelsSelectedCount = data.filterModels
      .reduce((acc, value) => {
        if (!value.model) {
          return acc;
        }

        if (Array.isArray(value.model) && !value.model.length) {
          return acc;
        }

        acc += 1;

        return acc;
      }, 0);

    if (data.sendAsDigest === null) {
      data.sendAsDigest = true;
    }

    return data;
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, 'curatedlists', data, Object.assign({ key: 'curatedLists' }, config));
  }

  public get(id, data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, `curatedlists/${id}`, data, Object.assign({ key: 'curatedList' }, config));
  }

  public delete(listId: number, options = {}): Observable<CuratedList> {
    return this._fsApi.request(`DELETE`, `curatedlists/${listId}`, {}, { key: 'curatedList',...options });
  }

  public put(data, config = {}): Observable<CuratedList> {
    return this._fsApi.put(`curatedlists/${data.id}`, data, Object.assign({ key: 'curatedList' }, config));
  }

  public post(config = {}): Observable<CuratedList> {
    return this._fsApi.post(`curatedlists`, null, Object.assign({ key: 'curatedList' }, config));
  }

  public image(listId: number, file): Observable<CuratedList> {
    return this._fsApi.post(`curatedlists/${listId}/image`, { file: file }, { key: 'curatedList' });
  }

  public orderObjects(data): Observable<any> {
    return this._fsApi.put(`curatedlists/order`, data, { key: null });
  }

  public previewEmail(id, data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`PUT`, `curatedlists/${id}/emailpreview`, data, Object.assign({ key: null }, config));
  }

  public test(listId: number, data, config = {}): Observable<any> {
    return this._fsApi.post(`curatedlists/${listId}/test`, data, Object.assign({ key: null }, config));
  }


}
