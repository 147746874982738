<div (click)="navigateByUrl('/account')" class="account-menu" [ngClass]="{ authorized: !!account }">
  <fs-badge *ngIf="account" size="35" shape="circle" [image]="account.image.small"></fs-badge>
  <!--<fs-badge size="35" shape="circle" [image]="'https://firestitch-dev.s3.amazonaws.com/pub/ai/small/c4ad57b49a6829d2efbf687504a06fd3_1551202837.png'"></fs-badge>-->
  <fs-badge
    *ngIf="!account"
    size="35"
    color="#fff"
    icon="person"
    iconSize="1.4"
  ></fs-badge>

  <div *ngIf="account" class="current-user">
    <span class="user-name">{{ account.firstName }} {{ account.lastName }}</span>
  </div>
</div>
