<ng-container *ngFor="let item of actions">
  <button
    *ngIf="!item.raised"
    type="button"
    mat-button
    [ngClass]="{ 'mat-primary': item.primary, 'hide-mobile': item.hideMobile }"
    (click)="onClick(item)">{{ item.label }}
  </button>
  <button
    *ngIf="item.raised"
    type="button"
    mat-raised-button
    [ngClass]="{ 'mat-primary': item.primary, 'hide-mobile': item.hideMobile }"
    (click)="onClick(item)">{{ item.label }}
  </button>
</ng-container>

<fs-menu>
  <ng-template
    *ngFor="let item of kebabActions"
    fs-menu-item
    (click)="onClick(item)"
  >
    {{ item.label }}
  </ng-template>
</fs-menu>
