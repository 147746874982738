import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MessageWebhookData {

  constructor(private _fsApi: FsApi) { }

  public create(data) {
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`messages/webhooks/${id}`, query, { key: 'webhook' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'messages/webhooks', data, { key: 'webhooks', ...config });
  }

  public post(data = {}, config = {}): Observable<any> {
    return this._fsApi.post('messages/webhooks', data, { key: 'webhook', ...config });
  }

  public delete(data = {}, config = {}): Observable<any> {
    return this._fsApi.delete('messages/webhooks', data, { key: 'webhook', ...config });
  }

}
