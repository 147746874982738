<h2>
  <ng-container *ngIf="itemType === 'wine'; else defaultTitleText">
    Your Tasting Notes
  </ng-container>

  <ng-template #defaultTitleText>
    Your Notes
  </ng-template>
</h2>
<div class="subheading-2">You can add your own notes about this {{ itemType }}</div>

<div class="bordered-list notes" *ngIf="item.notes.length">
  <ng-container *ngFor="let note of item.notes">
    <div class="note list-item" authorizedClick (click)="openNote(note)">
      <mat-icon class="note-icon small-icon">chat</mat-icon>
      <div class="info">
        <div class="title">
          {{ note | noteName }}
        </div>
        <div class="date">
          {{ note.createDate | date: 'LLL dd, yyyy'}}
        </div>
      </div>
      <mat-icon class="chevron">keyboard_arrow_right</mat-icon>
    </div>
  </ng-container>
</div>

<button mat-stroked-button authorizedClick (click)="addNotes()" class="mat-fullwidth">
  <ng-container *ngIf="itemType === 'wine'; else defaultButtonText">
    Add Tasting Notes
  </ng-container>

  <ng-template #defaultButtonText>
    Add Notes
  </ng-template>
</button>
