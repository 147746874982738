<ng-container *ngIf="back.observers.length">
  <a *ngIf="!disableRedirect" class="back" fsNavBack><mat-icon>keyboard_arrow_left</mat-icon></a>
  <a *ngIf="disableRedirect" class="back"><mat-icon>keyboard_arrow_left</mat-icon></a>
</ng-container>
<ng-content select="[navbarBack]"></ng-content>
<ng-content select="[navbarMenu]"></ng-content>
<ng-content select="[navbarImage]"></ng-content>
<div class="middle">
  <div class="titles">
    <div class="supertitle">
      <ng-content select="[navbarSupertitle]"></ng-content>
    </div>
    <div class="title">
      <ng-content select="[navbarTitle]"></ng-content>
    </div>
    <div class="subtitle">
      <ng-content select="[navbarSubtitle]"></ng-content>
    </div>
  </div>
  <ng-content select="[navbarLogo]"></ng-content>
</div>
<ng-content select="[navbarRightActions]"></ng-content>