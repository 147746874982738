import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import {
  TastingDialogComponent
} from './components';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        TastingDialogComponent
    ],
    exports: [
        TastingDialogComponent
    ]
})
export class TastingDialogModule { }
