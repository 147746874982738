import { Component, Input, OnInit } from '@angular/core';

import { Organization } from '../../interfaces';


@Component({
  selector: 'organization-price',
  templateUrl: './organization-price.component.html',
  styleUrls: ['./organization-price.component.scss']
})
export class OrganizationPriceComponent implements OnInit {

  @Input() public organization: Organization = null;

  constructor() { }

  public ngOnInit() {
  }

}
