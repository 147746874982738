import { Component, Inject, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FsMessage } from '@firestitch/message';
import { FilterComponent, ItemType } from '@firestitch/filter';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AttributeService } from 'app/core/services';
import { Attribute } from 'app/shared/interfaces';
import { AttributeManageComponent } from '../attribute-manage';
import { AttributeEditComponent } from '../attribute-edit';


@Component({
  templateUrl: './object-attribute-selector.component.html',
  styleUrls: ['./object-attribute-selector.component.scss']
})
export class ObjectAttributeSelectorComponent implements OnInit, OnDestroy {

  public title;
  public className;
  public projectId = null;
  public class = null;
  public objectId = null;
  public attributes: Attribute[] = [];
  public attributesSelected: { [index: number]: boolean } = {};
  public config = null;

  private _destroy = new Subject();

  @ViewChild('filter', { static: true }) filter: FilterComponent;

  @HostListener('window:keyup.esc') onKeyUp() {
    this.close();
  }

  constructor(
    private _dialogRef: MatDialogRef<any>,
    private _fsMessage: FsMessage,
    private _attributeService: AttributeService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public ngOnInit() {

    const cls = this._attributeService.getClass(this.data.class);

    this.projectId = this.data.projectId;
    this.objectId = this.data.objectId;
    this.class = this.data.class;
    this.title = cls.pluralName;
    this.className = cls.name;

    this.data.attributes.forEach(attribute => {
      this.attributesSelected[attribute.id] = true;
    });

    this._dialogRef.backdropClick().subscribe(() => {
      this.close();
    })

    this.config = {
      change: query => {
        this.load(query);
      },
      init: query => {
        this.load(query);
      },
      reload: query => {
        this.load(query);
      },
      items: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        }
      ]
    };
  }

  public ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  public toggle(attributeData: { attribute: Attribute, selected: boolean }) {

    let query$: Observable<any> = null;
    this.attributesSelected[attributeData.attribute.id] = attributeData.selected;
    if (attributeData.selected) {
      query$ = this._attributeService.assignObject(attributeData.attribute.id, this.objectId);
    } else {
      query$ = this._attributeService.deleteObject(attributeData.attribute.id, this.objectId);
    }

    query$
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this._fsMessage.success(`Saved Changes`);
      }, () => {
        this.attributesSelected[attributeData.attribute.id] = !attributeData.selected;
      });
  }

  public manageTags() {
    const dialogRef = this._dialog.open(AttributeManageComponent, {
      width: '30%',
      minWidth: '400px',
      data: { projectId: this.projectId,
              class: this.class }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.filter.change();
      });
  }

  public createAttribute(attribute: Attribute = { id: null }) {

    attribute.projectId = this.projectId;
    attribute.class = this.class;

    const dialogRef = this._dialog.open(AttributeEditComponent, {
      data: { attribute }
    });

    dialogRef.afterClosed()
    .subscribe(response => {
      if (response) {
        this.load();
      }
    });
  }

  public close() {

    const attributes = [];
    this.attributes.forEach(attribute => {
      if (this.attributesSelected[attribute.id]) {
        attributes.push(attribute);
      }
    });

    this._dialogRef.close(attributes);
  }

  private load(query: any = {}) {

    query.class = this.class;
    if (this.projectId) {
      query.projectId = this.projectId;
    }

    this._attributeService.gets(query)
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(attributes => this.attributes = attributes);
  }
}
