  <fs-dialog>
    <h1 mat-dialog-title>App Update Required</h1>
    <mat-dialog-content>
      <div fxLayout="column">
        <p>{{build.message}}</p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        color="primary"
        (click)="install()"
        >
        Install
      </button>
    </mat-dialog-actions>
  </fs-dialog>
