import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  templateUrl: './confirmed-dialog.component.html'
})
export class VerifyTastingConfirmedDialogComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<VerifyTastingConfirmedDialogComponent>
  ) { }

  public ngOnInit() {
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
