<div class="super-heading">{{ item.typeName }}</div>
<h1 *ngIf="headingType === '1'">
  <ng-container [ngTemplateOutlet]="itemName"></ng-container>
</h1>
<h2 *ngIf="headingType === '2'">
  <ng-container [ngTemplateOutlet]="itemName"></ng-container>
</h2>

<ng-template #itemName>
  {{ item.name }}
  <span *ngIf="item.isWine">
    <ng-container *ngIf="item.meta.nonVintage">NV</ng-container>
    <ng-container *ngIf="!item.meta.nonVintage && item.meta.vintage">
      {{ item.meta.vintage }}
    </ng-container>
  </span>
</ng-template>

<div [ngClass]="'subheading-' + headingType" *ngIf="!item.isOrganization && item.organization && !hideOrganizationName">
  <a [routerLink]="['/organizations/', item.organization?.id]">{{ item.organization?.name }}</a>
</div>

<app-item-rating *ngIf="showRating" [item]="item"></app-item-rating>

<div [ngClass]="'subheading-' + headingType" class="item-location" *ngIf="item.isOrganization && item.data.location">
  <i class="material-icons small-icon">near_me</i> <span>{{ item.data.location }}</span>
</div>

<div *ngIf="item.isOrganization && item.data.distanceInKm" class="distance-in-km">{{ item.data.distanceInKm | fsFormatNumber: 0 }} km</div>

<app-item-created-by *ngIf="item.isCollection" [item]="item"></app-item-created-by>

<app-percent-match-flag *ngIf="item.isWine" [percentMatch]="item.data.percentMatch"></app-percent-match-flag>
