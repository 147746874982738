import { Component, Input, OnInit } from '@angular/core';

import { Type } from '../../../shared/interfaces';


@Component({
  selector: 'app-type-image',
  templateUrl: `./type-image.component.html`,
  styleUrls: ['./type-image.component.scss']
})
export class TypeImageComponent implements OnInit {

  @Input() public size = 30;
  @Input() public type: Type = null;

  constructor() { }

  public ngOnInit() {
  }

}
