import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-bouncing-icon',
  templateUrl: './bouncing-icon.component.html',
  styleUrls: ['./bouncing-icon.component.scss']
})
export class BouncingIconComponent implements OnInit {

  @Input() public icon: string = null;
  @Input() public size = '70px';
  @Input() public color: string = null;

  constructor() { }

  public ngOnInit() {

  }

}
