import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { filter, capitalize } from 'lodash-es';

import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { FsListComponent, FsListConfig } from '@firestitch/list';
import { FsPrompt } from '@firestitch/prompt';
import { list, nameValue, sort, index } from '@firestitch/common';
import { FsNavService } from '@firestitch/nav';
import { ItemType } from '@firestitch/filter';

import { ObjectService, AccountService } from '@app/core/services';
import { Object } from '@app/shared/interfaces';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('list', { static: true })
  public list: FsListComponent = null;
  public config: FsListConfig = null;

  public classesFlatten: any = null;
  public classesIndexed: any = null;

  private queryParams: any = null;

  private _destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private objectService: ObjectService,
    private accountService: AccountService,
    private fsNavService: FsNavService,
    private fsPrompt: FsPrompt
  ) { }

  public ngAfterViewInit() {
    this.setTitle();
  }

  public ngOnInit() {

    this.classesFlatten = list(this.objectService.classes, 'name', 'value');
    this.classesIndexed = index(this.objectService.classes, 'value');

    this.fsNavService.routeChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.setTitle());

    this.config = {
      filters: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        },
        {
          name: 'modifyDate',
          type: ItemType.Date,
          label: 'Last Modified'
        },
        {
          name: 'creatorAccountId',
          label: 'Account',
          type: ItemType.AutoCompleteChips,
          values: keyword => {
            return this.accountService.gets({ keyword })
              .pipe(
                map(response => nameValue(response, 'name', 'id'))
              );
          }
        },
        // {
        //   name: 'projectId',
        //   type: ItemType.Select,
        //   label: 'Project',
        //   multiple: true,
        //   model: [],
        //   values: () => {
        //     return this.projectService.gets()
        //       .pipe(
        //         map(response => nameValue(response, 'name', 'id'))
        //       );
        //   }
        // },
        {
          name: 'class',
          type: ItemType.Select,
          label: 'Object Class',
          multiple: true,
          values: () => {

            const seachClasses = filter(this.objectService.classes, (cls) => {
              return this.objectService.searchClasses.indexOf(cls.value) >= 0;
            });

            return sort(seachClasses, 'value');
          }
        }
      ],
      actions: [],
      rowActions: [],
      fetch: query => {
        Object.assign(query, {  objects: true,
                                projects: true,
                                modifierAccounts: true });

        if (!query.class) {
          query.class = this.objectService.searchClasses.join(',');
        }

        return this.objectService.gets(query, { key: null })
          .pipe(
            map(response => ({ data: response.objects, paging: response.paging }))
          );
      }
    }
  }

  public navigateTo(data: Object) {
    this.objectService.navigate(data);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private setTitle() {

    this.queryParams = this.route.snapshot.queryParams;

    const query: any = {};

    if (this.queryParams) {
      query.keyword = this.queryParams.keyword || null;
      query.class = this.queryParams.class ? this.queryParams.class.split(',') : [];
      setTimeout(() => {
        this.list.filterRef.updateValues(query);
      }, 0);

    }

    this.fsNavService.setTitle('Search', capitalize(this.queryParams.class));
  }

}
