import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { Angulartics2 } from 'angulartics2';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { ItemService } from '@app/core/services';
import { ItemAddCustomNoteComponent } from './custom-note';
import { ItemAddFlavoursComponent } from './flavours';
import { ItemAddFinishesComponent } from './finishes';
import { NotesDataService } from '../../data/notes-data.service';
import { Note } from '../../../interfaces';
import { getItemTypeNameLabel } from '../../../helpers';


@Component({
  selector: 'app-item-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class ItemAddNotesComponent implements OnInit, OnDestroy {

  public features = [];
  public itemType: string;
  public note: Note;

  private _hasFinishes = false;
  private _hasFlavours = false;
  private _hasCustomNode = false;

  // messages
  private _ratedTemplate = 'Rating Saved';
  private _firstRatedTemplate =
    `<div class="heading">Added to My Cellar</div>Things I've Rated`;

  private _destroy$ = new Subject();

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<ItemAddNotesComponent>,
    private _fsMessage: FsMessage,
    private _notesData: NotesDataService,
    private _itemService: ItemService,
    private _angulartics2: Angulartics2,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (!this.data.item.accountItem) {
      this.data.item.accountItem = {};
    }
  }

  public ngOnInit() {
    this.itemType = getItemTypeNameLabel(this.data.item);

    this._notesData.get(this.data.item.id)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((note: Note) => {
        this.note = note;
      });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public addNote() {
    const dialogRef = this._dialog.open(ItemAddCustomNoteComponent, {
      data: {
        itemId: this.data.item.id,
        note: this.note,
      }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        this._hasCustomNode = this.note.popularNotes.length > 0 || !!this.note.customNote;
      });
  }

  public addFlavours() {
    const dialogRef = this._dialog.open(ItemAddFlavoursComponent, {
      data: {
        itemId: this.data.item.id,
        note: this.note,
      }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        this._hasFlavours = this.note.flavours.length > 0;
      });
  }

  public addFinishes() {
    const dialogRef = this._dialog.open(ItemAddFinishesComponent, {
      data: {
        itemId: this.data.item.id,
        note: this.note,
      }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        this._hasFinishes = this.note.finishes.length > 0;
      });
  }

  public close(data = null) {

    if (this._hasFinishes || this._hasFlavours || this._hasCustomNode) {
      this._angulartics2.eventTrack.next({
        action: 'add_note',
        properties: { objectId: this.data.item.id, noteId: this.note.id  }
      });

      this._dialogRef.close(this.note);
    } else {
      this._dialogRef.close(null);
    }
  }

  public ratingChanged(newRating) {
    const firstRated = !this.data.item.accountItem.rating;
    const template = firstRated ? this._firstRatedTemplate : this._ratedTemplate;

    this.data.item.accountItem.rating = newRating;

    this._itemService.rate(this.data.item)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((response) => {

        if (response.globalRating) {
          Object.assign(this.data.item.meta, response.globalRating);
        }

        if (this.data.item.accountItem.rating) {
          this._angulartics2.eventTrack.next({
            action: firstRated ? 'rate' : 'edit_rating',
            properties: { objectId: this.data.item.id, tasterRating: this.data.item.accountItem.rating  }
          });

          this._fsMessage.success(template, { icon: 'star' });
        } else {
          this._angulartics2.eventTrack.next({
            action: 'unrate',
            properties: { objectId: this.data.item.id  }
          });

          this._fsMessage.success('Rating Removed');
        }
        this.data.item.accountItem.triedIt = response.triedIt;
        this.data.item.accountItem.rate = !!this.data.item.accountItem.rating;
      });
  }
}
