import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-item-back-dialog-button',
  templateUrl: './back-dialog-button.component.html',
})
export class BackDialogButtonComponent {
  constructor(
    private _dialogRef: MatDialogRef<any>,
  ) {}

  @HostListener('click')
  public close() {
    this._dialogRef.close();
  }

}
