import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ThemeVariable } from '@libs/theming/consts';

import { SettingService } from '@app/core';
import { TakeTasteTestComponent } from '@app/taster/modules/search/modules/take-taste-test/components';


@Component({
  selector: 'app-percent-match-flag',
  templateUrl: './percent-match-flag.component.html',
  styleUrls: [
    './percent-match-flag.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentMatchFlagComponent implements AfterViewInit, OnChanges {

  @Input()
  public percentMatch: number;

  @ViewChild('flag', { read: ElementRef })
  public flagEl: ElementRef;

  constructor(
    private _brand: SettingService,
    private renderer: Renderer2,
    private _dialog: MatDialog,
  ) {
  }

  public ngAfterViewInit(): void {
    if (this.flagEl) {
      this._matchFlagBg();
    }
  }


  public ngOnChanges(changes: SimpleChanges) {
    if (changes.percentMatch && this.flagEl) {
      this._matchFlagBg();
    }
  }

  public openTakeTasteTestDialog(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    this._dialog.open(TakeTasteTestComponent, {
      data: {  }
    });
  }

  private _matchFlagBg() {
    if (this.percentMatch === null) {
      this._updateFlagBg(ThemeVariable.FlagColorUnknown);

      return;
    }

    if (this.percentMatch > 0.9) {
      this._updateFlagBg(ThemeVariable.FlagColor91100);
    } else if (this.percentMatch > 0.8) {
      this._updateFlagBg(ThemeVariable.FlagColor8190);
    } else if (this.percentMatch > 0.7) {
      this._updateFlagBg(ThemeVariable.FlagColor7180);
    } else if (this.percentMatch > 0.6) {
      this._updateFlagBg(ThemeVariable.FlagColor6170);
    } else if (this.percentMatch > 0.5) {
      this._updateFlagBg(ThemeVariable.FlagColor5160);
    } else if (this.percentMatch > 0) {
      this._updateFlagBg(ThemeVariable.FlagColor050);
    }
  }

  private _updateFlagBg(name) {
    this.renderer.setStyle(this.flagEl.nativeElement, 'background', `var(--${name})`)
  }

}
