import { Injectable } from '@angular/core';

import { Wine, Object } from '@app/shared/interfaces';
import { Item } from '@app/taster/modules/item/models';


@Injectable()
export class WineService {

  constructor() { }

  public getName(type, data: Wine | Object | Item) {

    const name = data.name || '';

    if (type === 'wine') {
      return `${name} ${this.getVintage(data['vintage'], data['nonVintage'])}`;
    }

    return `${name} ${data['meta'] ? this.getVintage(data['meta'].vintage, data['meta'].nonVintage) : ''}`;
  }

  public getVintage(vintage: number, nonVintage: boolean): string {
    if (nonVintage) {
      return 'NV';
    }

    if (vintage) {
      return `${vintage}`;
    }
    
    return '';
  }

}
