import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@app/main/home';
import { EnvironmentEmptyComponent } from '@app/main/environment-empty';
import { AdminGuard, LoggedInGuard, LoggedOutGuard, MobileWebGuard } from '@app/shared/guards';
import { CardComponent } from '@app/shared/components/outlets';

import { NotFoundComponent } from '@app/shared/components/not-found';
import { SideNavComponent } from '@app/main/side-nav';
import { CanActivateWithoutConnection } from '@app/shared/guards/can-activate-without-connection.service';
import { ShareClickComponent } from './main/share-click/share-click.component';

export const routes: Routes = [
  { path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [ CanActivateWithoutConnection ],
    children: [
      {
        path: '',
        component: SideNavComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('@app/taster/taster.module').then(m => m.TasterModule),
            data: { bodyClass: 'body-taster-portal' }
          },
          {
            path: 'manage',
            loadChildren: () => import('@app/manage/manage.module').then(m => m.ManageModule),
            canActivate: [ LoggedInGuard ],
            data: { bodyClass: 'body-manage' }
          },
          {
            path: 'labs',
            loadChildren: () => import('@app/labs/labs.module').then(m => m.LabsModule),
          },
          {
            path: 'search',
            loadChildren: () => import('@app/search/search.module').then(m => m.SearchModule),
          },
          {
            path: 'account',
            loadChildren: () => import('@app/account/account.module').then(m => m.AccountModule),
            canActivate: [ LoggedInGuard ],
          },
          {
            path: 'admin',
            loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule),
            canActivate: [ AdminGuard ],
          },
          {
            path: 'system',
            loadChildren: () => import('./system/system.module').then(m => m.SystemModule),
            canActivate: [ AdminGuard ]
          },
          {
            path: 'environment-empty',
            component: EnvironmentEmptyComponent,
            data: {
              fsNav: { root: true }, bodyClass: 'body-root'
            }
          },
        ],
      },
    ],
    canActivate: [MobileWebGuard],
  },
  {
    path: '',
    component: CardComponent,
    data: { bodyClass: 'body-password' },
    children: [{ path: 'password', loadChildren: () => import('app/password/password.module').then(m => m.PasswordModule) }],
    canActivateChild: [ CanActivateWithoutConnection ],
  },
  {
    path: '',
    component: CardComponent,
    data: { bodyClass: 'body-card body-invite' },
    children: [{ path: 'invite', loadChildren: () => import('app/invite/invite.module').then(m => m.InviteModule) }],
    canActivateChild: [ CanActivateWithoutConnection ],
  },
  {
    path: '',
    component: CardComponent,
    children: [{ path: 'signin', loadChildren: () => import('app/signin/signin.module').then(m => m.SigninModule) }],
    data: { bodyClass: 'body-card body-signin' },
  },
  {
    path: '',
    component: CardComponent,
    children: [{ path: 'signup', loadChildren: () => import('app/signup/signup.module').then(m => m.SignupModule) }],
    data: { bodyClass: 'body-card body-signup' },
    canActivate: [ LoggedOutGuard ],
  },
  {
    path: 'shareclick',
    component: ShareClickComponent,
  },
  {
    path: 'blank',
    component: CardComponent,
  },
  {
    path: 'notfound',
    component: SideNavComponent,
    children: [
      { path: '', pathMatch: 'full', component: NotFoundComponent, data: { bodyClass: 'body-notfound' } }
    ],
  },
  { path: '**', redirectTo: '/notfound' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
    routes,
    {
    useHash: (+document.location.port === 8080 || document.location.protocol === 'file:'),
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
