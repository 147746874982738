import { Image } from './image';
import { TypeClasses } from '../types';


export interface Type {
  readonly id: number;
  name?: string;
  pluralName?: string;
  state?: string;
  order?: number;
  class?: TypeClasses;
  image?: Image;
  mapImage?: Image;
  actionButtonType?: string;
  actionButtonLabel?: string;
  guid?: string;
  configs?: { settings: string[] };
  featureWinesProduced?: number;
  featureWineClub?: number;
  featureTastingRoom?: number;
  featureAwardsWon?: number;
  featurePurchasableWineLists?: number;
  organizationInviteMessageId?: number;
  notShownOnMap?: boolean;
  icon?: string;
  color?: string;

  // deprecated fields

  /**
   * @deprecated
   */
  workflowId?: number;
}
