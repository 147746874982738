import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FsMessage } from '@firestitch/message';
import { OrganizationService } from 'app/core/services';

import { FsTransferService } from '@firestitch/transfer';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-organization-import-dialog',
  templateUrl: './organization-import-dialog.component.html'
})
export class OrganizationImportDialogComponent {

  @ViewChild('fsImport', { static: true }) fsImport = null;

  private _$destroy = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<OrganizationImportDialogComponent>,
    private fsMessage: FsMessage,
    private _organizationService: OrganizationService,
    private transfer: FsTransferService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  config = () => {
    return this._organizationService.importConfig();
  }

  import(fsFile) {
    this.fsImport.import( this._organizationService.import({ file: fsFile.file }) );
  }

  sample() {
    return this._organizationService.importSample();
  }

  reset() {
    this.fsImport.reset();
  }

  close(data = null) {
    this._dialogRef.close(data);
  }

}
