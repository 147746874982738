import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';


@Injectable()
export class SectionService {

  constructor(private _fsApi: FsApi) { }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`sections/${id}`, query, { key: 'section' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'sections', data, Object.assign({ key: 'sections' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`sections/${data.id}`, data, Object.assign({ key: 'section' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('sections', data, { key: 'section' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`sections/${data.id}`, data, { key: 'section' });
  }

  public order(data, config = {}): Observable<any> {
    return this._fsApi.put(`sections/order`, data, Object.assign({ key: null }, config));
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }
}
