import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Angulartics2 } from 'angulartics2';

import { Subject } from 'rxjs';
import { takeUntil, switchMap, filter } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';
import { FsPrompt } from '@firestitch/prompt';

import { ItemService } from '@app/core/services';
import { ShareMenuDialogService } from '@app/taster/modules/share-menu';
import { Item } from '../../models';
import { ItemAddNotesComponent } from '../../components/your-notes';
import { AddToListService } from '../../services';


@Component({
  selector: 'app-item-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit, OnDestroy {

  private _item: Item = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public get item() {
    return this._item;
  }

  private _confirmMessage =
    `Are you sure you want to remove this item from the "Things I've Tried" in your Cellar?`;

  private _triedTemplateMessage =
    `<div class="heading">Added to My Cellar</div>Things I've Tried`;

  private _untriedTemplateMessage =
    `<div class="heading">Removed from My Cellar</div>Things I've Tried`;

  private _visitsTemplateMessage =
  `Do you want to add a visit to this organization?`;

  private _destroy$ = new Subject();

  constructor(
    private _fsMessage: FsMessage,
    private _fsPrompt: FsPrompt,
    private _itemService: ItemService,
    private _addToListService: AddToListService,
    private _dialog: MatDialog,
    private _angulartics2: Angulartics2,
    private _shareDialog: ShareMenuDialogService,
  ) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public rate() {
    const dialogRef = this._dialog.open(ItemAddNotesComponent, {
      data: { item: this.item }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => {
        if (response) {
          this._item.notes.push(response);
        }
      });
  }

  public addToList() {
    this._addToListService.openDialog(this.item)
      .afterClosed()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => { });
  }

  public share() {
    this._shareDialog.openMenu(this.item);
  }

  public toggleTryIt() {
    if (!this.item.accountItem.triedIt) {
      this.submitTryIt();
    } else {
      this.removeTryIt();
    }
  }

  public visits() {
    this._fsPrompt.confirm({
      title: 'Confirm',
      commitLabel: 'Yes',
      template: this._visitsTemplateMessage
    })
    .pipe(
      filter((response) => !!response),
      switchMap(() => {
        this._angulartics2.eventTrack.next({
          action: 'manual_visit',
          properties: {
            objectId: this.item.id,
          }
        });


        return this._itemService.visit(this.item.id)
          .pipe(
            takeUntil(this._destroy$),
          );
      }),
    )
    .subscribe((response) => {
      this.item.accountItem.visits = response.visits;
    });
  }

  private removeTryIt() {
    this._fsPrompt.confirm({
      title: 'Confirm',
      template: this._confirmMessage
    }).subscribe(() => {
      this.submitTryIt();
    })
  }

  private submitTryIt() {
    this.item.accountItem.triedIt = !this.item.accountItem.triedIt;
    this._itemService.tried(this.item.id, { triedIt: this.item.accountItem.triedIt})
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        if (response.triedIt) {

          this._angulartics2.eventTrack.next({
            action: 'tried_it',
            properties: { objectId: this.item.id }
          });

          this._fsMessage.success(this._triedTemplateMessage);
        } else {
          this._fsMessage.success(this._untriedTemplateMessage);
          this._angulartics2.eventTrack.next({
            action: 'untried_it',
            properties: { objectId: this.item.id }
          });
        }
      });
  }

}
