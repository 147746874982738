<fs-dialog mobileMode="bottom">
  <h1 mat-dialog-title>Sign In or Sign Up to</h1>
  <mat-dialog-content>
    <content-widget
      tag="signin_prompt"
    ></content-widget>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="column">
    <button type="button" mat-button mat-raised-button color="primary" (click)="proceed('signin')" [mat-dialog-close]="true">Sign In</button>
    <button type="button" mat-button mat-stroked-button (click)="proceed('signup')" [mat-dialog-close]="true">Sign Up</button>
  </mat-dialog-actions>
</fs-dialog>
