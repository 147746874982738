<ng-container *ngIf="item">
  <div class="action-bar">

    <div class="action-bar-item">
      <a authorizedClick (click)="rate()"
         [ngClass]="{ unselected: !item.accountItem?.rating, 'rate-selected': item.accountItem?.rating }">
        <mat-icon svgIcon="item-rate"></mat-icon>
        <div *ngIf="item.accountItem?.rating; else noRate" class="icons-text">Rated {{ item.accountItem?.rating }}/5</div>
        <ng-template #noRate>
          <div class="icons-text"> Rate </div>
        </ng-template>
      </a>
    </div>

    <div *ngIf="!item.isOrganization" class="action-bar-item">
      <a authorizedClick (click)="toggleTryIt()"
         [ngClass]="{ unselected: !item.accountItem?.triedIt, 'tried-it-selected': item.accountItem?.triedIt }">
        <mat-icon svgIcon="item-tried-it"></mat-icon>
        <div class="icons-text"> Tried It </div>
      </a>
    </div>

    <div *ngIf="item.isOrganization" class="action-bar-item">
      <a authorizedClick (click)="visits()"
          [ngClass]="{ unselected: !item.accountItem?.visits, 'visits-selected': item.accountItem?.visits }">
        <mat-icon svgIcon="item-tried-it"></mat-icon>
        <div class="icons-text">
          <app-item-visits [visits]="item.accountItem?.visits"></app-item-visits>
        </div>
      </a>
    </div>

    <div class="action-bar-item" *ngIf="!item.isCollection">
      <a authorizedClick (click)="addToList()"
         [ngClass]="{ unselected: !item.addedToList, 'add-to-list-selected': item.addedToList }">
        <mat-icon svgIcon="item-add-to-list"></mat-icon>
        <div class="icons-text"> {{ item.addedToList ? 'Added' : 'Add'}} to List </div>
      </a>
    </div>

    <div class="action-bar-item">
      <a authorizedClick (click)="share()"
        [ngClass]="{ unselected: !item.accountItem?.shared, 'share-selected': item.accountItem?.shared }">
        <mat-icon svgIcon="item-share"></mat-icon>
        <div *ngIf="item.meta?.shares; else noShares" class="icons-text">
          {{item.meta?.shares}} {{ item.meta?.shares > 1 ? ' Shares' : 'Share' }}
        </div>

        <ng-template #noShares>
          <div class="icons-text"> Share </div>
        </ng-template>
      </a>
    </div>
  </div>
</ng-container>
