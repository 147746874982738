import { Component, Input, OnInit } from '@angular/core';

import { find } from '@firestitch/common';

import { CategoryService } from 'app/core/services';


@Component({
  selector: 'app-tag-featured',
  templateUrl: './tag-featured.component.html',
  styleUrls: ['./tag-featured.component.scss']
})

export class TagFeaturedComponent implements OnInit {

  @Input() public show: boolean = null;

  public tag = null;

  constructor(
    private _categoryService: CategoryService
  ) { }

  public ngOnInit() {
    this.tag = find(this._categoryService.tags, { value: 'featured' });
  }

}
