import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'app/shared/interfaces';


@Component({
  templateUrl: './finishes.component.html',
  // styleUrls: ['./add-note.component.scss']
})
export class ItemAddFinishesComponent implements OnInit {

  public subscription: Subscription = null;
  public selectorData;
  public selectedAttributes = [];

  public features = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.selectorData = { objectId: this.data.note.id };
    this.selectedAttributes = this.data.note.finishes.slice();
  }

  public selectionChanged(event) {
    if (event.selected) {
      this.data.note.finishes.push(event.value);
    } else {
      const finishIndex = this.data.note.finishes
        .findIndex((finish) => finish.id === event.value.id);

      if (finishIndex > -1) {
        this.data.note.finishes.splice(finishIndex, 1);
      }
    }
  }

}
