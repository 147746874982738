<fs-autocomplete-chips
  [(ngModel)]="subscribers"
  [fetch]="fetch"
  placeholder="Add subscribers..."
  [removable]="true"
  (selected)="onSelected($event)"
  (remove)="onRemove($event)"
  name="autocomplete-subscribers">
  <ng-template let-data="item" fs-autocomplete-chip>
    <b>{{ data.name }}</b> <{{data.email}}>
  </ng-template>
</fs-autocomplete-chips>
