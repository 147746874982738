import { Account } from './account';
import { Category } from './category';
import { Project } from './project';
import { Status } from './status';
import { Attribute } from './attribute';
import { Type } from './type';

export interface Task {
  id: number | null;
  assignedAccount?: Account;
  assignedAccountId?: number;
  category?: Category;
  type?: Type;
  categoryId?: number;
  createAccount?: Account;
  createAccountId?: number;
  createDate?: Date;
  class?: string;
  modifyAccount?: Account;
  modifyAccountId?: number;
  modifyDate?: Date;
  name?: string;
  number?: string;
  priority?: number;
  project?: Project;
  projectId?: number;
  state?: string;
  status?: Status;
  statusId?: number;
  tags?: Attribute[];
  typeId?: number;
  content?: string;
  dueDate?: Date;
  objectVersion?: any;
  objectVersionId?: any;
  workflowId?: number;
  workflowTaskId?: number;
  workflowStepId?: number;
  subscribers?: Account[];
  subscribersIds?: number[];
}
