import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Collection } from '@app/shared/interfaces';
import { CollectionOwnerType } from '@app/shared/types';
import { CollectionOwnerTypes } from '@app/shared/consts';
import { CollectionAssignDialogComponent } from '@app/shared/components/dialogs';
import { AddToListDialogComponent } from '../add-to-list-dialog';
import { Item } from '../../models';
import { AclQueryService } from '@app/core/services';


@Component({
  templateUrl: './add-to-collection-dialog.component.html',
  styleUrls: ['./add-to-collection-dialog.component.scss']
})
export class AddToCollectionDialogComponent implements OnInit {

  public item: Item = null;
  public collection: Collection = null;
  public collectionOwnerTypes = CollectionOwnerTypes;

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<AddToCollectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
  }

  public save(type: CollectionOwnerType) {

    let dialogRef = null;

    switch (type) {
      case CollectionOwnerType.Organization:
        dialogRef = this._dialog.open(CollectionAssignDialogComponent, {
          data: { collectionObjects: [this.item], isBulk: false }
        });

        dialogRef.afterClosed()
            .subscribe((response) => {
              if(response)
                this.close();
            });
        break;
      case CollectionOwnerType.Taster:
        this.close();
        dialogRef = this._dialog.open(AddToListDialogComponent, {
          data: { item: this.item }
        });
        break;
    }

    dialogRef.afterClosed().subscribe(response => { });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
