import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MessageTemplateData {

  constructor(private _fsApi: FsApi) { }

  public create(data) {
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`messages/templates/${id}`, query, { key: 'messageTemplate' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'messages/templates', data, { key: 'messageTemplates', ...config });
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`messages/templates/${data.id}`, data, { key: 'messageTemplate', ...config });
  }

  public delete(data, config = {}): Observable<any> {
    return this._fsApi.delete(`messages/templates/${data.id}`, data, { key: 'messageTemplate', ...config });
  }

  public post(data, config = {}): Observable<any> {
    return this._fsApi.post('messages/templates/', data, { key: 'messageTemplate', ...config });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }

    return this.post(data);


  }
}
