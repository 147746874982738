import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SidenavMenuRef } from '@firestitch/sidenav';
import { ItemType } from '@firestitch/filter';

import { OrganizationDialogComponent } from '@app/shared/components/dialogs';
import { OrganizationService, SidenavService, SettingService } from '@app/core/services';
import { Organization } from '@app/shared/interfaces';


@Component({
  selector: 'app-search-organizations',
  templateUrl: './search-organizations.component.html',
  styleUrls: ['./search-organizations.component.scss']
})
export class SearchOrganizationsComponent implements OnInit, OnDestroy {

  public organizations: Organization[] = [];
  public query = null;
  public conf: any;
  public liteMode: boolean;

  private _defaultParams = { page: 1, limit: 10, types: true, subscriptions: true, order: 'name,asc' };

  private _destroy$ = new Subject();

  constructor(
    public menuRef: SidenavMenuRef<SearchOrganizationsComponent>,
    private _dialog: MatDialog,
    private _router: Router,
    private _organizationService: OrganizationService,
    private _sidenavService: SidenavService,
    private _settingService: SettingService,
    private _cd: ChangeDetectorRef,
  ) { }

  public ngOnInit() {

    this._settingService.gets('other', {}, true)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((response: any) => {
        this.liteMode = !!+response.liteMode;
        this._cd.detectChanges();
      });

    this.conf = {
      reload: false,
      items: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search',
          query: 'keyword'
        }
      ],
      change: query => {
        this.query = query;
        const q = Object.assign({}, this.query, this._defaultParams);
        this._organizationService.gets(q)
          .subscribe(response => {
            this.organizations = response;
            this._cd.detectChanges();
          });
      }
    };

    this._organizationService.gets(this._defaultParams, { key: null })
      .subscribe(response => {
        this.organizations = response.organizations;
        this._cd.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public close() {
    this.menuRef.close();
    this._sidenavService.close();
  }

  public createOrganizationDialog() {

    this.close();

    const dialogRef = this._dialog.open(OrganizationDialogComponent, {
      data: {},
      width: '30%',
      minWidth: '400px',
    });

    dialogRef.afterClosed()
    .pipe(
      filter(response => !!response),
    )
    .subscribe((response) => {
      this._router.navigate(['/manage', 'organizations', response.id]);
    });
  }

}
