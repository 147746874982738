import { Component, Output, Input, EventEmitter } from '@angular/core';


@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

    // For dialogs back button need to close dialog without redirect
    @Input() public disableRedirect = false;

    @Output() public back = new EventEmitter();
}
