<h2>Social Sign-in Accounts</h2>
  <div class="social-media-account">
      <div fxLayout="row" fxLayoutAlign="start center">
        <img src="assets/facebook-logo.svg">
        <div fxLayout="column">
          <span class="label">{{ socialAccounts.facebook.enabled ? 'Connected' : 'Not Connected'}} to Facebook</span>
          <small class="email">{{ socialAccounts.facebook.email }}</small>
        </div>
        <mat-slide-toggle [(ngModel)]="socialAccounts.facebook.enabled" labelPosition="before" name="facebook" (change)="toggleSocialChange('facebook')" *ngIf="allowEdit"></mat-slide-toggle>
      </div>
  </div>
  <!-- <div class="social-media-account">
    <div fxLayout="row" fxLayoutAlign="start center">
      <img src="assets/google-logo.svg">
      <div fxLayout="column">
        <span class="label">{{ socialAccounts.google.enabled ? 'Connected' : 'Not Connected'}} to Google</span>
        <small class="email">{{ socialAccounts.google.email }}</small>
      </div>
      <mat-slide-toggle [(ngModel)]="socialAccounts.google.enabled" labelPosition="before" name="google" (change)="toggleSocialChange('google')" *ngIf="allowEdit"></mat-slide-toggle>
    </div>
  </div> -->
