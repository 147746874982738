<div *fsSkeletonForm="!!awardOrganization">
  <form *ngIf="awardOrganization" (fsForm)="save()" #form="ngForm">
    <fs-dialog>
      <h1 mat-dialog-title>{{ awardOrganization.id ? 'Edit' : 'Create' }} Award Organization</h1>
      <mat-dialog-content>
        <div fxLayout="column">
          <mat-form-field class="responsive-hint">
            <mat-label>Name</mat-label>
<input matInput [(ngModel)]="awardOrganization.name" name="name" maxlength="100" fsFormRequired>
            <mat-hint *ngIf="!awardOrganization.id">Please check carefully that the Award Organization you need is not already on the list before you create a new one.</mat-hint>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button type="submit" color="primary">{{ awardOrganization.id ? 'Save' : 'Create' }}</button>
        <button mat-button type="button" mat-dialog-close>Cancel</button>
      </mat-dialog-actions>
    </fs-dialog>
  </form>
</div>
