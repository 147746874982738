import { Component, Input, OnInit } from '@angular/core';

import { list } from '@firestitch/common';

import { OrganizationService } from 'app/core/services';
import { StatusWine } from 'app/shared/types';


@Component({
  selector: 'app-wine-status-pill',
  templateUrl: './wine-status-pill.component.html',
  styleUrls: ['./wine-status-pill.component.scss']
})
export class WineStatusPillComponent implements OnInit {

  @Input() public status: StatusWine = null;

  public statesNameFlatten: any = {};
  public stateColorFlatten: any = {};

  constructor(
    private _organizationService: OrganizationService
  ) { }

  public ngOnInit() {
    this.statesNameFlatten = list(this._organizationService.statusesWine, 'name', 'value');
    this.stateColorFlatten = list(this._organizationService.statusesWine, 'color', 'value');
  }

}
