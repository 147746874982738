<form>
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Take the Taste Test</h1>
    <mat-dialog-content>
      <content-widget
        *ngIf="step === 'take'"
        tag="taste_test_prompt"
      ></content-widget>
      <content-widget
        *ngIf="step === 'not_now'"
        tag="taste_test_not_now"
      ></content-widget>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="step === 'take'" mat-raised-button type="button" color="primary" (click)="takeTasteTest()">Take the Taste Test</button>
      <button *ngIf="step === 'take'" mat-button (click)="notNow()">Not Now</button>
      <button *ngIf="step === 'not_now'" class="not-now-action" mat-raised-button type="button" color="primary" mat-dialog-close>Done</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
