<fs-dialog mobileMode="bottom">
  <h1 mat-dialog-title>Share</h1>
  <mat-dialog-content>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div fxLayout="column" fxLayoutGap="15px" class="mat-fullwidth">
      <fs-share
        *ngFor="let action of shareActions"
        [platform]="action.medium"
        [title]="action.config.title"
        [url]="action.config.url"
        [image]="action.config.image"
        [open]="open"
      >
        <button
          mat-raised-button
          color="primary">
          {{ action.label }}
        </button>
      </fs-share>
      
      <button type="button" mat-button [mat-dialog-close]="true">Cancel</button>
    </div>
  </mat-dialog-actions>
</fs-dialog>
