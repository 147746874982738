import { Injectable } from '@angular/core';

import { FsStore } from '@firestitch/store';
import { AclService } from './acl.service';
import { isObject } from 'lodash-es';


@Injectable()
export class AclQueryService {

  constructor(
    private fsStore: FsStore,
    private aclService: AclService
  ) {}

  public hasPermission(permission, objectId = null, access = null) {

    const permissions = this.getPermissions() || {};
    const existingPermissions = permissions[objectId || ''];

    if (!isObject(existingPermissions)) {
      return false;
    }

    const existingAccess = existingPermissions[permission];

    return existingAccess && (access === null || existingAccess >= access);
  }

  public hasApp() {
    return this.hasPermission(this.aclService.PERMISSION_APP);
  }

  public hasBackend() {
    return this.hasPermission(this.aclService.PERMISSION_APP_BACKEND);
  }

  public hasOrganizations(access = null) {
    return !!this.getObjectIds()
    .find(objectId => {
      return this.hasPermission(this.aclService.PERMISSION_ORGANIZATION, objectId, access);
    });
  }

  public hasOrganization(objectId, access = null) {
    return this.hasPermission(this.aclService.PERMISSION_ORGANIZATION, objectId, access);
  }

  public getObjectIds() {

    return Object.keys(this.getPermissions())
    .filter((value) => {
      return value;
    });
  }

  public getPermissions() {
    return this.fsStore.get('permissions');
  }

}
