import { Component, Input, OnInit } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'app-item-section-collection',
  templateUrl: './section-collection.component.html',
  styleUrls: ['./section-collection.component.scss']
})
export class SectionCollectionComponent implements OnInit {

  @Input() public collectionItem: Item = null;
  @Input() public items: Item[] = [];

  @Input() public heading: string = null;
  @Input() public subheading: string = null;
  @Input() public viewButtonTitle: string = null;
  @Input() public viewButtonLink: any[] = null;

  constructor() { }

  public ngOnInit() {
    this._init();
  }

  private _init() {
    if (!this.collectionItem) {
      return;
    }

    if (!this.heading) {
      this.heading = this.collectionItem.name;
    }

    if (!this.subheading) {
      this.subheading = this.collectionItem.description;
    }

    if (!this.viewButtonTitle) {
      this.viewButtonTitle = `View ${this.collectionItem.name}`;
    }

    if (!this.viewButtonLink) {
      this.viewButtonLink = ['/collections', this.collectionItem.id];
    }
  }

}
