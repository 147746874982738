<ng-container *ngIf="item">
  <div class="item-detail-heading">
    <item-image [item]="item" imageHeight="225"></item-image>
    <app-item-featured-banner [item]="item" *ngIf="item.featureCollection"></app-item-featured-banner>
    <div class="item-info">
      <div class="item-content">

        <item-headings [item]="item" [showRating]="showRating" headingType="1"></item-headings>

        <app-item-availability [item]="item"></app-item-availability>

        <!--<app-item-characteristics [item]="item"></app-item-characteristics>-->
      </div>
    </div>
  </div>
</ng-container>
