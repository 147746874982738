import { Account } from './account';
import { Category } from './category';
import { Project } from './project';
import { Status } from './status';
import { Attribute } from './attribute';
import { Type } from './type';

export interface Doc {
  id: number | null;
  category?: Category;
  categoryId?: number;
  content?: string;
  creatorAccount?: Account;
  creatorAccountId?: number;
  createDate?: Date;
  class?: string;
  fullName?: string;
  modifierAccount?: Account;
  modifierAccountId?: number;
  modifyDate?: Date;
  name?: string;
  number?: string;
  project?: Project;
  projectId?: number;
  state?: string;
  status?: Status;
  statusId?: number;
  tags?: Attribute[];
  typeId?: number;
  type?: Type;
  objectVersionId?: number;
  objectVersion?: any;
}
