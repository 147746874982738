import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Platform } from '@ionic/angular';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public ready$ = new ReplaySubject();

  constructor(private platform: Platform) {
  }

  public init() {
    if (environment.platform === 'app') {
      this.platform.ready().then(() => {
        this.ready$.next(true);
        this.ready$.complete();
      });
    } else {
        this.ready$.next(true);
        this.ready$.complete();
    }

  }

}
