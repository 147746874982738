import { Account } from './account';


export interface AccountRequest {
  id: number;
  creatorAccountId?: number;
  creatorAccount?: Account;
  accountId?: number;
  account?: Account;
  state?: string;
  createDate?: string;
  expiryDate?: string;
  responseDate?: string;
  guid?: string;
  creatorIp?: string;
  meta?: string;
  type?: string;
  email?: string;
  roleObjects?: any;
}
