import { Component, OnInit } from '@angular/core';

import { FsNavService } from '@firestitch/nav';


@Component({
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit {

  constructor(
    private _fsNavService: FsNavService,
  ) { }

  public ngOnInit() {
    this.setTitle();
  }


  private setTitle() {
    this._fsNavService.setTitle('Styling', 'Labs');
    this._fsNavService.showComponent('logo');
  }
}
