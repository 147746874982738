import { Component, Input } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'item-price',
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.scss']
})
export class ItemPriceComponent {

  @Input() public item: Item = null;

  constructor() { }

}
