import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { map } from 'rxjs/operators';

import { ItemType } from '@firestitch/filter';
import { FsListComponent, FsListConfig, ReorderPosition } from '@firestitch/list';
import { FsNavService } from '@firestitch/nav';
import { FsPrompt } from '@firestitch/prompt';

import { AttributeService } from 'app/core/services';
import { Attribute } from 'app/shared/interfaces';
import { AttributeEditComponent } from '../attribute-edit/attribute-edit.component';


@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss']
})
export class AttributeListComponent implements OnInit {

  @ViewChild('list', { static: true })
  public list: FsListComponent = null;
  public config: FsListConfig = null;

  @Input() class;
  @Input() public projectId;

  constructor(
    private attributeService: AttributeService,
    private fsNavService: FsNavService,
    private fsPrompt: FsPrompt,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  public reload() {
    this.list.reload();
  }

  public ngOnInit() {

    this.config = {
      filters: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        }
      ],
      actions: [
        {
          label: 'Create ' + this.class,
          click: (event) => {
            this.openDialog();
          }
        }
      ],
      rowActions: [
        {
          click: data => {
            return this.attributeService.delete(data);
          },
          remove: {
            title: 'Confirm',
            template: 'Are you sure you would like to delete this attribute?',
          },
          menu: true,
          label: 'Delete'
        }
      ],
      reorder: {
        position: ReorderPosition.Left,
        done: (data) => {
          this.saveOrder(data);
        }
      },
      fetch: query => {

        if (this.projectId) {
          query.projectId = this.projectId;
        }

        Object.assign(query, { order: 'order', class: this.class });
        return this.attributeService.gets(query, { key: null })
          .pipe(
            map(response => ({ data: response.attributes, paging: response.paging }))
          );
      },
      restore: {
        query: { state: 'deleted' },
        filterLabel: 'Show Deleted',
        menuLabel: 'Restore',
        click: (row) => {
          return this.attributeService.put({ id: row.id, state: 'active' })
        },
        filter: true
      }
    };
  }

  public openDialog(attribute: Attribute = { id: null }) {

    attribute.projectId = this.projectId;
    attribute.class = this.class;

    const dialogRef = this.dialog.open(AttributeEditComponent, {
      data: { attribute }
    });

    dialogRef.afterClosed()
    .subscribe(response => {
      if (response) {
        this.list.reload();
      }
    });
  }

  private saveOrder(data) {
    this.attributeService.order({
      attributeIds: data.map(item => item.data.id),
      limit: data.length,
      page: this.list.list.paging.page
    })
    .subscribe(() => {
      this.list.reload();
    });
  }
}
