import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs';
import { AppUseService } from './app-use.service';

@Injectable()
export class ActionService {

  constructor(
    private _fsApi: FsApi,
    private _appUseService: AppUseService
  ) {}

  public post(type, data, config = {}): Observable<any> {
    data.type = type;
    data.appUseGuid = this._appUseService.guid();

    return this._fsApi.post('action', data, Object.assign({ key: 'action' }, config));
  }
}
