import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';

import { FsConnectionService } from '@firestitch/connection';


@Injectable()
export class CanActivateWithoutConnection implements CanActivateChild {

  constructor(public _connection: FsConnectionService) {}

  canActivateChild(childRoute, state) {
    return this._connection.isUp;
  }
}
