<form fsForm [submit]="save" *fsSkeletonForm="build">
  <fs-dialog>
    <h1 mat-dialog-title>{{build.id ? 'Edit Build' : 'Create Build'}}</h1>
    <div mat-dialog-content>

      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Version</mat-label>
        <input
            matInput
            
            [(ngModel)]="build.version"
            name="version">
        </mat-form-field>

        <fs-radio-group [(ngModel)]="build.action" label="Update Action" orientation="vertical" name="action">
          <mat-radio-button *ngFor="let item of actions" [value]="item.value" required>{{ item.name }}</mat-radio-button>
          <!-- <mat-hint>Hint hint hint!</mat-hint> -->
        </fs-radio-group>

        <mat-form-field *ngIf="build.action==Action.ApiRedirect">
          <mat-label>API URL</mat-label>
        <input
            matInput
            [(ngModel)]="build.apiUrl"
            name="api_url"
            >
            <mat-hint>example: https://other-site.com/api/</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="build.action==Action.ForceUpdate">
            >
            <mat-hint>Message show to users on the update dialog.</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="build.action==Action.ForceUpdate">
          <mat-label>iOS Install URL</mat-label>
        <input
            matInput
            [(ngModel)]="build.iosInstallUrl"
            name="ios_install_url"
            >
        </mat-form-field>

        <mat-form-field *ngIf="build.action==Action.ForceUpdate">
          <mat-label>Android Install URL</mat-label>
        <input
            matInput
            [(ngModel)]="build.androidInstallUrl"
            name="android_install_url"
            >
        </mat-form-field>


      </div>
    </div>
    <div mat-dialog-actions>
      <button
        mat-button
        type="submit"
        color="primary">
        {{build.id ? 'Save' : 'Create'}}
      </button>

      <button
        mat-button
        [mat-dialog-close]="undefined">
        Close
      </button>
    </div>
  </fs-dialog>
</form>
