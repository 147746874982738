import { WorkflowActionType } from '../models/action-type';
import { WorkflowTask } from './workflow-task'
import { Account } from '@app/shared';

export interface WorkflowAction {
  id?: number;
  type?: WorkflowActionType;
  assignAccount?: Account;
  subscriberAccounts?: Account[];
  configs?: ActionConfig;
  order?: number;
  state?: string;
  workflowPathId?: number;
  targetObjectId?: number;
  targetWorkflowTaskId?: number;
  targetWorkflowTask?: WorkflowTask;
}

interface ActionConfig {
  comment?: string;
  commentRequired?: boolean;
  assignAccountId?: number;
  subscriberAccountIds: number[];
}
