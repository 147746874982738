import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { filter, list } from '@firestitch/common';
import { FsListComponent, FsListConfig } from '@firestitch/list';
import { FsMessage } from '@firestitch/message';

import { Observable, of, Subject } from 'rxjs';

import { forEach } from 'lodash';

import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AclQueryService, AclRoleService, EnvironmentService } from '../../../core/services';
import { AclRole, Environment } from '../../../shared/interfaces';


@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
})
export class RoleEditComponent implements OnInit, OnDestroy {

  @ViewChild('list') list: FsListComponent;

  public role: AclRole = null;
  public environment: Environment = null;

  public permissions: any[] = [];
  public listConfig: FsListConfig;

  public accesses: any[] = [];
  public accessesFlatten = null;
  public levels: any[] = [];
  public onlyFullAccess = false;
  public disabledBackendLevel = false;

  private _destroy$ = new Subject();

  constructor(
    private dialogRef: MatDialogRef<RoleEditComponent>,
    private fsMessage: FsMessage,
    private aclRoleService: AclRoleService,
    private aclQueryService: AclQueryService,
    private environmentService: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.accesses = this.aclRoleService.accesses;
    this.accessesFlatten = list(this.accesses, 'name', 'value');
    this.environment = this.data.environment;
    this.levels = this.aclRoleService.levels;

    if (!this.aclQueryService.hasBackend()) {
      this.disabledBackendLevel = true;
    }

    this.getRole()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((role) => {
        this.role = Object.assign({}, this.aclRoleService.create(role));
        this._initConfig();
      });
    // this.levels = filter(this.aclRoleService.levels, item => {
    //   return this.environment ?
    //     [
    //       this.aclRoleService.LevelWorkspace,
    //       RoleLevel.Project
    //     ].indexOf(item.value) !== -1 :
    //     [
    //       RoleLevel.Backend,
    //       RoleLevel.App,
    //     ].indexOf(item.value) !== -1;
    // });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getPermissions(): Observable<any> {
    return this.aclRoleService.permissions()
      .pipe(
        tap((permissions) => {
          this.permissions = permissions;
          forEach(this.permissions, item => {
            this.role.permissions[item.value] = this.role.permissions[item.value] || 0;
          });
          this.updatePermissions();
        }),
      );
  }

  public levelChanged() {
    this.reload();
    // this.updatePermissions();
  }

  public reload(): void {
    this.list.reload();
  }

  public getRole(): Observable<any> {

    if (!this.data.role.id) {
      return of(this.data.role);
    }

    if (this.environment) {
      return this.environmentService.getRole(this.environment.id, this.data.role.id);
    } else {
      return this.aclRoleService.get(this.data.role.id);
    }
  }

  public save() {
    const selectedLevelPermissions = this.permissions
      .filter((permission) => (permission.levels || []).includes(this.role.level));
    
    filter(this.permissions, { level: this.role.level });
    let isEmpty = true;

    for (const key in this.role.permissions) {
      if (this.role.permissions.hasOwnProperty(key)) {
        if (!selectedLevelPermissions.find(selectedLevelPermission => selectedLevelPermission.value === key )) {
          this.role.permissions[key] = 0;
        }
      }
    }

    for (const selectedLevelPermission of selectedLevelPermissions) {
      if (this.role.permissions[selectedLevelPermission.value]) {
        this.role.permissions = selectedLevelPermission.inherits
          ? Object.assign({}, this.role.permissions, selectedLevelPermission.inherits)
          : this.role.permissions;
        isEmpty = false;
      }
    }

    if (this.role.allPermissions) {
      isEmpty = false;
    }

    if (isEmpty) {
      this.fsMessage.error(`This role needs at least one permission`);
      return;
    }

    const request$ = this.environment
      ? this.environmentService.saveRole(this.environment.id, this.role)
      : this.aclRoleService.save(this.role);

    request$
      .subscribe(response => {
        this.fsMessage.success(`Role successfully saved`);
        this.close(response);
      });
  }

  public close(data = null) {
    this.dialogRef.close(data);
  }

  public updatePermissions() {
    const selectedLevelPermissions = filter(this.permissions, { level: this.role.level });

    forEach(selectedLevelPermissions, selectedLevelPermission => {
      if (this.role.allPermissions) {
        this.role.permissions[selectedLevelPermission.value] = this.getMaxAccess(selectedLevelPermission);
      }
    });
  }

  private getMaxAccess(permission) {
    return Math.max(...permission.accesses);
  }

  private _initConfig(): void {
    this.listConfig = {
      status: false,
      initialFetch: true,
      paging: false,
      noResults: {
        message: ''
      },
      fetch: (query) => {
        return this.getPermissions()
          .pipe(
            switchMap(() => {
              const group = filter(this.aclRoleService.groupPermissions(this.permissions), { level: this.role.level });
              const permissions = group[0] ? group[0].permissions : [];
              return of({ data: permissions });
            }),
          );
      }
    }
  }

}
