import { NgModule } from '@angular/core';
import { PercentMatchFlagComponent } from './components';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
  ],
  exports: [
    PercentMatchFlagComponent
  ],
  declarations: [
    PercentMatchFlagComponent,
  ]
})
export class PercentMatchFlagModule {

}
