import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';
import { guid } from '@firestitch/common';
import { environment } from 'environments/environment';
import { DynamicLinkService } from 'app/core/services/dynamic-link.service';
import { FsStore } from '@firestitch/store';

@Injectable()
export class VisitService {

  constructor(
    private _fsApi: FsApi,
    private _fsStore: FsStore
  ) { }

  public post(data, options: any = {}): Observable<any> {
    return this._fsApi.post('visits', data, Object.assign({ key: 'visit' }, options));
  }



  public trackVisit(trackingCode) {
    const data: any = {code: trackingCode};

    if (document.referrer) {
      data.referralUrl = document.referrer;
      data.referralDomain = new URL(document.referrer).hostname;
    };

    this.post(data).subscribe((visit: any) => {
      if (visit) {
        this._fsStore.set('visit_id', visit.id);
      }
    });

  }

}
