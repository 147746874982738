import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { WineService } from '@app/core/services';


@Component({
  selector: 'app-wine-vintage-view',
  templateUrl: './wine-vintage.component.html',
  styleUrls: ['./wine-vintage.component.scss']
})
export class WineVintageViewComponent implements OnInit, OnChanges {

  @Input()
  public vintage: number;

  @Input()
  public nonVintage: boolean;

  public name: string = null;

  constructor(
    private _wineService: WineService,
  ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this._init();
    }
  }

  private _init(): void {
    this.name = this._wineService.getVintage(this.vintage, this.nonVintage);
  }

}
