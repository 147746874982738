import {
  Component,
  EventEmitter,
  Input,
  HostBinding,
  OnChanges,
  Output,
  SimpleChange
} from '@angular/core';

import { Attribute } from '@app/shared/interfaces';
import { TileService } from '@app/core/services/tile.service';

@Component({
  selector: 'base-tile',
  templateUrl: './base-tile.component.html',
  styleUrls: ['./base-tile.component.scss'],
  providers: [ TileService ]
})
export class BaseTileComponent implements OnChanges {

  @Input() public type: 'alcohol' | 'sweetness' | 'acidity' | 'body' | 'tannins';
  @Input() public value: any;
  @Input() public sparkling: Attribute; // for sweetness
  @Input() public scale: number;
  @Output() public scaleChange = new EventEmitter<number>();

  @HostBinding('attr.class') public class = 'base-tile';

  public title = '';
  public subtitle = '';
  public postfix = '';

  constructor(
    private _tile: TileService
  ) { }

  public ngOnChanges(changes: { value: SimpleChange }) {
    if (!this.title && !this.postfix) {
      this.setOptions();
    }

    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.calculateValue();
    }
  }

  private setOptions() {
    let option = false;

    if (this.type === 'sweetness'
      && this.sparkling
      && this.sparkling.configs
      && this.sparkling.configs.sparkling) {
      option = true;
    }

    this._tile.setData(this.type, option);

    this.title = this._tile.title;
    this.postfix = this._tile.postfix;
  }

  private calculateValue() {
    if (isNaN(parseFloat(this.value))) {
      this.subtitle = '';
      this.scale = null;
    } else {
      const option = this._tile.calculateOption(this.value);
      this.subtitle = option.description;
      this.scale = option.scale;
    }

    setTimeout(() => {
      this.scaleChange.emit(this.scale);
    })
  }

}
