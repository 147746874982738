import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { RouteSubject } from '@firestitch/core';

import { ItemService } from '@app/core/services';
import { StateBase } from '@app/shared/types';


@Injectable()
export class ItemResolve implements Resolve<any> {

  public constructor(private _itemService: ItemService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const routeSubject = new RouteSubject();

    if (!route.params.id) {
      return routeSubject.next(this._itemService.create());
    }

    const params: any = {
      addedToList: true,
      notes: true,
      awardsWonCount: true,
      organizations: true,
      state: StateBase.Active,
      featureCollections: true,
      wineCounts: true,
      experienceCounts: true,
      collectionCounts: true,
      whereToBuyCount: true,
      percentMatch: true,
    };

    let query$ = this._itemService.get(route.params.id, params);

    if (route.data && route.data.class) {
      switch (route.data.class) {
        case 'collection':
          query$ = this._itemService.getCollection(route.params.id, params);
          break;
      }
    }

    return routeSubject.observe(query$);
  }

}
