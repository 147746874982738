<div class="characteristics" *ngIf="item.isWine">
  <div class="charactetistic-item" *ngIf="item.wine.dominantFlavour1">
    <img *ngIf="item.wine.dominantFlavour1.image.tiny" [src]="item.wine.dominantFlavour1.image.tiny">
    <span class="characteristic-name"> {{ item.wine.dominantFlavour1.name }} </span>
  </div>
  <div class="charactetistic-item" *ngIf="item.wine.dominantFlavour2">
    <img *ngIf="item.wine.dominantFlavour2.image.tiny" [src]="item.wine.dominantFlavour2.image.tiny">
    <span class="characteristic-name"> {{ item.wine.dominantFlavour2.name }}</span>
  </div>
  <div class="charactetistic-item" *ngIf="item.wine.body">
    <div class="sweetness">
      {{ item.wine.body }}/5
    </div>
    <span class="characteristic-name"> Body </span>
  </div>
  <div class="charactetistic-item" *ngIf="item.wine.sweetnessScale">
    <div class="sweetness">
       {{ item.wine.sweetnessScale }}/5
    </div>
    <span class="characteristic-name"> Sweet </span>
  </div>
</div>
