<form>
  <fs-dialog mobileMode="full">

    <navbar (back)="back()">
      <span navbarSupertitle>Super Title</span>
      <span navbarTitle>Title</span>
    </navbar>

    <mat-dialog-content>
      <div fxLayout="column">
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget consequat nisl, eget egestas orci. Aliquam hendrerit dui eu mauris luctus vehicula. Sed pulvinar lacinia enim vitae varius. Etiam finibus justo ut sapien condimentum, et molestie neque fermentum. Proin mattis erat augue, vitae tempor sem imperdiet in. Cras a eleifend nisi. Praesent accumsan lectus eu ligula volutpat, sed malesuada sapien vestibulum. Morbi rhoncus hendrerit condimentum. Phasellus nunc turpis, ultrices sodales arcu nec, aliquam mattis ipsum.</span>
        <mat-form-field>
          <mat-label>Winery Code</mat-label>
<input matInput>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="primary">Verify</button>
      <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
