<fs-dialog [mobileMode]="'full'">
  <navbar [disableRedirect]="true" (back)="close()">
    <span navbarSupertitle>
      {{data.item.name}}
      <ng-container *ngIf="data.item.isWine">{{ data.item.meta.vintage }}</ng-container>
      • Note
    </span>
    <span navbarTitle>{{ data.note.createDate | date: 'LLL dd, yyyy'}}</span>
  </navbar>

  <mat-dialog-content *ngIf="data.note">
    <fs-label-field *ngIf="data.note.flavours.length > 0">
      <fs-label>Flavours</fs-label>

      <fs-attributes class="wine_flavour" [data]="attributeData">
      </fs-attributes>
    </fs-label-field>

    <fs-label-field *ngIf="data.note.finishes.length > 0">
      <fs-label>Finishes</fs-label>

      <fs-attributes class="wine_finish" [active]="true" [attributes]="data.note.finishes">
      </fs-attributes>
    </fs-label-field>

    <fs-label-field *ngIf="data.note.popularNotes.length > 0">
      <fs-label>Popular Notes</fs-label>

      <fs-attributes class="popular_note" [active]="true" [attributes]="data.note.popularNotes">
      </fs-attributes>
    </fs-label-field>


    <fs-label-field *ngIf="!!data.note.customNote">
      <fs-label>Custom note</fs-label>

      {{ data.note?.customNote }}
    </fs-label-field>

  </mat-dialog-content>
</fs-dialog>
