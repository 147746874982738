import { env } from './env';

export const environment = {
  production: false,
  apiPath: '/api/',
  apiDomain: 'tasteadvisor.local.firestitch.com',
  platform: (<any>env).platform,
  googleClientId: '46829300559-mhh6fcjfsermhun515blfn58td8b1h29.apps.googleusercontent.com',
  googleMapApiKey: 'AIzaSyDwK5HDj6mtad3wQNzS6ZauLe7raegSPpo',
  facebookAppId: '197085513672785', // https://developers.facebook.com/apps/197085513672785/settings/basic/
  intercomAppId: 'q5sb8hgh',
  googleAnalyticsId: 'UA-138590760-1',
  firebaseLinkDomain: 'tasteadvisor.page.link',
  iosBundle: 'com.firestitch.tasteadvisor',
  iosAppstoreId: '',
  androidBundle: 'com.firestitch.tasteadvisor',
  urlScheme: 'tasteadvisorlocal',
  iosAppstoreUrl: 'https://apps.apple.com/us/app/tasteadvisor-drink-local-wine/id1471572055?ls=1',
  androidAppstoreUrl: 'https://play.google.com/store/apps/details?id=com.firestitch.tasteadvisor',
  appName: 'Wines of BC Explorer'
};
