import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Item } from '../../models';
import { ActionKebubMenuItem } from '../../interfaces';


@Component({
  selector: 'app-item-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {

  public showStatus = false;

  @Input()
  public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
    this.showStatus =
      this._item.status !== 'available' &&
      this._item.status !== 'open' &&
      ['organization', 'experience', 'wine'].indexOf(this._item.class) !== -1 &&
      !this._item.isCollection;
  }

  public get item() {
    return this._item;
  }

  private _item: Item = null;

  @Input()
  public showScores = false;

  @Input()
  public customActions: ActionKebubMenuItem[] = null;

  @Input()
  public disableActions = false;

  @Input() public hideOrganizationName = false;

  @Output()
  public ratingChanged = new EventEmitter<void>();

  @Output()
  public visitsChange = new EventEmitter<void>();

  @Output()
  public triedRemoved = new EventEmitter<void>();

  public settings = null;

  public onRatingChanged() {
    this.ratingChanged.emit();
  }

  public triedRemove() {
    this.triedRemoved.emit();
  }

  public visitsChanged(): void {
    this.visitsChange.emit();
  }

}
