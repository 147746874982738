import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsAddress } from '@firestitch/address';

import { SessionService, AccountService } from '@app/core/services';
import { Account } from '@app/shared/interfaces';


@Injectable({
  providedIn: 'root'
})
export class WhereToBuyService {

  constructor(
    private _accountService: AccountService,
    private _sessionService: SessionService,
  ) { }

  public setAddress(address: FsAddress) {
    this._sessionService.lastLocationAddress(address);
  }

  public getAddress(loggedInAccount: Account): Observable<FsAddress> {
    return new Observable((observer) => {
      let address = this._sessionService.lastLocationAddress() || {};

      if (address || !loggedInAccount) {
        observer.next(address);
        observer.complete();
        return;
      }

      this._accountService.get(loggedInAccount.id, { lastLocationAddresses: true })
        .subscribe((account: Account) => {
          if (account.lastLocationAddress) {
            address = account.lastLocationAddress;
          }
          observer.next(address);
          observer.complete();
        });
    });
  }

}
