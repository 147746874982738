import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { PANE_DATA } from '@firestitch/pane';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Angulartics2 } from 'angulartics2';

import { Item, ItemOrganizationModel } from '@app/taster/modules/item/models';
import { Collection } from '@app/shared/interfaces';
import { SettingService } from '@app/core/services';


@Component({
  selector: 'app-start-tasting-pane',
  templateUrl: './action-pane.component.html',
  styleUrls: ['./action-pane.component.scss']
})
export class OrganizationActionPaneComponent implements OnInit, OnDestroy {

  public item: Item = null;
  public organizationData: ItemOrganizationModel = null;
  public liteMode: boolean;

  public primaryCollection: Collection = null;

  private _destroy$ = new Subject();

  constructor(
    @Inject(PANE_DATA) public data,
    private _router: Router,
    private _settingService: SettingService,
    private _angulartics2: Angulartics2,
  ) { }

  public ngOnInit() {
    this._settingService.gets('other', {}, false)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((response) => {
        this.liteMode = !!+response.liteMode;
        this.item = this.data.item;
        this.organizationData = this.data.item.data;
        this.primaryCollection = this.organizationData.collections.find(item => !!item.primary);
      });
  }

  public get hasTastingButton(): boolean {
    return this.item.type.actionButtonType === 'tasting';
  }

  public get hasExternalUrlButton(): boolean {
    return this.item.type.actionButtonType === 'external_url' &&
      !!this.organizationData.actionButtonUrl;
  }

  public get hasViewWineListButton(): boolean {
    return this.item.type.actionButtonType === 'view_wine_list' && !!this.primaryCollection;
  }

  public get hasViewExperiencesButton(): boolean {
    return this.liteMode
      && !!this.item.data['experienceCount'];
  }

  public get hasReservationUrlButton(): boolean {
    return !!this.organizationData.reservationUrl;
  }

  public get hasActions(): boolean {
    return this.hasTastingButton ||
      this.hasExternalUrlButton ||
      this.hasViewWineListButton ||
      this.hasReservationUrlButton ||
      this.hasViewExperiencesButton;
  }

  /*
  public navigateTo() {
    this._registerClick();

    if (this.item.type.actionButtonType === 'tasting') {
      this._router.navigate(['/organizations', this.item.id, 'wines']);
    } else {
      if (this.organizationData.actionButtonUrl) {
        window.open(this.organizationData.actionButtonUrl, '_blank');
      }
    }
  }
  */

  public reservationClick() {
    this._angulartics2.eventTrack.next({
      action: 'click_reservation',
      properties: { objectId: this.item.id, buttonLabel: this.item.type.actionButtonLabel }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
