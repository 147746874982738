import { Component, Inject, HostListener, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-note-preview',
  templateUrl: './note-preview.component.html',
  styleUrls: [
    './note-preview.component.scss',
  ]
})
export class NotePreviewComponent implements OnInit {

  public attributeData = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _dialogRef: MatDialogRef<NotePreviewComponent>,
  ) { }

  public ngOnInit() {
    this.attributeData = { objectId: this.data.note.id };
  }

  @HostListener('click')
  public close() {
    this._dialogRef.close();
  }
}
