import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { guid } from '@firestitch/common';

import { TimezonesService } from 'app/core/services';
import { Timezone } from 'app/shared/interfaces/';


@Component({
  selector: 'timezone-select',
  templateUrl: './timezone-select.component.html',
  styleUrls: ['./timezone-select.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class TimezoneSelectComponent implements OnInit, OnDestroy {

  @Input() public placeholder = 'Timezone';
  @Input() public required = false;
  @Input() public disabled = false;
  @Output() public timezoneChange = new EventEmitter<string>();

  private _timezone: string = null;
  @Input() public set timezone(value: string) {
    if (value && value !== this._timezone) {
      this._timezone = value;
      this.timezoneChange.emit(this.timezone);
    }
  };

  public get timezone(): string {
    return this._timezone;
  }

  public guid: string = guid();

  public timezones: Timezone[] = [];

  private _browserTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private _destoy$ = new Subject();

  constructor(
    private _timezonesService: TimezonesService
  ) { }

  public ngOnInit() {
    this._timezonesService.gets()
      .pipe(
        takeUntil(this._destoy$)
      )
      .subscribe((response: Timezone[]) => {
        this.timezones = response;

        if (!this.timezone) {
          this.timezone = this._browserTimezone;
        }
      });
  }

  public ngOnDestroy() {
    this._destoy$.next();
    this._destoy$.complete();
  }

}
