import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { Angulartics2 } from 'angulartics2';

import { filter } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { ItemService, CollectionService } from '@app/core/services';
import { Item } from '../../models';
import { CollectionDialogComponent } from '../collection-dialog';


@Component({
  templateUrl: './add-to-list-dialog.component.html',
  styleUrls: ['./add-to-list-dialog.component.scss']
})
export class AddToListDialogComponent implements OnInit {

  public item: Item = null;
  public collectionItems: Item[] = [];

  constructor(
    private _dialogRef: MatDialogRef<AddToListDialogComponent>,
    private _dialog: MatDialog,
    private _itemService: ItemService,
    private _fsMessage: FsMessage,
    private _collectionService: CollectionService,
    private _angulartics2: Angulartics2,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {

    this.item = this.data.item;

    this._loadCollectionItems();
  }

  public addToList(item: Item) {
    this._collectionService.postObjects(item.id, { objectIds: [this.item.id] })
      .subscribe(response => {
        this._fsMessage.success(`
          <div class="heading">Added to List or Route</div>
          ${this.item.name}`,
          { icon: 'add' }
        );

        this._angulartics2.eventTrack.next({
          action: 'add_to_collection',
          properties: { objectId: this.item.id, collectionId: item.id  }
        });

        this.item.addedToList = true;
        this.close(this.item);
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public openCollectionDialog() {
    const dialogRef = this._dialog.open(CollectionDialogComponent, {
      data: { collection: this._collectionService.create() }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => this._loadCollectionItems());
  }

  private _loadCollectionItems() {
    this._itemService.getCollections({ owned: true, objectAddedToList: this.item.id, order: 'modify_date,desc' })
      .subscribe(response => {
        this.collectionItems = response.map(item => new Item(item));
      });
  }

}
