<mat-form-field class="mat-fullwidth">
  <mat-label>Description</mat-label>
        <textarea
    matInput
    [(ngModel)]="data.attribute.configs.description"
    name="description"
    
    cdkTextareaAutosize>
  </textarea>
</mat-form-field>
