<mat-form-field class="mat-fullwidth">
  <mat-label>Region</mat-label>
        <input
    matInput
    name="subregion"
    #subregionRef="ngModel"
    [(ngModel)]="subregion"
    (ngModelChange)="loadSubregions($event)"
    [matAutocomplete]="subregionSearchInput"
    />
  <mat-autocomplete #subregionSearchInput="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of subregions" [value]="item">
      <span>{{ item.name }}, {{ item.region.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
