import {
  isAfter,
  isBefore,
  format
} from 'date-fns';

import { date } from '@firestitch/date';
import { filter } from '@firestitch/common';

import { Attribute, Image, Variety, Organization } from '@app/shared/interfaces';
import { WineVarietyMode } from '@app/shared/types';

import { ItemOrganizationModel } from './item-organization.model';


export class ItemWineModel {
  public dominantFlavour1: { name: string; image: Image };
  public dominantFlavour2: { name: string; image: Image };
  public sweetness: number;
  public sweetnessScale: number;
  public tannins: number;
  public body: number;
  public acidity: number;
  public acidityScale: number;
  public alcohol: number;
  public alcoholScale: number;
  public colourAttributeId: number;
  public colour: Attribute;
  public styleAttributeId: number;
  public style: Attribute;
  public tastingRoom: number;
  public price: number;
  public percentMatch: number;
  public varietyMode: WineVarietyMode;
  public wineVarieties: Variety[];
  public cellaringYearsFrom: number;
  public cellaringYearsTo: number;
  public classificationsVisibleOnList: Attribute[] = [];
  public organization: ItemOrganizationModel;

  public availabilityLabel: string;

  private _dateFormat = 'MMM d, Y';

  constructor(data: any = {}) {
    this._init(data);
  }

  private _init(data) {
    this.sweetness = data.sweetness || null;
    this.sweetnessScale = data.sweetnessScale || null;
    this.body = data.body || null;
    this.tannins = data.tannins || null;
    this.acidity = data.acidity || null;
    this.acidityScale = data.acidityScale || null;
    this.alcohol = data.alcohol || null;
    this.alcoholScale = data.alcoholScale || null;
    this.colour = data.colour || null;
    this.colourAttributeId = data.colourAttributeId || null;
    this.styleAttributeId = data.styleAttributeId || null;
    this.style = data.style || null;
    this.price = data.price !== null ? data.price : null;
    this.percentMatch = data.percentMatch || null;
    this.varietyMode = data.varietyMode || null;
    this.cellaringYearsFrom = data.cellaringYearsFrom || null;
    this.cellaringYearsTo = data.cellaringYearsTo || null;
    this.organization = data.organization ? new ItemOrganizationModel(data.organization) : null;
    this.classificationsVisibleOnList = filter(data.classifications || [], item => {
      return item.configs && item.configs.visibleOnList;
    });

    this._sortVarieties(data);
    this._setFlavours(data);
    this._getWineAvailability(data);
  }

  private _setFlavours(data) {

    if (data.dominantFlavour1 && data.flavourProfile1) {
      const imageFlavour1 = data.flavourProfile1.configs && data.flavourProfile1.configs.smallImage ?
        { tiny: data.flavourProfile1.configs.smallImage.url } :
        data.flavourProfile1.image;
      this.dominantFlavour1 = { name: data.flavourProfile1.name, image: imageFlavour1 };
    }

    if (data.dominantFlavour2 && data.flavourProfile2) {
      const imageFlavour2 = data.flavourProfile2.configs && data.flavourProfile2.configs.smallImage ?
        { tiny: data.flavourProfile2.configs.smallImage.url } :
        data.flavourProfile2.image;
      this.dominantFlavour2 = { name: data.flavourProfile2.name, image: imageFlavour2 };
    }
  }

  // Sorting on FE is performed due to field structure in DB
  // we need to check variety_mode in wines table, while sorting is performed by wine_varieties table
  private _sortVarieties(data) {

    const wineVarieties = data.wineVarieties || [];

    if (data.varietyMode === WineVarietyMode.Percent) {
      wineVarieties.sort((a,b) => b.percent - a.percent);
    } else if (data.varietyMode === WineVarietyMode.Order) {
      wineVarieties.sort((a,b) => a.order - b.order);
    }

    this.wineVarieties = wineVarieties;
  }

  private _getWineAvailability(data) {

    const releaseDate = date(data.releaseDate);
    const soldOutDate = date(data.soldOutDate);
    const curentDate = date(new Date());

    if (isAfter(releaseDate, curentDate)) {
      this.availabilityLabel = format(releaseDate, this._dateFormat);
    }

  }
}
