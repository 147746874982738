import { Component, Input } from '@angular/core';

import { Attribute } from '@app/shared/interfaces';


@Component({
  selector: 'app-item-classification-pill',
  templateUrl: './classification-pill.component.html',
  styleUrls: ['./classification-pill.component.scss']
})
export class ItemClassificationPillComponent {

  @Input() public classification: Attribute = null;

}
