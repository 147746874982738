
import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

import { Attribute } from 'app/shared/interfaces';


@Component({
  selector: 'app-attribute-pill',
  templateUrl: './attribute-pill.component.html',
  styleUrls: ['./attribute-pill.component.scss']
})
export class AttributePillComponent implements OnChanges {

  @Input() public attribute: Attribute = null;
  @Input() public enableSwitch = false;
  @Input() public selected = true;
  @Input() public class;

  @Output() public toggle = new EventEmitter<{ attribute: Attribute, selected: boolean }>();

  public styles: any = {};

  constructor() { }

  public ngOnChanges(changes) {

    if (!changes) {
      return;
    }

    this.updateStyles();
  }

  public click() {
    this.toggle.emit({ attribute: this.attribute, selected: !this.selected });
  }

  private updateStyles() {
    this.styles = {};
    const opacity = this.selected ? 1 : 0.04;
    this.styles['background-color'] = 'rgb(' + this.hexToRgb(this.attribute.color).join(',') + ',' + opacity + ')';
    this.styles['border-color'] = '#fff';
  }

  public hexToRgb(hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              ,(m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));
  }
}

