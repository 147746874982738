<form (fsForm)="save()">
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Where Are You Tasting?</h1>
    <mat-dialog-content>
      <mat-form-field class="mat-fullwidth">
        <mat-label>Current Region</mat-label>
        <mat-select [(ngModel)]="region" (ngModelChange)="onRegionChange($event)"  name="region">
          <mat-option *ngFor="let item of regions" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-fullwidth">
        <mat-label>Sub-Region</mat-label>
        <mat-select [(ngModel)]="subregion" (ngModelChange)="onSubRegionChange($event)" [disabled]="!region"  name="sub_region">
          <mat-option *ngFor="let item of subregions" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-organization-picker
        [organization]="organization"
        [query]="organizationQueryData"
        title="Winery"
        (organizationChange)="onOrganizationChange($event)">
      </app-organization-picker>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button type="submit" color="primary">Go To Winery's Wines Page</button>
      <button mat-button type="button" [mat-dialog-close]="true">Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
