<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>{{ title }}</h1>
    <mat-dialog-content>
      <mat-form-field class="mat-fullwidth">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          [(ngModel)]="sourceObject['description']"
          maxlength="5000"
          name="description"
          
          cdkTextareaAutosize>
        </textarea>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="submit" color="primary">Save</button>
      <button mat-button type="button" mat-dialog-close>CANCEL</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
