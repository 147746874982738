import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { forEach } from 'lodash';

import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';
import { list, email } from '@firestitch/common';

import { AccountService, AclRoleService, EnvironmentService, AclQueryService } from 'app/core/services';
import { AccountRequest, Account, AclRole, EnvironmentAccount } from 'app/shared/interfaces';


@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent implements OnInit, OnDestroy {

  public accountRequest: AccountRequest | EnvironmentAccount = { id: null };
  public selectedAccounts: Account[] = [];

  public globalRoles: AclRole[] = null;
  public selectedRoles: AclRole[] = [];

  public organizationId: number = null;

  public hasApp = false;

  private _destroy$ = new Subject();

  public search = query => {
    return this._accountService.gets({ keyword: query, limit: 50 });
  };

  public validateText = (e) => {
    return email(e);
  }

  constructor(
    private _dialogRef: MatDialogRef<InviteDialogComponent>,
    private _fsMessage: FsMessage,
    private _accountService: AccountService,
    private _aclRoleService: AclRoleService,
    private _environmentService: EnvironmentService,
    private _aclQueryService: AclQueryService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.accountRequest = data.invite ? data.invite : this.accountRequest;
    this.organizationId = data.organizationId ? data.organizationId : null;
  }

  public ngOnInit() {

    this.hasApp = this._aclQueryService.hasApp();

    if (this.organizationId) {
      this._environmentService.getRoles(this.organizationId)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(response => {
          this.globalRoles = response.filter(acl_role => acl_role.level === 'organization');

          if (this.globalRoles.length === 1 && !this.accountRequest.id) {
            this.selectedRoles.push(...this.globalRoles);
          }

          if (this.accountRequest.id) {
            (this.accountRequest as EnvironmentAccount).aclRoles.forEach(loadedRole => {
              const selectedRole = this.globalRoles.find(role => role.id === loadedRole.id)
              this.selectedRoles.push(selectedRole);
            });
          }
        });
    } else {
      this._aclRoleService.gets()
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe((response) => {
          this.globalRoles = response;

          if (this.globalRoles.length === 1) {
            this.selectedRoles.push(...this.globalRoles);
          }
        });
    }

  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public save() {
    const aclRoleIds = list(this.selectedRoles, 'id');
    const requestRolesArray = [];

    /// @TODO put method

    requestRolesArray['acl_role_ids'] = aclRoleIds;

      if (!this.selectedAccounts.length) {
        return;
      }

      let source$ = of(true);

      forEach(this.selectedAccounts, item => {

        let email = '';
        if (item.type === 'text') {
          email = item.data;
        }

        if (item.type === 'object') {
          email = item.data.email;
        }

        const data = Object.assign(
          { email, environmentId: this.organizationId },
          this.accountRequest,
          requestRolesArray
        );

        // Subscription fires 1 time. Replace switchMap with concat to fire for all queries
        source$ = source$.pipe(
          switchMap(() => this._accountService.postInvites(data))
        );
      });

      source$
        .subscribe(response => {
          this._fsMessage.success('Invite sent');
          this.close(response);
        });
  }

  public openAccount() {
    if (this._aclQueryService.hasApp()) {
      this._router.navigateByUrl(`admin/account/${this.accountRequest.account.id}`);
      this.close();
    }
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
