<mat-form-field *ngIf="type === 'autocomplete'" class="mat-fullwidth">
  <mat-label>Primary Organization Contact</mat-label>
        <input
    matInput
    name="primary_organization_contact"
    #primaryPartnerRef="ngModel"
    [(ngModel)]="account"
    (ngModelChange)="loadAccounts($event)"
    [matAutocomplete]="primaryAccountSearchInput"
    />
  <mat-autocomplete #primaryAccountSearchInput="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let item of accounts" [value]="item">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <fs-badge shape="circle" image="{{ item?.image?.small }}"></fs-badge>
        <span>{{ item.firstName }} {{ item.lastName }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field *ngIf="type === 'select'" class="mat-fullwidth">
  <mat-label>Primary Organization Contact</mat-label><mat-select [ngModel]="selectAccountId" fsSelect name="primary_organization_contact_dropdown" (ngModelChange)="accountIdChange($event)">

    <mat-select-trigger *ngIf="account">
      <ng-container *ngTemplateOutlet="template;context:{ model: account }"></ng-container>
    </mat-select-trigger>

    <mat-option *ngFor="let item of accounts" [value]="item.id">
      <ng-container *ngTemplateOutlet="template;context:{ model: item }"></ng-container>
    </mat-option>

    <ng-template #template let-model="model">
      <img *ngIf="model.image && model.image.small" [src]="model.image.small" class="avatar">
      <span>{{ model.firstName }} {{ model.lastName }}</span>
    </ng-template>
  </mat-select>
</mat-form-field>
