import { Component, OnInit, Inject } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { FS_ATTRIBUTE_FIELD_DATA } from '@firestitch/attribute';


@Component({
  selector: 'app-attribute-list-use-sparkling',
  templateUrl: 'list-use-sparkling.component.html',
  styleUrls: ['list-use-sparkling.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AttributeListUseSparklingComponent implements OnInit {

  constructor(@Inject(FS_ATTRIBUTE_FIELD_DATA) public data: any) {}

  public ngOnInit() {

  }
}
