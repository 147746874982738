import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FsApi, RequestConfig } from '@firestitch/api';


@Injectable({
  providedIn: 'root'
})
export class BuildData {
  constructor(private _api: FsApi) {}

  public get(id, query = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.get(`builds/${id}`, query, Object.assign({ key: 'build' }, config));
  }

  public gets(data = {}, config: RequestConfig = {}): Observable<any> {
    return this._api.request('GET', `builds`, data, Object.assign({ key: 'builds' }, config));
  }

  public put(data, config: RequestConfig = {}): Observable<any> {
    return this._api.put(`builds/${data.id}`, data, Object.assign({ key: 'build' }, config));
  }

  public post(data, config: RequestConfig = {}): Observable<any> {
    return this._api.post(`builds`, data, Object.assign({ key: 'build' }, config));
  }

  public delete(data, config: RequestConfig = {}): Observable<any> {
    return this._api.delete(`builds/${data.id}`, data, Object.assign({ key: 'build' }, config));
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }
}
