<ng-container *ngIf="item">
  <h2>About</h2>

  <div class="description multi-lines-area" *ngIf="item.description">
    <fs-truncate [lines]="5" [more]="true">
      {{ item.description }}
    </fs-truncate>
  </div>

  <div class="classification" *ngIf="item.isOrganization" [hidden]="!showOrganizationClassification">
    <fs-attributes
      class="organization_classification"
      [ngClass]="['container']"
      [data]="{ objectId: item.id }"
      (dataReceived)="organisationReceived($event)">
    </fs-attributes>
  </div>

  <div class="classification" *ngIf="item.isWine" [hidden]="!showWineClassification">
    <fs-attributes
      class="wine_classification"
      [data]="{ objectId: item.id }"
      (dataReceived)="classificationRecevied($event)">
    </fs-attributes>
  </div>

  <div *ngIf="item.organization?.phone" class="contact-info">
    <mat-icon class="small-icon">phone</mat-icon>
    <a
      [href]="'tel:' + item.organization.phone"
      class="primary"
      angulartics2On="click"
      angularticsAction="click_item_phone"
      [angularticsProperties]="{ objectId: item.id }"
    >{{ item.organization.phone }}</a>
  </div>

  <ng-container *ngIf="item.isOrganization">
    <div *ngIf="item.organization.email" class="contact-info">
      <mat-icon class="small-icon">email</mat-icon>
      <a
        [href]="'mailto:' + item.organization.email"
        class="primary"
        angulartics2On="click"
        angularticsAction="click_item_email"
        [angularticsProperties]="{ objectId: item.id }"
      >{{ item.organization.email }}</a>
    </div>


    <div *ngIf="item.organization.website" class="contact-info contact-website">
      <mat-icon class="small-icon">open_in_new</mat-icon>
      <a
        fsLink
        [httpPrefix]="true"
        [openWindow]="true"
        [href]="item.organization.website"
        class="primary"
        angulartics2On="click"
        angularticsAction="click_item_website"
        [angularticsProperties]="{ objectId: item.id }"
      >{{ item.organization.website }}</a>
    </div>

    <div *ngIf="item.organization.address && (!item.organization.isAddressEmpty || item.organization.address.name)" class="contact-info" fxLayout="row">
      <mat-icon class="small-icon">near_me</mat-icon>
      <ng-container *ngIf="!item.organization.isAddressEmpty; else justAddressName">
        <a (click)="navigateAddress(item.organization.address)">
          <fs-address-format [address]="item.organization.address" [format]="AddressFormat.TwoLine"></fs-address-format>
        </a>
      </ng-container>
      <ng-template #justAddressName>
        {{ item.organization.address.name }}
      </ng-template>
    </div>
  </ng-container>

  <ng-content></ng-content>


</ng-container>
