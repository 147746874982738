import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable()
export class MobileWebGuard implements CanActivate {

  constructor(
    private router: Router,
    private _deviceDetectorService: DeviceDetectorService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (
      environment.platform === 'web'
      && this._deviceDetectorService.isMobile()
      && !sessionStorage.getItem('proceed_web')
    ) {
      return this.router.createUrlTree(['/shareclick'], { queryParams: { url: state.url } });
    }

    return true;
  }

}
