import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { FsStore } from '@firestitch/store';
import { FsPane } from '@firestitch/pane';

import { AuthService, AclQueryService } from '@app/core/services';


@Component({
  selector: 'account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {

  @Input() public account = null;
  public backendUrl;
  public hasBackend;
  public hasApp;

  constructor(
    private _router: Router,
    private authService: AuthService,
    private aclQueryService: AclQueryService,
    private fsStore: FsStore,
    private pane: FsPane,
  ) {
    this.backendUrl = '//' + (<any>window).location.hostname + '/backend';
  }

  public ngOnInit() {

    this.fsStore.observe('permissions')
    .subscribe((store) => {
      this.hasBackend = this.aclQueryService.hasBackend();
      this.hasApp = this.aclQueryService.hasApp();
    });

  }

  public signout() {
    this.pane.destroy('footer');
    this.pane.destroy('bottom');

    this.authService.signout();
  }

  public onClick($event) {
    $event.stopPropagation();
  }

  public navigateByUrl(url: string) {

    if (!this.account) {
      return;
    }

    this._router.navigateByUrl(url);
  }
}
