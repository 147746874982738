import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PANE_DATA } from '@firestitch/pane';

import { PaneControlService } from '@app/core/services';

@Component({
  selector: 'app-quiz-complete-action-pane',
  templateUrl: './complete-action-pane.component.html',
  styleUrls: ['./complete-action-pane.component.scss']
})
export class QuizCompleteActionPaneComponent  {

  constructor(
    private _router: Router,
    private _paneControlService: PaneControlService,
    @Inject(PANE_DATA) public data: any
  ) { }

  public doneQuiz() {
    this._hidePane();
    this._router.navigateByUrl('/');
  }

  private _hidePane() {
    this._paneControlService.hideBottomPane();
  }
}
