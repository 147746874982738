<ng-container *ngIf="percentMatch && percentMatch < 0 else matchFlag">
  <button mat-stroked-button
          type="button"
          [disableRipple]="true"
          (click)="openTakeTasteTestDialog($event)">
    Discover Your Match
  </button>
</ng-container>

<ng-template #matchFlag>
  <div class="match-flag" #flag>
    <ng-container *ngIf="percentMatch; else unknown">
      {{ percentMatch | percent }} match
    </ng-container>

    <ng-template #unknown>
      Unknown
    </ng-template>
  </div>
</ng-template>
