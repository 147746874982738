<form>
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Where To Buy</h1>
    <mat-dialog-content>
      <mat-list>
        <mat-divider></mat-divider>
        <ng-container *ngFor="let listItem of listItems">
          <mat-list-item>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="mat-fullwidth">
              <fs-badge *ngIf="listItem.image" [image]="listItem.image"></fs-badge>
              <mat-icon *ngIf="listItem.icon" class="item-icon" [svgIcon]="listItem.icon"></mat-icon>

              <div fxLayout="column" fxFlex>
                <b>{{ listItem.title }}</b>
                <small>{{ listItem.subtitle }}</small>
              </div>

              <a *ngIf="listItem.url" (click)="openUrl(listItem.url)">
                <mat-icon>launch</mat-icon>
              </a>

              <a *ngIf="listItem.linkMode === 'organization'" (click)="openOrganizationPage()">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>

              <a *ngIf="listItem.linkMode === 'findRetailersDialog'" (click)="openFindRetailersDialog(listItem)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-dialog-content>
  </fs-dialog>
</form>
