import { Project } from './project';

export interface Workflow {
  id: number;
  defaultWorkflowStepId?: number;
  projectId?: number;
  project?: Project;
  name?: string;
  state?: string;
  default?: number;
}
