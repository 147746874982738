<form *fsSkeletonForm="collection" (fsForm)="save()">
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>{{ collection.id ? 'Edit' : 'Create' }} List or Route</h1>
    <mat-dialog-content>
      <mat-form-field class="mat-fullwidth">
        <mat-label>Name</mat-label>
<input matInput [(ngModel)]="collection.name" name="name" fsFormRequired>
      </mat-form-field>
      <mat-form-field class="mat-fullwidth">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          [(ngModel)]="collection.description"
          name="description"
          
          cdkTextareaAutosize>
        </textarea>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button type="submit" color="primary">{{ collection.id ? 'Save' : 'Create' }}</button>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
