import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';

import { Season } from 'app/shared/interfaces';


@Injectable()
export class SeasonService {
  public WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  constructor(private _fsApi: FsApi) { }

  public create(data: Season = { id: null }): Season {
    data.seasonalStatus = data.seasonalStatus || 'open';
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`seasons/${id}`, query, { key: 'season' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'seasons', data, Object.assign({ key: 'seasons' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`seasons/${data.id}`, data, Object.assign({ key: 'season' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('seasons', data, { key: 'season' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`seasons/${data.id}`, data, { key: 'season' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }
}
