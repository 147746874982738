import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { WelcomeActionsPaneComponent } from './components/welcome-actions-pane';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        WelcomeActionsPaneComponent,
    ],
    exports: [
        WelcomeActionsPaneComponent
    ]
})
export class WelcomeActionsPaneModule { }
