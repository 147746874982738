import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { forEach, uniqBy } from 'lodash';

import { differenceInSeconds, startOfDay } from 'date-fns';

import { FsListComponent, FsListConfig } from '@firestitch/list';
import { list, nameValue } from '@firestitch/common';
import { ItemType } from '@firestitch/filter';
import { date } from '@firestitch/date';

import { AccountService, OrganizationService, EnvironmentService, AclQueryService } from 'app/core/services';
import { AccountRequest } from 'app/shared/interfaces';
import { InviteDialogComponent } from '../invite/invite-dialog.component';


@Component({
  selector: 'invites-list-dialog',
  templateUrl: './invites-list-dialog.component.html',
  styleUrls: ['./invites-list-dialog.component.scss']
})
export class InvitesListDialogComponent implements OnInit, OnDestroy {

  @ViewChild('list', { static: true })
  public list: FsListComponent = null;
  public config: FsListConfig = null;

  public statesFlatten = null;
  public organizationId: number = null;

  public hasApp = false;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<InvitesListDialogComponent>,
    private _dialog: MatDialog,
    private _accountService: AccountService,
    private _aclQueryService: AclQueryService,
    private _organizationService: OrganizationService,
    private _environmentService: EnvironmentService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.hasApp = this._aclQueryService.hasApp();

    this.organizationId = this.data.organizationId ? this.data.organizationId : null;

    this.statesFlatten = list(this._accountService.statesRequest, 'name', 'value');

    this.initConfig();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public openDialog(accountRequest: AccountRequest = { id: null }) {
    const dialogRef = this._dialog.open(InviteDialogComponent, {
      width: '30%',
      data: { accountRequest, organizationId: this.organizationId }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        if (response) {
          this.list.list.reload();
        }
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  private initConfig() {
    this.config = {
      sort: { value: 'create_date', direction: 'desc' },
      paging: {
        limit: 5,
        limits: [ 5, 15, 25, 50, 100 ],
      },
      filters: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        },
        {
          name: 'state',
          type: ItemType.Select,
          label: 'Status',
          multiple: true,
          values: this._accountService.statesRequest,
          isolate: { label: 'Show Deleted', value: 'deleted' }
        }
      ],
      actions: [
        {
          click: (event) => {
            this.openDialog();
          },
          label: this.organizationId ? 'Invite Staff' : 'Invite People'
        }
      ],
      rowActions: [
        {
          menu: true,
          label: 'Resend Invite',
          show: (data) =>  {
            return data.state !== this._accountService.STATE_REQUEST_DELETED
              && data.state !== this._accountService.STATE_REQUEST_USED
          },
          click: row => {
            this._accountService.resendInvite(row.id)
              .pipe(
                takeUntil(this._destroy$)
              )
              .subscribe((response) => {
                  this.list.reload();
                }
              );
          }
        },
        {
          click: data => {
            return this._accountService.deleteInvites(data);
          },
          show: data => data.state !== this._accountService.STATE_REQUEST_DELETED,
          remove: {
            title: 'Confirm',
            template: 'Are you sure you would like to delete this request?',
          },
          menu: true,
          label: 'Delete'
        }
      ],
      fetch: query => {
        if (this.organizationId) {
          query.environmentId = this.organizationId;
        }

        Object.assign(query, { creatorAccounts: true, environments: true });
        return this._accountService.getsInvites(query, { key: null })
          .pipe(
            map(response => {
              forEach(response.accountRequests, item => {
                item.expires = differenceInSeconds(startOfDay(date(item.expiryDate)), startOfDay(new Date()));
              });
              return response;
            }),
            map(response => ({ data: response.accountRequests, paging: response.paging })),
            takeUntil(this._destroy$),
          );
      }
    };


    if (!this.organizationId) {
      this.addOrganizationFilter();
    }
  }

  private addOrganizationFilter() {
    const organizationFilter = {
      name: 'environmentIds',
      type: ItemType.Select,
      multiple: true,
      label: 'Organizations',
      values: () => {
        return this._organizationService.gets()
          .pipe(
            map((response) =>
              [{ name: 'All', value: '__all' }, ...nameValue(response, 'name', 'id')]
            ),
            takeUntil(this._destroy$)
          );
      }
    };

    this.config.filters.splice(1, 0, organizationFilter)
  }

  public openSettings(invite: AccountRequest) {
    this.close();
    this._router.navigate(['/admin/account', invite.creatorAccountId]);
  }

}
