import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { ShareMenuComponent, ShareMenuDialogComponent } from './components';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ShareMenuComponent,
    ShareMenuDialogComponent,
  ],
  exports: [
    ShareMenuComponent,
    ShareMenuDialogComponent
  ],
})
export class ShareMenuModule {}
