import { Component, Input, HostBinding, OnChanges, SimpleChange, } from '@angular/core';

import { Attribute } from 'app/shared/interfaces';


@Component({
  selector: 'color-tile',
  templateUrl: './color-tile.component.html',
  styleUrls: ['./color-tile.component.scss'],
})
export class ColorTileComponent implements OnChanges {

  @Input() public attribute: Attribute = null;

  @HostBinding('attr.class') public class = 'color-tile';

  public title = '';
  public subtitle = '';
  public color = '';

  constructor() { }

  public ngOnChanges(changes: { attribute: SimpleChange }) {
    if (changes.attribute.currentValue) {
      if (this.attribute.parentAttribute) {
        this.title = this.attribute.parentAttribute.name;
        this.subtitle = this.attribute.name;
        this.color = this.attribute.parentAttribute.backgroundColor;
      }
    } else {
      this.title = '';
      this.subtitle = '';
      this.color = '';
    }
  }

}
