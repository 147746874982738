import { Component, Input, OnInit } from '@angular/core';

import { Account } from '../../../shared/interfaces';


@Component({
  selector: 'app-account-avatar',
  templateUrl: `./account-avatar.component.html`,
  styleUrls: ['./account-avatar.component.scss']
})
export class AccountAvatarComponent implements OnInit {

  public iconStyle: any = {};

  @Input() public size = 30;
  @Input() public shape = 'circle';
  @Input() public image;
  @Input() public color;
  @Input() public tooltip;
  @Input() public icon;
  @Input() public iconSize;
  @Input() public iconColor;

  @Input() set account(value: Account) {
    this._account = value;
    this.updateBadge();
  };

  get account(): Account {
    return this._account;
  }

  private _account: Account = null;

  constructor() { }

  public ngOnInit() {
    this.updateBadge();
  }

  public updateBadge() {

    const size = (this.size / 33) * 14;
    this.iconStyle = { width: size + 'px', height: size + 'px', 'font-size': size + 'px' };
  }

}
