import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable()
export class ActionsService {

  public types = [
    { value: 'view_detail_page', name: 'View Detail Page'},
    { value: 'tried_it', name: 'Tried it'},
    { value: 'untried_it', name: 'Untried it'},
    { value: 'rate', name: 'Rate'},
    { value: 'edit_rating', name: 'Edit Rating'},
    { value: 'unrate', name: 'Unrate'},
    { value: 'add_note', name: 'Add Note'},
    { value: 'share', name: 'Share'},
    { value: 'click_item_website', name: 'Click on Item Website'},
    { value: 'click_item_email', name: 'Click on Item Email'},
    { value: 'click_item_phone', name: 'Click on Item Phone'},
    { value: 'view_photos', name: 'View all Photos'},
    { value: 'click_action_button', name: 'Click Action Button'},
    { value: 'view_wines_page', name: 'View Org. Wines Page'},
    { value: 'view_experiences_page', name: 'View Org. Experiences Page'},
    { value: 'view_collections_page', name: 'View Org. Lists & Routes Page'},
    { value: 'verify_tasting', name: 'Verify Tasting'},
    { value: 'click_wine_club_email', name: 'Click on Wine Club Email'},
    { value: 'submit_wine_club_form', name: 'Submit Wine Club Form'},
    { value: 'add_to_collection', name: 'Add to List or Route'},
    { value: 'remove_from_collection', name: 'Remove from List or Route'},
    { value: 'order_collection', name: 'Order List or Route'},
    { value: 'edit_collection', name: 'Edit List or Route'},
    { value: 'delete_collection', name: 'Delete List or Route'},
    { value: 'create_collection', name: 'Create List or Route'},
    { value: 'view_root_page', name: 'View Root Page'},
    { value: 'use_curated_list', name: 'Use Curated List'},
    { value: 'app_use_start', name: 'Start App Use' },
    { value: 'app_use_end', name: 'End App Use' },
    { value: 'click_reservation', name: 'Reservation Click' },
    { value: 'manual_visit', name: 'Manual Visit' },
    { value: 'automatic_visit', name: 'Automatic Visit' },
  ];

  constructor(private _fsApi: FsApi) { }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'actions', data, Object.assign({ key: 'actions' }, config));
  }

}
