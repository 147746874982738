import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';

import { environment } from '@env/environment';
import { FsTransferService } from '@firestitch/transfer';
import { Account } from '../../shared/interfaces';


@Injectable()
export class AccountService {

  public STATE_ACTIVE = 'active';
  public STATE_DELETED = 'deleted';
  public STATE_PENDING_ACTIVATION	= 'pending_activation';

  public STATE_REQUEST_PENDING = 'pending';
  public STATE_REQUEST_USED = 'used';
  public STATE_REQUEST_DELETED = 'deleted';

  public STATE_REQUEST_TYPE_PASSWORD = 'password';
  public STATE_REQUEST_TYPE_INVITE = 'invite';
  public STATE_REQUEST_TYPE_ACTIVATION = 'activation';

  public states = [
    { name: 'Active', value: this.STATE_ACTIVE },
    { name: 'Pending', value: this.STATE_PENDING_ACTIVATION },
    { name: 'Deleted', value: this.STATE_DELETED }
  ];

  public statesRequest = [
    { name: 'Pending', value: this.STATE_REQUEST_PENDING },
    { name: 'Used', value: this.STATE_REQUEST_USED },
    { name: 'Deleted', value: this.STATE_REQUEST_DELETED }
  ];

  public typesRequest = [
    { name: 'Password', value: this.STATE_REQUEST_TYPE_PASSWORD },
    { name: 'Invite', value: this.STATE_REQUEST_TYPE_INVITE },
    { name: 'Activation', value: this.STATE_REQUEST_TYPE_ACTIVATION }
  ];

  public ACCOUNT_DELETE_MESSAGE = `If you delete this account, then the account holder will no longer be able
    to sign in to the ` + environment.appName + ` app. All of the lists & routes, notes, and other records belonging to this account
    will also be deleted.<br><br>Are you sure you would like to delete this account?`;

  public ACCOUNT_DELETE_OWN_MESSAGE = `If you delete your account, then you will be signed out of the ` + environment.appName + ` app
    immediately and you will not be able to sign back in. All of your lists & routes, notes, and other records within
    the app will also be deleted.<br><br>Are you sure you would like to delete your account?`;

  constructor(
    private _router: Router,
    private fsApi: FsApi,
    private transfer: FsTransferService
    ) { }

  public create(data: Account = { id: null }) {
    data = Object.assign({ image: {}, address: {}, phone: null }, data);
    return data;
  }

  public get(accountId, data: any = {}): Observable<any> {
    return this.fsApi.get(`accounts/${accountId}`, data, { key: 'account' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `accounts`, data, Object.assign({ key: 'accounts' }, config));
  }

  public put(account, config = {}): Observable<any> {
    return this.fsApi.put(`accounts/${account.id}`, account, Object.assign({ key: 'account' }, config));
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  export(data) {
    return this.transfer.post(`api/accounts/export`, data);
  }

  public getEnvironments(accountId: number, data = {}): Observable<any> {
    return this.fsApi.request(`GET`, `accounts/${accountId}/environments`, data, Object.assign({ key: 'environments' }));
  }

  public switchEnvironment(accountId: number, environmentId: number): Observable<any> {
    return this.fsApi.put(`accounts/${accountId}/environments/${environmentId}/switch`, null, Object.assign({ key: 'environment' }));
  }

  public post(account): Observable<any> {
    return this.fsApi.post(`accounts`, account, { key: 'account' });
  }

  public verifiedtasting(accountId, data): Observable<any> {
    return this.fsApi.post(`accounts/${accountId}/verifiedtasting`, data, { key: 'accountItem' });
  }

  public delete(account): Observable<any> {
    return this.fsApi.delete(`accounts/${account.id}`, account, { key: 'account' });
  }

  public image(account, file): Observable<any> {
    return this.fsApi.post(`accounts/${account.id}/image`, { file: file }, { key: 'account' });
  }

  public putPasswordChange(account, config = {}): Observable<any> {
    return this.fsApi.put(`accounts/${account.id}/password/change`, account, Object.assign({ key: 'account' }, config));
  }

  public putPasswordReset(account, config = {}): Observable<any> {
    return this.fsApi.put(`accounts/${account.id}/password/reset`, account, Object.assign({ key: 'account' }, config));
  }

  public getInvites(id, data: any = {}): Observable<any> {
    return this.fsApi.get(`accounts/invites/${id}`, data, { key: 'accountRequest' });
  }

  public getsInvites(data, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `accounts/invites`, data, Object.assign({ key: 'accountRequests' }, config));
  }

  public postInvites(data): Observable<any> {
    return this.fsApi.post(`accounts/invites`, data, { key: 'accountRequest' });
  }

  public resendInvite(accountInviteId: number): Observable<any> {
    return this.fsApi.post(`accounts/invites/${accountInviteId}/resend`, null, { key: 'accountRequest' });
  }

  public deleteInvites(data): Observable<any> {
    return this.fsApi.delete(`accounts/invites/${data.id}`, data, { key: 'accountRequest' });
  }

  public activate(accountId, data: any = {}): Observable<any> {
    return this.fsApi.post(`accounts/${accountId}/activation/activate`, data, { key: 'account' });
  }

  public emailActivation(accountId, data: any = {}): Observable<any> {
    return this.fsApi.post(`accounts/${accountId}/activation/email`, data, { key: 'account' });
  }

  public undelete(accountId, data: any = {}): Observable<any> {
    return this.fsApi.post(`accounts/${accountId}/undelete`, data, { key: 'account' });
  }

  public createAccount(data: any = {}): Observable<any> {
    return this.fsApi.post(`accounts/activations`, data, { key: 'accountRequest' });
  }

  public getSocialAccounts(accountId: number, data = {}): Observable<any> {
    return this.fsApi.request(`GET`, `accounts/${accountId}/socialaccounts`, data, Object.assign({ key: 'socialAccounts' }));
  }

  public postSocialAccounts(accountId: number, data = {}): Observable<any> {
    return this.fsApi.request(`POST`, `accounts/${accountId}/socialaccounts`, data, Object.assign({ key: 'socialAccount' }));
  }

  public deleteSocialAccounts(accountId: number, data = {}): Observable<any> {
    return this.fsApi.request(`DELETE`, `accounts/${accountId}/socialaccounts`, data, Object.assign({ key: 'socialAccount' }));
  }

  // Need full router tree reload to fire HomeComponent again
  public proceedHome() {
    this._router.navigate(['/blank']).then(() => {
      this._router.navigateByUrl(`/`);
    });
  }

}
