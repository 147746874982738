import { CuratedListDigestFrequency } from '../enums';


export const CuratedListDigestFrequencies = [
  {
    name: 'Once',
    value: CuratedListDigestFrequency.Once,
  },
  {
    name: 'Daily',
    value: CuratedListDigestFrequency.Daily,
  },
  {
    name: 'Weekly',
    value: CuratedListDigestFrequency.Weekly,
  },
  {
    name: 'Monthly',
    value: CuratedListDigestFrequency.Monthly,
  },
];
