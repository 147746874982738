<div *ngIf="item" class="marker" [ngClass]="{ clickable: clickable }">
  <mat-icon
      *ngIf="!item.type.mapImage?.small && item.type?.icon"
      [ngStyle]="{ color: item.type.color }">
    {{ item.type.icon }}
  </mat-icon>
  <img
    *ngIf="item.type.mapImage?.small"
    [src]="item.type.mapImage.small">
  <span
      *ngIf="showName"
      [ngStyle]="{ color: item.type.color }">
    {{ item.name }}
  </span>
</div>
