import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'social-button',
  templateUrl: `./social-button.component.html`,
  styleUrls: ['./social-button.component.scss']
})
export class SocialButtonComponent {

  public iconStyle: any = {};

  @Input() public label;
  @Input() public platform;
  @Output() public onClick = new EventEmitter();

  public clicked() {
    this.onClick.emit();
  }
}
