import { Component, Input, OnInit } from '@angular/core';

import { tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Platform, ShareConfig } from '@firestitch/share';
import { FsMessage } from '@firestitch/message';

import { ShareMenuService } from '../../services/share-menu.service';
import { Item } from '@app/taster/modules/item/models';
import { ShareService } from '@app/core/services';


@Component({
  selector: 'app-share-menu',
  templateUrl: './share-menu.component.html',
  styleUrls: ['./share-menu.component.scss'],
})
export class ShareMenuComponent implements OnInit {

  @Input() public item: Item = null;

  public shareActions: {
    label: string,
    handler: any,
    medium: Platform,
    config: ShareConfig,
    code: string,
  }[] = [];

  constructor(
    private _shareMenu: ShareMenuService,
    private _shareService: ShareService,
    private _fsMessage: FsMessage
  ) {}

  public ngOnInit(): void {
    this._shareMenu.actions.forEach((action) => {

      const shareData = this._loadShareConfig();

      this.shareActions.push({
        ...action,
        config: shareData.config,
        code: shareData.code,
      });
    });
  }

  
  public open = (event) => {

    if (event.platform === 'copy') {
      this._fsMessage.info('Link copied.');
    }

    const shareAction = this.shareActions.find(action => action.medium === event.platform);

    if (shareAction) {
      return this._shareService.post({
        code: shareAction.code,
        medium: event.platform,
        objectId: this.item.id
      }).pipe(
        tap(
          response => {
            Object.assign(this.item.accountItem, response.accountItem);
            Object.assign(this.item.meta, response.object.meta);
          }
        )
      );
    } else {
      return of(false);
    }
  }

  private _loadShareConfig() {
    const shareData: any = this._shareService.getShareData(
      this.item.id,
      window.location.pathname
    );

    return {
      config: {
        url: shareData.url,
        title: this.item.name,
        image: this.item.image.large,
      },
      code: shareData.code,
    };
  }

}
