import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';

import { AwardOrganization } from '../../shared/interfaces';
import { StateBase } from 'app/shared/types';


@Injectable()
export class AwardOrganizationService {

  public states = [
    { name: 'Active', value: StateBase.Active },
    { name: 'Deleted', value: StateBase.Deleted }
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: AwardOrganization = { id: null }): AwardOrganization {
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`awards/organizations/${id}`, query, { key: 'awardOrganization' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'awards/organizations', data, Object.assign({ key: 'awardOrganizations' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`awards/organizations/${data.id}`, data, Object.assign({ key: 'awardOrganization' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('awards/organizations', data, { key: 'awardOrganization' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`awards/organizations/${data.id}`, data, { key: 'awardOrganization' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

}
