export enum ObjectClass {
  Doc = 'doc',
  Task = 'task',
  File = 'file',
  Account = 'account',
  Tag = 'tag',
  Type = 'type',
  Status = 'status',
  Address = 'address',
  Project = 'project',
  AssetImage = 'asset_image',
  AssetFile = 'asset_file',
  AssetVideo = 'asset_video',
  Component = 'component',
  DataObjectTable = 'data_object_table',
  DataObjectColumn = 'data_object_column',
  Comment = 'comment',
  Plan = 'plan',
  Scenario = 'scenario',
  Data = 'data',
}
