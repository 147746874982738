import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { FsMessage } from '@firestitch/message';
import { BuildData } from '../../data/build.data';
import { BuildActions } from '@app/system/modules/build/consts'
import { BuildAction } from '@app/system/modules/build/enums'

@Component({
  templateUrl: './build.component.html',
  styleUrls: ['./build.component.scss']
})
export class BuildComponent implements OnInit {

  public build = null;
  public actions = BuildActions;
  public Action = BuildAction;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _dialogRef: MatDialogRef<BuildComponent>,
    private _message: FsMessage,
    private _buildData: BuildData,
  ) {}

  public ngOnInit() {
    if (this._data.build.id) {
      this._buildData.get(this._data.build.id)
        .subscribe(response => {
          this.build = response;
        });
    } else {
      this.build = Object.assign({}, this._data.build);
    }
  }

  public save = () => {
    return this._buildData.save(this.build)
      .pipe(
        tap(
          response => {
            this._message.success('Saved Changes');
            this._dialogRef.close(response);
          })
      );
  }
}
