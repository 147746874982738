import { Injectable } from '@angular/core';

import { Observable, Subject, of, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FsApi } from '@firestitch/api';


@Injectable({
  providedIn: 'root',
})
export class SettingService {

  public CuratedListTypeRotating = 'rotating';
  public CuratedListTypeFiltered = 'filtered';

  public curatedListTypes = [
    { value: this.CuratedListTypeRotating, name: 'Rotating' },
    { value: this.CuratedListTypeFiltered, name: 'Filtered' },
  ];

  public  settingsData: { [key: string]: any } = {};
  private _change$ = new Subject();

  private promises = {};

  public get change$() {
    return this._change$.asObservable();
  }

  constructor(
    private _fsApi: FsApi,
  ) { }

  public load(): Promise<any> {
    this.promises['other'] = this._fsApi.request('GET', 'settings/other', {}, Object.assign({ key: 'settings' }))
    .pipe(
      tap((response) => this._setData('other', response))
    );

    this.promises['branding'] = this._fsApi.request('GET', 'settings/branding', {}, Object.assign({ key: 'settings' } ))
      .pipe(
        tap((response) => this._setData('branding', response))
      );

    this.promises['map'] = this._fsApi.request('GET', 'settings/map', {}, Object.assign({ key: 'settings' }))
      .pipe(
        tap((response) => this._setData('map', response))
      );

    return forkJoin(this.promises)
      .toPromise();
  }

  public get(data = {}) {
    return this._fsApi.request('GET', `settings`, data, { key: null });
  }

  public gets(scope: string, data = {}, forceQuery = false, config = {}): Observable<any> {

    if(forceQuery) {
      let url = 'settings';
      if (scope) {
        url += `/${scope}`;
      }

      scope = scope || 'default';

      return this._fsApi.request('GET', url, data, Object.assign({ key: 'settings' }, config ))
        .pipe(
          tap((response) => this._setData(scope, response))
        );
    }

    scope = scope || 'default';

    return this.settingsData[scope] ? of(this.settingsData[scope]) : this.promises[scope];
  }

  public post(scope: string, data, config = {}): Observable<any> {
    return this._fsApi.post(`settings/${scope}`, data, Object.assign({ key: 'settings' }, config ))
      .pipe(
        tap((response) => this._setData(scope, response))
      );
  }

  public delete(scope: string, config = {}) {
    return this._fsApi.delete(`settings/${scope}`, null, Object.assign({ key: 'settings' }, config))
      .pipe(
        tap((response) => this._setData(scope, response))
      );
  }

  public shareClickImage(): Observable<any> {
    return this._fsApi.get(`settings/shareclickimage`, {}, { key: '' });
  }

  public shareClickImageUpload(file): Observable<any> {
    return this._fsApi.post(`settings/shareclickimage`, { file: file }, { key: '' });
  }

  private _setData(scope, value) {
    this.settingsData[scope] = value;
    this._change$.next(this.settingsData[scope]);
  }

}
