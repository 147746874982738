import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Angulartics2 } from 'angulartics2';

import { Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { Collection, Account } from '@app/shared/interfaces';
import { CollectionService, AuthService } from '@app/core/services';
import { CollectionOwnerType } from '@app/shared/types';


@Component({
  templateUrl: './collection-dialog.component.html',
  styleUrls: ['./collection-dialog.component.scss']
})
export class CollectionDialogComponent implements OnInit, OnDestroy {

  public collection: Collection = null;

  private _loggedInAccount: Account = null;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<CollectionDialogComponent>,
    private _collectionService: CollectionService,
    private _authService: AuthService,
    private _fsMessage: FsMessage,
    private _angulartics2: Angulartics2,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => this._loggedInAccount = response.value);

    (this.data.collection.id ?
      this._collectionService.get(this.data.collection.id) :
      of(this.data.collection)
    )
      .subscribe(response => this.collection = Object.assign({}, this._collectionService.create(response)));
  }

  public save() {

    if (!this.collection.id) {
      this.collection.ownerType = CollectionOwnerType.Taster;
      this.collection.ownerObjectId = this._loggedInAccount.id;
    }

    this._collectionService.save(this.collection)
      .subscribe(response => {

        if (!this.collection.id) {
          this._angulartics2.eventTrack.next({
            action: 'create_collection',
            properties: { objectId: response.id }
          });
        } else {
          this._angulartics2.eventTrack.next({
            action: 'edit_collection',
            properties: { objectId: this.collection.id }
          });
        }

        this._fsMessage.success(`Changes saved`);
        this.close(response);
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
