import { Component, Input, OnInit } from '@angular/core';

import { filter } from '@firestitch/common';

import { ActionInline } from '@app/shared/interfaces';


@Component({
  selector: 'app-actions-inline',
  templateUrl: './actions-inline.component.html',
  styleUrls: ['./actions-inline.component.scss']
})
export class ActionsInlineComponent implements OnInit {

  private _actions: ActionInline[] = [];
  @Input() public set actions(data: ActionInline[]) {
    this._actions = data.map(item => {
      item.primary = item.primary === undefined ? true : false;
      item.raised = item.raised === undefined ? true : false;
      item.hideMobile = item.hideMobile || false;
      return item;
    });

    this.kebabActions = filter(this._actions, { hideMobile: true });
  }

  public get actions(): ActionInline[] {
    return this._actions;
  }

  public kebabActions: ActionInline[] = [];

  constructor(
  ) { }

  public ngOnInit() {
  }

  public onClick(action: ActionInline) {
    action.action();
  }

}
