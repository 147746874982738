<div *fsSkeleton="sourceObject">
  <fs-gallery
      [config]="config">
    <ng-template fsGalleryThumbnail let-item="item" let-template="template">
      <ng-template 
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ item: item }">
      </ng-template>
      <!--
        @TODO seems like this code is not actual in the latest fs-gallery
      <ng-container [ngSwitch]="item.galleryMime">
        <ng-template [ngSwitchCase]="'image'">
          <a class="bottom-left image-icon">
            <mat-icon>image</mat-icon>
          </a>
        </ng-template>

        <ng-template [ngSwitchCase]="'application'">
          <a class="bottom-left application-icon">
            <mat-icon>insert_drive_file</mat-icon>
          </a>
        </ng-template>

        <ng-template [ngSwitchCase]="'video'">
          <a class="bottom-left video-icon">
            <mat-icon>videocam</mat-icon>
          </a>
        </ng-template>

        <ng-template ngSwitchDefault>
          <a class="bottom-left">
            <mat-icon>help</mat-icon>
          </a>
        </ng-template>
      </ng-container>
      -->
    </ng-template>
    <ng-template fsGalleryPreview let-item="item">
      <div>{{ item.description }}</div>
    </ng-template>

    <fs-gallery-list-column>
      <ng-template fs-gallery-list-cell let-row let-openPreview="openPreview">
        <a (click)="openPreview(row)" class="name">{{ row.name }}</a>
      </ng-template>
    </fs-gallery-list-column>
    <!-- <ng-template fsGalleryThumbnailContainer let-item="item">
      <fs-gallery-thumbnail-preview [item]="item"></fs-gallery-thumbnail-preview>
      <div class="content">
        <fs-gallery-thumbnail-info [item]="item"></fs-gallery-thumbnail-info>
        <div class="custom-footer">Uploaded on Jan 12</div>
      </div>
    </ng-template> -->
  </fs-gallery>
</div>
