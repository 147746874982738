<!-- <div class="banner {{item.class}}" [ngClass]="{'banner-border': item.featureCollection && withBorder}"> -->
<div class="banner">
  <a class="link" [routerLink]="['/', 'collections', item.featureCollection.id]">
    <div>
      View All {{ item.featureCollection.name }}
    </div>
    <div class="arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </a>
</div>
<!-- </div> -->
