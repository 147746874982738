import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';

import { COUNTRIES } from '@firestitch/address';
import { find } from '@firestitch/common';

import { Region } from '../../shared/interfaces';


@Injectable()
export class RegionService {

  constructor(
    private _fsApi: FsApi,
    @Inject(COUNTRIES) private _countries
  ) { }

  public create(data: Region = { id: null }): Region {
    return data;
  }

  public get(id, data = {}): Observable<any> {
    return this._fsApi.get(`regions/${id}`, data, { key: 'region' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'regions', data, Object.assign({ key: 'regions' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`regions/${data.id}`, data, Object.assign({ key: 'region' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('regions', data, { key: 'region' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`regions/${data.id}`, data, { key: 'region' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public getsSubregions(region_id = null, data = {}, config = {}): Observable<any> {
    const url = region_id ? `regions/${region_id}/subregions` : `regions/subregions`;
    return this._fsApi.request('GET', url, data, Object.assign({ key: 'subregions' }, config));
  }

  public putSubregion(region_id, data, config = {}): Observable<any> {
    return this._fsApi.put(`regions/${region_id}/subregions/${data.id}`, data, Object.assign({ key: 'subregion' }, config));
  }

  public postSubregion(region_id, data): Observable<any> {
    return this._fsApi.post(`regions/${region_id}/subregions`, data, { key: 'subregion' });
  }

  public saveSubregion(region_id, data): Observable<any> {
    if (data.id) {
      return this.putSubregion(region_id, data);
    }
    return this.postSubregion(region_id, data);
  }

  public deleteSubregion(region_id, data): Observable<any> {
    return this._fsApi.delete(`regions/${region_id}/subregions/${data.id}`, data, { key: 'subregion' });
  }

  public updateRegionCountry(region: Region): Region {
    const country = find(this._countries, { code: region.country });
    const countryRegions = country && country.regions ? country.regions : [];
    region['countryData'] = country;
    region['provinceData'] = countryRegions.length ? find(countryRegions, { code: region.province }) : null;
    return region;
  }

}
