import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SidenavMenuRef, MENU_DATA } from '@firestitch/sidenav';
import { FsStore } from '@firestitch/store';

import {
  PortalService,
  AuthService,
  AclQueryService,
  AccountService,
  SessionService,
  SidenavService
} from 'app/core/services';
import { LastPortal } from 'app/shared/types';
import { Account } from 'app/shared/interfaces';
import { OrganizationDialogComponent } from 'app/shared/components/dialogs';
import { EnvironmentSwitchComponent } from '../environment-switch';


@Component({
  templateUrl: './environment-menu.component.html',
  styleUrls: ['./environment-menu.component.scss']
})
export class EnvironmentMenuComponent implements OnInit, OnDestroy {

  public lastPortal: LastPortal = null;
  public LastPortal = LastPortal;

  public loggedInAccount: Account = null;

  public environment = null;
  public environments = null;

  public hasApp = true;
  public hasPermissionOrganization = true;

  public showSwitchToAdmin = true;
  public showSwitchToManagement = true;
  public showSwitchToTaster = true;

  private _destroy$ = new Subject();

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _fsStore: FsStore,
    private _portalService: PortalService,
    private _authService: AuthService,
    private _aclQueryService: AclQueryService,
    private _accountService: AccountService,
    private _sessionService: SessionService,
    private _sidenavService: SidenavService,
    private _menuRef: SidenavMenuRef<EnvironmentMenuComponent>,
    @Inject(MENU_DATA) public data
  ) { }

  public ngOnInit() {

    this.hasApp = this._aclQueryService.hasApp();
    this.hasPermissionOrganization = this._aclQueryService.hasOrganizations();

    this._portalService.lastPortal$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => {
        this.lastPortal = response;
        this.showSwitchToAdmin = this.hasApp && this.lastPortal !== LastPortal.Admin;
        this.showSwitchToManagement = this.hasPermissionOrganization && this.lastPortal !== LastPortal.Management;
        this.showSwitchToTaster = this.lastPortal !== LastPortal.Taster;
      });

    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(store => {

        this.loggedInAccount = store.value;

        if (!this.loggedInAccount) {
          return;
        }

        this._accountService.getEnvironments(this.loggedInAccount.id)
          .subscribe(environments => this.environments = environments);
      });

    this._fsStore.observe('environment')
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(store => this.environment = store.value);
  }

  public changePortal(lastPortal: LastPortal) {

    this.loggedInAccount.lastPortal = lastPortal;

    this._accountService.save(this.loggedInAccount)
      .subscribe(() => {
        this._sessionService.account(Object.assign(this.loggedInAccount, { lastPortal: lastPortal }));
        this.close();
        this._router.navigateByUrl(this._portalService.getHomePageUrl(this.environment));
      });
  }

  public proceedOrganization() {
    this._router.navigate(['/manage', 'organizations', this.environment.id]);
    this.close();
  }

  public createOrganizationDialog() {
    this.close();

    const dialogRef = this._dialog.open(OrganizationDialogComponent, {
      data: {
        updateJobTitle: !this.loggedInAccount.jobTitle,
      },
      width: '30%',
      minWidth: '400px',
    });

    dialogRef.afterClosed()
      .pipe(
        filter(organization => !!organization)
      )
      .subscribe(organization => this.switch(organization));
  }

  public close() {
    this._menuRef.close();
    this._sidenavService.close();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private switch(environment) {
    this._accountService.switchEnvironment(this.loggedInAccount.id, environment.id)
      .subscribe(response => {
        this._sessionService.environment(response);
        this._router.navigate(['/manage', 'organizations', environment.id]);
      });
  }

  public switchDialog() {
    this.close();

    const dialogRef = this._dialog.open(EnvironmentSwitchComponent, {
      data: { environments: this.environments }
    });

    dialogRef.afterClosed()
      .subscribe(response => { });
  }

}
