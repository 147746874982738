import { Component, Input, OnInit } from '@angular/core';

import { WineService } from '@app/core/services';
import { Wine, Object } from '@app/shared/interfaces';
import { Item } from '@app/taster/modules/item/models';


@Component({
  selector: 'app-wine-name',
  templateUrl: './wine-name.component.html',
  styleUrls: ['./wine-name.component.scss']
})
export class WineNameComponent implements OnInit {

  @Input() public set wine(value: Wine) {
    this.name = this._wineService.getName('wine', value);
  }

  @Input() public set object(value: Object) {
    this.name = this._wineService.getName('object', value);
  }

  @Input() public set item(value: Item) {
    this.name = this._wineService.getName('item', value);
  }

  public name: string = null;

  constructor(
    private _wineService: WineService
  ) { }

  public ngOnInit() {
  }

}
