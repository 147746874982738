<div class="list-element">
  <mat-icon class="left-icon" [svgIcon]="icon"></mat-icon>
  <div class="title">
    <b> {{ title }} </b>
  </div>
  <div class="right-part">
    <div class="subheading-1"> {{ value }} </div>
    <mat-icon class="right-icon">
      keyboard_arrow_right
    </mat-icon>
  </div>
</div>
