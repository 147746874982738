<mat-card>
  <div fxLayout="row" fxLayoutGap="5px">
    <button mat-raised-button color="primary" (click)="openDialog()">Open Verify Dialog</button>
    <button mat-raised-button color="primary" (click)="openFull()">Open Full Dialog</button>
    <button mat-raised-button color="primary" (click)="toggleRatings()">Toggle Ratings</button>
    <button mat-raised-button color="primary" (click)="toogleFooter()">Toggle Footer</button>
  </div>
</mat-card>

<p>
  Donec vel libero ut dui feugiat consectetur. Proin dolor ante, posuere eget libero et, dignissim pretium eros. Sed quis sollicitudin nisi, id interdum risus. Pellentesque et ligula purus. Etiam quis sapien placerat, viverra felis at, egestas nibh. Etiam sollicitudin laoreet sapien vel sagittis. Donec id turpis quis enim tincidunt finibus. Curabitur vestibulum tellus placerat, vestibulum ex sed, efficitur mauris. Nunc convallis at felis mattis malesuada. Proin volutpat urna odio, at scelerisque enim ultrices at. Pellentesque placerat ullamcorper sollicitudin. Nam lobortis iaculis malesuada. Maecenas semper, leo in venenatis ullamcorper, felis lorem aliquet felis, ut vestibulum neque magna non mi. Nunc id hendrerit lectus.
</p>
<p>
  Suspendisse scelerisque ligula at ex eleifend, eu aliquet purus gravida. Praesent sed purus sit amet tortor suscipit dapibus nec ac quam. Quisque congue sapien urna, convallis vehicula libero mattis et. Suspendisse ut est sit amet libero ultricies elementum ac ut metus. Proin sodales eu risus non interdum. Suspendisse volutpat leo ut diam ultricies dapibus. Integer nec mattis diam.
</p>
<p>
  Morbi sit amet massa elit. Donec finibus sem eu laoreet volutpat. Integer varius egestas purus, vitae mollis augue. Nulla facilisi. Nulla fermentum accumsan velit sed placerat. In eleifend id lacus eget ultrices. Aenean ac sem ac ligula eleifend laoreet et ut lorem. Suspendisse potenti. Cras suscipit neque felis, eget interdum magna finibus sit amet. Maecenas risus felis, mattis a dictum a, mollis in mi.
</p>
<p>
  Quisque quam ante, sollicitudin non enim rutrum, interdum scelerisque felis. Aenean nulla arcu, volutpat nec arcu et, dictum interdum turpis. Maecenas ac cursus justo. Vivamus faucibus nulla et massa euismod, vel tempus dui porttitor. Integer vel velit hendrerit, dictum lorem sed, imperdiet nisl. Curabitur fermentum gravida elementum. Duis interdum, eros at fermentum mollis, nibh massa dapibus dui, eu dapibus turpis augue id purus. Aenean ornare dapibus neque, at imperdiet sem hendrerit sit amet.
</p>


