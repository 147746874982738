import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { FsNavService } from '@firestitch/nav';
import { FsStore } from '@firestitch/store';

import { PortalService } from '@app/core/services';

/**
 * Don't replace this code in another components
 * It breaks behaviour
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy, OnInit {

  constructor(
    private _router: Router,
    private _fsStore: FsStore,
    private _portalService: PortalService,
    private _fsNavService: FsNavService
  ) { }

  public ngOnDestroy() { }

  public ngOnInit() {
    combineLatest(
      this._portalService.lastPortal$,
      this._fsStore.observe('environment')
    )
      .pipe(
        take(1),
        untilDestroyed(this),
      )
      .subscribe((response: any) => {
        this._router.navigateByUrl(this._portalService.getHomePageUrl(response[1].value));
      });
  }

}
