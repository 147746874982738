import { Component } from '@angular/core';

@Component({
  selector: 'or-line',
  templateUrl: './or-line.component.html',
  styleUrls: ['./or-line.component.scss']
})
export class OrLineComponent {

}
