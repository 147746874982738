import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetService, SettingService } from '@app/core';

import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';


@Component({
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialComponent implements OnInit {

  @ViewChild('image')
  public image = null;

  public currentIndex = 0;
  public assets: any[] = [];

  public imageLoaded$ = new BehaviorSubject(false);
  public get imageLoaded(): boolean {
    return this.imageLoaded$.getValue();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _dialogRef: MatDialogRef<TutorialComponent>,
    private _cdRef: ChangeDetectorRef,
    private _settingService: SettingService,
    private _assetService: AssetService,
  ) { }

  public ngOnInit() {
    this._dialogRef.addPanelClass('tutorial');

    this._settingService.gets('other')
      .pipe(
        switchMap((settings) => {
          return this._assetService.gets({
            objectId: settings.tutorialObjectId,
            files: true,
            order: 'order,asc'});
        }),
        tap((assets) => this.assets = assets),
      )
      .subscribe((assets) => {
        this._preloadImage(this.assets[this.currentIndex]?.file?.preview?.actual);
        this._loadProgress();
        this._cdRef.markForCheck();
      });
  }


  private _preloadImage(url) {
    if(this.image) {
      this.image.nativeElement.classList.add('loading');
    }

    const img = new Image();
    img.onload = () => {
      const interval = setInterval(() => {
          if(this.image) {
            this.image.nativeElement.src = url;
            this.image.nativeElement.classList.remove('loading');
            clearInterval(interval);
          }
        },
        20
      );
    }
    img.src = url;
  }

  public next() {

    this.imageLoaded$.next(false);
    if (this.currentIndex < this.assets.length - 1) {
      this.currentIndex++;
      this._preloadImage(this.assets[this.currentIndex]?.file?.preview?.actual);
    } else if (this.currentIndex === this.assets.length - 1) {
      this._dialogRef.close(true);
    }
  }

  public back() {
    this.imageLoaded$.next(false);
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this._preloadImage(this.assets[this.currentIndex]?.file?.preview?.actual);
    }
  }

  public close() {
    this._dialogRef.close();
  }

  private _loadProgress(): void {
    if (!this.assets.length) {
      return;
    }
    const interval = setInterval(() => {
        if(this.image) {
          this.image.nativeElement.onload = () => {
              this.imageLoaded$.next(true);
              this._cdRef.markForCheck();
          };
          clearInterval(interval);
        }
      },
      20
    );
  }

}
