<fs-autocomplete
  *ngIf="!readonly"
  [fetch]="loadOrganizations"
  [fetchOnFocus]="true"
  [displayWith]="displayFn"
  [(ngModel)]="organization"
  [placeholder]="title"
  (ngModelChange)="organizationSelected($event)"
  [fsFormRequired]="required"
  name="organization">
  <ng-template fsAutocompleteSuffix let-data="data"></ng-template>
  <ng-template fsAutocompleteTemplate let-data="data">
    <span>{{ data.name }}</span>
  </ng-template>
</fs-autocomplete>

<fs-label-field *ngIf="readonly">
  <fs-label>{{ title }}</fs-label>
  {{ organization?.name }}
</fs-label-field>
