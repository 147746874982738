import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';

import { Collection } from '@app/shared/interfaces';
import { CollectionType, CollectionOwnerType, CollectionShowOnPage, CollectionObjectType } from '@app/shared/types';
import { CollectionOrderBy } from '@app/shared/enums';


@Injectable()
export class CollectionService {

  public tags = [
    { name: 'For You', value: 'for_you' }
  ];

  public collectionObjectTypes = [
    { name: 'Organization', value: CollectionObjectType.Organization },
    { name: 'Wine', value: CollectionObjectType.Wine },
    { name: 'Experience', value: CollectionObjectType.Experience },
  ];

  public collectionShowOnPage = [
    { name: 'Wines', value: CollectionShowOnPage.Wines },
    { name: 'Wineries', value: CollectionShowOnPage.Wineries },
    { name: 'To Do', value: CollectionShowOnPage.ToDo },
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: Collection = { id: null }): Collection {

    if (!data.id) {
      data.type = data.type || CollectionType.General;
    }

    data.orderBy = data.orderBy || CollectionOrderBy.Custom;

    return data;
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(id, data = {}): Observable<any> {
    return this._fsApi.get(`collections/${id}`, data, { key: 'collection' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, `collections`, data, Object.assign({ key: 'collections' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`collections/${data.id}`, data, Object.assign({ key: 'collection' }, config));
  }

  public post(data): Observable<any> {

    switch (data.ownerType) {
      case CollectionOwnerType.Organization:
        data.organizationId = data.ownerObjectId;
        break;
      case CollectionOwnerType.Taster:
        data.accountId = data.ownerObjectId;
        break;
    }

    let urlData = ['collections'];

    // Post query with not empty id means that duplicate requested
    if (data.id) {
      urlData = [...urlData, data.id, 'duplicate'];
    }

    return this._fsApi.post(urlData.join('/'), data, { key: 'collection' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`collections/${data.id}`, data, { key: 'collection' });
  }

  public image(data, file): Observable<any> {
    return this._fsApi.post(`collections/${data.id}/image`, { file: file }, { key: 'collection' });
  }

  public imageDelete(data): Observable<any> {
    return this._fsApi.delete(`collections/${data.id}/image`, data, { key: 'collection' });
  }

  public bulkDeleteCollections(collectionIds: number[], query = {}): Observable<any> {
    return this._fsApi.delete(`collections/bulk`, { collectionIds }, { key: null })
  }

  public getsObjects(collectionId, data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, `collections/${collectionId}/objects`, data, Object.assign({ key: null }, config));
  }

  public postObjects(collectionId, data): Observable<any> {
    return this._fsApi.post(`collections/${collectionId}/objects`, data, { key: 'collectionObjects' });
  }

  public orderObjects(collectionId, data): Observable<any> {
    return this._fsApi.put(`collections/${collectionId}/objects/order`, data, { key: null });
  }

  public deleteObjects(collectionId, data): Observable<any> {
    return this._fsApi.delete(`collections/${collectionId}/objects`, data, { key: null });
  }

}
