import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { switchMap, map } from 'rxjs/operators';

import { AclQueryService, ItemService } from '@app/core/services';

import { AddToCollectionDialogComponent } from '../components/add-to-collection-dialog';
import { AddToListDialogComponent } from '../components/add-to-list-dialog';
import { Item } from '../models';


@Injectable()
export class AddToListService {

  constructor(
    private _dialog: MatDialog,
    private _aclQueryService: AclQueryService,
    private _itemService: ItemService,
  ) { }

  public openDialog(item: Item) {

    let dialogComponent = null;

    if (
      this._aclQueryService.hasBackend() ||
      this._aclQueryService.hasApp() ||
      this._aclQueryService.hasOrganizations()
    ) {
      dialogComponent = AddToCollectionDialogComponent;
    } else {
      dialogComponent = AddToListDialogComponent;
    }

    return this._dialog.open(dialogComponent, {
      data: { item }
    });
  }

}
