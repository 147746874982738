import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { startCase, toLower } from 'lodash-es';

import { SourceObject } from 'app/shared/types';


@Component({
  selector: 'app-description-dialog',
  templateUrl: './description-dialog.component.html',
  styleUrls: ['./description-dialog.component.scss']
})
export class DescriptionDialogComponent implements OnInit {

  public sourceObject: SourceObject = null;
  public type: 'organization' | 'wine' | 'experience' = null;
  public title: string = null;

  constructor(
    private _dialogRef: MatDialogRef<DescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.sourceObject = Object.assign({}, this.data.sourceObject);
    this.type = this.data.type;
    this.title = this.data.title || `Edit ${startCase(toLower(this.type))} Description`;
  }

  public save() {
    this.close(this.sourceObject);
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

}
