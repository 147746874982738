import { ItemType } from '../enums';


export function getItemTypeNameLabel(item) {
  
  switch (item.class) {
    case ItemType.Wine: {
      return 'wine';
    }

    case ItemType.Organisation: {
      return item.typeName && item.typeName.toLowerCase();
    }

    case ItemType.Experience: {
      return item.typeName && item.typeName.toLowerCase();
    }

    case ItemType.Collection: {
      return 'list or route';
    }
  }
}
