<mat-card>
  <mat-card-content>
    <content-widget
      tag="organization_not_selected"
    ></content-widget>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="createOrganizationDialog()">Create Organization</button>
    <button *ngIf="hasApp && lastPortal !== 'admin'" mat-button color="primary" (click)="changePortal(LastPortal.Admin)">Switch To Admin Portal</button>
    <button *ngIf="lastPortal !== 'taster'" mat-button color="primary" (click)="changePortal(LastPortal.Taster)">Switch to Taster Portal</button>
  </mat-card-actions>
</mat-card>
