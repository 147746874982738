import { Component, Inject, OnInit } from '@angular/core';

import { PANE_DATA } from '@firestitch/pane';

import { Item, ItemExperienceModel } from '@app/taster/modules/item/models';


@Component({
  selector: 'app-experience-action-pane',
  templateUrl: './action-pane.component.html',
  styleUrls: ['./action-pane.component.scss']
})
export class ExperienceActionPaneComponent implements OnInit {

  public item: Item = null;
  public experienceData: ItemExperienceModel = null;

  constructor(
    @Inject(PANE_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
    this.experienceData = this.data.item.data;
  }

}
