import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AttributeService } from 'app/core/services';
import { AttributeEditComponent } from '../attribute-edit/attribute-edit.component';
import { Attribute } from 'app/shared/interfaces';



@Component({
  templateUrl: './attribute-manage.component.html',
  styleUrls: ['./attribute-manage.component.scss']
})
export class AttributeManageComponent implements OnInit, OnDestroy {

  @ViewChild('attributeList', { static: true }) attributeList;

  public title;
  public projectId;
  public class;
  private _destroy = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    private _attributeService: AttributeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  public ngOnInit() {
    this.projectId = this.data.projectId;
    this.class = this.data.class;
    this.title = this._attributeService.getClass(this.class).pluralName;
  }

  public ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  public close() {
    this._dialogRef.close();
  }
}
