import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';

import { SearchRoutingModule } from './search-routing.module';
import {
  SearchDialogComponent,
  SearchComponent
} from './';


@NgModule({
    imports: [
        SearchRoutingModule,
        SharedModule
    ],
    declarations: [
        SearchDialogComponent,
        SearchComponent,
    ]
})
export class SearchModule { }
