import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { list } from '@firestitch/common';
import { FsMessage } from '@firestitch/message';

import { Organization, Collection } from '@app/shared/interfaces';
import { CollectionService, PortalService, AclQueryService } from '@app/core/services';
import { CollectionOwnerType, LastPortal } from '@app/shared/types';
import { CollectionObject } from '@app/shared/types';


@Component({
  selector: 'app-collection-assign-dialog',
  templateUrl: './collection-assign-dialog.component.html',
  styleUrls: ['./collection-assign-dialog.component.scss']
})
export class CollectionAssignDialogComponent implements OnInit, OnDestroy {

  public collectionObjects: CollectionObject[] = [];
  public isBulk = false;
  public ownerType: CollectionOwnerType = CollectionOwnerType.Organization;

  public organization: Organization = null;
  public collectionOrRoute: 'new' | 'existing' = 'existing';
  public collections: Collection[] = [];
  public organizationIds = null;

  public collectionId: number = null;
  public collectionName: string = null;
  public collectionDescription: string = null;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<CollectionAssignDialogComponent>,
    private _collectionService: CollectionService,
    private _portalService: PortalService,
    private _fsMessage: FsMessage,
    private _aclQuery: AclQueryService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {

    this.collectionObjects = this.data.collectionObjects;
    this.isBulk = this.data.isBulk;

    if (!this._aclQuery.hasApp()) {
      this.organizationIds = this._aclQuery.getObjectIds();

      this._portalService.lastPortal$
        .pipe(
          takeUntil(this._destroy$),
          filter(response => [LastPortal.Management, LastPortal.Admin].indexOf(response) !== -1)
        )
        .subscribe(() => {
          // In Management Portal we are not able to select Organization from the picker.
          // Prefill data from specified objects
          if (this.collectionObjects.length && this.collectionObjects[0]['organization']) {
            this.onOrganizationChange(this.collectionObjects[0]['organization']);
          }
        });
    } else {
      if (this.collectionObjects.length && this.collectionObjects[0]['organization']) {
        this.onOrganizationChange(this.collectionObjects[0]['organization']);
      }
    }
  }

  public save() {
    new Observable(observer => {
      if (this.collectionOrRoute === 'existing') {
        observer.next(this.collectionId);
        observer.complete();
        return;
      }

      this._collectionService.save(
        this._collectionService.create({
          id: null,
          name: this.collectionName,
          description: this.collectionDescription,
          ownerObjectId: this.organization.id,
          ownerType: this.ownerType
        })
      )
        .subscribe(response => {
          observer.next(response.id);
          observer.complete();
        });
    })
      .subscribe(collectionId => {
        this._collectionService.postObjects(collectionId, { objectIds: list(this.collectionObjects, 'id') })
          .subscribe(response => {
            this._fsMessage.success(`Changes saved`);
            this.close(response);
          });
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public onOrganizationChange(data: Organization) {
    this.organization = data;
    this._loadCollections();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _loadCollections() {
    this.collections = [];

    if (this.collectionId) {
      this.collectionId = null;
    }

    if (this.collectionName) {
      this.collectionName = null;
    }

    if (this.collectionDescription) {
      this.collectionDescription = null;
    }

    if (!this.organization) {
      return;
    }

    this._collectionService.gets({ ownerObjectId: this.organization.id })
      .subscribe(response => {
        this.collections = response;
        this.collectionOrRoute = this.collections.length ? 'existing' : 'new';
      });
  }

}
