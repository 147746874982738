import { Injectable } from '@angular/core';


@Injectable()
export class FeatureService {

  public features = [
    { name: 'Wines Produced', value: 'featureWinesProduced' },
    { name: 'Wine Club', value: 'featureWineClub' },
    { name: 'Tasting Room', value: 'featureTastingRoom' },
    { name: 'Awards Won', value: 'featureAwardsWon'},
    { name: 'Purchasable Wine Lists', value: 'featurePurchasableWineLists'}
  ];

  constructor() { }

}
