import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';
import { FsPrompt } from '@firestitch/prompt';
import { FsFileImagePickerComponent, FsFile } from '@firestitch/file';

import { AccountService, SessionService, AuthService } from '@app/core/services';
import { Account } from '@app/shared/interfaces';
import { Gender } from '@app/shared/enums';
import { PasswordBehavior } from '@firestitch/signin-security';


@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, OnDestroy {

  @Input('account')
  set setAccount(account) {
    this.account = account;
    this.isAccountSignedIn = account && account.id === this.sessionService.account().id;
    this.showJobTitle = account && account.aclEntries && account.aclEntries.length > 0;
  }

  @Input() public social = false;
  @Input() public socialEdit = false;
  @Input() public preferences = true;

  @Output() public changeAccount = new EventEmitter<Account>();

  @ViewChild('avatarPicker') public avatarPicker: FsFileImagePickerComponent = null;

  public account: Account = null;
  public isAccountSignedIn = false;
  public showJobTitle = false;
  public PasswordBehavior = PasswordBehavior;

  public genders = {
    male: Gender.Male,
    female: Gender.Female,
    other: Gender.Other
  };

  public statuses = [
    {
      value: 'deleted',
      name: 'Deleted',
      deleted: true
    },
    {
      value: 'active',
      name: 'Active',
      undelete: true
    },
  ];


  private _destroy$ = new Subject();

  constructor(
    private accountService: AccountService,
    private sessionService: SessionService,
    private fsPrompt: FsPrompt,
    private authService: AuthService,
    private fsMessage: FsMessage,
  ) { }

  public ngOnInit() {
  }

  public save() {
    this.accountService.save(this.account)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(account => {
        this.fsMessage.success('Saved Changes');
        this.onChangeAccount(account);
      });
  }

  public onUpload(fsFile: FsFile) {

    this.accountService.image(this.account, fsFile.file)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (account) => {
          this.fsMessage.success('Image saved');
          this.onChangeAccount(account);
        },
        () => {

        },
        () => {
          this.avatarPicker.cancel();
        });
  }

  public changePassword = (data) => {
    return this.accountService.putPasswordChange({
      id: this.account.id,
      password: data.newPassword,
      currentPassword: data.currentPassword,
    })
      .pipe(
        tap(() => {
          this.fsMessage.success('Saved Changes');
        }),
      );
  };

  public resetPassword = (data) => {
    return this.accountService.putPasswordReset({
      id: this.account.id,
      password: data.password,
      changePassword: data.changePassword,
      emailPassword: data.emailPassword,
    })
      .pipe(
        tap(() => {
          this.fsMessage.success('Successfully reset password');
        }),
      );
  };

  public statusAction(data) {
    let query$ = null;
    let confirmMessage = '';
    let successMessage = 'Saved Changes';
    switch (data.action) {
      case 'activate':
        query$ = this.accountService.activate(this.account.id);
        confirmMessage = 'Are you sure you would like to activate this account?';
        break;
      case 'email_activation':
        query$ = this.accountService.emailActivation(this.account.id);
        confirmMessage = 'Are you sure you would like to send email?';
        successMessage = 'Email successfully sent';
        break;
      case 'delete':

        if (this.isAccountSignedIn) {
          query$ = this.accountService.delete(this.account);
          confirmMessage = this.accountService.ACCOUNT_DELETE_OWN_MESSAGE;
        } else {
          query$ = this.accountService.delete(this.account);
          confirmMessage = this.accountService.ACCOUNT_DELETE_MESSAGE;
        }
        break;
      case 'undelete':
        query$ = this.accountService.undelete(this.account.id, data.data);
        confirmMessage = 'Are you sure you would like to undelete this account?';
        break;
    }

    this.fsPrompt.confirm({
      title: 'Confirm',
      template: confirmMessage
    })
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        query$
          .pipe(
            takeUntil(this._destroy$)
          )
          .subscribe(response => {

            this.accountService.get(this.account.id)
              .pipe(
                takeUntil(this._destroy$)
              )
              .subscribe(account => {
                this.fsMessage.success(successMessage);
                this.onChangeAccount(account);

                if (this.isAccountSignedIn && data.action === 'delete') {
                  this.authService.signout();
                }
              });

          });
      });
  }

  public selfDelete() {
    this.statusAction({ action: 'delete' });
  }

  private onChangeAccount(account: Account) {

    Object.assign(this.account, account);
    if (this.isAccountSignedIn) {
      this.sessionService.account(this.account);
    }
    this.changeAccount.emit(this.account);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
