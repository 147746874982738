<form fsForm [submit]="save">
  <fs-dialog>
    <h1 mat-dialog-title>Ask a Question</h1>
    <mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Name</mat-label>
        <input
            
            [(ngModel)]="question.name"
            name="name"
            matInput
            [fsFormMaxLength]="200"
            [required]="true">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Name</mat-label>
        <input
            
            [(ngModel)]="question.lastName"
            [fsFormMaxLength]="200"
            name="last_name"
            matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Email</mat-label>
        <input
            matInput
            [(ngModel)]="question.email"
            [required]="true"
            fsFormEmail
            
            name="email">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Question/Feedback</mat-label>
        <textarea
            matInput
            [(ngModel)]="question.question"
            name="question"
            
            [required]="true"
            maxlength="1000"
            cdkTextareaAutosize>
          </textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        type="submit"
        mat-raised-button
        color="primary">
        Submit
      </button>
      <button
        type="button"
        mat-button
        mat-dialog-close>
        Cancel
      </button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
