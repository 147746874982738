import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { ButtonComponent, AskQuestionFormComponent, ConfirmComponent } from './components';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ButtonComponent,
    AskQuestionFormComponent,
    ConfirmComponent,
  ],
  exports: [
    ButtonComponent,
    AskQuestionFormComponent,
  ]
})
export class AskQuestionModule { }
