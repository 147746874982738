import { Component, Input, OnInit } from '@angular/core';
import { FsNavService } from '@firestitch/nav';


@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public constructor(private navService: FsNavService) {

  }

  ngOnInit() {
    this.navService.setTitle('Not Found');
  }
}
