import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'app-item-attribute-tiles',
  templateUrl: './attribute-tiles.component.html',
  styleUrls: ['./attribute-tiles.component.scss']
})
export class AttributeTilesComponent implements OnInit {
  @Input() public title = null;
  @Input() public showCreate = false;
  @Input() public showSelect = false;
  @Input() public type = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  @Output() dataLoaded = new EventEmitter();

  @HostBinding('attr.hidden')
  public hiddenBlock;

  public hiddenAttribute = true;

  private _item: Item = null;

  constructor() { }

  public get item() {
    return this._item;
  }

  public ngOnInit() {
  }

  public dataReceived(data: any) {
    const dataExists = data && data.length;
    this.hiddenAttribute = !dataExists;

    if (!dataExists) {
      this.hiddenBlock = true;
    } else {
      this.hiddenBlock = void 0;
    }

    this.dataLoaded.emit(data);
  }

}
