<fs-dialog [mobileMode]="'full'">
  <app-item-back-dialog-button>
    Add Notes
  </app-item-back-dialog-button>
  <mat-dialog-content>
    <h2>Select from Popular Notes</h2>
    <fs-attribute-selector
      class="popular_note"
      [data]="selectorData"
      [selectedAttributes]="selectedAttributes"
      (selectedToggled)="selectionChanged($event)"
    >
    </fs-attribute-selector>

    <h2>Add Your Own Custom Note</h2>

    <mat-form-field class="custom-note-field">
      <mat-label>What did you think?</mat-label>
        <input matInput
             name="note"
             [(ngModel)]="customNote"
             
      >
    </mat-form-field>
    <div class="save-button">
      <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  </mat-dialog-content>
</fs-dialog>
