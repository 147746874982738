<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>
      {{ organizationId ? accountRequest.id ? 'Edit Staff Roles' : 'Invite Staff' : 'Invite People'}}
    </h1>
    <div mat-dialog-content>
      <div fxLayout="column">
        <fs-autocomplete-chips
          *ngIf="!accountRequest.id"
          [(ngModel)]="selectedAccounts"
          [fetch]="search"
          placeholder="Account or Email Address"
          fsFormRequired
          [allowText]="true"
          [validateText]="validateText"
          invalidTextMessage="Invalid email address"
          [chipImage]="'image.small'"
          name="selected_accounts">
          <ng-template fsAutocompleteObject let-object="object">
            {{ object.firstName }} {{ object.lastName }}
          </ng-template>
        </fs-autocomplete-chips>

        <fs-label-field *ngIf="accountRequest.id">
          <fs-label> Account</fs-label>
          <a *ngIf="hasApp" (click)="openAccount()">
            <app-account-image
              [account]="accountRequest.account"
              [longName]="true">
            </app-account-image>
          </a>
          <app-account-image
            *ngIf="!hasApp"
            [account]="accountRequest.account"
            [longName]="true">
          </app-account-image>
        </fs-label-field>

        <fs-checkbox-group
          *ngIf="globalRoles"
          fsFormRequired
          fsFormRequiredMessage="Please select at least one role"
          [(ngModel)]="selectedRoles"
          name="roles" label="Roles" orientation="vertical">
          <mat-checkbox
            *ngFor="let role of globalRoles"
            [value]="$any(role)"
            [disabled]="globalRoles.length === 1">
            {{ role.name }}
          </mat-checkbox>
        </fs-checkbox-group>
      </div>
    </div>
    <mat-dialog-actions>
      <button type="submit" mat-button color="primary">
        {{ accountRequest.id && organizationId ? 'Save' : 'Invite' }}
      </button>
      <button type="button" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
