import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { Angulartics2 } from 'angulartics2';

import { FsMessage } from '@firestitch/message';
import { FsPrompt } from '@firestitch/prompt';

import { Subject } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';

import { ItemService } from '@app/core/services';

import { Item } from '../../models';
import { ActionKebubMenuItem } from '../../interfaces';
import { ItemAddNotesComponent } from '../your-notes';
import { AddToListService } from '../../services';


@Component({
  selector: 'item-list-vew-action-bar',
  templateUrl: './list-vew-action-bar.component.html',
  styleUrls: ['./list-vew-action-bar.component.scss']
})
export class ItemListViewActionBarComponent implements OnInit, OnDestroy {

  public item: Item = null;
  @Input('item') set setItem(item: Item) {
    this.item = item;
  };

  @Input()
  public customActions: ActionKebubMenuItem[] = null;

  @Output()
  public ratingChanged = new EventEmitter<void>();

  @Output()
  public visitsChange = new EventEmitter<void>();

  @Output()
  public triedRemoved = new EventEmitter<void>();

  private _confirmMessage =
    `Are you sure you want to remove this item from the "Things I've Tried" in your Cellar?`;

  private _triedTemplateMessage =
    `<div class="heading">Added to My Cellar</div>Things I've Tried`;

    private _untriedTemplateMessage =
      `<div class="heading">Removed from My Cellar</div>Things I've Tried`;

  private _visitsTemplateMessage =
    `Do you want to add a visit to this organization?`;

  private _destroy$ = new Subject();

  constructor(
    private _dialog: MatDialog,
    private _fsMessage: FsMessage,
    private _fsPrompt: FsPrompt,
    private _itemService: ItemService,
    private _angulartics2: Angulartics2,
    private _addToListService: AddToListService,
    private _cd: ChangeDetectorRef,
  ) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onRatingChanged() {
    this.ratingChanged.emit();
  }

  public triedRemove() {
    this.triedRemoved.emit();
  }

  public rate() {
    const dialogRef = this._dialog.open(ItemAddNotesComponent, {
      data: { item: this.item }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        if (response) {
          this.item.notes.push(response);
        }

        this.ratingChanged.emit();
      });
  }

  public visits() {
    this._fsPrompt.confirm({
      title: 'Confirm',
      commitLabel: 'Yes',
      template: this._visitsTemplateMessage
    })
    .pipe(
      filter((response) => !!response),
      switchMap(() => {
        this._angulartics2.eventTrack.next({
          action: 'manual_visit',
          properties: {
            objectId: this.item.id,
          }
        });

        return this._itemService.visit(this.item.id);
      }),
    )
    .subscribe((response) => {
      this.item.accountItem.visits = response.visits;
      this.visitsChange.emit();
    });
  }

  public toggleTryIt() {
    if (!this.item.accountItem.triedIt) {
      this.submitTryIt();
    } else {
      this.removeTryIt();
    }
  }

  private removeTryIt() {
    this._fsPrompt.confirm({
      title: 'Confirm',
      template: this._confirmMessage
    }).subscribe(() => {
      this.submitTryIt(() => {
        this._cd.detectChanges();
        this.triedRemoved.emit();
      });
    })
  }

  private submitTryIt(cb = () => {}) {
    this.item.accountItem.triedIt = !this.item.accountItem.triedIt;
    this._itemService.tried(this.item.id, { triedIt: this.item.accountItem.triedIt})
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        if (response.triedIt) {

          this._angulartics2.eventTrack.next({
            action: 'tried_it',
            properties: { objectId: this.item.id }
          });

          this._fsMessage.success(this._triedTemplateMessage);
        } else {
          this._fsMessage.success(this._untriedTemplateMessage);
          this._angulartics2.eventTrack.next({
            action: 'untried_it',
            properties: { objectId: this.item.id }
          });
        }
        cb();
      });
  }

  public addToList() {

    this._addToListService.openDialog(this.item)
      .afterClosed()
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((response) => { });
  }

}
