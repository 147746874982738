<mat-form-field class="mat-fullwidth">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select
    [(ngModel)]="timezone"
    [fsFormRequired]="required"
    [disabled]="disabled"
    name="timezone_select_{{ guid }}">
      <mat-option *ngFor="let item of timezones" [value]="item.identifier">
        {{ item.description }}
      </mat-option>
  </mat-select>
</mat-form-field>
