/**
 * Core module it's a main module in project after AppModule.
 * This module contain providers initializations and usually all modules .forRoot() expressions
 * Core module can be imported only once and by this reason providers which has been declared here
 * will be a singleton across all project. (if they was not re-declared in some component)
 */
import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FsAddressModule } from '@firestitch/address';
import { FS_ANALYTICS_CONFIG, FsAnalyticsModule } from '@firestitch/analytics';
import {
  FS_API_REQUEST_INTERCEPTOR,
  FS_API_RESPONSE_HANDLER,
  FsApi,
  FsApiModule
} from '@firestitch/api';
import { FS_ATTRIBUTE_CONFIG, FsAttributeModule } from '@firestitch/attribute';
import { FsBodyModule } from '@firestitch/body';
import {
  //CordovaFileClickInterceptor,
  CordovaHttpInterceptor,
  FsCordova,
  FsCordovaHttp,
  FsCordovaModule,
} from '@firestitch/cordova';
import { FsDrawerModule } from '@firestitch/drawer';
import { FsFileModule } from '@firestitch/file';
import { FsGalleryModule } from '@firestitch/gallery';
import { FsNavModule } from '@firestitch/nav';
import { FsPasswordModule } from '@firestitch/password';
import { FsPrompt } from '@firestitch/prompt';
import { FsRatingModule } from '@firestitch/rating';
import { FsSelectionModule } from '@firestitch/selection';
import { FsStore, FsStoreModule } from '@firestitch/store';
import { FsTreeModule } from '@firestitch/tree';
import { FsUploadModule } from '@firestitch/upload';

import { Platform } from '@ionic/angular';

import {
  FsScrollComponent,
  FsScrollContentComponent,
  FsScrollModule,
  FsScrollService
} from '@firestitch/scroll';

import { FsAutocompleteChipsModule } from '@firestitch/autocomplete-chips';
import { FsConnectionModule } from '@firestitch/connection';
import { FsHtmlEditorModule } from '@firestitch/html-editor';
import { FsMessage, FsMessageModule } from '@firestitch/message';
import { FsPaneModule } from '@firestitch/pane';
import { FsPhoneModule } from '@firestitch/phone';
import { FS_TRANSFER_HANDLER, FsTransferService } from '@firestitch/transfer';

import { DragulaModule } from 'ng2-dragula';

import { TransferHandler } from 'app/core/handlers/transfer.handler';
import { appAnalyticsConfig, attributeConfigFactory } from './helpers';
import { throwIfAlreadyLoaded } from './module-import-guard';

import {
  AccountService,
  AclEntryService,
  AclQueryService,
  AclRoleService,
  AclService,
  ActionService,
  ActionsService,
  Angulartics2Action,
  AppUseService,
  AssetService,
  AttributeService,
  AuthService,
  AwardOrganizationService,
  AwardService,
  BackgroundGeolocationService,
  CategoryService,
  CollectionService,
  CommentService,
  ContentService,
  DynamicLinkService,
  EnvironmentService,
  FeatureService,
  IntercomService,
  InviteService,
  ItemService,
  MessageService,
  NavService,
  ObjectService,
  OrderService,
  OrganizationService,
  PaneControlService,
  PortalService,
  RegionService,
  RoleService,
  SeasonService,
  SectionService,
  SessionService,
  ShareService,
  SidenavService,
  SigninService,
  SignupService,
  SocialAuthService,
  SubscriptionService,
  TaskService,
  TasteTestService,
  TimezonesService,
  TypeService,
  VisitService,
  WineService
} from './services';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BuildModule } from '@app/system/modules/build';
import { BuildService } from '@app/system/modules/build/services/build.service';
import { FS_APP_MESSAGE_CONFIG } from '@firestitch/app-message';
import { BuildConfig, FS_BUILD_CONFIG, UpdateAction } from '@firestitch/build';
import { FS_MAP_GOOGLE_MAP_KEY, FsMapModule } from '@firestitch/map';
import { FsTabsModule } from '@firestitch/tabs';
import { ThemingModule } from '@libs/theming';
import { Angulartics2Module } from 'angulartics2';
import { environment } from 'environments/environment';
import { MessageData, MessageQueueData, MessageTemplateData, MessageWebhookData } from './data';
import { ApiHandler } from './handlers';
import { appMessageConfig } from './helpers/app-message-config';
import { ApiInterceptorFactory } from './interceptors';


export function jwtOptionsFactory(fsStore) {
  return {
    tokenGetter: () => {
      return '';
    },
    whitelistedDomains: []
  }
}

@NgModule({
  imports: [
    FsApiModule.forRoot({
      maxFileConnections: 5
    }),
    FsPasswordModule.forRoot(),
    FsGalleryModule.forRoot(),
    FsMessageModule.forRoot({
      toastTimeout: 1.5
    }),
    FsNavModule.forRoot({
      watchBrowserBackButton: false
    }),
    FsScrollModule.forRoot(),
    FsAnalyticsModule.forRoot(),
    FsBodyModule.forRoot(),
    FsUploadModule.forRoot(),
    FsHtmlEditorModule.forRoot({
      activationKey: '2J1B10dD7F6F5A3F3I3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C9A4E5A3G3B2G2==',
    }),
    FsFileModule.forRoot(),
    FsAutocompleteChipsModule.forRoot(),
    FsPhoneModule.forRoot(),
    FsMapModule,
    FsAttributeModule.forRoot(),
    FsRatingModule.forRoot({
      selectedColor: '#6f2c3e'
    }),
    FsAddressModule.forRoot(),
    DragulaModule.forRoot(),
    FsPaneModule.forRoot(),
    FsTabsModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
        excludedRoutes: [
          '/admin',
          '/manage'
        ],
      }
    }),
    FsCordovaModule.forRoot({
      enabled: true,
      apiDomain: environment.apiDomain,
    }),
    FsConnectionModule.forRoot(),
    ThemingModule,
    FsDrawerModule,
    FsStoreModule,
    FsTreeModule,
    FsSelectionModule,
    BuildModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CordovaHttpInterceptor,
      multi: true,
      deps: [FsCordova, FsCordovaHttp],
    },
    {
      provide: FS_API_REQUEST_INTERCEPTOR,
      useFactory: ApiInterceptorFactory,
      deps: [ FsStore, BuildService ]
    },
    {
      provide: FS_API_RESPONSE_HANDLER,
      useClass: ApiHandler,
      deps: [ FsMessage, Router, SessionService, SigninService, MessageService ]
    },
    FsTransferService,
    {
      provide: FS_TRANSFER_HANDLER,
      useClass: TransferHandler,
      deps: [ FsMessage, SessionService ]
    },
    {
      provide: FS_MAP_GOOGLE_MAP_KEY,
      useFactory: () => environment.googleMapApiKey,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { maxWidth: '95vw', hasBackdrop: true, closeOnNavigation: true  }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'auto', appearance: 'outline' },
    },
    {
      provide: FS_ANALYTICS_CONFIG,
      useFactory: appAnalyticsConfig,
      deps: [Injector],
    },
    {
      provide: FS_ATTRIBUTE_CONFIG,
      useFactory: attributeConfigFactory,
      deps: [
        AttributeService,
        FsPrompt,
        FsMessage,
      ],
    },
    {
      provide: FS_BUILD_CONFIG,
      useFactory: (): BuildConfig => {
        return {
          updateAction: environment.production ?
            UpdateAction.NavigationUpdate : UpdateAction.PromptUpdate,
        };
      },
    },
    {
      provide: FS_APP_MESSAGE_CONFIG,
      useFactory: appMessageConfig,
      deps: [
        MessageData, MessageQueueData, MessageTemplateData,
        MessageWebhookData, AccountService, SessionService,
      ],
    },
    FsApi,
    FsStore,
    SessionService,
    FsScrollService,
    AuthService,
    AccountService,
    OrderService,
    TypeService,
    AttributeService,
    ObjectService,
    RoleService,
    AssetService,
    SectionService,
    NavService,
    AclService,
    AclEntryService,
    AclQueryService,
    TaskService,
    InviteService,
    AclRoleService,
    CommentService,
    SocialAuthService,
    SignupService,
    SidenavService,
    ShareService,
    SubscriptionService,
    TimezonesService,
    AwardService,
    AwardOrganizationService,
    RegionService,
    OrganizationService,
    EnvironmentService,
    FeatureService,
    SeasonService,
    IntercomService,
    ContentService,
    PortalService,
    WineService,
    CollectionService,
    ItemService,
    CategoryService,
    ActionService,
    ActionsService,
    Angulartics2Action,
    PaneControlService,
    SigninService,
    MessageService,
    DynamicLinkService,
    Platform,
    TasteTestService,
    VisitService,
    AppUseService,
    BackgroundGeolocationService,
  ],
  exports: [
    Angulartics2Module,
    FsScrollComponent,
    FsScrollContentComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
