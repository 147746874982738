import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SidenavOrganizationService {

  private _organization$ = new BehaviorSubject<any>(null);

  public set organization(organization) {
    this._organization$.next(organization);
  }

  public get organization() {
    return this._organization$.getValue();
  }

  public get organization$() {
    return this._organization$;
  }

}
