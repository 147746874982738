import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable()
export class DynamicLinkService {

  constructor() { }

  public url(url: string): string {
    return url;
    return 'https://' + environment.firebaseLinkDomain + '/?link=' + encodeURIComponent(url) +
      '&apn=' + environment.androidBundle +
      '&ibi=' + environment.iosBundle +
      '&isi=' + environment.iosAppstoreId +
      '&afl=' + encodeURIComponent(url) +
      '&efr=1';
  }

}
