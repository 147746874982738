<div [ngClass]="classes">
  <div class="icons">
    <mat-icon *ngIf="item.isCollection" svgIcon="collection-circle"></mat-icon>

    <ng-container *ngIf="item.featureCollection">
      <ng-container *ngIf="themeData$ | async as themeData">
        <fs-badge
          [size]="32"
          [image]="themeData.brandingFeaturedIcon">
        </fs-badge>
      </ng-container>
    </ng-container>
  </div>
  <img class="image" [src]="item.image.med" [style.height]="imageHeight" [style.width]="imageWidth">
</div>
