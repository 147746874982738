import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FsMessage } from '@firestitch/message';

import { TypeClasses } from 'app/shared/types';
import { Organization, Type, Subregion, Account } from 'app/shared/interfaces';
import {
  TypeService,
  OrganizationService,
  AccountService,
  AuthService,
  SessionService
} from 'app/core/services';


@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss']
})
export class OrganizationDialogComponent implements OnInit, OnDestroy {

  public organization: Organization = { id: null };
  public account: Account = null;

  public types: Type[] = [];

  public updateJobTitle = false;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<OrganizationDialogComponent>,
    private _fsMessage: FsMessage,
    private _typeService: TypeService,
    private _organizationService: OrganizationService,
    private _accountService: AccountService,
    private _sessionService: SessionService,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.updateJobTitle = !!data.updateJobTitle;
  }

  public ngOnInit() {

    this._authService.loggedInAccount$
    .pipe(
      takeUntil(this._destroy$)
    )
    .subscribe(store => {

      this.account = store.value;
    });

    this.organization = this._organizationService.create({ id: null });
    this.loadTypes();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public save() {
    this.organization.typeId = this.organization.type.id;

    this._organizationService.save(this.organization)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => {
        this._fsMessage.success('Organization created');
        this.close(response);
      });
  }

  public saveJobTitle() {
    this._accountService.save(this.account)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => {
        this._sessionService.account(Object.assign(this.account, response));
        this.updateJobTitle = false;
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public onSubregionChange(subregion: Subregion) {
    this.organization.subregionId = subregion ? subregion.id : null;
    this.organization.subregion = subregion;
  }

  private loadTypes() {
    this._typeService.gets({ class: TypeClasses.Organization })
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((types: Type[]) => this.types = types);
  }

}
