export enum AuditMetaAction {
  Create = 'create',
  Delete = 'delete',
  Change = 'change',
  Add = 'add',
  Remove = 'remove',
  Relate = 'relate',
  Unrelate = 'unrelate',
  Signin = 'signin',
  Info = 'info',
  Highlight = 'highlight',
  Unhighlight = 'unhighlight'
}
