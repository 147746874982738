import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FsNavService } from '@firestitch/nav';

import { Subject } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';

import { PortalService, SettingService } from '@app/core/services';
import { LastPortal } from '@app/shared/types';
import { routesDataMerge } from '@app/common/helpers';


@Component({
  selector: 'app-taster-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class TasterBannerComponent implements OnInit, OnDestroy {

  public lastPortal: LastPortal = null;
  public image: string = null;
  public url: string = null;

  public LastPortal = LastPortal;

  private _destroy$ = new Subject();

  private _settings: any = null;

  constructor(
    private _portalService: PortalService,
    private _fsNav: FsNavService,
    private _route: ActivatedRoute,
    private _settingService: SettingService,
  ) { }

  public ngOnInit(): void {

    this._portalService.lastPortal$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => this.lastPortal = response);

    this._settingService.gets('branding')
      .pipe(
        tap((response) => this._settings = response),
        tap(() => this._init()),
        switchMap(
          () => this._fsNav.routeChange
            .pipe(
              takeUntil(this._destroy$),
            ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._init();
      });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _init() {
    const data = routesDataMerge(this._route);
    this.image = data.bannerImage ? this._settings[data.bannerImage] : null;
    this.url = data.bannerImage ? this._settings[data.bannerImage + 'Url'] : null;
  }

}
