import {
  format,
  isAfter,
  isBefore,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { isString } from 'lodash-es';

import { filter } from '@firestitch/common';
import { date } from '@firestitch/date';

import { Address, Attribute, Collection, Subscription, Tasting, Type, WineClub } from '@app/shared/interfaces';


export class ItemOrganizationModel {
  public address: Address;
  public id: number;
  public location: string;
  public name: string;
  public tasting: Tasting;
  public phone: string;
  public email: string;
  public website: string;
  public wineClubConfigured: number;
  public wineClub: WineClub;
  public subscription: Subscription;
  public subscriptionId: number;
  public wineCount: number;
  public experienceCount: number;
  public collectionCount: number;
  public distanceInKm: number;
  public actionButtonUrl: string;
  public classificationsVisibleOnList: Attribute[] = [];
  public type: Type;
  public collections: Collection[] = [];
  public reservationUrl: string = null;

  public mapLatCalculated: number = null;
  public mapLngCalculated: number = null;

  public availabilityLabel: string;
  public activeSeasonSchedule: {day: string, hours: string}[];
  public activeSeasonRange: string;
  public tastingRoom: number = null;

  private _dateFormat = 'MMM d, Y';

  constructor(data: any = {}) {
    this._init(data);
  }

  public get isAddressEmpty() {
    if (!this.address
      || !this.address.city
      && !this.address.street
      && !this.address.region
      && !this.address.zip) {
      return true;
    }

    return false;
  }

  private _init(data) {
    this.address = data.address || null;
    this.tastingRoom = data.tastingRoom || null;
    this.id = data.id || null;
    this.tasting = data.tasting || null;
    this.phone = data.phone || null;
    this.email = data.email || null;
    this.name = data.name || '';
    this.website = data.website || null;
    this.wineClubConfigured = data.wineClubConfigured || null;
    this.wineClub = data.wineClub || null;
    this.subscription = data.subscription || null;
    this.subscriptionId = data.subscriptionId || null;
    this.wineCount = data.wineCount || null;
    this.collectionCount = data.collectionCount || null;
    this.experienceCount = data.experienceCount || null;
    this.distanceInKm = data.distanceInKm || null;
    this.actionButtonUrl = data.actionButtonUrl || null;
    this.type = data.type || null;
    this.reservationUrl = data.reservationUrl || null;
    this.collections = data.collections || [];
    this.classificationsVisibleOnList = filter(data.classifications || [], item => {
      return item.configs && item.configs.visibleOnList;
    });

    if (data.mapOverrideLat && data.mapOverrideLng) {
      this.mapLatCalculated = +data.mapOverrideLat;
      this.mapLngCalculated = +data.mapOverrideLng;
    } else if (this.address) {
      this.mapLatCalculated = +this.address.lat;
      this.mapLngCalculated = +this.address.lng;
    }

    this._setLocation();
    this._getOrganizationAvailability(data);
  }

  private _setLocation() {
    this.location = '';

    if (!this.address) {
      return;
    }

    if (this.address.city) {
      this.location += this.address.city;
      this.location += this.address.region ? ', ' : '';
    }

    if (this.address.region) {
      this.location += this.address.region;
      this.location += this.address.country ? ', ' : '';
    }

    if (this.address.country) {
      this.location += this.address.country
    }
  }

  private _getOrganizationAvailability(data) {

    // move to shared/constants
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    if (data.openDate && isString(data.openDate)) {
      data.openDate = utcToZonedTime(data.openDate, null);
    }

    if (data.closedDate && isString(data.closedDate)) {
      data.closedDate = utcToZonedTime(data.closedDate, null);
    }

    const openDate = date(data.openDate);
    const closedDate = date(data.closedDate);
    const curentDate = date(new Date());

    if (isAfter(openDate, curentDate)) {
      this.availabilityLabel = 'Opening ' + format(openDate, this._dateFormat);
    } else if (data.organizationIsClosed && isBefore(closedDate, curentDate)) {
      this.availabilityLabel = 'Closed ' + format(closedDate, this._dateFormat);
    } else {
      const curentSeason = data.seasons.find(season => {
        return isBefore(date(season.startDate), curentDate) && isAfter(date(season.endDate), curentDate);
      });

      if (curentSeason && curentSeason.seasonalStatus === 'open') {

        this.activeSeasonRange = 'Hours for ' +
          format(date(utcToZonedTime(curentSeason.startDate, null)), this._dateFormat) +
          ' - ' +
          format(date(utcToZonedTime(curentSeason.endDate, null)), this._dateFormat);

        let hasSchedule = false;
        const seasonSchedule = [];

        daysOfWeek.forEach(dayName => {
          hasSchedule = hasSchedule || !!curentSeason[dayName];

          seasonSchedule.push({
            day: dayName,
            hours: curentSeason[dayName] || 'Closed',
          });
        });

        if (hasSchedule) {

          this.activeSeasonSchedule = seasonSchedule;

          const todayHours = curentSeason[curentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()];
          if (todayHours) {
            this.availabilityLabel = 'Open today: ' + todayHours;
          } else {
            this.availabilityLabel = 'Closed today';
          }
        } else {
          this.availabilityLabel = 'Open this season';
        }
      } else {

        const nextSeason = data.seasons.find(
          season => season.seasonalStatus === 'open' && isBefore(curentDate, date(season.startDate))
        );

        if (nextSeason) {
          this.availabilityLabel = 'Reopening ' + format(date(nextSeason.startDate), this._dateFormat);
        } else if (!curentSeason) {
          this.availabilityLabel = 'Hours not available';
        }
      }
    }
  }

}
