import { Component } from '@angular/core';


@Component({
  selector: 'app-body-navbar',
  templateUrl: './body-navbar.component.html',
  styleUrls: ['./body-navbar.component.scss']
})
export class BodyNavbarComponent {
}
