<form (fsForm)="save()" #form="ngForm" *fsSkeletonForm="attribute">
  <fs-dialog>
    <h1 mat-dialog-title>{{ attribute.id ? 'Edit' : 'Create' }} {{title}}</h1>
    <mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Name</mat-label>
<input matInput [(ngModel)]="attribute.name" name="name" fsFormRequired>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Color</mat-label>
<input matInput fsColorPicker [(ngModel)]="attribute.color" name="color" fsFormRequired>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="submit" color="primary">{{ attribute.id ? 'Save' : 'Create' }}</button>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
