import { Component, ChangeDetectorRef, OnInit, Inject, OnDestroy } from '@angular/core';

import { ControlContainer, NgForm } from '@angular/forms';

import { FsFormDirective } from '@firestitch/form';

import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  FS_ATTRIBUTE_CONFIG,
  FS_ATTRIBUTE_FIELD_DATA,
  FsAttributeConfig
} from '@firestitch/attribute';
import { FsFile } from '@firestitch/file';
import { AttributeService } from '@app/core/services';


@Component({
  templateUrl: 'edit-flavour-profile-image.component.html',
  styleUrls: ['./edit-flavour-profile-image.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class AttributeEditFlavourProfileImageComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(FS_ATTRIBUTE_FIELD_DATA) public data,
    @Inject(FS_ATTRIBUTE_CONFIG) private attributeConfig: FsAttributeConfig,
    @Inject(FsFormDirective) public form: FsFormDirective,
    private attributeService: AttributeService,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (!this.data.attribute.configs) {
      this.data.attribute.configs = {};
    }
  }

  selectImage(fsFile: FsFile) {

    this.attributeService.configImage(this.data.attribute, 'smallImage', fsFile.file)
    .pipe(
      untilDestroyed(this)
    )
    .subscribe((attribute) => {
      this._makeDirty();
      this.data.attribute.configs = attribute.configs;
    });
  }

  ngOnDestroy() {}

  // HACK: We need ability to enable submit buttons from this abstraction level
  // without extra efforts
  private _makeDirty(): void {
    // FIXME
    // this.form._submitButtons.forEach((button) => {
    //   button.enable();
    // });
    this._cd.markForCheck();
  }
}
