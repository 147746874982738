<a class="share-button" [fsMenuTriggerFor]="shareMenu">
  <ng-content></ng-content>
</a>

<fs-menu #shareMenu>
  <ng-template fs-menu-title>
    Share
  </ng-template>
  <ng-container *ngFor="let action of shareActions">
    <ng-template fs-menu-item>
      <fs-share [platform]="action.medium"
                [title]="action.config.title"
                [url]="action.config.url"
                [image]="action.config.image"
                [open]="open">
        {{ action.label }}
      </fs-share>
    </ng-template>
  </ng-container>
</fs-menu>
