<mat-card>
  <nav mat-tab-nav-bar>
    <a mat-tab-link
       *ngFor="let link of links"
       [routerLink]="link.path"
       routerLinkActive
       #rla="routerLinkActive"
       [routerLinkActiveOptions]="{ exact: true }"
       [active]="rla.isActive">
      {{ link.label }}
    </a>
  </nav>

  <router-outlet></router-outlet>
</mat-card>
