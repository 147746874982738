import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { filter } from 'lodash';

import { FsApi } from '@firestitch/api';

import { SessionService } from './session.service';
import { Object, Project } from '../../shared/interfaces';
import { ObjectClass } from '@app/shared/enums';

@Injectable()
export class ObjectService {

  public classes = [
    { name: 'Doc', value: ObjectClass.Doc, icon: 'description', abr: 'D', color: '#0266ff' },
    { name: 'Task', value: ObjectClass.Task, icon: 'assignment', abr: 'T', color: '#00cc33' },
    { name: 'File', value: ObjectClass.File, icon: 'insert_drive_file', abr: 'F' },
    { name: 'Account', value: ObjectClass.Account, icon: 'person', abr: 'A' },
    { name: 'Type', value: ObjectClass.Type, icon: 'person' },
    { name: 'Status', value: ObjectClass.Status, icon: 'person' },
    { name: 'Address', value: ObjectClass.Address, icon: 'person' },
    { name: 'Project', value: ObjectClass.Project, icon: 'person' }
  ];

  public searchClasses = [
    ObjectClass.Doc,
    ObjectClass.Task
  ];

  private maxRecentCount = 15;


  constructor(
    private _fsApi: FsApi,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) { }

  public get(objectId, query = {}): Observable<any> {
    return this._fsApi.get(`objects/${objectId}`, query, { key: 'object' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'objects', data, Object.assign({ key: 'objects' }, config));
  }

  public getsObjectAwards(objectId, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `objects/${objectId}/awards`, data, Object.assign({ key: 'objectAwards' }, config));
  }

  public putObjectAward(objectId, data, config = {}): Observable<any> {
    return this._fsApi.put(`objects/${objectId}/awards/${data.id}`, data, Object.assign({ key: 'objectAward' }, config));
  }

  public postObjectAward(objectId, data): Observable<any> {
    return this._fsApi.post(`objects/${objectId}/awards`, data, { key: 'objectAward' });
  }

  public saveObjectAward(objectId, data): Observable<any> {
    if (data.id) {
      return this.putObjectAward(objectId, data);
    }
    return this.postObjectAward(objectId, data);
  }

  public deleteObjectAward(objectId, data): Observable<any> {
    return this._fsApi.delete(`objects/${objectId}/awards/${data.id}`, data, { key: 'objectAward' });
  }

  public getRecent() {
    return this.sessionService.searchRecentIds();
  }

  public navigate(object: Object) {
    let codeIdentifier = '';
    switch (object.class) {
      case ObjectClass.Project:
        this.router.navigate(['/projects', object.id, 'overview', 'settings']);
        break;
      case ObjectClass.Account:
        this.router.navigate(['/admin', 'account', object.id]);
        break;
      case ObjectClass.Doc:
      case ObjectClass.Task:
        codeIdentifier = this.getObjectIdentifierCode(object.project, object);
        this.router.navigate(
          [],
          { relativeTo: this.route, queryParams: { object: codeIdentifier } }
        );
        break;
    }

    this.pushRecentId(object.id);
  }

  public objectIdentifier(project: Project, object: Object) {
    let projectAbr = '';
    let objectAbr = '';
    let objectNumber = '';

    if (project) {
      projectAbr = project.abr;
      if (project.meta && project.meta.abr) {
        projectAbr = project.meta.abr;
      }
    }

    if (object) {
      const cls = filter(this.classes, { value: object.class })[0];
      objectAbr = cls ? cls.abr : '';

      objectNumber = object.number;

      if (object.meta && object.meta.number) {
        objectNumber = object.meta.number;
      }
    }

    return { projectAbr, objectAbr, objectNumber };
  }

  public getObjectIdentifierCode(project: Project, object: Object) {
    const identifier = this.objectIdentifier(project, object);
    return `${identifier.projectAbr}-${identifier.objectAbr}${identifier.objectNumber}`;
  }

  public navigateToDrawer(project: Project, object: Object) {
    const objectIdentifier = this.getObjectIdentifierCode(project, object);
    this.router.navigate(
      [],
      { relativeTo: this.route, queryParams: { object: objectIdentifier }, queryParamsHandling: 'merge' }
    );
  }

  private pushRecentId(id: number) {
    const data = this.sessionService.searchRecentIds() || [];

    const index = data.indexOf(id);
    if (index > -1) {
      data.splice(index, 1);
    }

    data.unshift(id);

    if (data.length > this.maxRecentCount) {
      data.splice(this.maxRecentCount, data.length);
    }

    this.sessionService.searchRecentIds(data);
  }

  public getVersion(objectId, objectVersionId, query = {}): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/versions/${objectVersionId}`, query, { key: 'objectVersion' });
  }

  public getVersions(objectId, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `objects/${objectId}/versions`, data, Object.assign({ key: 'objectVersions' }, config));
  }

  public restoreVersion(objectId: number, objectVersionId: number) {
    return this._fsApi.post(`objects/${objectId}/versions/${objectVersionId}/restore`, null, {key: 'objectVersion'})
  }
}
