<div *ngIf="lastPortal === LastPortal.Taster && image">
  <a
    *ngIf="url; else noLink"
    [href]="url"
    target="_blank">
    <div
      [style.background-image]="'url(' + image + ')'"
      class="banner"
    >
    </div>
  </a>

  <ng-template #noLink>
    <div
      [style.background-image]="'url(' + image + ')'"
      class="banner"
    >
    </div>
  </ng-template>
</div>
