<navbar>
  <a class="back" navbarBack fsNavBack><mat-icon>keyboard_arrow_left</mat-icon></a>
  <span fsNavComponent="image" type="image" navbarImage></span>
  <span fsNavSupertitle navbarSupertitle></span>
  <span fsNavTitle navbarTitle></span>
  <span fsNavSubtitle navbarSubtitle></span>
  <span navbarRightActions>
    <div class="menu-actions" fsNavMenu="right-menu"></div>
  </span>
</navbar>
