<fs-list [config]="config" #list>
  <fs-list-column width="60px">
    <ng-template fs-list-header>Color</ng-template>
    <ng-template fs-list-cell let-row="row">
      <fs-badge [color]="row.color"></fs-badge>
    </ng-template>
  </fs-list-column>
  <fs-list-column>
    <ng-template fs-list-header>Name</ng-template>
    <ng-template fs-list-cell let-row="row">
      <a (click)="openDialog(row)"> {{ row.name }}</a>
    </ng-template>
  </fs-list-column>
</fs-list>
