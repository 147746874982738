import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class QuestionService {

  constructor(
    private _fsApi: FsApi,
  ) { }

  public post(data, config = {}): Observable<any> {
    return this._fsApi.post('questions', data, Object.assign({ key: null }, config));
  }

}
