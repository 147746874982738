<div *ngIf="isShow" fxLayout="column" fxLayoutGap="10px" class="awards-lister">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h2 *ngIf="heading" class="heading">{{ heading }}</h2>
    <div fxFlex></div>
    <button type="button" mat-raised-button color="primary" (click)="openDialog()">Add Award Won</button>
  </div>
  <small *ngIf="hint">{{ hint }}</small>
  <fs-list [config]="config" #list>
    <fs-list-column name="award_organizations.name" [sortable]="true" title="Award Organization">
      <ng-template fs-list-cell let-row="row">
        {{ row?.awardOrganization?.name }}
      </ng-template>
    </fs-list-column>
    <fs-list-column>
      <ng-template fs-list-header>Award Won</ng-template>
      <ng-template fs-list-cell let-row="row">
        <a (click)="openDialog(row)">{{ row.award }}</a>
      </ng-template>
    </fs-list-column>
    <fs-list-column name="year" [sortable]="true" title="Year">
      <ng-template fs-list-cell let-row="row">
        {{ row.year }}
      </ng-template>
    </fs-list-column>
  </fs-list>
</div>