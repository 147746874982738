import { Component, Input, OnInit } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'app-item-group-size',
  templateUrl: './group-size.component.html',
  styleUrls: ['./group-size.component.scss']
})
export class GroupSizeComponent implements OnInit {

  private _item: Item = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public get item() {
    return this._item;
  }

  constructor() { }

  public ngOnInit() {
  }

}
