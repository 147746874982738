<div class="pane" fxLayout="column" *fsSkeleton="item">

  <ng-container *ngIf="item.type && organizationData.tastingRoom && item.type.featureTastingRoom; else noTastingRoom">
    <organization-price
      [organization]="item.organization">
    </organization-price>

    <div>
      <ng-container *ngIf="organizationData.tasting.waivedWithPurchase">
        <b> Fee waived with purchase
          {{ organizationData.tasting.donatedToCause || organizationData.tasting.reservationsRequired ? ',' : ''}} </b>
      </ng-container>
      <ng-container *ngIf="organizationData.tasting.donatedToCause">
        <b> Fee donated to a cause{{ organizationData.tasting.reservationsRequired ? ',' : '' }} </b>
      </ng-container>
      <ng-container *ngIf="organizationData.tasting.reservationsRequired">
        <b> Reservations required </b>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="hasActions" class="actions" fxLayout="column" fxLayoutGap="12px">
    <a
      *ngIf="hasTastingButton"
      class="mat-raised-button mat-primary"
      [routerLink]="['/organizations', item.id, 'wines']"
      angulartics2On="click"
      angularticsAction="click_action_button"
      [angularticsProperties]="{ objectId: item.id, buttonLabel: item.type.actionButtonLabel }"
    >
      {{ item.type.actionButtonLabel | titlecase }}
    </a>
    <a
      *ngIf="hasExternalUrlButton"
      class="mat-raised-button mat-primary"
      fsLink
      [httpPrefix]="true"
      [openWindow]="true"
      [href]="organizationData.actionButtonUrl"
      angulartics2On="click"
      angularticsAction="click_action_button"
      [angularticsProperties]="{ objectId: item.id, buttonLabel: item.type.actionButtonLabel }"
    >
      {{ item.type.actionButtonLabel | titlecase }}
    </a>
    <a
      *ngIf="hasViewWineListButton"
      class="mat-raised-button mat-primary"
      [routerLink]="['/collections', primaryCollection.id]"
      angulartics2On="click"
      angularticsAction="click_action_button"
      [angularticsProperties]="{ objectId: item.id, buttonLabel: item.type.actionButtonLabel }"
    >
      {{ item.type.actionButtonLabel | titlecase }}
    </a>

    <a
      *ngIf="hasReservationUrlButton"
      class="mat-raised-button"
      fsLink
      [httpPrefix]="true"
      [openWindow]="true"
      [href]="organizationData.reservationUrl"
      (click)="reservationClick()"
    >
      Make a Reservation
    </a>

    <a
        *ngIf="hasViewExperiencesButton"
        class="mat-raised-button mat-primary"
        [routerLink]="['/organizations', item.id, 'experiences']"
        angulartics2On="click"
        angularticsAction="click_action_button"
        [angularticsProperties]="{ objectId: item.id, buttonLabel: 'View Experiences' }">
      View Experiences
    </a>
  </div>
</div>


<ng-template #noTastingRoom>
  <span *ngIf="item.type && item.type.featureTastingRoom">
    <b class="light">No tasting room</b>
  </span>
</ng-template>
