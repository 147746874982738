<form (fsForm)="save()" [dirtySubmitButton]="false">
  <fs-dialog mobileMode="float">
    <h1 mat-dialog-title>Find {{ type.pluralName }} Near You</h1>
    <mat-dialog-content>
      <fs-address-picker *ngIf="address" [(address)]="address" [config]="config" format="twoline"></fs-address-picker>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="submit" color="primary">Find {{ type.pluralName }}</button>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
