export interface Timezone {
  city: string;
  countryCode: string;
  countryName: string;
  description: string;
  dst: number;
  dstOffset: number;
  dstOffsetFormatted: string;
  identifier: string;
  utcOffset: number;
  utcOffsetFormatted: string;
  zoneCode: string;
  zoneDstCode: string;
  zoneName: string;
  indentifier: string;
}
