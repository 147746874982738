import { Component, Input } from '@angular/core';

import { Attribute } from '@app/shared/interfaces';


@Component({
  selector: 'app-item-classification-pills',
  templateUrl: './classification-pills.component.html',
  styleUrls: ['./classification-pills.component.scss']
})
export class ItemClassificationPillsComponent {

  @Input() public classifications: Attribute[] = [];

}
