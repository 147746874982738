<fs-dialog>
  <h1 mat-dialog-title>Confirmation</h1>
  <mat-dialog-content>
    <div fxLayout="column">
      <content-widget
        tag="ask_a_question_confirmation"
      ></content-widget>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      type="button"
      mat-button
      color="primary"
      mat-dialog-close>
      OK
    </button>
  </mat-dialog-actions>
</fs-dialog>
