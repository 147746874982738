<div class="pane" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <app-experience-price [experience]="$any(item.data)"></app-experience-price>
      <!--<app-item-rating [item]="item"></app-item-rating>-->
    </div>
    <a
      *ngIf="experienceData.actionButtonLabel"
      class="mat-raised-button mat-primary"
      fsLink
      [httpPrefix]="true"
      [openWindow]="true"
      [href]="experienceData.actionButtonUrl"
      angulartics2On="click"
      angularticsAction="click_action_button"
      [angularticsProperties]="{ objectId: item.id, buttonLabel: experienceData.actionButtonLabel }"
    >
      {{ experienceData.actionButtonLabel | titlecase }}
    </a>
  </div>

  <div *ngIf="experienceData.reservationRequired">
    <b> Reservations required </b>
  </div>

</div>
