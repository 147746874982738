import { AclRole } from './acl-role';
import { Account } from './account';
import { Object } from './object';


export interface AclEntry {
  id: number;
  aclRoleId?: number;
  aclRole?: AclRole;
  accountId?: number;
  account?: Account;
  objectId?: number;
  object?: Object;
}
