import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';

import { AnonymousSignInComponent, SignInDialogComponent } from './components';
import { AuthorizedClickDirective } from './directives';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    SignInDialogComponent,
    AuthorizedClickDirective,
    AnonymousSignInComponent,
  ],
  exports: [
    AuthorizedClickDirective,
    AnonymousSignInComponent,
  ],
})
export class AnonymousSignInModule {}
