import { JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@app/shared';

import BackgroundGeolocation, {
  Location,
  MotionChangeEvent,
  MotionActivityEvent,
  GeofenceEvent,
  Geofence,
  HttpEvent,
  ConnectivityChangeEvent,
  ProviderChangeEvent
} from 'cordova-background-geolocation-lt';
import { differenceInSeconds } from 'date-fns';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ItemService } from './item.service';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class BackgroundGeolocationService {

  public trackingSeconds: number = 30;
  public log: {date, event}[] = [];

  private _location$ = new BehaviorSubject<Location>(null);
  private _nearbyOrganizations: {organization: any, startDate: Date, visited: boolean}[] = [];
  private _initialized: boolean = false;
  private _fakeIt: boolean = false; // set this to true for fake location updates in browser

  public get location$() {
    return this._location$.asObservable();
  }

  public get nearbyOrganizations() {
    return this._nearbyOrganizations;
  }

  constructor(
    private _itemService: ItemService,
    private _angulartics2: Angulartics2,
  ) {

  }

  public init(): void {

    if (!this._initialized && (this._fakeIt || (window as any).cordova)) {

      this._location$.subscribe((location: Location) => {
        if (!location) {
          return;
        }

        this.logEvent(`Position ${location.coords.latitude}x${location.coords.longitude}`);

        // fetch nearby organizations
        this._itemService.getNearbyOrganizations({
          'lat': location.coords.latitude,
          'lng': location.coords.longitude,
        })
          .subscribe(response => {

            if (response.geolocationTrackingDuration) {
              this.trackingSeconds = response.geolocationTrackingDuration;
            }

            const organizations = response.organizations;
            // alert('fetched nearby ' + organizations);

            // store new nearby organizations with timestamp of first appearance
            organizations.forEach((organization) => {
              if (!this._nearbyOrganizations.some((nearbyOrganization) => nearbyOrganization.organization.id === organization.id)) {
                this.logEvent(`New nearby organization - ${organization.name}`);
                this._nearbyOrganizations.push({
                  organization,
                  startDate: new Date(),
                  visited: false,
                });
              } else {
                //already in list.. update distance.
                const nearby = this._nearbyOrganizations.find(
                  (nearbyOrganization) => nearbyOrganization.organization.id === organization.id
                );
                if (nearby) {
                  nearby.organization.distance = organization.distance;
                }
              }
            });

            // check for any organizations that have been in hte list for X minutes or more and record a visit.
            // note: we leave them in the list with a visited flag so that we dont trigger multiple recordings.
            // they will get removed from the list when user leaves the location
            let closest;
            this._nearbyOrganizations.forEach( (nearbyOrganization) => {
              if (!closest || nearbyOrganization.organization.distance < closest.organization.distance) {
                closest = nearbyOrganization;
              }
            });
            if (
              closest
              && !closest.visited && differenceInSeconds(new Date, closest.startDate) > this.trackingSeconds
            ) {
              closest.visited = true;
            }


            // record an action with the visit duration for any orgs that are no longer nearby
            this._nearbyOrganizations.forEach( nearbyOrganization => {
              if (!organizations.some((organization) =>
                organization.id === nearbyOrganization.organization.id
              )) {
                if (nearbyOrganization.visited) {
                  this.logEvent(`Automatic visit action recorded - ${nearbyOrganization.organization.name}`);
                  this._angulartics2.eventTrack.next({
                    action: 'automatic_visit',
                    properties: {
                      objectId: nearbyOrganization.organization.id,
                      visitDuration: differenceInSeconds(new Date, nearbyOrganization.startDate),
                    }
                  });
                }
              };
            });

            // kick any organizations out of list that are no longer near by
            setTimeout(() => {
              this._nearbyOrganizations = this._nearbyOrganizations.filter( nearbyOrganization => {
                const stillNearby = organizations.some((organization) =>
                  organization.id === nearbyOrganization.organization.id
                );

                if(!stillNearby) {
                  this.logEvent(`Organization no longer nearby - ${nearbyOrganization.organization.name}`);
                }

                return stillNearby;
              });
            }, 1000);



          });

      });


      if ((window as any).cordova) {
        BackgroundGeolocation.onLocation((location: Location) => {
          this._location$.next(location);
        });

        BackgroundGeolocation.ready({
          reset: true,
          // debug: true,
          // logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
          desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
          preventSuspend: true,
          stationaryRadius: 10,
          locationAuthorizationRequest: 'Any',
          disableLocationAuthorizationAlert: true,
          backgroundPermissionRationale: {
            title: 'Allow {applicationName} to access to this device\'s location in the background?',
            message: 'Your location is used to show your position on the map, discover nearby organizations and update your Places I\'ve Visited history.',
            positiveAction: 'Change to {backgroundPermissionOptionLabel}',
            negativeAction: 'Cancel'
          }
        }, (state) => {
          if (!state.enabled) {
            BackgroundGeolocation.start();
          }
        });
      }

      this._initialized = true;



      if (this._fakeIt) {
        // for testing in browser.. updates location slightly every 4 seconds
        const location: Location = {
          timestamp: '',
          odometer: 1,
          is_moving: false,
          uuid: '',
          battery: { is_charging: false, level: 1},
          coords: {latitude: 49.30433346551046, longitude: -123.05516165054622, accuracy: 1},
          activity: { activity: '', confidence: 4}
        };

        this._location$.next(location);

        setInterval(() => {
          location.coords.latitude += .005;
          location.coords.longitude += .005;
          this._location$.next(location);
        }, 4000)
      }

    }


  }


  /**
   * check if app has permission to use geolocation
   */
  public checkIfAuthorized(): Observable<boolean> {
    if (this._fakeIt || !(window as any).cordova) {
      return of(true);
    } else {

      return new Observable((subscriber) => {
        BackgroundGeolocation.getProviderState()
          .then((providerState: ProviderChangeEvent) => {
            const authorized = providerState.status && [
              BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED,
              BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED,
            ].indexOf(providerState.status) === -1;

            subscriber.next(authorized);
            subscriber.complete();
        });
      });
    }
  }


  public getCurrentPermission(): Observable<string> {
    if (this._fakeIt || !(window as any).cordova) {
      return of('none');
    } else {

      return new Observable((subscriber) => {
        BackgroundGeolocation.getProviderState()
          .then((providerState: ProviderChangeEvent) => {
            const authorized = providerState.status && [
              BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED,
              BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED,
            ].indexOf(providerState.status) === -1;

            const permissions = {};
            permissions[BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS] = 'always';
            permissions[BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE] = 'inuse';

            let permission = 'none';
            if (permissions[providerState.status]) {
              permission = permissions[providerState.status];
            }

            subscriber.next(permission);
            subscriber.complete();
        });
      });
    }
  }


  public logEvent(event: string) {
    console.log('bgloc: ' + event);

    this.log.push({
      date: new Date(),
      event: event,
    });
  }



  public emailLog() {
    const Logger = BackgroundGeolocation.logger;
    Logger.emailLog('mike@firestitch.com').then((success) => {
      console.log('[emailLog] success');
    }).catch((error) => {
      console.log('[emailLog] FAILURE: ', error);
    });
  }
}
