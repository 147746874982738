<form>
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Verify Tasting</h1>
    <mat-dialog-content>
      <p>Congratulations!</p>
      <p>Your tasting was successfully verified.</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-raised-button color="primary" mat-dialog-close>Done</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
