import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import {
  VerifyTastingDialogComponent,
  VerifyTastingConfirmedDialogComponent
} from './components';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        VerifyTastingDialogComponent,
        VerifyTastingConfirmedDialogComponent
    ],
    exports: [
        VerifyTastingDialogComponent,
        VerifyTastingConfirmedDialogComponent
    ]
})
export class VerifyTastingDialogModule { }
