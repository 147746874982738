import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { IFsAddressConfig, FsAddress } from '@firestitch/address';

import { Type, Account } from '@app/shared/interfaces';
import { AuthService } from '@app/core/services';
import { Item } from '@app/taster/modules/item/models';
import { WhereToBuyService } from '@app/taster/modules/wine/services';


@Component({
  templateUrl: './retailers.component.html',
  styleUrls: ['./retailers.component.scss']
})
export class WhereToBuyRetailersComponent implements OnInit, OnDestroy {

  public item: Item = null;
  public type: Type = null;

  public loggedInAccount: Account = null;

  public config: IFsAddressConfig = {
    city: {required: true},
    label: 'Your Location',
    hint: 'City, postal code, or full address',
  };
  public address: FsAddress = null;

  private _destroy$ = new Subject();

  constructor(
    private _dialogRef: MatDialogRef<WhereToBuyRetailersComponent>,
    private _router: Router,
    private _whereToBuyService: WhereToBuyService,
    private _authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
    this.type = this.data.type;

    this._authService.loggedInAccount$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(store => {
        this.loggedInAccount = store ? store.value : null;
        this._whereToBuyService.getAddress(this.loggedInAccount)
          .subscribe((address: FsAddress) => this.address = address);
      });
  }

  public save() {
    this._whereToBuyService.setAddress(this.address);
    this.close(this.address);
    this._router.navigate(['/wines', this.item.id, 'where_to_buy', this.type.id]);
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
