import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { map, filter } from 'rxjs/operators';

import { FsListComponent, FsListConfig } from '@firestitch/list';

import { ObjectService } from '../../../../core/services';
import { ObjectAward, Organization } from '../../../../shared/interfaces';
import { AwardWonDialogComponent } from '../../dialogs';
import { ObjectAwardParent } from 'app/shared/types';


@Component({
  selector: 'app-award-won-list',
  templateUrl: './award-won-list.component.html',
  styleUrls: ['./award-won-list.component.scss']
})
export class AwardWonListComponent implements OnInit {

  @Input() public parentObject: ObjectAwardParent = null;
  @Input() public heading: string = null;
  @Input() public hint: string = null;

  public isShow = false;

  @ViewChild('list')
  public list: FsListComponent = null;
  public config: FsListConfig = null;

  constructor(
    private _objectService: ObjectService,
    private _dialog: MatDialog
  ) { }

  public ngOnInit() {

    if (this.parentObject.class === 'wine') {
      this.isShow = true;
    }

    if (this.parentObject['type'] && (this.parentObject as Organization).type.featureAwardsWon) {
      this.isShow = true;
    }

    this.config = {
      // heading: 'Awards Won',
      paging: false,
      sort: { value: 'year', direction: 'desc' },
      filters: [],
      actions: [
      /*
        {
          label: 'Add Award Won',
          click: event => {
            this.openDialog();
          }
        }
      */
      ],
      rowActions: [
        {
          click: data => {
            return this._objectService.deleteObjectAward(this.parentObject.id, data);
          },
          remove: {
            title: 'Confirm',
            template: 'Are you sure you would like to delete this Award Won?',
          },
          menu: true,
          label: 'Delete'
        }
      ],
      fetch: query => {

        Object.assign(query, {
          objectId: this.parentObject.id,
          awards: true,
          awardOrganizations: true
        });

        return this._objectService.getsObjectAwards(this.parentObject.id, query, { key: null })
          .pipe(
            map(response => ({ data: response.objectAwards }))
          );
      }
    };
  }

  public openDialog(data: ObjectAward = { id: null }) {

    const dialogRef = this._dialog.open(AwardWonDialogComponent, {
      data: {
        objectAward: Object.assign({}, data),
        parentObject: this.parentObject
      }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => {
        this.list.updateData(
          response,
          (listRow: any) => {
            return listRow.id === response.id;
        }) || this.list.list.reload();
      });
  }

}
