import { Component, Input } from '@angular/core';

import { Item } from '../../models';


@Component({
  selector: 'item-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent {

  public showStatus = false;

  @Input()
  public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
    this.showStatus =
      this._item.status !== 'available' &&
      this._item.status !== 'open' &&
      ['organization', 'experience', 'wine'].indexOf(this._item.class) !== -1 &&
      !this._item.isCollection;
  }

  @Input()
  public showScores = false;

  @Input() public hideOrganizationName = false;

  public settings = null;

  private _item: Item = null;

  constructor() { }

  public get item() {
    return this._item;
  }

}
