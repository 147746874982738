import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { AskQuestionFormComponent } from '@libs/ask-question/components/form';


@Component({
  selector: 'app-ask-question-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
  }

  public openDialog(): void {
    const dialogRef = this._dialog.open(AskQuestionFormComponent, {
      data: { }
    });

    dialogRef.afterClosed()
      .subscribe(response => { });
  }

}
