import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { Angulartics2 } from 'angulartics2';

import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import { find } from '@firestitch/common';
import { FsGalleryComponent, FsGalleryConfig, GalleryLayout, GalleryThumbnailSize } from '@firestitch/gallery';

import { Asset } from '@app/shared/interfaces';
import { Item } from '../../models';


@Component({
  selector: 'app-item-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  @ViewChild('gallery', { static: true })
  public gallery: FsGalleryComponent = null;

  @Input() public assets: Asset[] = [];

  @Input() public item: Item = null;

  public config: FsGalleryConfig = {
    allow: 'image/*, application/*',
    thumbnail: {
      size: GalleryThumbnailSize.Cover,
      width: 300,
      heightScale: .4,
    },
    map: (data) => {
      return {
        name: data.file ? data.file.name : '',
        preview: data.file ? data.file.preview.small : null,
        url: data.file ? data.file.preview.actual : null,
        index: data.file ? data.id : null,
        galleryMime: data.file ? data.file.type : null,
      };
    },
    // imageWidth: 100,
    // imageHeightScale: 1,
    draggable: false,
    info: false,
    zoom: false,
    layout: GalleryLayout.FlowNoWrap,
    filters: null,
    fetch: query => {
      return of(<any>this.assets);
    }
  };

  constructor(private _angulartics2: Angulartics2) { }

  public ngOnInit() {}

  public trackOpen() {
    this._angulartics2.eventTrack.next({
      action: 'view_photos',
      properties: { objectId: this.item.id }
    });
  }

  public open() {
    this.gallery.data$
      .pipe(
        take(1)
      )
      .subscribe(response => {
        this.gallery.galleryService.openPreview(find(response, { galleryMime: 'image' }));
      });
  }
}
