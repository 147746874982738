<form *ngIf="account" (fsForm)="save()" #form="ngForm" class="full-width-fields">
  <mat-card-content>
    <div class="h2-container">
      <div fxLayout="row" fxLayoutGap="40px" fxLayoutGap.xs="0" fxLayout.xs="column">
        <div fxFlex="50%">
          <h2>Personal Information</h2>
          <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start" fxLayoutGap.xs="10px" fxLayoutGap.sm="10px">
            <div fxLayout="row" fxLayoutGap="20px">
              <fs-file-image-picker
                fxFlexAlign.xs="center"
                fxFlexAlign.sm="center"
                #avatarPicker
                (select)="onUpload($event)"
                [url]="account.image.small"
                [imageWidth]="600"
                [imageHeight]="600"
                [imageQuality]="0.8"
                [previewDiameter]="100"
                *ngIf="account.id">
              </fs-file-image-picker>

              <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.md="0" fxFlex="grow">
                <mat-form-field>
                  <mat-label>First Name</mat-label>
<input matInput [(ngModel)]="account.firstName"
                        name="first_name" fsFormRequired>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Last Name</mat-label>
<input matInput [(ngModel)]="account.lastName" name="last_name"
                        fsFormRequired>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="column">
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
                <mat-form-field fxFlex="grow">
                  <mat-label>Email</mat-label>
<input matInput [(ngModel)]="account.email" name="email"
                    fsFormEmail fsFormRequired>
                </mat-form-field>

                <mat-form-field fxFlex="grow">
                  <mat-label>Phone Number</mat-label>
        <input
                    matInput
                    
                    [(ngModel)]="account.phone"
                    name="phone"
                    fsPhone
                    fsFormPhone>
                </mat-form-field>
              </div>

              <mat-form-field class="mat-fullwidth">
                <mat-label>Age</mat-label>
        <input
                  matInput
                  [(ngModel)]="account.age"
                  fsFormInteger
                  fsFormMin="0"
                  [fsFormMax]="150"
                  
                  name="age">
              </mat-form-field>

              <fs-radio-group [(ngModel)]="account.gender" label="Gender" name="gender">
                <mat-radio-button [value]="genders.female"> Female </mat-radio-button>
                <mat-radio-button [value]="genders.male"> Male </mat-radio-button>
                <mat-radio-button [value]="genders.other"> Other </mat-radio-button>
              </fs-radio-group>

              <mat-form-field *ngIf="showJobTitle" class="mat-fullwidth">
                <mat-label>Job Title</mat-label>
        <input
                  matInput
                  [(ngModel)]="account.jobTitle"
                  fsFormRequired
                  
                  name="job_title">
              </mat-form-field>

              <fs-address-picker [(address)]="account.address" format="twoline"></fs-address-picker>
              <timezone-select [(timezone)]="account.timezone" fxFlex="grow"></timezone-select>
            </div>

            <ng-container *ngIf="preferences">
              <h2>Subscription Preferences</h2>
              <mat-checkbox [(ngModel)]="account.subscribeTop10" name="subscribe_top10">Subscribe to Top 10 Wines For You Emails</mat-checkbox>
              <mat-checkbox [(ngModel)]="account.subscribeFeatured" name="subscribe_featured">Subscribe to Featured Wines, Wineries and Experiences Emails</mat-checkbox>
              <mat-checkbox [(ngModel)]="account.subscribeNews" name="subscribe_news">Receive News & Communication Emails</mat-checkbox>
            </ng-container>

            <div fxFlex="grow">
              <button mat-raised-button type="submit" color="primary">Save</button>
            </div>
          </div>
        </div>

        <div fxFlex *ngIf="account.id">
          <h2>Account Status</h2>
          <fs-account-status
            [ngClass]="{'self-edit': isAccountSignedIn}"
            [statuses]="statuses"
            [created]="account.createDate"
            [email]="account.email"
            [status]="account.state"
            [activationEmailDate]="account.activateEmailDate"
            [activationEmailMessage]="account.activateEmailMessage"
            (action)="statusAction($event)"
          >

          </fs-account-status>

          <button 
            *ngIf="isAccountSignedIn"
            mat-raised-button 
            (click)="selfDelete()"
            type="button">
            DELETE ACCOUNT
          </button>

          <h2>Sign-in & Security</h2>
          <fs-signin-security
            *ngIf="isAccountSignedIn"
            [email]="account.email"
            [lastSignIn]="account.signinDate"
            [passwordBehavior]="account.state === 'deleted' ? PasswordBehavior.Deleted : PasswordBehavior.Change"
            [changePassword]="changePassword">
          </fs-signin-security>
          <fs-signin-security
            *ngIf="!isAccountSignedIn"
            [email]="account.email"
            [lastSignIn]="account.signinDate"
            [passwordBehavior]="account.state === 'deleted' ? PasswordBehavior.Deleted : PasswordBehavior.Reset"
            [resetPassword]="resetPassword">
          </fs-signin-security>

          <ng-container *ngIf="social">
            <app-account-social [accountId]="account.id" [allowEdit]="socialEdit"></app-account-social>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-card-content>
</form>
