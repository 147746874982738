import { Image, Type } from '@app/shared/interfaces';

import { Attribute, Collection } from '@app/shared';
import { ItemType } from '../enums';
import { Note } from '../interfaces';
import { ItemCollectionModel } from './item-collection.model';
import { ItemExperienceModel } from './item-experience.model';
import { ItemOrganizationModel } from './item-organization.model';
import { ItemWineModel } from './item-wine.model';


export class Item {
  public id: number;
  public type: Type;
  public typeId: number;
  public image: Image;
  public name: string;
  public awardsWonCount: number;
  public status: string;
  public class: string;
  public notes: Note[];
  public description: string;
  public defaultObject: any;
  public ratingCountScore: any;
  public ratingScore: any;
  public flavourProfile1Score: any;
  public flavourProfile2Score: any;
  public flavourProfile3Score: any;
  public bodyScore: any;
  public sweetnessScaleScore: any;
  public recommendationRanking: any;
  public pricingModel: string;
  public priceFrom: number;
  public priceTo: number;
  public onlineShopItemUrl: string;
  public whereToBuyCount: any;
  public items: any;

  public addedToList: boolean;
  public objectAddedToList: boolean;

  public accountItem: { rate?: boolean, triedIt?: boolean, shared?: boolean, rating?: number, visits?: number };
  public meta: { shares?: number, itemRating?: number, ratingCount?: number, vintage?: number, nonVintage?: boolean };

  public groupSizeLimit: number;
  public languages: Attribute[];
  public classifications: any[];
  public featureCollection: Collection;
  public features = [];
  public typeName: string;
  public createDate: string;
  public data: ItemWineModel | ItemCollectionModel | ItemExperienceModel | ItemOrganizationModel;

  public get organization(): ItemOrganizationModel {
    return this.isOrganization ?
      this.data as ItemOrganizationModel :
      (<ItemWineModel | ItemCollectionModel | ItemExperienceModel>this.data)?.organization as ItemOrganizationModel;
  }

  public get wine(): ItemWineModel {
    return this.isWine ?
      this.data as ItemWineModel :
      null;
  }

  public get collection(): ItemCollectionModel {
    return this.isCollection ?
      this.data as ItemCollectionModel :
      null;
  }

  public get experience(): ItemExperienceModel {
    return this.isExperience ?
      this.data as ItemExperienceModel :
      null;
  }

  private _isWine = false;
  private _isOrganization = false;
  private _isCollection = false;
  private _isExperience = false;
  private _route = '';

  constructor(data: any = {}) {
    this._switchType(data);
    this._init(data);
    this._setImage(data);
  }

  get isWine() {
    return this._isWine;
  }

  get isOrganization() {
    return this._isOrganization;
  }

  get isExperience() {
    return this._isExperience;
  }

  get isCollection() {
    return this._isCollection;
  }

  get route() {
    return this._route;
  }

  private _switchType(data) {
    switch (data.class) {
      case ItemType.Organisation: {
        this.data = new ItemOrganizationModel(data);
        this._isOrganization = true;
        this.typeName = data.type ? data.type.name : 'organization';
        this._route = 'organizations';
      } break;
      case ItemType.Experience: {
        this.data = new ItemExperienceModel(data);
        this._isExperience = true;
        this.typeName = data.type ? data.type.name : 'experience';
        this._route = 'experiences';
      } break;
      case ItemType.Collection: {
        this.data = new ItemCollectionModel(data);
        this._isCollection = true;
        this.typeName = 'List or Route';
        this._route = 'collections';
      } break;
      case ItemType.Wine: {
        this.data = new ItemWineModel(data);
        this._isWine = true;
        this.typeName = data.productLine && data.productLine.wineType ? data.productLine.wineType.name : 'wine';
        this._route = 'wines';
      } break;
    }
  }

  private _init(data) {
    this.id = data.id || null;
    this.type = data.type || null;
    if (this.type) {
      this.type.actionButtonLabel = `${this.type.actionButtonLabel}` || null;
    }

    this.ratingCountScore = data.ratingCountScore;
    this.ratingScore = data.ratingScore;
    this.flavourProfile1Score = data.flavourProfile1Score;
    this.flavourProfile2Score = data.flavourProfile2Score;
    this.flavourProfile3Score = data.flavourProfile3Score;
    this.sweetnessScaleScore = data.sweetnessScaleScore;
    this.bodyScore = data.bodyScore;
    this.recommendationRanking = data.recommendationRanking;


    this.typeId = data.typeId || null;
    this.name = data.name;
    this.class = data.class || null;
    this.awardsWonCount = data.awardsWonCount || null;
    this.status = data.status || null;
    this.notes = data.notes || [];
    this.description = data.description || null;
    this.createDate = data.createDate || null;

    this.onlineShopItemUrl = data.onlineShopItemUrl || null;

    this.objectAddedToList = data.objectAddedToList || false;
    this.addedToList = data.addedToList || false;

    this.accountItem = data.accountItem || {};
    // Technical field. Used as a flag to switch rating without extra queries to the server
    this.accountItem.rate = !!this.accountItem.rating;
    this.meta = data.meta || {};
    this.meta.nonVintage = !!data.nonVintage;

    this.groupSizeLimit = data.groupSizeLimit || null;
    this.languages = data.languages || [];
    this.whereToBuyCount = data.whereToBuyCount || [];

    // thank you BE :(
    this.featureCollection = data.featureCollections.length ? data.featureCollections[0] : null;
    this.features = data.features || [];
    this.defaultObject = data.defaultObject;
  }

  private _setImage(data) {
    this.image = data.image || this.type && this.type.image || null;
    // if (this.isCollection) {
    //   this.image = data.image && { small: data.image, large: data.image } || null;
    // } else {
    //   this.image = data.image || this.type && this.type.image || null;
    // }
  }

}
