import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';
import { FsFile } from '@firestitch/file';
import { FsTransferService } from '@firestitch/transfer';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MessageData {

  constructor(
    private _api: FsApi,
    private _transfer: FsTransferService,
  ) { }

  public create(data) {
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._api.get(`messages/${id}`, query, { key: 'message' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._api.request('GET', 'messages', data, { key: 'messages', ...config });
  }

  public put(data, config = {}): Observable<any> {
    return this._api.put(`messages/${data.id}`, data, { key: 'message', ...config });
  }

  public sendTest(data, recipient, type, config = {}): Observable<any> {
    return this._api.post(`messages/${data.id}/sendtest`, { recipient, type }, { key: 'message', ...config });
  }

  public messageAttachmentGets(message, query = {}, config = {}): Observable<any> {
    return this._api.get(`messages/${message.id}/attachments`, query, { key: 'messageAttachments', ...config });
  }

  public messageAttachmentPost(message, fsFile: FsFile): Observable<any> {
    return this._api.post(`messages/${message.id}/attachments`, { file: fsFile.file }, { key: 'messageAttachment' });
  }

  public messageAttachmentDelete(messageAttachment): Observable<any> {
    return this._api.delete(`messages/${messageAttachment.messageId}/attachments/${messageAttachment.id}`, {}, { key: 'messageAttachment' });
  }

  public messageAttachmentDownload(messageAttachment): void {
    this._transfer.post(`messages/${messageAttachment.messageId}/attachments/${messageAttachment.id}/download`);
  }

  public messageAttachmentSave(messageAttachment): Observable<any> {
    return this._api.put(`messages/${messageAttachment.messageId}/attachments/${messageAttachment.id}`, {}, { key: 'messageAttachment' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
  }
}
