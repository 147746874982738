import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { TasterBannerComponent } from './components';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    TasterBannerComponent,
  ],
  exports: [
    TasterBannerComponent,
  ],
})
export class TasterBannerModule { }
