<fs-dialog [mobileMode]="'full'">
  <app-item-back-dialog-button>
    Add Flavours
  </app-item-back-dialog-button>
  <mat-dialog-content>
    <fs-attribute-selector-with-groups
      class="wine_flavour_profile"
      [data]="selectorData"
      [selectedAttributes]="selectedAttributes"
      (selectedToggled)="selectionChanged($event)"
      placeholder="What do you smell or taste?">
    </fs-attribute-selector-with-groups>
  </mat-dialog-content>
</fs-dialog>
