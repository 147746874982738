import { Injectable } from '@angular/core';


@Injectable()
export class AclService {

  public PERMISSION_APP = 'app';
  public PERMISSION_APP_BACKEND = 'backend';
  public PERMISSION_ORGANIZATION = 'organization';

  public ACCESS_NONE 	= 0;
  public ACCESS_READ 	= 5;
  public ACCESS_WRITE = 10;
  public ACCESS_FULL 	= 15;
}
