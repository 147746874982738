import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { FsPane } from '@firestitch/pane';
import { FsNavService } from '@firestitch/nav';

import { PaneControlService } from '@app/core/services';
import { VerifyComponent, RatingComponent, FullComponent } from '../../components';


@Component({
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss']
})
export class LayersComponent implements OnDestroy, OnInit {

  public footer = true;
  public ratings = false;

  constructor(
    private _dialog: MatDialog,
    private _pane: FsPane,
    private _paneControlService: PaneControlService,
    private _fsNavService: FsNavService
  ) { }

  public ngOnInit() {
    this.setTitle();
  }

  public ngOnDestroy() {
    this._paneControlService.hideBottomPane();
  }

  public openDialog() {
    const dialogRef = this._dialog.open(VerifyComponent, {
      data: {}
    });

    dialogRef.afterClosed()
    .pipe(
      untilDestroyed(this)
    )
    .subscribe(() => { });
  }

  public toggleRatings() {

    if (this.ratings) {
      this._paneControlService.hideBottomPane();
    } else {
      this._paneControlService.openBottomPane(RatingComponent, { rating: 4.5 });
    }
    this.ratings = !this.ratings;
  }

  public toogleFooter() {


    if (this.footer) {
      this._paneControlService.hideFooter();
    } else {
      this._paneControlService.showFooter();
    }

    this.footer = !this.footer;
  }

  public openFull() {
    this._dialog.open(FullComponent);
  }

  private setTitle() {
    this._fsNavService.setTitle('Tile', 'Super Ttitle');
    this._fsNavService.showComponent('logo');
  }
}
