import { Subregion } from './subregion';


export interface Region {
  readonly id: number;
  country?: string;
  province?: string;
  name?: string;
  state?: string;
  subregions?: Subregion[];
}
