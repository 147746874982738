import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ContentWidgetData {

  constructor(private _api: FsApi) {}

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(query): Observable<any> {
    return this._api.get(`contentwidgets`, query, { key: 'contentWidget' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._api.request(`GET`, `contentwidgets`, data, Object.assign({ key: 'contentWidgets' }, config));
  }

  public put(contentWidget, config = {}): Observable<any> {
    return this._api.put(`contentwidgets/${contentWidget.id}`, contentWidget, Object.assign({ key: 'contentWidget' }, config));
  }

  public post(contentWidget): Observable<any> {
    return this._api.post(`contentwidgets`, contentWidget, { key: 'contentWidget' });
  }

  public delete(contentWidget): Observable<any> {
    return this._api.delete(`contentwidgets/${contentWidget.id}`, contentWidget, { key: 'contentWidget' });
  }

  public contentGet(tag): Observable<any> {
    return this._api.get(`contentwidget`, { tag }, { key: 'content' });
  }
}
