import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Angulartics2Module } from 'angulartics2';

import { SharedModule } from '@app/shared';
import { ItemModule } from '@app/taster/modules/item/item.module';

import {
  OrganizationActionPaneComponent
} from './components';


@NgModule({
    imports: [
        Angulartics2Module,
        RouterModule,
        SharedModule,
        ItemModule,
    ],
    declarations: [
        OrganizationActionPaneComponent
    ],
    exports: [
        OrganizationActionPaneComponent
    ]
})
export class OrganizationActionPaneModule { }
