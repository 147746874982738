<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>Add to List or Route</h1>
    <mat-dialog-content>
      <div fxLayout="column">
        <fs-label-field *ngIf="isBulk && collectionObjects.length">
          {{ collectionObjects.length }} item{{ collectionObjects.length === 1 ? '' : 's' }} selected
        </fs-label-field>

        <app-organization-picker
          [organization]="organization"
          [organizationIds]="organizationIds"
          title="Organization"
          (organizationChange)="onOrganizationChange($event)">
        </app-organization-picker>

        <content-widget
          *ngIf="organization && organization.subscription && !organization.subscription.featureCollections; else collectionRef"
          tag="requires_collection_subscription_dialog"
        ></content-widget>

        <ng-template #collectionRef>
          <fs-label-field *ngIf="!organization || collections.length">
            <fs-label>List or Route</fs-label>
            <mat-radio-group [(ngModel)]="collectionOrRoute" [disabled]="!organization" fxLayout="row" fxLayoutGap="10px" name="class">
              <mat-radio-button value="existing">Existing</mat-radio-button>
              <mat-radio-button value="new">New</mat-radio-button>
            </mat-radio-group>
          </fs-label-field>

          <mat-form-field *ngIf="collectionOrRoute === 'existing'" class="mat-fullwidth">
            <mat-label>Existing List or Route</mat-label>
        <mat-select [(ngModel)]="collectionId" fsFormRequired [disabled]="!organization"  name="collection_id">
              <mat-option *ngFor="let item of collections" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="collectionOrRoute === 'new'">
            <mat-form-field class="mat-fullwidth">
              <mat-label>New List or Route Name</mat-label>
<input matInput [(ngModel)]="collectionName" [disabled]="!organization" fsFormRequired name="collection_name">
            </mat-form-field>

            <mat-form-field class="mat-fullwidth">
              <mat-label>New List or Route Description</mat-label>
        <textarea
                matInput
                [(ngModel)]="collectionDescription"
                name="description"
                
                cdkTextareaAutosize>
              </textarea>
            </mat-form-field>
          </ng-container>
        </ng-template>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button *ngIf="!(organization && organization.subscription && !organization.subscription.featureCollections)" mat-button type="submit" color="primary">Add</button>
      <button type="button" mat-button mat-dialog-close>CANCEL</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
