import { Address } from './address';
import { Type } from './type';
import { Subregion } from './subregion';
import { Subscription } from './subscription';
import { Account } from './account';
import { Image } from './image';
import { StatusOrganization } from '../types';
import { Tasting } from './tasting';
import { WineClub } from './wine-club';
import { Season } from './season';
import { Object } from './object';


export interface Organization {
  readonly id: number;
  addressId?: number;
  address?: Address;
  typeId?: number;
  type?: Type;
  subregionId?: number;
  subregion?: Subregion;
  subscriptionId?: number;
  subscription?: Subscription;
  primaryAccountId?: number;
  primaryAccount?: Account;
  name?: string;
  image?: Image;
  description?: string;
  state?: string;
  status?: StatusOrganization;
  createDate?: string;
  openDate?: string | Date;
  closedDate?: string | Date;
  email?: string;
  phone?: string;
  website?: string;
  onlineShopUrl?: string;
  actionButtonUrl?: string;
  groupSizeLimit?: number;
  tastingRoom?: number;
  tasting?: Tasting;
  wineClub?: WineClub;
  wineClubConfigured?: number;
  seasons?: Season[];
  buttonClickCount?: number;
  verifiedTastingCount?: number;
  viewCount?: number;
  wineClubSubmissionCount?: number;
  modifiedDate?: Date | string;
  modifierAccountId?: number;
  modifierAccount?: Account;
  twitter?: string;
  instagram?: string;
  facebook?: string;
  organizationIsClosed?: boolean;
  reservationUrl?: string;
  notShownOnMapPage?: boolean;
  mapOverrideLng?: number;
  mapOverrideLat?: number;
  // This field for FE only
  class?: string;
  opengraphUrl?: string;
  object?: Object;
  languages?: any;
}
