import { Address } from './address';
import { AclRole } from './acl-role';
import { LastPortal } from '../types';
import { TasteTest } from './taste-test';

/* Update most objects as interfaces */

export interface Account {
  id?: number;
  gender?: string;
  phone?: string;
  address?: Address;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  guid?: string;
  state?: string;
  activateEmailDate?: string;
  activateEmailMessage?: string;
  password?: string;
  createDate?: string;
  signinDate?: string;
  passwordChange?: boolean;
  permissions?: {
    app: number;
  };
  image?: any;
  aclRoles?: AclRole[];
  apiKey?: string;
  apiSecret?: string;
  type?: string;
  timezone?: string;
  lastPortal?: LastPortal;
  jobTitle?: string;
  age?: number;
  tasteTestId?: number;
  tasteTest?: TasteTest;
  tasteTestPrompt?: boolean;
  lastPromptDate?: string | Date;
  triedCount?: number;
  ratedCount?: number;
  visitedCount?: number;
  lastLocationAddressId?: number;
  lastLocationAddress?: Address;
  subscribeTop10?: boolean;
  subscribeFeatured?: boolean;
  subscribeNews?: boolean;
  organizationVisitedCount?: number;
}
