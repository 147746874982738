import { Injectable } from '@angular/core';


@Injectable()
export class TileService {

  public calculations = {
    alcohol: {
      title: 'Alcohol',
      postfix: '%',
      calculationGroups: [
        [
          {
            limit: 10,
            scale: 1,
            description: 'Low'
          },
          {
            limit: 11.5,
            scale: 2,
            description: 'Medium-Low'
          },
          {
            limit: 13.5,
            scale: 3,
            description: 'Medium'
          },
          {
            limit: 15,
            scale: 4,
            description: 'Medium-High'
          },
          {
            max: true,
            scale: 5,
            description: 'High'
          }

        ]
      ]
    },
    sweetness: {
      title: 'Sweetness',
      postfix: '/5',
      calculationGroups: [
        [
          {
            limit: 4.1,
            scale: 1,
            description: 'Bone Dry'
          },
          {
            limit: 17.1,
            scale: 2,
            description: 'Dry'
          },
          {
            limit: 35.1,
            scale: 3,
            description: 'Off-Dry'
          },
          {
            limit: 120.1,
            scale: 4,
            description: 'Sweet'
          },
          {
            max: true,
            scale: 5,
            description: 'Very Sweet'
          }
        ],
        [
          {
            limit: 3,
            scale: 1,
            description: 'Brut Nature'
          },
          {
            limit: 7,
            scale: 1,
            description: 'Extra Brut'
          },
          {
            limit: 12,
            scale: 1,
            description: 'Brut'
          },
          {
            limit: 17,
            scale: 2,
            description: 'Extra Dry'
          },
          {
            limit: 32,
            scale: 3,
            description: 'Dry'
          },
          {
            limit: 50,
            scale: 4,
            description: 'Demi-Sec'
          },
          {
            max: true,
            scale: 5,
            description: 'Doux'
          }
        ]
      ]
    },
    acidity: {
      title: 'Acidity',
      postfix: '/5',
      calculationGroups:[
        [
          {
            limit: 3,
            scale: 1,
            description: 'Low'
          },
          {
            limit: 5,
            scale: 2,
            description: 'Medium-Low'
          },
          {
            limit: 7,
            scale: 3,
            description: 'Medium'
          },
          {
            limit: 9,
            scale: 4,
            description: 'Medium-High'
          },
          {
            max: true,
            scale: 5,
            description: 'High'
          }
        ]
      ]
    },
    body: {
      title: 'Body',
      postfix: '/5',
      calculationGroups: [
        [
          {
            scale: 1,
            description: 'Light'
          },
          {
            scale: 2,
            description: 'Light-Medium'
          },
          {
            scale: 3,
            description: 'Medium'
          },
          {
            scale: 4,
            description: 'Medium-Full'
          },
          {
            max: true,
            scale: 5,
            description: 'Full'
          }
        ]
      ]
    },
    tannins: {
      title: 'Tannins',
      postfix: '/5',
      calculationGroups: [
        [
          {
            scale: 1,
            description: 'None-Low'
          },
          {
            scale: 2,
            description: 'Medium-Low'
          },
          {
            scale: 3,
            description: 'Medium'
          },
          {
            scale: 4,
            description: 'Medium-High'
          },
          {
            max: true,
            scale: 5,
            description: 'High'
          }
        ]
      ]
    }
  };

  private _data = null;
  private _options = [];

  constructor() {

  }

  get title() {
    return this._data ? this._data.title : '';
  }

  get postfix() {
    return this._data ? this._data.postfix : '';
  }

  public setData(type: 'alcohol' | 'sweetness' | 'acidity' | 'body' | 'tannins', option = false) {
    this._data = this.calculations[type];

    if (this._data.calculationGroups.length === 1 && type !== 'sweetness') {
      this._options = this._data.calculationGroups[0];
    } else if ( type === 'sweetness') { // if it is a sweetness type and has sparkling
      if (option) {
        this._options = this._data.calculationGroups[1];
      } else {
        this._options = this._data.calculationGroups[0];
      }
    }
  }

  public calculateOption(value: number) {
    let resultedOption = null;

    for (let i = 0, length = this._options.length; i < length; i++ ) {
      const option = this._options[i];

      if (option.limit || option.max) {
        if (value < option.limit || option.max) {
          resultedOption = option;
          break;
        }

      } else {

        // if it is just a scale field
        if (+value === option.scale || +value < 1) {
          resultedOption = option;
          break;
        }
      }
    }

    return resultedOption;
  }

}
