<form (fsForm)="save()">
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Verify Tasting</h1>
    <mat-dialog-content>
      <content-widget
        tag="verify_tasting"
      ></content-widget>

      <mat-form-field class="mat-fullwidth">
        <mat-label>Winery Code</mat-label>
<input matInput [(ngModel)]="code" fsFormRequired name="code">
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button type="submit" color="primary">Verify</button>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
