import { Component, Input, OnInit } from '@angular/core';

import { SourceObject } from 'app/shared/types';
import { Image } from 'app/shared/interfaces';


@Component({
  selector: 'app-source-object-image',
  templateUrl: `./source-object-image.component.html`,
  styleUrls: ['./source-object-image.component.scss']
})
export class SourceObjectImageComponent implements OnInit {

  @Input() public size = 30;
  @Input() public sourceObject: SourceObject = null;

  @Input() public image: Image = null;

  constructor() { }

  public ngOnInit() {
    if (this.sourceObject) {
      this.image = this.sourceObject['image'];
    }
  }

}
