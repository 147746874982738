import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isArray } from 'lodash-es';

import { LastPortal } from '@app/shared/types';

import { OrganizationService, PortalService } from '../../../core/services';
import { Organization, Environment } from '../../interfaces';


@Component({
  selector: 'app-organization-picker',
  templateUrl: './organization-picker.component.html',
  styleUrls: ['./organization-picker.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class OrganizationPickerComponent implements OnInit, OnDestroy {

  private _organization: Organization = null;
  @Input() public set organization(value: Organization) {
    this._organization = value;
  }
  public get organization(): Organization {
    return this._organization;
  }

  @Input() public title = 'Winery';

  @Input() public query: any = {};

  @Input() public required = true;

  @Input() public readonly = null;
  @Input() public organizationIds = null;

  @Output() public organizationChange = new EventEmitter<Organization>();

  public organizations: Organization[] | Environment[] = [];

  private _destroy$ = new Subject();

  private _lastPortal: LastPortal = null;

  constructor(
    private _organizationService: OrganizationService,
    private _portalService: PortalService
  ) { }

  public ngOnInit() {

    this._portalService.lastPortal$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => {
        this._lastPortal = response;
        // Disable picker for Management Portal
        // User can isist on specified value
        if (this._lastPortal === LastPortal.Management && this.readonly === null) {
          this.readonly = true;
        }
      });
  }

  public loadOrganizations = (name: string) => {

    const query: any =
    {
      name: name,
      subscriptions: true,
      page: 1,
      limit: 10
    };

    if (isArray(this.organizationIds)) {
      query.organizationIds = this.organizationIds.join(',');
    }

    if (this._lastPortal === LastPortal.Management) {
      query.member = true;
    }

    return this._organizationService.gets(Object.assign(query, this.query));
  }

  public displayFn(data: any): string {
    return data ? `${data.name}` : '';
  }

  public organizationSelected(organization: Organization): void {
    this.organization = organization;
    this.organizationChange.emit(organization);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
