import { Component, OnInit, Inject, SkipSelf } from '@angular/core';
import {
  FS_ATTRIBUTE_CONFIG,
  FS_ATTRIBUTE_FIELD_DATA,
  FsAttributeConfig
} from '@firestitch/attribute';
import { ControlContainer } from '@angular/forms';


@Component({
  selector: 'app-attribute-edit-months',
  templateUrl: 'edit-months.component.html',
  styleUrls: ['edit-months.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class AttributeEditMonthsComponent implements OnInit {

  constructor(
    @Inject(FS_ATTRIBUTE_FIELD_DATA) public data,
    @Inject(FS_ATTRIBUTE_CONFIG) private attributeConfig: FsAttributeConfig
  ) {}

  public ngOnInit() {
    if (!this.data.attribute.configs) {
      this.data.attribute.configs = {};
    }
  }
}
