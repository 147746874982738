import { Status } from './status';

export interface Project {
  id: number;
  statusId?: number;
  status?: Status;
  name?: string;
  state?: string;
  guid?: string;
  meta?: any;
  abr?: string;
  image?: any;
}
