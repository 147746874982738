<fs-badge
    [size]="size"
    [shape]="shape"
    [image]="account?.image?.small"
    [color]="color"
    [tooltip]="tooltip"
    [icon]="icon"
    [iconSize]="iconSize"
    [iconColor]="iconColor"
    class="avatar"
    >
</fs-badge>
