<app-item-card-section>
	<h1>{{ heading }}</h1>
	<div class="subheading-1">
		<fs-truncate [lines]="2" [more]="true">
			{{ subheading }}
		</fs-truncate>
	</div>
	<div class="section-content">
		<item-card-view [item]="item" *ngFor="let item of items"></item-card-view>
	</div>
	<div *ngIf="viewButtonTitle && viewButtonLink" class="actions">
		<button mat-stroked-button [routerLink]="viewButtonLink" class="mat-fullwidth">{{ viewButtonTitle }}</button>
	</div>
</app-item-card-section>
