import { Component, Input } from '@angular/core';
import { StatusOrganization } from '@app/shared/types';
import {
  Item
} from '@app/taster/modules/item/models';


@Component({
  selector: 'app-item-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent {

  public displaySchedule = false;
  public StatusOrganization = StatusOrganization;

  public hideAvailability = false;

  private _item: Item = null;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);

    if (this._item.isWine && this._item.status === 'available') {
      this.hideAvailability = true;
    }

    if (this._item.isExperience && !this._item.experience.availabilityLabel) {
      this.hideAvailability = true;
    }

    if (this._item.isCollection) {
      this.hideAvailability = true;
    }

    this.displaySchedule = false;
  }

  public get item(): Item {
    return this._item;
  }

  public toggleSchedule() {
    this.displaySchedule = !this.displaySchedule;
  }

}
