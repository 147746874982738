import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Account } from '@app/shared/interfaces';
import { AuthService } from '@app/core/services';

import { Item, ItemCollectionModel } from '../../../../modules/item/models';


@Component({
  selector: 'app-item-created-by',
  templateUrl: './created-by.component.html',
  styleUrls: ['./created-by.component.scss']
})
export class ItemCreatedBy implements OnInit, OnDestroy {

  @Input()
  public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public account: Account = null;

  private _item: Item = null;
  private _destroy$ = new Subject();

  constructor(
    private _authService: AuthService,
  ) { }

  public get item() {
    return this._item;
  }

  public get ownerTasterName() {
    const collectionItem = (this.item.data as ItemCollectionModel);

    if (collectionItem.ownerObjectId === this.account.id) {
      return 'You';
    } else {
      const taster = collectionItem.ownerObject.meta;
      return `${taster.firstName} ${taster.lastName[0]}.`; // short version of the name
    }
  }

  public ngOnInit() {
    this._loadAccount();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _loadAccount() {
    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((store) => {
        this.account = store.value;
      })
  }
}
