import { Component, ViewEncapsulation, Renderer2, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { Angulartics2Action, AppUseService, BackgroundGeolocationService, SettingService, VisitService } from './core/services';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { BuildService } from './system/modules/build/services/build.service';
import { AppService } from './core/services/app.service';
import { Platform } from '@ionic/angular';
import { Build } from './system/modules/build/interfaces/build.interface';
import { UpdateDialogComponent } from './system/modules/builds/components';
import { MatDialog } from '@angular/material/dialog';
import { BuildAction } from './system/modules/build/enums';
import { ThemingService } from '@libs/theming/services';
import { SettingsMap } from './shared';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FsAnalytics } from '@firestitch/analytics';

// As the handleOpenURL handler kicks in before the App is started,
// declare the handler function at the top of app.component.ts (outside the class definition)
// to track the passed Url
// (window as any).handleOpenURL = (url: string) => {
//   alert('handling url from outside appcomponent.ts!');
//   (window as any).handleOpenURL_LastURL = url;
// };

// document.addEventListener('handleopenurl', (e) => {
//   setTimeout(function() {
//     alert('event!');
//   },0);
//  }, false);





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    StatusBar,
    SplashScreen,
  ]
})
export class AppComponent {

  private deeplinks: Deeplinks = new Deeplinks();

  constructor(
    public router: Router,
    private renderer: Renderer2,
    private angulartics2Action: Angulartics2Action,
    private statusBar: StatusBar,
    private _platform: Platform,
    private _appService: AppService,
    private splashScreen: SplashScreen,
    private ngZone: NgZone,
    private visitService: VisitService,
    private _buildService: BuildService,
    private _dialog: MatDialog,
    private _appUseService: AppUseService,
    private _themingService: ThemingService,
    private _settingService: SettingService,
    private _backgroundGeolocationService: BackgroundGeolocationService,
    private _deviceService: DeviceDetectorService,
    private _analytics: FsAnalytics,
  ) {
    this.angulartics2Action.startTracking();
    this._themingService.init();
    this._analytics.init();

    const platformBodyClass = this._deviceService.isMobile() ? 'platform-app' : 'platform-' + environment.platform;
    this.renderer.addClass(document.body, platformBodyClass);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((<any>window).ga) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }

        if ((<any>window)?.cordova?.plugins?.firebase?.analytics) {
          (<any>window).cordova.plugins.firebase.analytics.logEvent("page_view", {page: event.urlAfterRedirects});
        }
      }
    });

    this._appService.ready$.subscribe(() => {
      this.statusBar.show();
      this.statusBar.styleDefault();

      this.splashScreen.hide();

      this.subscribeDeeplinks();
    });



    this._appUseService.start();

    this._platform.resume.subscribe(() => {
      this._buildService.init();
    })


    this._buildService.build$.subscribe((build: Build) => {
      if (build.action === BuildAction.ForceUpdate) {
        // redirec to blank page
        this.router.navigateByUrl('/blank');

        // show update dialog
        const dialogRef = this._dialog.open(UpdateDialogComponent, {
          disableClose: true,
          data: { build: build}
        });

      }
    });


    // background geolocation tracking
    this._settingService.gets('map')
      .subscribe((settings: SettingsMap) => {
        if (!!settings.geolocationTrackingEnabled) {
          this._backgroundGeolocationService.checkIfAuthorized()
            .subscribe((authorized) => {
              if (authorized) {
                this._backgroundGeolocationService.init();
              }
            });
        }
      });
  }

  private subscribeDeeplinks() {
    this.deeplinks.route({
      // not using these on purpose.. handling all deeplinks through nomatch
    }).subscribe(match => {
      // not using these on purpose.. handling all deeplinks through nomatch
    }, nomatch => {

      if (nomatch === 'cordova_not_available') {
        return;
      }
      this.ngZone.run(() => {
        if (nomatch.$link) {
          const url = new URL(nomatch.$link.url)

          const trackingCode = url.searchParams.get('tc');
          if (trackingCode) {
            this.visitService.trackVisit(trackingCode);
          }

          let pathParm = url.searchParams.get('path');
          const urlParm = url.searchParams.get('url');

          if (pathParm) {
            pathParm = decodeURIComponent(pathParm);
            // alert('deep link nav pathparm: ' + pathParm);
            this.router.navigateByUrl(pathParm);
          } else if (urlParm) {
            // alert('deep link nav urlparm: ' + urlParm);
            this.router.navigateByUrl(urlParm);
          } else {
            const regex = /:\/\/([^?]*)/gi;
            const matches = regex.exec(nomatch.$link.url);
            // alert('deep link nav regex: ' + matches[1]);
            this.router.navigateByUrl(matches[1]);
          }

          // gets unsubscribed when there is no match for some crazy reason.
          // https://github.com/ionic-team/ionic-plugin-deeplinks/issues/77
          this.subscribeDeeplinks();
        }
      });
    });
  }



}
