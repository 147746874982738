import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { ItemModule } from '@app/taster/modules/item/item.module';
import { VerifyTastingDialogModule } from '@app/taster/modules/tastings/modules/verify-tasting-dialog';

import {
  TastingRoomPaneComponent
} from './components';


@NgModule({
    imports: [
        SharedModule,
        ItemModule,
        VerifyTastingDialogModule
    ],
    declarations: [
        TastingRoomPaneComponent
    ],
    exports: [
        TastingRoomPaneComponent
    ]
})
export class TastingRoomPaneModule { }
