import { Component, ViewChild, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { filter } from 'lodash';
import { filter as fsFilter, list, index } from '@firestitch/common';
import { ItemType } from '@firestitch/filter';

import { ObjectService } from 'app/core/services';
import { Object } from 'app/shared/interfaces';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

  @Input() public advancedSearch = true;
  @Input() public selectable = false;
  @Input() public linkable = true;
  @Input() public selected = [];
  @Input() public availableClasses = this.objectService.searchClasses;

  @Input() public highlightOriginObject: Object = null;

  @Output() public selectedEntity = new EventEmitter();
  @Output() public goToEntity = new EventEmitter();
  @Output() public viewAllClicked = new EventEmitter();
  @Output() public highlightSwitch = new EventEmitter<any>();

  @ViewChild('filter', { static: true }) public filterRef = null;

  public config = null;
  public objectClasses = [];
  public classesIndexed: any = null;
  public objects: { [key: string]: Object[] } = {};
  public recentHistory: number[] = null;

  private queryParams: any = {};

  constructor(
    private objectService: ObjectService,
    private router: Router
  ) { }

  public ngOnInit() {

    this.recentHistory = this.objectService.getRecent();
    this.classesIndexed = index(this.objectService.classes, 'value');

    this.config = {
      items: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        }
      ],

      init: query => {
        this.query(query);
      },

      reload: query => {
        this.query(query);
      },

      change: query => {
        this.recentHistory = null;
        this.query(query);
      }
    }
  }

  public query(query) {
    query.objects = true;
    query.limit = 10;
    query.projects = true;
    query.class = this.availableClasses.join(',');
    let highlightedObjectsIds = [];

    if (this.highlightOriginObject) {
      for (const key in this.selected) {
        if (!this.selected[key]) {
          continue;
        }
        highlightedObjectsIds = [...highlightedObjectsIds, ...list(filter(this.selected[key], { relatedObjectHighlight: 1 }), 'id')];
      }
    }

    if (!query.keyword && !this.recentHistory) {
      this.objects = {};
      this.objectClasses = [];
      return;
    }

    if (this.recentHistory) {
      query.objectId = this.recentHistory;
    }

    this.queryParams = query;

    this.objectService.gets(query, { key: null })
      .subscribe(response => {

        if (this.recentHistory) {
          response.objects.sort((a, b) => {
            return this.recentHistory.indexOf(a.id) - this.recentHistory.indexOf(b.id);
          });
        }

        const objects = {};
        response.objects.forEach(object => {

          object.relatedObjectHighlight = highlightedObjectsIds.indexOf(object.id) !== -1;
          object.selected = this.selected[object.class]
            ? this.selected[object.class].findIndex((obj) => obj.id === object.id) !== -1
            : false ;

          objects[object.class] = objects[object.class] || [];
          objects[object.class].push(object);
        });

        this.objects = objects;

        this.objectClasses = [];
        Object.keys(objects).forEach(cls => {
          this.objectClasses.push(filter(this.objectService.classes, { value: cls })[0]);
        });
      });
  }

  public viewAll(data = null) {
    if (this.advancedSearch) {
      this.viewAllClicked.emit();

      const params = data
        ? { queryParams: { class: data.value, keyword: this.queryParams.keyword } }
        : {};

      this.router.navigate(['/search'], params );
    }
  }

  public selectEntity(event, entity: Object) {
    const data = this.getObjectById(entity);
    if (data) {
      data.selected = event.checked;
      this.selectedEntity.emit({ checked: event.checked, record: entity });
    } else {
      console.warn('Error. Object wasn\'t found');
    }
  }

  public navigateTo(data: Object) {
    if (this.linkable) {
      this.goToEntity.emit(data);
    }
  }

  public reload() {
    setTimeout(() => {
      this.filterRef.change();
    });
  }

  public onHighlightSwitch($event) {
    const data = this.getObjectById($event.relatedObject);
    data.relatedObjectHighlight = $event.action === 'highlight' ? 1 : 0;
    this.highlightSwitch.emit($event);
  }

  private getObjectById(entity: Object) {
    const objectsByClass = this.objects[entity.class];
    if (objectsByClass && objectsByClass.length) {
      const data = fsFilter(objectsByClass, { id: entity.id });
      return data.length ? data[0] : null;
    }
  }

}
