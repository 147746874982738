import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs';


@Injectable()
export class ContentService {

  constructor(private fsApi: FsApi) {}

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(query): Observable<any> {
    return this.fsApi.get(`contents`, query, { key: 'content' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `contents`, data, Object.assign({ key: 'contentWidgets' }, config));
  }

  public put(contentWidget, config = {}): Observable<any> {
    return this.fsApi.put(`contents/${contentWidget.id}`, contentWidget, Object.assign({ key: 'contentWidget' }, config));
  }

  public post(contentWidget): Observable<any> {
    return this.fsApi.post(`contents`, contentWidget, { key: 'contentWidget' });
  }

  public delete(contentWidget): Observable<any> {
    return this.fsApi.delete(`contents/${contentWidget.id}`, contentWidget, { key: 'contentWidget' });
  }
}
