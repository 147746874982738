import { Image } from './image';


export interface Category {
  readonly id: number;
  name?: string;
  guid?: string;
  image?: Image;
  color?: string;
  featured?: number;
  order?: number;
  state?: string;
}
