import { Pipe, PipeTransform } from '@angular/core';

import { Note } from '@app/taster/modules/item/interfaces';


@Pipe({
  name: 'noteName'
})
export class NoteNamePipe implements PipeTransform{

  public transform(value: Note, ...args): any {
    const flavoursNames = value.flavours.map((flavour) => {
      return flavour.name;
    });

    const finishesNames = value.finishes.map((finish) => {
      return finish.name;
    });

    const popularNotesNames = value.popularNotes.map((note) => {
      return note.name;
    });

    const name = [
      ...flavoursNames,
      ...finishesNames,
      ...popularNotesNames,
    ];

    if (value.customNote) {
      name.push(value.customNote);
    }

    return name.join(', ');
  }
}
