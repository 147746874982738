import { Workflow } from './workflow';
import { Status } from './status';
import { WorkflowPath } from './workflow-path';
import { WorkflowTask } from './workflow-task';


export interface WorkflowStep {
  id: number;
  workflowId?: number;
  workflow?: Workflow;
  statusId?: number;
  workflowTask?: WorkflowTask;
  workflowTaskId?: number;
  status?: Status;
  styles?: any;
  x1?: number;
  y1?: number;
  name?: string;
  type?: string;
  state?: string;
  workflowPaths?: WorkflowPath[];
  workbackTime?: number;
}
