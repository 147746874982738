import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';


@Component({
  selector: 'app-item-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class ItemVisitsComponent implements OnInit, OnDestroy {

  @Input()
  public visits: number = null;

  private _destroy$ = new Subject();

  constructor() { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
