<fs-dialog [mobileMode]="'bottom'">
  <mat-dialog-content>
    <div class="section">
      <div mat-dialog-title>Rate</div>
      <div class="title message">How would you rate this {{ itemType }}?</div>

      <fs-rating [ngModel]="data.item.accountItem.rating"
                 (ngModelChange)="ratingChanged($event)"
                 [showValue]="false"
                 [selectable]="true"
                 [maxRange]="5">
        <ng-template fsRatingLabel>Awful</ng-template>
        <ng-template fsRatingLabel>Lacking</ng-template>
        <ng-template fsRatingLabel>Meh</ng-template>
        <ng-template fsRatingLabel>Good</ng-template>
        <ng-template fsRatingLabel>Epic</ng-template>
      </fs-rating>
    </div>

    <br>
    <h2>Add Notes</h2>

    <div class="bordered-list" *fsSkeleton="!!note">
      <ng-container *ngIf="data.item.isWine">

        <div class="list-item" (click)="addFlavours()">
          <mat-icon class="left-icon" svgIcon='flavour'></mat-icon>
          <div class="title">
            <b> Flavours </b>
          </div>
          <mat-icon class="right-icon">
            keyboard_arrow_right
          </mat-icon>
        </div>

        <div class="list-item" (click)="addFinishes()">
          <mat-icon class="left-icon" svgIcon='finishes'></mat-icon>
          <div class="title">
            <b> Finishes </b>
          </div>
          <mat-icon class="right-icon">
            keyboard_arrow_right
          </mat-icon>
        </div>
      </ng-container>

      <div class="list-item" (click)="addNote()">
        <mat-icon class="left-icon" svgIcon='notes-circle'></mat-icon>
        <div class="title">
          <b> Notes </b>
        </div>
        <mat-icon class="right-icon">
          keyboard_arrow_right
        </mat-icon>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-raised-button color="primary" mat-dialog-close (click)="close()">DONE</button>
  </mat-dialog-actions>
</fs-dialog>
