import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService {

  public STATE_ACTIVE = 'active';
  public STATE_DELETED = 'deleted';

  public states = [
    { name: 'Active', value: this.STATE_ACTIVE },
    { name: 'Deleted', value: this.STATE_DELETED }
  ];

  constructor(private fsApi: FsApi) {}

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public get(orderId): Observable<any> {
    return this.fsApi.get(`orders/${orderId}`, {}, { key: 'order' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `orders`, data, Object.assign({ key: 'orders' }, config));
  }

  public put(order, config = {}): Observable<any> {
    return this.fsApi.put(`orders/${order.id}`, order, Object.assign({ key: 'order' }, config));
  }

  public post(order): Observable<any> {
    return this.fsApi.post(`orders`, order, { key: 'order' });
  }

  public delete(order): Observable<any> {
    return this.fsApi.delete(`orders/${order.id}`, order, { key: 'order' });
  }

  public dummyPost(): Observable<any> {
    return this.fsApi.post(`orders/dummy`, {}, { key: 'order' });
  }
}
