
<fs-sidenav-menu *fsSkeleton="liteMode !== undefined">
  <div class="search">
    <div class="search-input">
      <fs-filter [filter]="conf"></fs-filter>
    </div>

    <button mat-icon-button (click)="createOrganizationDialog()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <fs-sidenav-menu-content>
    <div *ngFor="let organization of organizations" class="organization">
      <fs-badge [image]="organization?.image?.small" size="30"></fs-badge>
      <div class="organization-info">
        <a class="organization-name" [routerLink]="['/manage', 'organizations', organization.id]" (click)="close()">
          <span class="organization-header">{{ organization.name }}</span>
        </a>
        <div class="organization-links">
          <a *ngIf="!liteMode && organization?.type?.featureWinesProduced" [routerLink]="['/manage', 'organizations', organization.id, 'wines', 'wines']" (click)="close()">
            Wines
          </a>
          <a *ngIf="organization?.subscription?.featureExperiences" [routerLink]="['/manage', 'organizations', organization.id, 'experiences']" (click)="close()">
            Experiences
          </a>
          <a *ngIf="organization?.subscription?.featureCollections" [routerLink]="['/manage', 'organizations', organization.id, 'collections']" (click)="close()">
            Lists & Routes
          </a>
          <a [routerLink]="['/manage', 'organizations', organization.id]" (click)="close()">
            Settings
          </a>
        </div>
      </div>
    </div>
  </fs-sidenav-menu-content>

  <fs-sidenav-menu-actions>
    <button mat-button (click)="close()">Done</button>
  </fs-sidenav-menu-actions>
</fs-sidenav-menu>
