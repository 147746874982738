<ng-container [ngSwitch]="item.class">
  <organization-price
    *ngSwitchCase="'organization'"
    [organization]="item.organization">
  </organization-price>
  <app-experience-price
    *ngSwitchCase="'experience'"
    [experience]="$any(item.experience)">
  </app-experience-price>
  <ng-container *ngSwitchCase="'wine'"><b>{{ item.wine.price | fsFormatCurrency: 2 }}</b></ng-container>
</ng-container>
