<div class="actions">
  <div class="action-buttons">
    <a
      class="raiting-icon"
      authorizedClick
      [ngClass]="{ active: !!item.accountItem.rate }"
      (click)="rate()"
    >
      <mat-icon>star</mat-icon>
      <span *ngIf="item.accountItem?.rating; else noRate">{{ item.accountItem?.rating }}/5</span>
      <ng-template #noRate>
        <span>Rate</span>
      </ng-template>
    </a>
    <a
      *ngIf="!item.isOrganization"
      class="tried-icon"
      authorizedClick
      [ngClass]="{ 'active': item.accountItem.triedIt }"
      (click)="toggleTryIt()"
    >
      <mat-icon>check</mat-icon>
      <span>Tried</span>
    </a>
    <a
      *ngIf="item.isOrganization"
      class="visits-icon"
      authorizedClick
      [ngClass]="{ 'active': item.accountItem?.visits }"
      (click)="visits()"
    >
      <mat-icon>check</mat-icon>
      <span><app-item-visits [visits]="item.accountItem?.visits"></app-item-visits></span>
    </a>
    <a
      *ngIf="!item.isCollection"
      authorizedClick
      class="added-icon"
      [ngClass]="{ 'active': item.addedToList }"
      (click)="addToList()"
    >
      <mat-icon>add</mat-icon>
      <span>List</span>
    </a>
    <app-item-action-kebub-menu
      class="explore-list-item-actions"
      [item]="item"
      [customActions]="customActions"
      (ratingChanged)="onRatingChanged()">
    </app-item-action-kebub-menu>
  </div>
</div>
