import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { forEach, isArrayLikeObject } from 'lodash';

import { FsApi } from '@firestitch/api';
import { indexOf, find, filter } from '@firestitch/common';

import { AclRole } from 'app/shared/interfaces';


@Injectable()
export class AclRoleService {

  public Active = 'active';
  public Deleted = 'deleted';

  public LevelBackend = 'backend';
  public LevelApp = 'app';
  public LevelOrganization = 'organization';
  // public LevelProject = 'project';

  public states = [
    { name: 'Active', value: this.Active },
    { name: 'Deleted', value: this.Deleted }
  ];

  public levels = [
    { name: 'Backend', value: this.LevelBackend },
    { name: 'App', value: this.LevelApp },
    // { name: 'Project', value: this.LevelProject }
  ];

  public accesses = [
    { value: 0, name: 'None' },
    { value: 5, name: 'Read' },
    { value: 10, name: 'Write' },
    { value: 15, name: 'Full' }
  ];

  public create(data: AclRole = { id: null }) {

    data.permissions = data.permissions || {};

    if (isArrayLikeObject(data.permissions)) {
      data.permissions = {};
    }

    return data;
  }

  constructor(private fsApi: FsApi) { }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public permissions(): Observable<any> {
    return this.fsApi.get(`acl/permissions`, {}, { key: 'permissions' });
  }

  public get(id, data = {}): Observable<any> {
    return this.fsApi.get(`acl/roles/${id}`, data, { key: 'aclRole' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `acl/roles`, data, Object.assign({ key: 'aclRoles' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this.fsApi.put(`acl/roles/${data.id}`, data, Object.assign({ key: 'aclRole' }, config));
  }

  public post(data): Observable<any> {
    return this.fsApi.post(`acl/roles`, data, { key: 'aclRole' });
  }

  public delete(data): Observable<any> {
    return this.fsApi.delete(`acl/roles/${data.id}`, data, { key: 'aclRole' });
  }

  public groupPermissions(permissions, groupBy = 'level') {

    const result = [];

    forEach(permissions, permission => {
      const index = indexOf(result, { [groupBy]: permission[groupBy] });
      let data = null;
      if (index === -1) {
        data = {
          category: permission.category,
          [groupBy]: permission[groupBy],
          permissions: []
        };
        result.push(data);
      } else {
        data = result[index];
      }

      if (permission.inherits) {
        data.inherits = true;
        permission.inheritsLength = Object.keys(permission.inherits).length;
        permission.inheritedPermissions = [];
        permission.inheritedPermissionsTooltip = '';
        for (const key in permission.inherits) {
          if (!permission.inherits[key]) {
            continue;
          }
          const item = find(permissions, { value: key });
          if (item) {
            permission.inheritedPermissions.push(item);

            permission.inheritedPermissionsTooltip
              += item.name + ' ' + this.accesses.find(access => access.value === permission.inherits[item.value]).name + `\n`;
          }
        }
      }
      data.permissions.push(permission);
    });

    return result;
  }

  public getAccess(permissions, role: AclRole) {

    const result: any = [];
    role.permissions = role.permissions || {};
    const data = filter(permissions, { level: role.level });

    forEach(data, permission => {
      result.push(role.permissions[permission.value] ? role.permissions[permission.value] : 0);
    });

    return result.length === 1 ? result[0] : Math.max(...result);
  }

  public getInheritsCount(permissions, role: AclRole) {
    let result: number = null;

    const data = find(permissions, { level: role.level });

    if (data && data.inherits) {
      result = Object.keys(data.inherits).length
    }

    return result;
  }

}
