import { Component, Input, OnInit } from '@angular/core';

import { Account, Object } from '../../../shared/interfaces';


@Component({
  selector: 'app-account-image',
  templateUrl: `./account-image.component.html`,
  styleUrls: ['./account-image.component.scss']
})
export class AccountImageComponent {

  public name = '';
  public firstName = '';
  public lastInitial = '';

  public image: string = null;

  @Input() public size = 30;
  @Input() public shortName = false;
  @Input() public longName = false;

  @Input('account')
  set account(account: Account) {
    if (account) {
      this.image = account.image.small;
      this.name = account.firstName + ' ' + account.lastName;
      this.firstName = account.firstName;
      this.lastInitial = account.lastName.substr(0,1);
    }
  };

  @Input('object')
  set object(object: Object) {
    if (!object) {
      return;
    }
    object.meta = object.meta || {};
    object.meta.lastName = `${object.meta.lastName}`;
    this.image = object.imageUrl;
    this.name = object.name;
    this.firstName = object.meta.firstName;
    this.lastInitial = object.meta.lastName.substr(0, 1);
  };
}
