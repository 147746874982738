<form>
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Add To List or Route</h1>

      <ng-container *ngFor="let item of collectionOwnerTypes; let i = index">
        <button
          *ngIf="i === 0"
          mat-raised-button
          color="primary"
          class="mat-fullwidth"
          (click)="save(item.value)"
        >{{ item.name }}</button>

        <button
          *ngIf="i !== 0"
          mat-stroked-button
          class="mat-fullwidth"
          (click)="save(item.value)"
        >{{ item.name }}</button>
      </ng-container>

      <button type="button" mat-stroked-button [mat-dialog-close]="true" class="mat-fullwidth">Cancel</button>

  </fs-dialog>
</form>
