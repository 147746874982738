export const ThemeVariable = {
  Primary: 'brand-primary-color',
  WhiteLogo: 'brand-logo-white',
  ColorLogo: 'brand-logo-color',
  Secondary: 'brand-secondary-color',
  TriedIt: 'brand-tried-it-color',
  Collection: 'brand-collection-color',
  Awards: 'brand-awards-color',
  Wine: 'brand-wine-color',
  WineClub: 'brand-wine-club-color',
  Top10: 'brand-top-10-color',
  BackgroundImage: 'brand-background-image',

  FlagColor050: 'flag-color-0-50',
  FlagColor5160: 'flag-color-51-60',
  FlagColor6170: 'flag-color-61-70',
  FlagColor7180: 'flag-color-71-80',
  FlagColor8190: 'flag-color-81-90',
  FlagColor91100: 'flag-color-91-100',
  FlagColorUnknown: 'flag-color-unknown'
};
