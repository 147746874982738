<ng-container *ngIf="data.attribute.id">
  <fs-file-image-picker
    (select)="selectImage($event)"
    [url]="data.attribute.configs?.smallImage?.url"
    [imageQuality]=".8"
    imageWidth="600"
    imageHeight="600"
    [previewDiameter]="75"
    [label]="data.field?.label">
  </fs-file-image-picker>
  <div><small>Shown in wine properties bar for List/Card/Page headings</small></div>
</ng-container>