<fs-sidenav-menu-items *ngIf="loggedInAccount">
  <button fs-sidenav-menu-item
          *ngIf="showSwitchToTaster"
          type="button"
          (click)="changePortal(LastPortal.Taster)">
    Switch to User Portal
  </button>
  <button fs-sidenav-menu-item
          *ngIf="showSwitchToManagement"
          type="button"
          (click)="changePortal(LastPortal.Management)">
    Switch to Management Portal
  </button>
  <button fs-sidenav-menu-item
          *ngIf="showSwitchToAdmin"
          type="button"
          (click)="changePortal(LastPortal.Admin)">
    Switch to Admin Portal
  </button>
  <ng-container *ngIf="!lastPortal || lastPortal === 'management'">
    <b *ngIf="
      environment ||
      hasApp ||
      (!environments || (environments && environments.length))
    ">Management Portal</b>
    <button fs-sidenav-menu-item
            type="button"
            *ngIf="environment"
            class="menu-items-group"
            (click)="proceedOrganization()">
      Organization Settings
    </button>
    <button fs-sidenav-menu-item
            type="button"
            *ngIf="hasApp"
            class="menu-items-group"
            (click)="createOrganizationDialog()">
      Create New Organization
    </button>
    <button fs-sidenav-menu-item
            type="button"
            *ngIf="!environments || (environments && environments.length)"
            class="menu-items-group"
            (click)="switchDialog()">
      Switch Organization
    </button>
  </ng-container>
</fs-sidenav-menu-items>
