import { WineBody } from '../enums';


export const WineBodies = [
	{ name: `${WineBody.Light} • Light`, value: WineBody.Light },
	{ name: `${WineBody.LightMedium} • Light-Medium`, value: WineBody.LightMedium },
	{ name: `${WineBody.Medium} • Medium`, value: WineBody.Medium },
	{ name: `${WineBody.MediumFull} • Medium-Full`, value: WineBody.MediumFull },
	{ name: `${WineBody.Full} • Full`, value: WineBody.Full },
];
