import { Component, Input, OnInit } from '@angular/core';

import { find } from '@firestitch/common';

import { CollectionService } from 'app/core/services';
import { CollectionShowOnPage } from '@app/shared/types';


@Component({
  selector: 'app-tag-show-on-page',
  templateUrl: './tag-show-on-page.component.html',
  styleUrls: ['./tag-show-on-page.component.scss']
})

export class TagShowOnPageComponent implements OnInit {

  @Input() public show: boolean = null;
  @Input() public value: CollectionShowOnPage = null;
  @Input()
  public settings: any = null;

  public tag = null;

  constructor(
    private _collectionService: CollectionService
  ) { }

  public ngOnInit() {
    this.tag = find(this._collectionService.collectionShowOnPage, { value: this.value });

    switch (this.value) {
      case CollectionShowOnPage.Wineries:
        this.tag.name = this.settings?.brandingOrganizationPageTitle || this.tag.name;
        break;
      case CollectionShowOnPage.ToDo:
        this.tag.name = this.settings?.brandingActivitiesPageTitle || this.tag.name;
        break;
    }
  }

}
