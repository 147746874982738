import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { FsMessage, MessageMode } from '@firestitch/message';

import { DeviceDetectorService } from 'ngx-device-detector';
import { SessionService, SigninService } from '../../core/services';
import { Account } from '../../shared/interfaces';


@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(
    //public jwtHelper: JwtHelperService,
    private router: Router,
    private sessionService: SessionService,
    private signingService: SigninService,
    private fsMessage: FsMessage,
    private _deviceDetectorService: DeviceDetectorService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // if it is mobile&web the shareclick guard is going to force user to /shareclick.
    // this hack is to prevent the session expired message from showing
    // if (environment.platform === 'web' && this._deviceDetectorService.isMobile()) {
    //   return true;
    // }

    if (!this.sessionService.signedIn()) {
      if (this.sessionService.account()) {
        this.fsMessage.error('Your session has expired. Please signin again.', { mode: MessageMode.Toast });
      } else if (state.url !== '/') {
        this.fsMessage.error('Please signin to access this page.', { mode: MessageMode.Toast });
      }

      return this.signingService.createRedirectUrlTree(state);
    }

    const account: Account = this.sessionService.account();

    if (account && account.passwordChange) {
      return this.router.createUrlTree([
          '/signin/passwordchange'
        ],
        {
          queryParams: { redirect: state.url }
        }
      );
    }

    return true;
  }

}
