import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { isObject } from 'lodash-es';

import { find, list } from '@firestitch/common';
import { ItemType } from '@firestitch/filter';

import { EnvironmentService } from '../../../core/services';
import { Account, Organization } from '../../interfaces';
import { AccountType } from 'app/shared/types';
import { NgForm, ControlContainer } from '@angular/forms';


@Component({
  selector: 'app-primary-organization-contact',
  templateUrl: './primary-organization-contact.component.html',
  styleUrls: ['./primary-organization-contact.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PrimaryOrganizationContactComponent implements OnInit {

  @Input() public type: ItemType.AutoComplete | ItemType.Select = ItemType.Select;

  @Input() public organization: Organization = null;

  private _account: Account = null;
  @Input() public set account(value: Account) {
    this._account = value;
    this.selectAccountId = this._account ? this._account.id : null;
  }
  public get account(): Account {
    return this._account;
  }

  @Output() public change = new EventEmitter<Account>();
  @Output() public accountChange = new EventEmitter<Account>();

  public accounts: Account[] = [];
  public selectAccountId: number = null;

  constructor(
    private _environmentService: EnvironmentService
  ) { }

  public ngOnInit() {
    if (this.type === ItemType.Select) {
      this.loadAccounts(true);
    }
  }

  public loadAccounts($event: any): void {
    this.accounts = [];
    const query: any = {
      accountType: AccountType.Person,
      accounts: true
    };

    if (!$event) {
      return;
    }

    if (this.type === 'autocomplete') {
      Object.assign(query, {
        keyword: $event,
        page: 1,
        limit: 10
      });
    }

    if (!isObject($event)) {
      this._environmentService.getAccounts(this.organization.id, query, { key: null })
        .subscribe(response => this.accounts = list(response.environmentAccounts, 'account'));
    } else {
      this.accountSelected($event);
    }
  }

  public displayFn(data: any): string {
    return data ? `${data.firstName} ${data.lastName}` : '';
  }

  public accountSelected(account: Account): void {
    this.change.emit(account);
    this.accountChange.emit(account);
  }

  public accountIdChange(id: number) {
    this.account = find(this.accounts, { id });
    this.change.emit(this.account);
    this.accountChange.emit(this.account);
  }

}
