import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';

import { PANE_DATA } from '@firestitch/pane';

import { Item } from '@app/taster/modules/item/models';
import { VerifyTastingDialogComponent } from '@app/taster/modules/tastings/modules/verify-tasting-dialog/components';


@Component({
  templateUrl: './tasting-room-pane.component.html',
  styleUrls: ['./tasting-room-pane.component.scss']
})
export class TastingRoomPaneComponent implements OnInit {

  public item: Item = null;

  constructor(
    private _dialog: MatDialog,
    @Inject(PANE_DATA) public data
  ) { }

  public ngOnInit() {
    this.item = this.data.item;
  }

  public verifyTastingDialog() {
    const dialogRef = this._dialog.open(VerifyTastingDialogComponent, {
      data: {
        item: this.item
      }
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => {  });
  }

}
