import { Component, OnInit, Inject } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  FS_ATTRIBUTE_CONFIG,
  FS_ATTRIBUTE_FIELD_DATA,
  FsAttributeConfig
} from '@firestitch/attribute';


@Component({
  selector: 'app-attribute-edit-regional-reporting',
  templateUrl: 'edit-regional-reporting.component.html',
  styleUrls: ['edit-regional-reporting.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AttributeEditRegionalReportingComponent implements OnInit {

  constructor(
    @Inject(FS_ATTRIBUTE_FIELD_DATA) public data,
    @Inject(FS_ATTRIBUTE_CONFIG) private attributeConfig: FsAttributeConfig
  ) {}

  ngOnInit() {
    if (!this.data.attribute.configs) {
      this.data.attribute.configs = {};
    }
  }
}
