import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Platform } from '@firestitch/share';


@Injectable({
  providedIn: 'root',
})
export class ShareMenuService {

  private _activeActions: { label: string, handler: any, medium: Platform }[];

  private _mobileActions = [
    { label: 'Facebook', medium: Platform.Facebook, handler: this._shareFacebook.bind(this) },
    { label: 'Twitter', medium: Platform.Twitter, handler: this._shareTwitter.bind(this) },
    { label: 'Other', medium: Platform.Any, handler: this._shareOther.bind(this) },
  ];

  private _desktopActions = [
    { label: 'Facebook', medium: Platform.Facebook, handler: this._shareFacebook.bind(this) },
    { label: 'Twitter', medium: Platform.Twitter, handler: this._shareTwitter.bind(this) },
    { label: 'Get Share Link', medium: Platform.Copy, handler: this._shareLink.bind(this) },
  ];

  constructor() {
    this.initActions();
  }

  public get actions() {
    return this._activeActions;
  }

  public initActions() {
    if (environment.platform === 'app') {
      this._activeActions = this._mobileActions;
    } else {
      this._activeActions = this._desktopActions;
    }
  }

  private _shareFacebook() {

  }

  private _shareTwitter() {

  }

  private _shareLink() {

  }

  private _shareOther() {

  }
}
