import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { LastPortal } from '@app/shared/types';
import { Account } from '@app/shared/interfaces';
import { AuthService } from './auth.service';


@Injectable()
export class PortalService {

  private _lastPortal$ = new BehaviorSubject<LastPortal>(null);
  private _account: Account = null;

  public get lastPortal$(): Observable<LastPortal> {
    return this._lastPortal$
      .asObservable();
  }

  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) {
    this._authService.loggedInAccount$
      .pipe(
        tap(store => this._account = store && store.value ? store.value : null),
        map(store => store && store.value ? store.value.lastPortal : null),
        filter(response => !!response)
      )
      .subscribe((lastPortal: LastPortal) => this.setLastPortal(lastPortal));
  }

  public setLastPortal(value: LastPortal) {
    this._lastPortal$.next(value);
  }

  public getHomePageUrl(environment) {

    if (!this._account) {
      return this._router.createUrlTree(['/wineries']);
    }

    const lastPortal = this._lastPortal$.getValue();

    switch (lastPortal) {
      case LastPortal.Admin: {
        return this._router.createUrlTree(['/manage', 'organizations']);
      }

      case LastPortal.Management: {
        if (environment) {
          return this._router.createUrlTree(
            ['/manage', 'organizations', environment.id]
          );
        } else {
          return this._router.createUrlTree(['/manage', 'noorganization']);
        }
      }

      default: {
        return this._router.createUrlTree(['/wineries']);
      }
    }
  }
}
