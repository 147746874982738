import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '@app/core';

import { SignInDialogComponent } from '../components';


@Directive({
    selector: '[authorizedClick]',
})
export class AuthorizedClickDirective implements OnDestroy {

  constructor(
    private _el: ElementRef,
    private _dialog: MatDialog,
    private _authService: AuthService
  ) {
    this._el.nativeElement.addEventListener('click', this.clickHandler);
  }

  public ngOnDestroy(): void {
    this._el.nativeElement.removeEventListener('click', this.clickHandler);
  }

  public clickHandler = (e) => {
    if (!this._authService.isLoggedIn) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      this._dialog.open(SignInDialogComponent);
    }
  }

}
