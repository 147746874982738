import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  isAfter,
  isBefore,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { isString } from 'lodash-es';

import { FsApi } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';
import { date, parseLocal } from '@firestitch/date';

import { Organization, Wine, Experience, ProductLine, Tasting, Variety, Attribute } from '../../shared/interfaces';
import { StatusOrganization, StatusWine, StatusExperience, ExperiencePricingModel, WineVarietyMode } from 'app/shared/types';
import { isWithinInterval } from 'date-fns/esm';


@Injectable()
export class OrganizationService {

  public statusesOrganization = [
    { value: StatusOrganization.ComingSoon, name: 'Coming Soon', color: '#589446' },
    { value: StatusOrganization.Open, name: 'Open', color: '#589446' },
    { value: StatusOrganization.ClosedPermanently, name: 'Closed Permanently', color: '#DE3A2E' },
    { value: StatusOrganization.ClosedSeason, name: 'Closed For Season', color: '#DE3A2E' }
  ];

  public statusesWine = [
    { value: StatusWine.ComingSoon, name: 'Coming Soon', color: '#589446' },
    { value: StatusWine.Available, name: 'Available', color: '#589446' },
    { value: StatusWine.SoldOut, name: 'Sold Out at Winery', color: '#DE3A2E' }
  ];

  public statusesExperience = [
    { value: StatusExperience.ComingSoon, name: 'Coming Soon', color: '#589446' },
    { value: StatusExperience.Open, name: 'Open', color: '#589446' },
    { value: StatusExperience.Closed, name: 'Closed', color: '#DE3A2E' }
  ];

  public experiencePricingModels = [
    { value: ExperiencePricingModel.Flat, name: 'Flat' },
    { value: ExperiencePricingModel.Range, name: 'Range' },
    { value: ExperiencePricingModel.Free, name: 'Free' }
  ];

  constructor(
    private _fsApi: FsApi,
    private _transfer: FsTransferService
  ) { }

  public create(data: Organization = { id: null }): Organization {
    data.address = data.address || {};
    data.subregion = data.subregion || null;
    data.seasons = data.seasons || [];
    data.class = 'organization';
    data.type = data.type || null;

    if (data.openDate && isString(data.openDate)) {
      data.openDate = utcToZonedTime(data.openDate, null);
    }

    if (data.closedDate && isString(data.closedDate)) {
      data.closedDate = utcToZonedTime(data.closedDate, null);
    }

    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`organizations/${id}`, query, { key: 'organization' })
      .pipe(this._transform());
  }

  public gets(data = {}, config: any = {}): Observable<any> {
    config = Object.assign({ key: 'organizations' }, config);
    return this._fsApi.request('GET', 'organizations', data, config)
      .pipe(
      map(response => {
        if (config.key) {
          response.map(item => this.create(item));
          return response;
        }
        response.organizations.map(item => this.create(item));
        return response;
      })
    );
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`organizations/${data.id}`, data, Object.assign({ key: 'organization' }, config))
      .pipe(this._transform());
  }

  public post(data): Observable<any> {
    return this._fsApi.post('organizations', data, { key: 'organization' })
      .pipe(this._transform());
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`organizations/${data.id}`, data, { key: 'organization' })
      .pipe(this._transform());
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public image(data, file): Observable<any> {
    return this._fsApi.post(`organizations/${data.id}/image`, { file: file }, { key: 'organization' })
      .pipe(this._transform());
  }

  public bulkDelete(organizationIds: number[], query = {}): Observable<any> {
    return this._fsApi.delete(`organizations/bulk`, { organizationIds }, { key: null })
  }

  public getWineClubs(organizationId: number, data = {}, config = {} ): Observable<any> {
    return this._fsApi.request('GET', `organizations/${organizationId}/wineclubs`, data, Object.assign({ key: 'wineClubs' }, config));
  }

  public saveWineClubs(organizationId: number, data, config = {} ): Observable<any> {
    return this._fsApi.put(`organizations/${organizationId}/wineclubs`, data, Object.assign({ key: 'wineClub' }, config));
  }

  public sendWineClubMessage(organizationId: number, data, config = {} ): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/wineclubs/send`, data, Object.assign({ key: 'wineClub' }, config));
  }

  public createWine(data: Wine = { id: null }): Wine {
    data.image = data.image || {};
    data.productLine = data.productLine || { id: null };
    data.releaseDate = data.releaseDate || null;
    data.soldOutDate = data.soldOutDate || null;
    data.class = 'wine';
    return data;
  }

  public getWines(organizationId, id, data = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/wines/${id}` : `wines/${id}`;
    return this._fsApi.get(url, data, { key: 'wine' });
  }

  public getsWines(organizationId, data = {}, config = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/wines` : `wines`;
    return this._fsApi.request('GET', url, data, Object.assign({ key: 'wines' }, config));
  }

  public putWines(organizationId, data, config = {}): Observable<any> {
    return this._fsApi.put(`organizations/${organizationId}/wines/${data.id}`, data, Object.assign({ key: 'wine' }, config));
  }

  public postWines(organizationId, data): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/wines`, data, { key: 'wine' });
  }

  public duplicateWine(organizationId: number, wineId: number, data: any): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/wines/${wineId}/duplicate`, data, { key: 'wine' });
  }

  public imageWines(organizationId, data, file): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/wines/${data.id}/image`, { file: file }, { key: 'wine' });
  }

  public deleteWines(organizationId, data): Observable<any> {
    return this._fsApi.delete(`organizations/${organizationId}/wines/${data.id}`, data, { key: 'wine' });
  }

  public bulkWines(wineIds: number[], data = {}): Observable<any> {
    return this._fsApi.put(`wines/bulk`, { wineIds, ...data }, { key: null })
  }


  public bulkDeleteWines(wineIds: number[], query = {}): Observable<any> {
    return this._fsApi.delete(`wines/bulk`, { wineIds }, { key: null })
  }

  public saveWines(organizationId, data): Observable<any> {
      if (data.id) {
      return this.putWines(organizationId, data);
    }
    return this.postWines(organizationId, data);
  }

  public createExperience(data: Experience = { id: null }): Experience {
    data.address = data.address || {};
    data.pricingModel = data.pricingModel || ExperiencePricingModel.Flat;
    data.openDate = data.openDate ? parseLocal(data.openDate) : null;
    data.closedDate = data.closedDate ? parseLocal(data.closedDate) : null;
    data.image = data.image || {};
    return data;
  }

  public getExperiences(organizationId, id, data = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/experiences/${id}` : `experiences/${id}`;
    return this._fsApi.get(url, data, { key: 'experience' });
  }

  public getsExperiences(organizationId, data = {}, config = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/experiences` : `experiences`;
    return this._fsApi.request('GET', url, data, Object.assign({ key: 'experiences' }, config));
  }

  public putExperiences(organizationId, data, config = {}): Observable<any> {
    return this._fsApi.put(`organizations/${organizationId}/experiences/${data.id}`, data, Object.assign({ key: 'experience' }, config));
  }

  public postExperiences(organizationId, data): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/experiences`, data, { key: 'experience' });
  }

  public imageExperiences(organizationId, data, file): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/experiences/${data.id}/image`, { file: file }, { key: 'experience' });
  }

  public deleteExperiences(organizationId, data): Observable<any> {
    return this._fsApi.delete(`organizations/${organizationId}/experiences/${data.id}`, data, { key: 'experience' });
  }

  public bulkDeleteExperiences(experienceIds: number[], query = {}): Observable<any> {
    return this._fsApi.delete(`experiences/bulk`, { experienceIds }, { key: null })
  }

  public saveExperiences(organizationId, data): Observable<any> {
    if (data.id) {
      return this.putExperiences(organizationId, data);
    }
    return this.postExperiences(organizationId, data);
  }

  public createProductLine(data: ProductLine = { id: null }): ProductLine {
    data.organization = data.organization || null;
    return data;
  }

  public getProductLines(organizationId, id, data = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/products/lines/${id}` : `products/lines/${id}`;
    return this._fsApi.get(url, data, { key: 'productLine' });
  }

  public getsProductLines(organizationId, data = {}, config = {}): Observable<any> {
    const url = organizationId ? `organizations/${organizationId}/products/lines` : `products/lines`;
    return this._fsApi.request('GET', url, data, Object.assign({ key: 'productLines' }, config));
  }

  public putProductLines(organizationId, data, config = {}): Observable<any> {
    return this._fsApi.put(`organizations/${organizationId}/products/lines/${data.id}`,
      data, Object.assign({ key: 'productLine' }, config));
  }

  public postProductLines(organizationId, data): Observable<any> {
    return this._fsApi.post(`organizations/${organizationId}/products/lines`, data, { key: 'productLine' });
  }

  public saveProductLines(organizationId, data): Observable<any> {
    if (data.id) {
      return this.putProductLines(organizationId, data);
    }
    return this.postProductLines(organizationId, data);
  }

  public bulkProductLines(productLineIds: number[], data = {}): Observable<any> {
    return this._fsApi.put(`products/lines/bulk`, { productLineIds, ...data }, { key: null })
  }

  public deleteProductLines(organizationId, data): Observable<any> {
    return this._fsApi.delete(`organizations/${organizationId}/products/lines/${data.id}`, data, { key: 'productLine' });
  }

  public bulkDeleteProductLines(productLineIds: number[], query = {}): Observable<any> {
    return this._fsApi.delete(`products/lines/bulk`, { productLineIds }, { key: null })
  }

  public saveTasting(organizationId: number, tasting: Tasting, config = {}): Observable<any> {
    return this._fsApi.put(`organizations/${organizationId}/tastings`, tasting, Object.assign({ key: 'tasting' }, config));
  }

  public createWineAgingMethod(data: Attribute = { id: null }): Attribute {
    // thank you BE :(
    data.objectConfigs = data.configs && data.configs.months && (data.objectConfigs && !data.objectConfigs.months)
      ? { months: data.configs['months'] }
      : data.objectConfigs && data.objectConfigs.months
        ? { months: data.objectConfigs['months'] }
        : { months: null };
    data.class = 'wine_aging';
    return data;
  }

  public getWineAgingMethod(wineId: number, id: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `objects/${wineId}/attributes/${id}`, data, Object.assign({ key: 'attribute' }, config));

  }

  public getsWineAgingMethods(wineId: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `objects/${wineId}/attributes`, data, Object.assign({ key: 'attributes' }, config));

  }

  public putWineAgingMethod(wineId: number, data, config = {}): Observable<any> {
    return this._fsApi.put(`objects/${wineId}/attributes/${data.id}`,
      data, Object.assign({ key: 'attribute' }, config));
  }

  public saveWineAgingMethod(wineId: number, data): Observable<any> {
    // if (data.id) {
      return this.putWineAgingMethod(wineId, data);
    // }
    // return this.postWineAgingMethod(wineId, data);
  }

  public orderAgingMethods(wineId: number, data): Observable<any> {
    return this._fsApi.put(`objects/${wineId}/attributes/order`, data, { key: null });
  }

  public deleteWineAgingMethod(wineId: number, agingMethod): Observable<any> {
    return this._fsApi.delete(`objects/${wineId}/attributes/${agingMethod.id}`, agingMethod, { key: 'attribute' });
  }

  public createVariety(data: Variety = { id: null }): Variety {
    data.class = 'wine_variety';
    return data;
  }

  public getVariety(wineId: number, id: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `wines/${wineId}/varieties/${id}`, data, Object.assign({ key: 'wineVariety' }, config));
  }

  public getsVarieties(wineId: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', `wines/${wineId}/varieties`, data, Object.assign({ key: 'wineVarieties' }, config));

  }

  public putVariety(wineId: number, data, config = {}): Observable<any> {
    return this._fsApi.put(`wines/${wineId}/varieties/${data.id}`,
      data, Object.assign({ key: 'wineVariety' }, config));
  }

  public postVariety(wineId: number, data): Observable<any> {
    return this._fsApi.post(`wines/${wineId}/varieties`, data, { key: 'wineVariety' });
  }

  public saveVariety(wineId: number, data): Observable<any> {
    if (data.id) {
      return this.putVariety(wineId, data);
    }
    return this.postVariety(wineId, data);
  }

  public orderVarieties(wineId: number, data): Observable<any> {
    return this._fsApi.put(`wines/${wineId}/varieties/order`, data, { key: null });
  }

  public updateVarietyMode(wineId: number, mode: WineVarietyMode): Observable<any> {
    return this._fsApi.put(`wines/${wineId}/varieties/mode`, { varietyMode: mode }, { key: 'wine' });
  }

  public deleteVariety(wineId: number, agingMethod): Observable<any> {
    return this._fsApi.delete(`wines/${wineId}/varieties/${agingMethod.id}`, agingMethod, { key: 'wineVariety' });
  }


  public importConfig(query = {}): Observable<any> {
    return this._fsApi.get(`organizations/import_config`, query, { key: 'config' });
  }

  public importSample(data = {}, config = {}) {
    return this._transfer.post(`api/organizations/import_sample`);
  }

  public import(data, config = {}): Observable<any> {
    return this._fsApi.post(`organizations/import_results`, data, Object.assign({ key: 'result' }, config));
  }

  public recalculateOrganizationStatus(organization: Organization): StatusOrganization {

    const openDate = date(organization.openDate);
    const closedDate = date(organization.closedDate);

    if (isBefore(closedDate, new Date())) {
      return StatusOrganization.ClosedPermanently;
    } else if (isAfter(openDate, new Date())) {
      return StatusOrganization.ComingSoon;
    }

    const isClosed = !!organization.seasons.find(season =>
      season.seasonalStatus === 'closed' && isWithinInterval(new Date(), { start: date(season.startDate), end: date(season.endDate)})
    );

    if (isClosed) {
      return StatusOrganization.ClosedSeason;
    } else {
      return StatusOrganization.Open;
    };
  }

  public recalculateWineStatus(wine: Wine): StatusWine {

    const releaseDate = date(wine.releaseDate);
    const soldOutDate = date(wine.soldOutDate);

    if (isBefore(soldOutDate, new Date())) {
      return StatusWine.SoldOut;
    } else if (isAfter(releaseDate, new Date())) {
      return StatusWine.ComingSoon;
    }

    return StatusWine.Available;
  }

  public recalculateExperienceStatus(experience: Experience): StatusExperience {

    const openDate = date(experience.openDate);
    const closedDate = date(experience.closedDate);

    if (isBefore(closedDate, new Date())) {
      return StatusExperience.Closed;
    } else if (isAfter(openDate, new Date())) {
      return StatusExperience.ComingSoon;
    }

    return StatusExperience.Open;
  }

  public validateDates(startDate, endDate) {
    return new Observable(observer => {
      startDate = date(startDate);
      endDate = date(endDate);

      if (startDate && endDate && isAfter(startDate, endDate)) {
        // Datepicker reset on date change. If it was null & reset, change not fire and dialog doesn't clear
        setTimeout(() => {
          observer.error();
          observer.complete();
        });
        return;
      }

      observer.next();
      observer.complete();
    });

  }

  private _transform() {
    return map((item: Organization) => this.create(item));
  }

}
