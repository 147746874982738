<div *ngIf="item.isCollection" class="subheading-2">
  Created by
  <ng-template [ngIf]="item.collection.isOwnerTaster" [ngIfElse]="ownerOrganization">
    {{ ownerTasterName }}
  </ng-template>
  
  <!--<ng-container *ngIf="item.createDate">on {{ item.createDate | fsDate }}</ng-container>-->

  <ng-template #ownerOrganization>
    <a [routerLink]="['/organizations', item.collection.ownerObject.id]">&nbsp;{{ item.collection.ownerObject?.name }}&nbsp;</a>
  </ng-template>
</div>
