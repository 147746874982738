import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ItemType } from '@firestitch/filter';
import { FsGalleryComponent, FsGalleryConfig, FsGalleryItem, GalleryThumbnailSize } from '@firestitch/gallery';

import { AssetService } from 'app/core/services';
import { Asset, Experience, Organization } from 'app/shared/interfaces';


@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
})
export class MediaGalleryComponent implements OnInit, OnDestroy {

  @Input() type: 'organization' | 'experience' = null;
  @Input() sourceObject: Organization | Experience;
  @Input() environmentId: number;

  @ViewChild(FsGalleryComponent, { static: false })
  public gallery: FsGalleryComponent = null;

  public config: FsGalleryConfig = null;

  private _destroy$ = new Subject();

  constructor(
    private _assetService: AssetService,
  ) { }

  public ngOnInit() {
    this.initGalleryConfig();
  }

  private initGalleryConfig() {
    this.config = {
      allow: 'image/*, video/*, application/*',
      thumbnail: {
        size: GalleryThumbnailSize.Cover,
        width: 300,
        heightScale: .4,
      },
      map: (data) => {
        const file = data.file || { preview: {} };
        return {
          name: data.name,
          preview: file.preview.small,
          url: file.preview.actual,
          index: data.id
        };
      },
      filters: [
        {
          name: 'keyword',
          type: ItemType.Keyword,
          label: 'Search'
        },
      ],
      info: {
        icon: true,
        menu: {
          items: [
            {
              label: 'Delete',
              click: item => {
                this.onDeleteImage(item);
              }
            }
          ]
        }
      },
      fetch: (query) => {
        query.files = true;
        query.order = 'order';
        query.objectId = this.sourceObject.id; // loading images depends on context
        query.environmentId = this.environmentId;

        return this._assetService.gets(query)
          .pipe(
            takeUntil(this._destroy$)
          )
      },
      upload: {
        select: (files) => { return this.uploadImage(files) }
      },
      reorderEnd: (files: any) => {
        const assetIds = files
          .map((file) => file.data.id)
          .join(',');

        this._assetService
          .order({
            objectId: this.sourceObject.id,
            assetIds
          })
          .subscribe(() => { });
      },
    };
  }

  public uploadImage(files: any) {
    if (files && !Array.isArray(files)) {
      files = [files];
    }

    const assets$ = [];

    files.forEach((fsFile) => {
      const data: Asset = { id: null };
      data.objectId = this.sourceObject.id;
      data.environmentId = this.environmentId;

      data.file = fsFile.file;

      assets$.push(this._assetService.save(data));
    });

    return forkJoin(...assets$);
  }

  public onDeleteImage(file: Asset | FsGalleryItem) {
    this._assetService.delete(file)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.gallery.reload();
      });
  }

  public onReorderImages(items: FsGalleryItem[]) {
    const asset_ids = items
    .map((item) => {
      return item.data.id;
    })
    .join(',');

    this._assetService.order({ asset_ids })
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(() => { });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
