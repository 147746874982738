import { Component, EventEmitter, Inject, Input, Output, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { isObject, isEqual } from 'lodash';

import { COUNTRIES } from '@firestitch/address';
import { filter, find } from '@firestitch/common';


export interface Country {
  code: string;
  name: string;
  regions?: CountryRegion[];
}

export interface CountryRegion {
  code: string;
  name: string;
}


@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CountryComponent implements OnInit {

  @Input() public data: any = null;
  @Output() public dataChange = new EventEmitter();

  public selectedCountry: Country = null;
  public selectedRegion: CountryRegion = null;

  public countries: Country[] = [];
  public countryRegions: CountryRegion[] = [];
  public regions: CountryRegion[] = [];

  public displayFn = (data => {

    if (!data) {
      return '';
    }
    return `${data.name}`;

  }).bind(this);

  public validationFunction = ((formControl) => {
    if (formControl.value && !formControl.value.code) {
      throw 'Doesn\'t have a code';
    }
  }).bind(this);

  constructor(
    @Inject(COUNTRIES) private _countries
  ) { }

  public ngOnInit() {
    if (this.data.country) {
      this.countries = this._countries;
      this.loadCountries(find(this.countries, { code: this.data.country }));
      if (this.data.province && this.countryRegions.length) {
        this.loadRegions(find(this.countryRegions, { code: this.data.province }));
      }
    }
  }

  public loadCountries($event: any): void {
    this.countries = [];

    if (!$event) {
      this.countrySelected(null);
      return;
    }

    if (!isObject($event)) {
      this.countries = filter(this._countries, country => {
        return country.name.match(new RegExp($event, 'i'));
      });
    } else {
      this.countrySelected($event);
    }
  }

  private countrySelected(data: Country): void {
    this.selectedCountry = data;
    this.data.country = data ? data.code : null;
    this.clearRegion();
    if (this.selectedCountry) {
      this.countryRegions = this.selectedCountry.regions || [];
    }

    this.dataChange.emit(this.data);
  }

  public loadRegions($event: any): void {
    this.regions = [];

    if (!$event) {
      this.regionSelected(null);
      return;
    }

    if (!isObject($event)) {
      this.regions = filter(this.countryRegions, region => {
        return region.name.match(new RegExp($event, 'i'));
      });
    } else {
      this.regionSelected($event);
    }
  }

  private regionSelected(data: CountryRegion): void {
    this.selectedRegion = data;
    this.data.province = data ? data.code : null;
    this.dataChange.emit(this.data);
  }

  private clearRegion() {
    this.countryRegions = [];
    this.regions = [];
    this.selectedRegion = null;
  }

}
