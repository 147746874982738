import { Account } from './account';
import { Organization } from './organization';
import { AclEntry } from './acl-entry';
import { AclRole } from './acl-role';


export interface EnvironmentAccount {
  readonly id: number;
  accountId?: number;
  account?: Account;
  environmentId?: number;
  environment?: Organization;
  createDate?: string;
  state?: string;
  signinDate?: string;
  aclEntries?: AclEntry[];
  aclRoles?: AclRole[];
}
