<fs-dialog>
  <h1 mat-dialog-title>Organizations: Upload</h1>
  <mat-dialog-content>

    <p>Upload a CSV file of Organizations, following the same format as the sample file available below.  All of the listed columns must be included in the uploaded file, but the column order does not matter.</p>

    <p>The system detects duplicates based on Name.  If an uploaded organization has the same Name as an existing organization, then that organization will be skipped.  All skipped or failed rows will be displayed in a message after upload.</p>

    <fs-import [config]="config" #fsImport>
      <ng-template fsImportConfigFooter></ng-template>
      <ng-template fsImportResultFooter></ng-template>
    </fs-import>

  </mat-dialog-content>
  <mat-dialog-actions>

    <div fxLayout="row" fxLayoutGap="5px" md.fxLayout="column">
      <ng-container *ngIf="fsImport.mode === 'config'">
        <fs-file (select)="import($event)">
            <button mat-raised-button color="primary">Upload File</button>
        </fs-file>
        <button mat-raised-button color="primary" (click)="sample()">Download Sample File</button>
        <button mat-raised-button (click)="close()">Done</button>
      </ng-container>
      <ng-container *ngIf="fsImport.mode === 'processing'">
        <button mat-raised-button (click)="close()">Done</button>
      </ng-container>
      <ng-container *ngIf="fsImport.mode === 'result'">
        <button mat-raised-button color="primary" (click)="reset()">Upload Again</button>
        <button mat-raised-button (click)="close()">Done</button>
      </ng-container>
    </div>
  </mat-dialog-actions>
</fs-dialog>

<!--
<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>Create Organization</h1>
    <mat-dialog-content>
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Name</mat-label>
<input matInput [(ngModel)]="organization.name" name="name" fsFormRequired>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Organization Type<mat-label>
        <mat-select
            [(ngModel)]="organization.type"
            
            name="type"
            fsSelect
            fsFormRequired>
              <mat-select-trigger *ngIf="organization.type">
                <ng-container *ngTemplateOutlet="template;context:{ model: organization.type }"></ng-container>
              </mat-select-trigger>

              <mat-option *ngFor="let type of types" [value]="type">
                <ng-container *ngTemplateOutlet="template;context:{ model: type }"></ng-container>
              </mat-option>

              <ng-template #template let-model="model">
                <img *ngIf="model.image" [src]="model.image.small" class="avatar">
                <span>{{ model.name }}</span>
              </ng-template>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="submit" color="primary">CREATE</button>
      <button mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form> -->
