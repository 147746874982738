<fs-dialog>
  <div mat-dialog-title>Switch Organization</div>
  <mat-dialog-content>
    <div fxLayout="column" class="environment-items">
      <div
        class="environment-item"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngFor="let environment of environments"
        (click)="switch(environment)">
          <fs-badge shape="circle" [image]="environment.image?.small"></fs-badge>
          <span>{{ environment.name }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button color="primary" mat-button (click)="close()">DONE</button>
  </mat-dialog-actions>
</fs-dialog>
