<ng-container *ngIf="
  organization &&
  organization.type &&
  organization.tasting &&
  organization.tastingRoom &&
  organization.type.featureTastingRoom">

  <div *ngIf="organization.tasting.winesIncluded === 'all'
        && (organization.tasting.tastingFee || organization.tasting.tastingFee === 0)" class="price">
    <b> {{ organization.tasting.tastingFee | fsFormatCurrency: 2 }} </b>
  </div>

  <div *ngIf="organization.tasting.winesIncluded === 'fixed'
              && (organization.tasting.tastingFee || organization.tasting.tastingFee === 0)
              && organization.tasting.numberOfWines" class="price">
    <b> {{ organization.tasting.tastingFee | fsFormatCurrency: 2 }} </b>
    for choice of {{ organization.tasting.numberOfWines }} {{ organization.tasting.numberOfWines > 1 ? 'wines' : 'wine' }}
   </div>

</ng-container>
