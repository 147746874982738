import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ThemeVariable } from '../consts';
import { IThemeData } from '../interfaces';


@Injectable({
  providedIn: 'root',
})
export class ThemingService {

  private _themeData$ = new BehaviorSubject<IThemeData>(null);

  public get themeData$() {
    return this._themeData$.asObservable();
  }

  public get themeData() {
    return this._themeData$.getValue();
  }

  constructor(private _http: HttpClient) { }

  public load(): Observable<void> {

    let url = '/api/settings/branding';
    if (environment.platform === 'app' && environment.apiDomain) {
      url = ('https://' + environment.apiDomain).concat(url);
    }

    return this._http.get<any>(url, { headers: { 'Content-Case': 'camel' } })
      .pipe(
        tap((response) => {
          this._themeData$.next(response.data.settings);
        }),
      );
  }

  public updateThemeVariables(variables: IThemeData) {
    this._themeData$.next(variables);
    this._clear();
    this.init();
  }

  public init(): void {

    const link = window.document.querySelector('link[rel="icon"]');
    const titleDom = window.document.querySelector('title');
    const settings: IThemeData = this.themeData;
    if (settings) {
      if (link) {
        link.setAttribute('href', settings.brandingFavIconImage);
      }
      if (titleDom) {
        titleDom.innerHTML = settings.appName;
      }

      this.setStyle(ThemeVariable.Primary, settings.brandingPrimaryColor);
      this.setImageStyle(ThemeVariable.ColorLogo, settings.brandingLogoColor);
      this.setImageStyle(ThemeVariable.WhiteLogo, settings.brandingLogoWhite);
      this.setStyle(ThemeVariable.Secondary, settings.brandingSecondaryColor);
      this.setStyle(ThemeVariable.TriedIt, settings.brandingTriedItColor);
      this.setStyle(ThemeVariable.Collection, settings.brandingCollectionColor);
      this.setStyle(ThemeVariable.Awards, settings.brandingAwardColor);
      this.setStyle(ThemeVariable.Wine, settings.brandingWineColor);
      this.setStyle(ThemeVariable.WineClub, settings.brandingWineClubColor);
      this.setStyle(ThemeVariable.Top10, settings.brandingTop10Color);
      this.setStyle(ThemeVariable.FlagColor050, settings.branding050FlagColor);

      this.setStyle(ThemeVariable.FlagColor5160, settings.branding5160FlagColor);
      this.setStyle(ThemeVariable.FlagColor6170, settings.branding6170FlagColor);
      this.setStyle(ThemeVariable.FlagColor7180, settings.branding7180FlagColor);
      this.setStyle(ThemeVariable.FlagColor8190, settings.branding8190FlagColor);
      this.setStyle(ThemeVariable.FlagColor91100, settings.branding91100FlagColor);
      this.setStyle(ThemeVariable.FlagColorUnknown, settings.brandingUnknownFlagColor);
      this.setImageStyle(ThemeVariable.BackgroundImage, settings.brandingBackgroundImage);
    }
  }

  public setStyle(name: string, value: string): void {
    if (value) {
      document.body.style.setProperty('--' + name, value);
    }
  }

  public setImageStyle(name: string, value: string): void {
    if (value) {
      this.setStyle(name, `url(${value})`);
    }
  }

  private _clear() {
    Object.keys(ThemeVariable).forEach((key) => {
      this.setStyle(ThemeVariable[key], null);
    })
  }

}
