import { Component, Input, OnInit } from '@angular/core';

import { Collection } from '../../../shared/interfaces';


@Component({
  selector: 'app-collection-image',
  templateUrl: `./collection-image.component.html`,
  styleUrls: ['./collection-image.component.scss']
})
export class CollectionImageComponent implements OnInit {

  @Input() public collection: Collection = null;

  constructor() { }

  public ngOnInit() {

  }

}
