<div class="search" fxLayout="column">
  <fs-filter [filter]="config" #filter></fs-filter>
  <a *ngIf="advancedSearch" (click)="viewAll()"
     class="advanced-search" fxLayoutAlign="end start">Advanced Search</a>
</div>
<div class="classes">
  <ng-container *ngFor="let class of objectClasses">
    <div *ngIf="objects[class.value].length" class="object-class">
      <div class="class" fxLayout="row" fxLayoutAlign="start center">
        <span class="name" fxFlex>{{ recentHistory ? 'Recent ' : '' }}{{ class.name }}s</span>
        <a *ngIf="!recentHistory" (click)="viewAll(class)" class="view-all"><small>View All</small></a>
      </div>
      <div *ngFor="let object of objects[class.value]" class="object" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-checkbox *ngIf="selectable" (change)="selectEntity($event, object)" [checked]="$any(object).selected"></mat-checkbox>
        <fs-badge *ngIf="object?.project?.image.small" size="35" shape="circle" [image]="object.project.image.small"></fs-badge>
        <div class="details" fxLayout="column" fxLayoutAlign="center start">
          <a class="name" (click)="navigateTo(object)">{{ object.name }}</a>
          <div class="identifier" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="classesIndexed[object.class]" [ngStyle]="{ color: classesIndexed[object.class].color }">{{ classesIndexed[object.class].icon }}</mat-icon>
          </div>
        </div>
        <div fxFlex></div>
      </div>
    </div>
  </ng-container>
</div>
