import { Component, Input, OnInit } from '@angular/core';

import { list } from '@firestitch/common';

import { OrganizationService } from 'app/core/services';
import { Experience } from 'app/shared/interfaces';


@Component({
  selector: 'app-experience-price',
  templateUrl: './experience-price.component.html',
  styleUrls: ['./experience-price.component.scss']
})
export class ExperiencePriceComponent implements OnInit {

  @Input() public experience: Experience = null;

  public pricingModelsFlatten: any = {};

  constructor(
    private _organizationService: OrganizationService
  ) { }

  public ngOnInit() {
    this.pricingModelsFlatten = list(this._organizationService.experiencePricingModels, 'name', 'value');
  }

}
