import { Component } from '@angular/core';


@Component({
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent {

  back() {
    alert('Back Clicked!');
  }

}
