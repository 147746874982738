import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Angulartics2 } from 'angulartics2';

import { Organization, Region, Subregion } from '@app/shared/interfaces';
import { RegionService } from '@app/core/services/region.service';


@Component({
  templateUrl: './tasting-dialog.component.html',
  styleUrls: ['./tasting-dialog.component.scss']
})
export class TastingDialogComponent implements OnInit {

  public regions: Region[] = [];
  public subregions: Subregion[] = [];

  public region: Region = null;
  public subregion: Subregion = null;
  public organization: Organization = null;

  public organizationQueryData: any = { producedWine: true };

  constructor(
    private _router: Router,
    private _regionService: RegionService,
    private angulartics2: Angulartics2,
    private _dialogRef: MatDialogRef<TastingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  public ngOnInit() {
    this._regionService.gets({ subregions: true })
      .subscribe(response => this.regions = response);
  }

  public save() {
    this.close();
    this._router.navigate(['/organizations', this.organization.id, 'wines']);
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public onRegionChange(region: Region) {
    this.subregions = region && region.subregions ? region.subregions : [];
    this.subregion = null;
    this.organization = null;
    this._updateOrganizationQueryData();
  }

  public onSubRegionChange(subregion: Subregion) {
    this.organization = null;
    this._updateOrganizationQueryData();
  }

  public onOrganizationChange(organization: Organization) {
    this.organization = organization;
  }

  private _updateOrganizationQueryData() {
    this.region ?
      this.organizationQueryData.regionId = this.region.id :
      delete(this.organizationQueryData.regionId);

    this.subregion ?
      this.organizationQueryData.subregionId = this.subregion.id :
      delete(this.organizationQueryData.subregionId);
  }

}
