import { ObjectClass } from '../enums';

import { IObjectClass } from './../interfaces/object-class';

export const ObjectClasses: IObjectClass[] = [
  {
    name: 'Task',
    value: ObjectClass.Task,
    icon: 'assignment',
    abr: 'T',
    color: '#00cc33',
    searchable: true,
    pluralName: 'Tasks',
  },
  {
    name: 'Doc',
    value: ObjectClass.Doc,
    icon: 'description',
    abr: 'D',
    color: '#0266ff',
    searchable: true,
    pluralName: 'Docs',
  },
  {
    name: 'Account',
    value: ObjectClass.Account,
    icon: 'person',
    abr: 'A',
    pluralName: 'Accounts',
  },
  {
    name: 'Address',
    value: ObjectClass.Address,
    icon: 'person',
    pluralName: 'Addresses',
  },
  {
    name: 'Mockup',
    value: ObjectClass.AssetImage,
    parentClass: 'asset',
    icon: 'photo',
    abr: 'M',
    color: '#ff0100',
    searchable: true,
    pluralName: 'Mockups',
  },
  {
    name: 'File',
    value: ObjectClass.AssetFile,
    parentClass: 'asset',
    icon: 'insert_drive_file',
    abr: 'F',
    pluralName: 'Files',
  },
  {
    name: 'Video',
    value: ObjectClass.AssetVideo,
    parentClass: 'asset',
    icon: 'videocam',
    abr: 'V',
    color: '#232B99',
    pluralName: 'Videos',
  },
  {
    name: 'Data Table',
    value: ObjectClass.DataObjectTable,
    icon: 'storage',
    abr: 'X',
    color: '#7A297B',
    searchable: true,
    pluralName: 'Data Tables',
  },
  {
    name: 'Comment',
    value: ObjectClass.Comment,
    pluralName: 'Comments',
  },
  {
    name: 'Component',
    value: ObjectClass.Component,
    icon: 'extension',
    abr: 'C',
    color: '#DB334F',
    searchable: true,
    pluralName: 'Components',
  },
  {
    name: 'File',
    value: ObjectClass.File,
    pluralName: 'Files',
  },
  {
    name: 'Plan',
    value: ObjectClass.Plan,
    icon: 'assignment_turned_in',
    abr: 'P',
    color: '#811FF0',
    pluralName: 'Plans',
  },
  {
    name: 'Project',
    value: ObjectClass.Project,
    icon: 'folder',
    color: '#0061AF',
    pluralName: 'Projects',
  },
  {
    name: 'Scenario',
    value: ObjectClass.Scenario,
    icon: 'power',
    abr: 'S',
    color: '#E6DC21',
    searchable: true,
    pluralName: 'Scenarios',
  },
  {
    name: 'Tag',
    value: ObjectClass.Tag, icon:
      'person',
    pluralName: 'Tags',
  },
  {
    name: 'Type',
    value: ObjectClass.Type,
    icon: 'person',
    pluralName: 'Types',
  },
  {
    name: 'Status',
    value: ObjectClass.Status,
    icon: 'person',
    pluralName: 'Statuses',
  },
];

