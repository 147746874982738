import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';

import { Type } from '../../shared/interfaces';
import { TypeClasses, TypeReferences } from 'app/shared/types';


@Injectable()
export class TypeService {

  public classes = [
    { name: 'Organization', value: TypeClasses.Organization },
    { name: 'Experience', value: TypeClasses.Experience },
    { name: 'Collection', value: TypeClasses.Collection }
  ];

  public references = [
    { name: 'General', value: TypeReferences.General },
    { name: 'Wishlist', value: TypeReferences.Wishlist }
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: Type = { id: null }): Type {
    data.class = data.class || TypeClasses.Organization;
    data.configs = data.configs && data.configs.settings ? data.configs : { settings: [] };
    data.icon = data.icon || 'pin_drop';
    data.color = data.color || '#000000';
    return data;
  }

  public get(typeId: number, query = {}): Observable<any> {
    return this._fsApi.get(`types/${typeId}`, query, { key: 'type' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'types', data, Object.assign({ key: 'types' }, config));
  }

  public put(type: Type, config = {}): Observable<any> {
    return this._fsApi.put(`types/${type.id}`, type, Object.assign({ key: 'type' }, config));
  }

  public post(type): Observable<any> {
    return this._fsApi.post('types', type, { key: 'type' });
  }

  public image(type: Type, file): Observable<any> {
    return this._fsApi.post(`types/${type.id}/image`, { file: file }, { key: 'type' });
  }

  public delete(type: Type): Observable<any> {
    return this._fsApi.delete(`types/${type.id}`, type, { key: 'type' });
  }

  public mapImage(type: Type, file): Observable<any> {
    return this._fsApi.post(`types/${type.id}/mapimage`, { file: file }, { key: 'type' });
  }

  public mapImageDelete(type: Type): Observable<any> {
    return this._fsApi.delete(`types/${type.id}/mapimage`, { key: 'type' });
  }

  public save(data: Type): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('types/order', data, { key: null });
  }

}
