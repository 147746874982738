<ng-container *ngIf="item">
  <h2 *ngIf="title">{{ title }}</h2>
  <div class="content">
    <fs-attributes
      [class]="type"
      [hidden]="hiddenAttribute"
      [data]="{ objectId: item.id }"
      (dataReceived)="dataReceived($event)">
      <ng-template fsAttributeTemplate let-attribute="attribute">
        <div class="main-title">{{ attribute.name }}</div>
        <ng-container *ngIf="type === 'wine_aging'">
          <ng-container *ngIf="attribute.configs && attribute.configs.months">
            <div class="sub-title">
              {{ attribute.configs.months }} {{ attribute.configs.months > 1 ? 'months' : 'month' }}
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </fs-attributes>
  </div>
</ng-container>
