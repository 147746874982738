import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';
import { FsMessage } from '@firestitch/message';

@Injectable()
export class InviteService {

  constructor(private _fsApi: FsApi,
              private _fsMessage: FsMessage,
              private _router: Router) { }

  public valid(data, options: any = {}): Observable<any> {
    return this._fsApi.post('invites/valid', data, Object.assign({ key: null }, options));
  }

  public email(data): Observable<any> {
    return this._fsApi.post('invites/email', data, { key: 'exists' });
  }

  public signin(data): Observable<any> {
    return this._fsApi.post('invites/signin', data, { key: null });
  }

  public signupEmail(guid, account): Observable<any> {
    return this._fsApi.post('invites/signup/email', { account: account, guid: guid }, { key: null });
  }

  public signupFacebook(guid, accessToken, account): Observable<any> {
    const data = {
      accessToken: accessToken,
      account: account,
      guid: guid
    };
    return this._fsApi.post('invites/signup/facebook', data, { key: null });
  }

  public signupGoogle(guid, idToken, accessToken, account): Observable<any> {
    const data = {
      idToken: idToken,
      accessToken: accessToken,
      account: account,
      guid: guid
    };
    return this._fsApi.post('invites/signup/google', data, { key: null });
  }

  public token(data): Observable<any> {
    return this._fsApi.post('invites/token', data, { key: null });
  }

  public resend(data): Observable<any> {
    return this._fsApi.post('invites/resend', data, { key: null });
  }

  public completeInvite(account, scope) {
    this._fsMessage.success(
      `Welcome, ${account.firstName} ${account.lastName}. You now have access to ${scope}`
    );
    this._router.navigateByUrl(`/`);
  }

}
