import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  FS_ATTRIBUTE_FIELD_DATA,
} from '@firestitch/attribute';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
  templateUrl: 'edit-flavour-profile-description.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class AttributeEditFlavourProfileDescriptionComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(FS_ATTRIBUTE_FIELD_DATA) public data,
  ) {}

  ngOnInit() {
    if (!this.data.attribute.configs) {
      this.data.attribute.configs = {};
    }
  }

  ngOnDestroy() {}
}
