import { Project } from './project';
import { Account } from './account';
import { Section } from './section';
import { Type } from './type';
import { Attribute } from './attribute';
import { Organization } from './organization';


export interface Asset {
  id: number;
  organization?: Organization;
  organizationId?: number;
  objectId?: number;
  environmentId?: number;
  createAccountId?: number;
  createAccount?: Account;
  modifierAccountId?: number;
  modifierAccount?: Account;
  sectionId?: number;
  section?: Section;
  fileId?: number;
  file?: any;
  typeId?: number;
  type?: Type;
  tags?: Attribute[];
  state?: string;
  number?: number;
  order?: number;
  modifyDate?: string;
  name?: string;
  class?: string;
  content?: string;

  // deprecated fields
  /**
   *  @deprecated
   */
  projectId?: number;

  /**
   *  @deprecated
   */
  project?: Project;
}
