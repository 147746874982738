<app-header></app-header>
<div class="app-container" name="app-container" [style.padding-bottom]="contentPaddingBottom">
  <div class="app-content">
    <div class="app-scroll-content">
      <div class="container container-side-nav">
        <mat-sidenav-container>
          <mat-sidenav
            [mode]="sidenavService.mode"
            [opened]="sidenavService.opened"
            [fixedTopGap]="sidenavService.topGap"
            (closedStart)="closedStart()"
            [fixedInViewport]="true">

              <div class="sidenav-container">

              <app-logo type="light" width="125"></app-logo>

              <div class="account">
                <account-menu [account]="account"></account-menu>
              </div>

              <fs-sidenav [config]="sideNavMain" *ngIf="sideNavMain" (click)="clickNav($event)"></fs-sidenav>
              <div class="spacer"></div>
              <fs-sidenav [config]="sideNavManagement" *ngIf="account && lastPortal === LastPortal.Management && sideNavManagement" (click)="clickNav($event)"></fs-sidenav>
              <fs-sidenav [config]="sideNavTaster" *ngIf="account && lastPortal === LastPortal.Taster && sideNavTaster" (click)="clickNav($event)"></fs-sidenav>
              <fs-sidenav [config]="sideNavAdmin" *ngIf="account && lastPortal === LastPortal.Admin &&sideNavAdmin" (click)="clickNav($event)"></fs-sidenav>
              <fs-sidenav [config]="sideNavAuth" *ngIf="sideNavAuth" (click)="clickNav($event)"></fs-sidenav>
            </div>

          </mat-sidenav>
          <mat-sidenav-content>
            <app-taster-banner></app-taster-banner>
            <app-body-navbar></app-body-navbar>
            <router-outlet></router-outlet>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>
    <fs-pane name="bottom" class="bottom-pane"></fs-pane>
    <fs-pane name="footer" class="footer-pane"></fs-pane>
  </div>
</div>

