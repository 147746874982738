import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FsMessage } from '@firestitch/message';

import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { QuestionService, AuthService } from '@app/core/services';
import { Question, Account } from '@app/shared/interfaces';

import { ConfirmComponent } from '../confirm';


@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class AskQuestionFormComponent implements OnInit, OnDestroy {

  public question: Question = {};

  private _destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _dialogRef: MatDialogRef<AskQuestionFormComponent>,
    private _dialog: MatDialog,
    private _questionService: QuestionService,
    private _authService: AuthService,
    private _fsMessage: FsMessage,
  ) { }

  public ngOnInit(): void {
    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((store) => {
        if (store && store.value) {
          const account: Account = store.value;
          Object.assign(this.question, {
            name: account.firstName,
            lastName: account.lastName,
            email: account.email,
          });
        }
      });
  }

  public save = (): Observable<any> => {
    return this._questionService.post(this.question)
      .pipe(
        tap((response) => {
          this._fsMessage.success('Saved Changes');
          this._openConfirm();
          this._dialogRef.close(response);
        })
      );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _openConfirm(): void {
    const dialogRef = this._dialog.open(ConfirmComponent, {
      data: { }
    });

    dialogRef.afterClosed()
      .subscribe(response => { });
  }

}
