import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';

import { QuizCompleteActionPaneComponent } from './components/complete-action-pane';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        QuizCompleteActionPaneComponent,
    ],
    exports: [
        QuizCompleteActionPaneComponent
    ]
})
export class QuizCompleteActionPaneModule { }
