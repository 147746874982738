import { Component, Input, OnInit } from '@angular/core';

import { list } from '@firestitch/common';

import { OrganizationService } from 'app/core/services';
import { StatusOrganization } from 'app/shared/types';


@Component({
  selector: 'app-organization-status-pill',
  templateUrl: './organization-status-pill.component.html',
  styleUrls: ['./organization-status-pill.component.scss']
})
export class OrganizationStatusPillComponent implements OnInit {

  @Input() public status: StatusOrganization = null;

  public statesNameFlatten: any = {};
  public stateColorFlatten: any = {};

  constructor(
    private _organizationService: OrganizationService
  ) { }

  public ngOnInit() {
    this.statesNameFlatten = list(this._organizationService.statusesOrganization, 'name', 'value');
    this.stateColorFlatten = list(this._organizationService.statusesOrganization, 'color', 'value');
  }

}
