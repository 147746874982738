import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';


@Injectable({
  providedIn: 'root',
})
export class UploadData {

  constructor(private _fsApi: FsApi) { }

  public image(file): Observable<any> {
    return this._fsApi.post(`upload/image`, { file }, { key: 'url' });
  }

}
