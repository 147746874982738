<form>
  <fs-dialog mobileMode="bottom">
    <h1 mat-dialog-title>Add To List or Route</h1>

    <div class="fixed-label" fxLayout="row" fxLayoutAlign="space-between">
      <fs-label-field>
        <fs-label>Lists & Routes</fs-label>
      </fs-label-field>
      <a (click)="openCollectionDialog()">
        <mat-icon>add</mat-icon>
        <b>New</b>
      </a>
    </div>

    <mat-dialog-content>
      <fs-label-field>
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutGap="5px" *ngFor="let collectionItem of collectionItems">
            <item-card-view [item]="collectionItem"></item-card-view>
            <button *ngIf="!collectionItem.objectAddedToList" mat-raised-button color="primary" (click)="addToList(collectionItem)">Add</button>
            <button *ngIf="collectionItem.objectAddedToList" mat-raised-button disabled>Added</button>
          </div>
        </div>
      </fs-label-field>
    </mat-dialog-content>
  </fs-dialog>
</form>
