import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({ providedIn: 'root' })
export class IconsFactory {
  public icons = [];

  constructor(
    private _matIconRegistory: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
  ) {}

  public init() {
    this._setConfigIcons();
    this._registration();
  }

  private _setConfigIcons() {
    this.icons = [
      {
        name: 'item-share',
        path: 'assets/icons/share.svg'
      },
      {
        name: 'item-tried-it',
        path: 'assets/icons/tried_it.svg'
      },
      {
        name: 'item-add-to-list',
        path: 'assets/icons/add_to_list.svg'
      },
      {
        name: 'item-rate',
        path: 'assets/icons/rate.svg'
      },
      {
        name: 'award-circle',
        path: 'assets/icons/award_circle.svg'
      },
      {
        name: 'award',
        path: 'assets/icons/award.svg'
      },
      {
        name: 'for-you',
        path: 'assets/icons/for_you.svg'
      },
      {
        name: 'for-you-burgundy',
        path: 'assets/icons/for_you_burgundy.svg'
      },
      {
        name: 'explore',
        path: 'assets/icons/explore.svg'
      },
      {
        name: 'explore-burgundy',
        path: 'assets/icons/explore_burgundy.svg'
      },
      {
        name: 'tastings',
        path: 'assets/icons/tastings.svg'
      },
      {
        name: 'tastings-burgundy',
        path: 'assets/icons/tastings_burgundy.svg'
      },
      {
        name: 'my-cellar',
        path: 'assets/icons/my_cellar.svg'
      },
      {
        name: 'my-cellar-burgundy',
        path: 'assets/icons/my_cellar_burgundy.svg'
      },
      {
        name: 'notes',
        path: 'assets/icons/notes.svg'
      },
      {
        name: 'notes-circle',
        path: 'assets/icons/notes_circle.svg'
      },
      {
        name: 'flavour',
        path: 'assets/icons/flavour_circle.svg'
      },
      {
        name: 'finishes',
        path: 'assets/icons/finishes_circle.svg'
      },
      {
        name: 'collection-circle',
        path: 'assets/icons/collection_circle.svg'
      },
      {
        name: 'wine-circle',
        path: 'assets/icons/wine_circle.svg'
      },
      {
        name: 'wineclub-circle',
        path: 'assets/icons/wineclub_circle.svg'
      },
      {
        name: 'experience-circle',
        path: 'assets/icons/experience_circle.svg'
      },
      {
        name: 'wineries-grey',
        path: 'assets/icons/wineries_grey.svg'
      },
      {
        name: 'wineries-primary',
        path: 'assets/icons/wineries_primary.svg'
      },
      {
        name: 'where-to-buy',
        path: 'assets/icons/where_to_buy.svg'
      },
      {
        name: 'to-do-grey',
        path: 'assets/icons/to_do_grey.svg'
      },
      {
        name: 'to-do-primary',
        path: 'assets/icons/to_do_primary.svg'
      },
      {
        name: 'top-10',
        path: 'assets/top_10_circle.svg'
      },
      {
        name: 'map',
        path: 'assets/icons/map.svg'
      },
      {
        name: 'map-white',
        path: 'assets/icons/map-white.svg'
      },
    ]
  }

  private _registration() {
    this.icons.forEach((icon) => {
      this._matIconRegistory.addSvgIcon(
        icon.name,
        this._domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    })
  }
}
