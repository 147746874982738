import { Component, Inject} from '@angular/core';
import { Router } from '@angular/router';

import { PANE_DATA } from '@firestitch/pane';

import { PaneControlService } from '@app/core/services';
import { Account } from '@app/shared/interfaces';


@Component({
  selector: 'app-welcome-actions-pane',
  templateUrl: './welcome-actions-pane.component.html',
  styleUrls: ['./welcome-actions-pane.component.scss']
})
export class WelcomeActionsPaneComponent {
  public account: Account = null;

  constructor(
    private _router: Router,
    private _paneControlService: PaneControlService,
    @Inject(PANE_DATA) public data: any
  ) { }

  public startQuiz() {
    this._hidePane();
    this._router.navigateByUrl('/tastetest');
  }

  public skip() {
    this._hidePane();
    this._router.navigateByUrl('/');
  }

  private _hidePane() {
    this._paneControlService.hideBottomPane();
  }
}
