import { Workflow } from './workflow';
import { WorkflowAction } from './workflow-action';
import { WorkflowStep } from './workflow-step';


export interface WorkflowPath {
  id?: number;
  workflowId?: number;
  workflow?: Workflow;
  workflowActions?: WorkflowAction[];
  sourceWorkflowStepId?: number;
  targetWorkflowStepId?: number;
  targetWorkflowStep?: WorkflowStep;
  name?: string;
  state?: string;
  order?: number;
  action?: string;
  configs?: string;
  tooltip?: string;
}
