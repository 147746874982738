<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>{{ type === 'full' ? 'Create Account' : 'Invite People' }}</h1>
    <div mat-dialog-content>
      <div fxLayout="column">
        <div *ngIf="type === 'full'">
          <fs-radio-group [(ngModel)]="action" fsFormRequired label="Create Method" name="dialog_type" orientation="vertical">
            <mat-radio-button value="invite">
              <div fxLayout="column">
                <div>Invite</div>
                <div class="checkbox-hint"><small>The person invited via email can create an Account using a supplied invite link.</small></div>
              </div>
            </mat-radio-button>
            <mat-radio-button value="create">
              <div>Create Account & Require Activation</div>
              <div class="checkbox-hint"><small>The Account is created with status of Pending Activation. An email can be sent to prompt the person to activate their account using a supplied activation link.</small></div>
            </mat-radio-button>
            <mat-radio-button value="activate">
              <div>Create Account & Auto Activation</div>
              <div class="checkbox-hint"><small>The Account is created with status of Active.</small></div>
            </mat-radio-button>
          </fs-radio-group>
        </div>

      <ng-container *ngIf="action === 'invite'">
        <fs-account-picker [search]="search" [(ngModel)]="selectedAccounts" fsFormRequired name="selected_accounts">
          <ng-template fs-account-picker-result let-account="data">
            <div *ngIf="account.id" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <app-account-avatar [account]="account" [size]="24"></app-account-avatar>
              <span>{{ account.firstName }} {{ account.lastName }}</span>
            </div>
            <div *ngIf="!account.id">{{ account.email }}</div>
          </ng-template>
        </fs-account-picker>
      </ng-container>

      <ng-container *ngIf="action !== 'invite'">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.xs="0" fxLayout.xs="column">
          <mat-form-field fxFlex="50">
            <mat-label>First Name</mat-label>
<input matInput [(ngModel)]="account.firstName" name="first_name" fsFormRequired>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Last Name</mat-label>
<input matInput [(ngModel)]="account.lastName" name="last_name" fsFormRequired>
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <mat-form-field fxFlex>
            <mat-label>Email</mat-label>
<input matInput [(ngModel)]="account.email" name="email" fsFormEmail fsFormRequired>
          </mat-form-field>
        </div>

        <fs-checkbox-group label="Activation Email Delivery" *ngIf="action === 'create'">
          <mat-checkbox name="send_email" [(ngModel)]="sendActivationEmail">Send Activation Email</mat-checkbox>
        </fs-checkbox-group>
      </ng-container>

      <fs-checkbox-group [(ngModel)]="selectedRoles" name="roles" label="Roles" orientation="vertical" *ngIf="mode === 'global' && globalRoles">
        <mat-checkbox *ngFor="let role of globalRoles" [value]="$any(role)">{{ role.name }}</mat-checkbox>
      </fs-checkbox-group>

      <ng-container *ngIf="mode !== 'global'">
        <fs-radio-group [(ngModel)]="mode" (ngModelChange)="changeMode()" fsFormRequired label="Invite to access" name="role_mode" orientation="vertical">
          <mat-radio-button value="project">Selected projects</mat-radio-button>
        </fs-radio-group>

        <fs-checkbox-group [(ngModel)]="selectedRoles" name="roles" label="Project Roles" orientation="vertical" *ngIf="mode === 'project' && projectRoles">
          <mat-checkbox *ngFor="let role of projectRoles" [value]="$any(role)" fsFormRequired>{{ role.name }}</mat-checkbox>
        </fs-checkbox-group>

        <ng-container *ngIf="mode === 'project'">
          <mat-form-field>
            <mat-label>Projects</mat-label>
        <mat-select [(ngModel)]="selectedProjects" name="projects"  multiple="true" fsFormRequired>
              <mat-option *ngFor="let project of projects" [value]="project">{{ project.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions>
    <button type="submit" mat-button color="primary">{{ type === 'full' ? 'Create' : 'Invite' }}</button>
    <button type="button" mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</fs-dialog>
</form>
