import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';
import { FsTransferService } from '@firestitch/transfer';

import { filter } from 'lodash-es';

import { Attribute } from '@app/shared';


@Injectable()
export class AttributeService {

  public ClassTag = 'tag';
  public ClassOrganizationClassification = 'organization_classification';
  public ClassFeature = 'feature';
  public ClassLanguage = 'language';
  public ClassWineType = 'wine_type';
  public ClassWineVariety = 'wine_variety';
  public ClassWineClassification = 'wine_classification';
  public ClassWineFlavourProfile = 'wine_flavour_profile';
  public ClassWineFlavour = 'wine_flavour';
  public ClassWineFinishes = 'wine_finish';
  public ClassWineColorType = 'wine_color_type';
  public ClassWineColor = 'wine_color';
  public ClassWineAging = 'wine_aging';
  public ClassWinePairing = 'wine_pairing';
  public ClassWineStyles = 'wine_style';
  public ClassPopularNote = 'popular_note';

  public classes = [
    { value: this.ClassTag, name: 'Tag', pluralName: 'Tags' },
    { value: this.ClassOrganizationClassification, name: 'Organization Classification', pluralName: 'Organization Classifications' },
    { value: this.ClassFeature, name: 'Feature', pluralName: 'Features' },
    { value: this.ClassLanguage, name: 'Language', pluralName: 'Languages' },
    { value: this.ClassWineType, name: 'Wine Type', pluralName: 'Wine Types', childClass: this.ClassWineVariety },
    { value: this.ClassWineVariety, name: 'Wine Variety', pluralName: 'Wine Varieties' },
    { value: this.ClassWineClassification, name: 'Wine Classification', pluralName: 'Wine Classifications' },
    { value: this.ClassWineFlavourProfile, name: 'Flavour Profile', pluralName: 'Flavour Profiles', childClass: this.ClassWineFlavour },
    { value: this.ClassWineFlavour, name: 'Flavour', pluralName: 'Flavours' },
    { value: this.ClassWineFinishes, name: 'Finish', pluralName: 'Finishes' },
    { value: this.ClassWineColorType, name: 'Colour Type', pluralName: 'Colour Types', childClass: this.ClassWineColor },
    { value: this.ClassWineColor, name: 'Colour', pluralName: 'Colours' },
    { value: this.ClassWineAging, name: 'Aging', pluralName: 'Aging' },
    { value: this.ClassWinePairing, name: 'Pairing', pluralName: 'Pairings' },
    { value: this.ClassWineStyles, name: 'Style', pluralName: 'Styles' },
    { value: this.ClassPopularNote, name: 'Popular Note', pluralName: 'Popular Notes' },
  ];

  constructor(
    private _fsApi: FsApi,
    private _fsTransferService: FsTransferService,
  ) { }

  public getClass(cls) {
    return filter(this.classes, { value: cls })[0];
  }

  public create(data: Attribute = { id: null }): Attribute {
    data.configs = data.configs || {};
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`attributes/${id}`, query, { key: 'attribute' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'attributes', data, Object.assign({ key: 'attributes' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`attributes/${data.id}`, data, Object.assign({ key: 'attribute' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('attributes', data, { key: 'attribute' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`attributes/${data.id}`, data, { key: 'attribute' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public order(data): Observable<any> {
    return this._fsApi.put('attributes/order', data, { key: null });
  }

  public getObjectAttributes(objectId, data, options = {}): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/attributes`, data, Object.assign({ key: 'attributes' }, options));
  }

  public getObjectAttribute(objectId, attributeId, data): Observable<any> {
    return this._fsApi.get(`objects/${objectId}/attributes/${attributeId}`, data, { key: 'attribute' });
  }

  public assignObject(attributeId, objectId): Observable<any> {
    return this._fsApi.put(`objects/${objectId}/attributes/${attributeId}`, null, { key: 'attribute' });
  }

  public deleteObject(attributeId, objectId): Observable<any> {
    return this._fsApi.delete(`objects/${objectId}/attributes/${attributeId}`, null, { key: null });
  }

  public image(data, file): Observable<any> {
    return this._fsApi.post(`attributes/${data.id}/image`, { file: file }, { key: 'attribute' });
  }

  public configImage(data, name, file): Observable<any> {
    return this._fsApi.post(`attributes/${data.id}/config/image`, { name: name, file: file }, { key: 'attribute' });
  }

  export(data) {
    return this._fsTransferService.post(`api/attributes/export`, data);
  }

  public import(data, config = {}): Observable<any> {
    return this._fsApi.post(`attributes/import`, data, Object.assign({ key: null }, config));
  }

}
