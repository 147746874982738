import { Component, OnDestroy, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FsNavService } from '@firestitch/nav';

import { NavService } from '../../core/services';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {

  public links = [];

  private _destroy = new Subject();

  constructor(
    private _navService: NavService,
    private _fsNav: FsNavService
  ) { }

  public ngOnInit() {

    this.links = [
      { label: 'Wines', path: `/content/wines` },
      { label: 'Product Lines', path: `/content/wines/productlines` }
    ];

    this._fsNav.routeChange
      .pipe(
        takeUntil(this._destroy)
      ).subscribe(() => {
        this.setTitle();
      });

    this.setTitle();
  }

  public ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private setTitle() {
    this._navService.setListTitle('Content', 'Wines');
  }

}
