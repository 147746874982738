<form (fsForm)="save()" #form="ngForm">
  <fs-dialog>
    <h1 mat-dialog-title>Create Organization</h1>
    <mat-dialog-content>

      <content-widget class="create_organization_message" tag="create_organization"></content-widget>

      <ng-container *ngIf="!updateJobTitle">

        <div fxLayout="column">
          <mat-form-field>
            <mat-label>Name</mat-label>
<input matInput [(ngModel)]="organization.name" name="name" fsFormRequired>
          </mat-form-field>
  
          <mat-form-field>
            <mat-label>Organization Type</mat-label>
        <mat-select
              [(ngModel)]="organization.type"
              
              name="type"
              fsSelect
              fsFormRequired>
                <mat-select-trigger *ngIf="organization.type">
                  <ng-container *ngTemplateOutlet="template;context:{ model: organization.type }"></ng-container>
                </mat-select-trigger>
  
                <mat-option *ngFor="let type of types" [value]="type">
                  <ng-container *ngTemplateOutlet="template;context:{ model: type }"></ng-container>
                </mat-option>
  
                <ng-template #template let-model="model">
                  <img *ngIf="model.image" [src]="model.image.small" class="avatar">
                  <span>{{ model.name }}</span>
                </ng-template>
            </mat-select>
          </mat-form-field>
  
          <app-subregion-picker [subregion]="organization.subregion" (subregionChange)="onSubregionChange($event)"></app-subregion-picker>
        </div>

      </ng-container>
      <ng-container *ngIf="updateJobTitle">

        <div fxLayout="column">
          <mat-form-field class="mat-fullwidth">
            <mat-label>Your Job Title</mat-label>
        <input
              matInput
              [(ngModel)]="account.jobTitle"
              fsFormRequired
              
              name="job_title">
          </mat-form-field>
        </div>    

      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type="button" color="primary" (click)="saveJobTitle()" *ngIf="updateJobTitle">CONTINUE</button>
      <button mat-button type="submit" color="primary" *ngIf="!updateJobTitle">CREATE</button>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </fs-dialog>
</form>
