import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotePreviewComponent } from '../../modules/note-preview/components';
import { ItemAddNotesComponent } from './add-notes';
import { Item } from '../../models';
import { getItemTypeNameLabel } from '../../helpers';


@Component({
  selector: 'app-item-your-notes',
  templateUrl: './your-notes.component.html',
  styleUrls: [
    './your-notes.component.scss',
  ],
})
export class YourNotesComponent implements OnDestroy {

  @Input()
  public set item(value) {
    this._initItem(value);
  }

  public itemType: string;

  private _item: Item;
  private _destroy$ = new Subject<void>();

  constructor(private _dialog: MatDialog) {

  }

  public get item() {
    return this._item;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public addNotes() {
    const dialogRef = this._dialog.open(ItemAddNotesComponent, {
      data: { item: this.item }
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(response => {
        if (response) {


          let index = null;
          let existingNote = this._item.notes.find((note, idx) => {
            index = idx;
            return response.id===note.id;
          });

          if(existingNote) {
            this._item.notes[index] = response;
          } else {
            this._item.notes.push(response);
          }

        }
      });
  }

  public openNote(note) {
    this._dialog.open(NotePreviewComponent, {
      data: {
        item: this.item,
        note: note,
      }
    });
  }

  private _initItem(value) {
    this._item = value instanceof Item ? value : new Item(value);
    this.itemType = getItemTypeNameLabel(this._item);
  }
}
