import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { FsNavService } from '@firestitch/nav';

import {
  PortalService,
  NavService,
  AclQueryService,
  AuthService,
  AccountService,
  SessionService
} from '../../core/services';
import { Account } from 'app/shared/interfaces';
import { LastPortal } from 'app/shared/types';
import { OrganizationDialogComponent } from 'app/shared/components/dialogs';


@Component({
  selector: 'app-environment-empty',
  templateUrl: './environment-empty.component.html',
  styleUrls: ['./environment-empty.component.scss']
})
export class EnvironmentEmptyComponent implements OnInit, OnDestroy {

  public hasApp = false;

  public lastPortal: LastPortal = null;
  public LastPortal = LastPortal;

  public loggedInAccount: Account = null;

  private _destroy$ = new Subject();

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _portalService: PortalService,
    private _navService: NavService,
    private _fsNavService: FsNavService,
    private _aclQueryService: AclQueryService,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _sessionService: SessionService
  ) { }

  public ngOnInit() {

    this.hasApp = this._aclQueryService.hasApp();

    this._fsNavService.routeChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.setTitle());

    this._portalService.lastPortal$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(response => this.lastPortal = response);

    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(store => this.loggedInAccount = store.value);

    this.setTitle();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public changePortal(lastPortal: LastPortal) {

    this.loggedInAccount.lastPortal = lastPortal;

    this._accountService.save(this.loggedInAccount)
      .subscribe(() => {
        this._sessionService.account(Object.assign(this.loggedInAccount, { lastPortal: lastPortal }));
        this._accountService.proceedHome();
      });
  }

  public createOrganizationDialog() {

    const dialogRef = this._dialog.open(OrganizationDialogComponent, {
      data: {
        updateJobTitle: !this.loggedInAccount.jobTitle,
      },
      width: '30%',
      minWidth: '400px',
    });

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => this.switch(response));
  }

  private switch(environment) {
    this._accountService.switchEnvironment(this.loggedInAccount.id, environment.id)
      .subscribe(response => {
        this._sessionService.environment(response);
        this._router.navigate(['/manage', 'organizations', environment.id]);
      });
  }

  private setTitle() {
    this._navService.setListTitle(null, 'Organization Not Selected');
    this._fsNavService.setComponent('image', null);
  }

}
