import { Component, Inject } from '@angular/core';
import { PANE_DATA } from '@firestitch/pane';


@Component({
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {

  public rating = 0;

  constructor(@Inject(PANE_DATA) public data: any) {
    this.rating = data.rating;
  }

}
