
import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';

import { Attribute } from 'app/shared/interfaces';


@Component({
  selector: 'app-attribute-pills',
  templateUrl: './attribute-pills.component.html',
  styleUrls: ['./attribute-pills.component.scss']
})
export class AttributePillsComponent {

  @Input() public attributes: Attribute[] = [];
  @Input() public selected = {};
  @Input() public allSelected = false;
  @Input() public class;
  @Output() public toggle = new EventEmitter();

}
