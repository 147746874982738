import { ActivatedRoute } from '@angular/router';

export function routesDataMerge(route: ActivatedRoute) {
  const params = [];
  let routeCurrent = route.root.snapshot;
  do {
    params.push(routeCurrent.data);
    routeCurrent = routeCurrent.firstChild;
  } while (routeCurrent);

  return Object.assign({}, ...params);
}
