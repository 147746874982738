import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../../core/services';


@Injectable()
export class LoggedOutGuard implements CanActivate {

  constructor(private router: Router, private sessionService: SessionService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.sessionService.signedIn()) {
      return this.router.createUrlTree(['/']);
    }

    return true;
  }
}
