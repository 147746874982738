<div
  *ngIf="item"
  class="item-card"
  [ngClass]="{ featured: item.featureCollection, 'item-card-wine': item.isWine }"
>
  <div class="item-image-actions">
    <a [routerLink]="['/', item.route, item.id]">
      <item-image [item]="item" imageHeight="100" imageWidth="100"></item-image>
    </a>
    <div class="item-actions">
      <mat-icon *ngIf="item.accountItem && item.accountItem.rating > 0" class="raiting-icon">star</mat-icon>
      <mat-icon *ngIf="item.accountItem?.triedIt || item.accountItem?.visits > 0" class="tried-icon">check</mat-icon>
      <mat-icon *ngIf="item.addedToList" class="added-icon">add</mat-icon>
    </div>
  </div>
  <div class="item-info">
    <a [routerLink]="['/', item.route, item.id]">
      <div class="item-content">
        <div *ngIf="showStatus || (item.data && item.data['classificationsVisibleOnList'] && item.data['classificationsVisibleOnList'].length)" class="super-header">
          <ng-container *ngIf="showStatus" [ngSwitch]="item.class">
            <app-organization-status-pill
              *ngSwitchCase="'organization'"
              [status]="$any(item.status)">
            </app-organization-status-pill>

            <app-experience-status-pill
              *ngSwitchCase="'experience'"
              [status]="$any(item.status)">
            </app-experience-status-pill>

            <app-wine-status-pill
              *ngSwitchCase="'wine'"
              [status]="$any(item.status)">
            </app-wine-status-pill>
          </ng-container>
          <app-item-classification-pills
            *ngIf="item.data['classificationsVisibleOnList'] && item.data['classificationsVisibleOnList'].length"
            [classifications]="item.data['classificationsVisibleOnList']"
          ></app-item-classification-pills>
        </div>

        <item-headings
          [item]="item"
          [hideOrganizationName]="hideOrganizationName"
          [showRating]="false"
        ></item-headings>

        <item-price [item]="item"></item-price>

        <div *ngIf="item.isCollection && item.collection.itemsCount">
          <span>
            {{ item.collection.itemsCount }}
            {{ item.collection.itemsCount > 1 ? 'Items' : 'Item' }}
          </span>
        </div>
        <!--<app-item-characteristics [item]="item"></app-item-characteristics>-->
      </div>
    </a>


    <div *ngIf="showScores" class="recommended-scores">
      <div>Wine Score: {{item.recommendationRanking}}</div>
      <div>Rating Count Modifier: {{item.ratingCountScore}}</div>
      <div>Rating Modifier: {{item.ratingScore}}</div>
      <div>Flavour Profile Modifier 1: {{item.flavourProfile1Score}}</div>
      <div>Flavour Profile Modifier 2: {{item.flavourProfile2Score}}</div>
      <div>Flavour Profile Modifier 3: {{item.flavourProfile3Score}}</div>
      <div>Body Modifier: {{item.bodyScore}}</div>
      <div>Sweetness Scale Modifier: {{item.sweetnessScaleScore}}</div>
    </div>
  </div>
</div>

<!--
<app-item-featured-banner [item]="item" *ngIf="item.featureCollection"></app-item-featured-banner>
-->
