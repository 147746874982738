import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, switchMap, map } from 'rxjs/operators';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { FsStore } from '@firestitch/store';
import { BodyClassRenderer } from '@firestitch/body';
import { Item, ItemActionType, SidenavConfig } from '@firestitch/sidenav';
import { FsPane } from '@firestitch/pane';

import { AskQuestionFormComponent } from '@libs/ask-question/components';

import { PaneControlService, SidenavService, SidenavOrganizationService } from '@app/core/services';
import {
  AclQueryService,
  AuthService,
  PortalService,
  EnvironmentService,
  OrganizationService,
  SettingService,
} from '@app/core/services';
import { Account } from '@app/shared/interfaces';
import { LastPortal } from '@app/shared/types';

import { SearchOrganizationsComponent } from './search-organizations';
import { EnvironmentMenuComponent } from './environment-menu';
import { TutorialComponent } from '@app/taster/modules/tutorial/components';


@Component({
  selector: 'app-page-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {

  public searchName: string;
  public account: Account = null;
  public contentPaddingBottom = '0px';
  public activePaneComponentName = null;
  public sideNavMain: SidenavConfig = { items: [] };

  public sideNavAdmin: SidenavConfig = null;
  public sideNavTaster: SidenavConfig = null;
  public sideNavManagement: SidenavConfig = null;
  public lastPortal: LastPortal = null;
  public LastPortal = LastPortal;
  public sideNavAuth: SidenavConfig = null;
  public liteMode: boolean;
  public brandingSettings = null;

  private _organizationSidenavItem: Item;
  private _environment: any = null;

  private _askQuestionBtn = {
    label: 'Ask a Question',
    click: () => {
      this._dialog.open(AskQuestionFormComponent, {
        data: { }
      });
    },
    image: 'assets/icons/intercom.svg',
    class: 'ask-question',
    exact: false
  };

  private _tutorialBtn = {
    label: 'Quick Start',
    click: () => {
      this._dialog.open(TutorialComponent, {
        data: { }
      });
    },
    image: 'assets/icons/tutorial.svg',
    class: 'tutorial',
    exact: false
  };

  private _destroy$ = new Subject();

  constructor(
    public sidenavService: SidenavService,
    private _store: FsStore,
    private _dialog: MatDialog,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private _authService: AuthService,
    private _aclQueryService: AclQueryService,
    private _portalService: PortalService,
    private _environmentService: EnvironmentService,
    private _organizationService: OrganizationService,
    private _bodyClassRenderer: BodyClassRenderer,
    private _paneControlService: PaneControlService,
    private _pane: FsPane,
    private _sidenavOrganizationService: SidenavOrganizationService,
    private _settingService: SettingService,
    private _cdRef: ChangeDetectorRef,
  ) {
    this._bodyClassRenderer.addBodyClass('body-side-nav');

    combineLatest(
      this._authService.loggedInAccount$,
      this._portalService.lastPortal$,
      this._environmentService.environment$
    )
    .pipe(
      switchMap(([account, lastPortal, env]) => {
        return this._settingService.gets('other', {}, false)
          .pipe(
            map((response) => {
              this.liteMode = !!+response.liteMode;

              return [account, lastPortal, env];
            }),
          );
      }),
      switchMap(([account, lastPortal, env]) => {
        return this._settingService.gets('branding', {}, false)
          .pipe(
            map((response) => {
              this.brandingSettings = response;

              return [account, lastPortal, env];
            }),
          );
      }),
      takeUntil(this._destroy$),
    )
    .subscribe(([account, lastPortal, env]) => {
      this.account = account.value;
      this.lastPortal = lastPortal;
      this._environment = env.value;

      this.initMenu();
      this._initFooter();
      this._listenPaneChanges();
      this._initAdminMenu();

      if (this.account) {
        this.sideNavAuth = { items: [
          {
            label: 'Sign Out',
            click: () => {
              this._authService.signout();
            },
            icon: 'logout'
          }
        ] };
      } else {
        this.sideNavAuth = { items: [
          {
            label: 'Sign In / Sign Up',
            click: () => {
              this._router.navigate(['/signin']);
            },
            icon: 'input'
          }
        ] };
      }

      this._cd.markForCheck();
    });

    this._sidenavOrganizationService.organization$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe((organization) => {
        this._organizationSidenavItem = organization ? {
          superlabel: organization.type ? organization.type.name : '',
          label: organization.name,
          path: '/manage/organizations/' + organization.id,
          class: ['organization'],
          items: [
            {
              label: 'Wines',
              hide: !(organization.type && organization.type.featureWinesProduced) || this.liteMode,
              path: `/manage/organizations/${organization.id}/wines/wines`,
              paths: [/\/manage\/organizations\/\d+\/wines/]
            },
            {
              label: 'Experiences',
              path: `/manage/organizations/${organization.id}/experiences`,
              paths: [/\/manage\/organizations\/\d+\/experiences/]
            },
            {
              label: 'Lists & Routes',
              path: `/manage/organizations/${organization.id}/collections`,
              paths: [/\/manage\/organizations\/\d+\/collections/],
            },
            {
              label: 'Settings',
              path: `/manage/organizations/${organization.id}/general`,
              paths: [/\/manage\/organizations\/\d+\/(general|tasting|photos|staff)/],
            }
          ]
        } : null;

        this.initMenu();
        this.sidenavService.close();
      });
  }

  public ngOnInit() {
    this._initFooter();
  }

  public closedStart() {
    this.sidenavService.opened = false;
  }

  public initMenu() {
    this.sideNavMain = { items: [] };
    if (!this.account) {
      return this.initTasterMainMenu();
    }

    switch (this.lastPortal) {
      case LastPortal.Admin:
        this.initAdminMainMenu();
        break;
      case LastPortal.Management:
        this.initManagementMainMenu();
        break;
      case LastPortal.Taster:
        this.initTasterMainMenu();
        break;
    }


    this._cdRef.markForCheck();
  }

  public initAdminMainMenu() {
    this.sideNavMain = {
      items: [
        {
          label: 'Organizations',
          path: '/manage/organizations',
          image: 'assets/organization.svg',
          actions: [
            {
              type: ItemActionType.Menu,
              icon: 'more_vert',
              menu: {
                component: SearchOrganizationsComponent,
                panelClass: 'menu-search-organizations'
              }
            }
          ]
        },
        this._organizationSidenavItem,
        {
          label: 'Wines',
          path: '/manage/wines/wines',
          image: 'assets/icons/wine.svg',
          hide: this.liteMode,
          exact: false,
          paths: [/\/manage\/wines/],
        },
        {
          label: 'Experiences',
          path: '/manage/experiences',
          image: 'assets/icons/experience.svg',
          exact: false
        },
        {
          label: 'Lists & Routes',
          path: '/manage/collections',
          image: 'assets/icons/collection_white.svg',
          exact: false
        },
        {
          label: 'Analytics',
          path: '/manage/analytics',
          image: 'assets/icons/analytics.svg',
          exact: false
        },
      ].filter((item) => {
        return !!item;
      })
    };

    this._cdRef.markForCheck();
  }

  private _listenPaneChanges() {
    this._pane.componentChanges$('bottom')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((event) => {
        const ref = this._pane.getPaneRef(event.name);
        if (ref) {
          if (this._pane.paneModifiedType(event.type)) {
            this.contentPaddingBottom = ref.elementRef.getBoundingClientRect().height + 'px';
            this.activePaneComponentName = event.name;
          } else {
            if (this.activePaneComponentName === event.name) {
              this.contentPaddingBottom = undefined;
            }
          }
        }
      });
  }

  public initManagementMainMenu() {
    const item: any = {
      superlabel: 'Organization Not Selected'
    };

    if (this._environment) {
      this.sideNavMain = null;
      this._organizationService.get(this._environment.id, { types: true, subscriptions: true })
        .subscribe((organization) => {
          this.sideNavMain = {
            items: [
            {
              label: 'Organization',
              path: `/manage/organizations/${this._environment.id}`,
              image: 'assets/organization.svg',
              paths: [/\/manage\/organizations\/\d+\/(general|tasting|photos|staff)+/]
            },
            {
              label: 'Wines',
              path: `/manage/organizations/${this._environment.id}/wines/wines`,
              image: 'assets/icons/wine.svg',
              hide: !(organization && organization.type && organization.type.featureWinesProduced) || this.liteMode,
              paths: [/\/manage\/organizations\/\d+\/wines/]
            },
            {
              label: 'Experiences',
              path: `/manage/organizations/${this._environment.id}/experiences`,
              image: 'assets/icons/experience.svg',
              paths: [/\/organizations\/\d+\/experiences/]
            },
            {
              label: 'Lists & Routes',
              path: `/manage/organizations/${this._environment.id}/collections`,
              image: 'assets/icons/collection_white.svg',
              hide: !(organization && organization.subscription && organization.subscription.featureCollections),
              paths: [/\/organizations\/\d+\/collections/]
            },
          ]
        };
        });
    }

    this.sideNavManagement = {
      items: [
        {
          superlabel: 'Management Portal',
          actions: [
            {
              type: ItemActionType.Menu,
              icon: 'more_vert',
              menu: {
                component: EnvironmentMenuComponent,
                data: { }
              }
            }
          ]
        },
        {
          superlabel: null,
          label: this._environment.name,
          image: this._environment.image.small,
          class: 'organization-item',
          path: `/manage/organizations/${this._environment.id}`,
          paths: [/\/organizations\/\d+/],
        }
      ]
    };
  }

  public initTasterMainMenu() {
    this.sideNavMain = {
      items: [
        {
          label: 'Wines',
          path: '/wines',
          hide: this.liteMode,
          paths: [/^\/wines/],
          image: 'assets/icons/tastings_white.svg'
        },
        {
          label: this.brandingSettings.brandingOrganizationPageTitle || 'Wineries',
          path: '/wineries',
          image: this.brandingSettings.brandingOrganizationPageLogo || 'assets/icons/wineries_white.svg'
        },
        {
          label: 'Map',
          path: '/map',
          image: 'assets/icons/map-white.svg',
        },
        {
          label: this.brandingSettings.brandingActivitiesPageTitle || 'To Do',
          path: '/todo',
          image: this.brandingSettings.brandingActivitiesPageLogo || 'assets/icons/to_do_white.svg'
        },
        {
          label: this.brandingSettings.brandingCollectionPageTitle || 'My Cellar',
          path: '/cellar',
          class: 'my-cellar',
          image: this.brandingSettings.brandingCollectionPageLogo || 'assets/icons/my_cellar_white.svg'
        },
        {
          label: 'Taste Test',
          path: '/tastetest',
          class: 'taste-test',
          hide: this.liteMode,
          image: 'assets/icons/taste_test_white.svg',
          paths: [/^\/tastetest/],
        },
        this._tutorialBtn,
        this._askQuestionBtn,
      ]
    };

    if (this.account) {
      const actions = [
        {
          type: ItemActionType.Menu,
          icon: 'more_vert',
          menu: {
            component: EnvironmentMenuComponent,
            data: { }
          }
        }
      ];

      this.sideNavTaster = {
        items: [
          {
            superlabel: 'User Portal',
            path: '',
            actions: this._aclQueryService.hasApp() || this._aclQueryService.hasOrganizations() ?
              actions : []
          }
        ]
      };
    }
  }

  public clickNav($event) {
    this.sidenavService.hide();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _initFooter() {
    if (!this.lastPortal || this.lastPortal === LastPortal.Taster) {
      this._paneControlService.createFooter();
    }
  }

  private _initAdminMenu() {
    this._store.observe('permissions')
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        const items = [];
        if (this._aclQueryService.hasApp()) {
          items.push(...[
              {
                superlabel: 'Admin Portal',
                actions: [
                  {
                    type: ItemActionType.Menu,
                    icon: 'more_vert',
                    menu: {
                      component: EnvironmentMenuComponent,
                      data: { }
                    }
                  }
                ]
              },
              {
                label: 'Settings',
                path: '/admin',
                icon: 'settings',
                class: ['admin'],
                items: [
                  {
                    label: 'Accounts',
                    path: '/admin/accounts/accounts',
                    exact: false,
                    paths: [/\/admin\/account/]
                  },
                  {
                    label: 'Organization',
                    path: '/admin/organization',
                    exact: false
                  },
                  {
                    label: 'Wine',
                    path: '/admin/wine',
                    hide: this.liteMode,
                    exact: false
                  },
                  {
                    label: 'Experience',
                    path: '/admin/experience',
                    exact: false
                  },
                  {
                    label: 'Messages',
                    path: '/admin/message',
                    exact: false
                  },
                  {
                    label: 'Global',
                    path: '/admin/system',
                    exact: false
                  },
                  {
                    label: 'App Settings',
                    path: '/admin/app-settings',
                    exact: false
                  },
                  {
                    label: 'Content',
                    path: '/admin/content',
                    exact: false
                  }
                ]
              }
            ]);
        }

        if (this._aclQueryService.hasBackend()) {
          items.push(...[
            {
              label: 'System',
              path: '/system',
              icon: 'personal_video',
              items: [
                {
                  label: 'Settings',
                  path: '/system/settings'
                },
                {
                  label: 'Crons',
                  path: '/system/crons'
                },
                {
                  label: 'Builds',
                  path: '/system/builds'
                },
                {
                  label: 'File Manager',
                  path: '/system/files'
                },
                {
                  label: 'Processes',
                  path: '/system/processes'
                },
                {
                  label: 'Logs',
                  path: '/system/logs'
                },
                {
                  label: 'Info',
                  path: '/system/info'
                },
                {
                  label: 'Migration',
                  path: '/system/migration'
                },
                {
                  label: 'Geolocation Debug',
                  path: '/system/geolocation'
                },
              ]
            }
          ]);
        }

        this.sideNavAdmin = items.length ? { items: items } : null;
        this._cdRef.markForCheck();
      });
  }
}
