import { Injectable } from '@angular/core';

import { FsApi } from '@firestitch/api';

import { Observable } from 'rxjs';


@Injectable()
export class ItemService {

  constructor(private _fsApi: FsApi) { }

  public create(data = { id: null }) {
    return data;
  }

  public get(itemId: number, data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, `items/${itemId}`, data, Object.assign({ key: 'object' }, config));
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request(`GET`, `items`, data, Object.assign({ key: 'objects' }, config));
  }

  public getRecommendedDaily(data: any = {}, config = {}): Observable<any> {
    data.percentMatch = true;
    return this._fsApi.request(`GET`, `items/wines/recommendations`, data, Object.assign({ key: 'objects' }, config));
  }

  public getOrganizations(data = {}, config = {}): Observable<any> {
    return this._fsApi.get( `items/organizations`, data, Object.assign({ key: 'objects' }, config));
  }

  public getNearbyOrganizations(data = {}, config = {}): Observable<any> {
    return this._fsApi.get( `items/nearbyorganizations`, data, Object.assign({ key: '' }, config));
  }

  public getWines(data: any = {}, config = {}): Observable<any> {
    data.percentMatch = true;
    return this._fsApi.get( `items/wines`, data, Object.assign({ key: 'objects' }, config));
  }

  public getExperiences(data = {}, config = {}): Observable<any> {
    return this._fsApi.get( `items/experiences`, data, Object.assign({ key: 'objects' }, config));
  }

  public getCollections(data = {}, config = {}): Observable<any> {
    return this._fsApi.get( `items/collections`, data, Object.assign({ key: 'objects' }, config));
  }

  public getCollection(id, data = {}, config = {}): Observable<any> {
    return this._fsApi.get( `items/${id}/collections`, data, Object.assign({ key: 'object' }, config));
  }

  public rate(item): Observable<any> {
    return this._fsApi.post(`items/${item.id}/rate`, { rating: item.accountItem.rating}, { key: 'accountItem' });
  }

  public tried(itemId: number, tried): Observable<any> {
    return this._fsApi.post(`items/${itemId}/tried`, tried, { key: 'accountItem' });
  }

  public visit(itemId: number, data = null): Observable<any> {
    return this._fsApi.post(`items/${itemId}/visit`, data, { key: 'accountItem' });
  }

  public share(item): Observable<any> {
    return this._fsApi.post(`items/${item.id}/share`, { }, { key: 'object' });
  }
}
