<mat-form-field>
  <mat-label>Months</mat-label>
        <input 
         [(ngModel)]="data.attribute.configs.months"
         name="months-input"
         matInput
         fsFormInteger
         fsFormMin="0"
  />
</mat-form-field>
