<div>
  <div class="priority" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
       [matTooltipDisabled]="showName"
       [matTooltip]="priorityValue?.name">

    <div class="priority-sign" *ngIf="priorityValue?.icon"
         fxLayoutAlign="center center"
         [style.background-color]="priorityValue?.color">
      <mat-icon>{{priorityValue?.icon}}</mat-icon>
    </div>

    <span *ngIf="showName"> {{ priorityValue?.name }}</span>
  </div>
</div>
