
import { AuditMetaAction } from '../enums';

export const AuditMetaActions = [
  { name: 'Created', value: AuditMetaAction.Create },
  { name: 'Added', value: AuditMetaAction.Add },
  { name: 'Changed', value: AuditMetaAction.Change },
  { name: 'Removed', value: AuditMetaAction.Remove },
  { name: 'Info', value: AuditMetaAction.Info },
  { name: 'Related', value: AuditMetaAction.Relate },
  { name: 'Unrelated', value: AuditMetaAction.Unrelate },
  { name: 'Signed In', value: AuditMetaAction.Signin },
  { name: 'Highlighted', value: AuditMetaAction.Highlight },
  { name: 'Unhighlighted', value: AuditMetaAction.Unhighlight },
];
