import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FsNavService } from '@firestitch/nav';

import { AuthService, SidenavService } from '@app/core/services';
import { Account } from '@app/shared/interfaces';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public account: Account = null;

  private _destroy$ = new Subject();

  constructor(
    private _authService: AuthService,
    private _sidenavService: SidenavService,
    private _navService: FsNavService) {
  }

  public ngOnInit() {
    this._loadAccount();
    this._initLogoHeader();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public sideNavClick() {
    this._sidenavService.toggle();
  }

  public back() {
    (<any>window).history.back();
  }

  private _loadAccount() {
    this._authService.loggedInAccount$
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((store) => {
        this.account = store.value;
      });
  }

  private _initLogoHeader() {
    this._navService.setComponent('logo', '/assets/wobc_horiz_colour.svg', true);
    this._navService.hideComponent('logo');
  }
}
