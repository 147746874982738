import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { AddressFormat, addressOneLineFormat } from '@firestitch/address';

import { openUrl } from '@app/common/helpers';

import { Item } from '../../models/item.model';


@Component({
  selector: 'app-item-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public showWineClassification = false;
  public showOrganizationClassification = false;
  public AddressFormat = AddressFormat;

  private _item: Item = null;

  @HostBinding('attr.hidden')
  public hiddenBlock;

  @Input() public set item(value) {
    this._item = value instanceof Item ? value : new Item(value);
  }

  public get item() {
    return this._item;
  }

  constructor() {}

  public ngOnInit() {
    this.checkIfItemHasContent();
  }

  public organisationReceived(data: any) {
    this.showOrganizationClassification = data?.length;
    this.checkIfItemHasContent();
  }

  public classificationRecevied(data: any) {
    this.showWineClassification = data?.length;
    this.checkIfItemHasContent();
  }

  public checkIfItemHasContent() {
    const itemData: any = this.item.data;

    const hiddenBlock =
      !(this.item.isOrganization && this.showOrganizationClassification) &&
      !(this.item.isWine && this.showWineClassification) &&
      !this.item.description &&
      !itemData.phone &&
      !itemData.email &&
      !itemData.website &&
      itemData.isAddressEmpty &&
      !(itemData.address && itemData.address.lat && itemData.address.lng);

    if (hiddenBlock) {
      this.hiddenBlock = true;
    } else {
      this.hiddenBlock = undefined;
    }
  }

  public navigateAddress(address) {
    openUrl(`http://www.google.com/maps/place/${addressOneLineFormat(address).join('+')}/@${address.lat},${address.lng}`);
  }
}
