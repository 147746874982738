import { FsMessage } from '@firestitch/message';
import { FsTransferHandler } from '@firestitch/transfer';
import { SessionService } from 'app/core/services/session.service';


export class TransferHandler extends FsTransferHandler {

  constructor(
    private fsMessage: FsMessage,
    private sessionService: SessionService
  ) {
    super();
  }

  public begin(request) {
    // const token = this.sessionService.getToken(false);
    // request.params['Token'] = 'Bearer ' + token;
    //request.params['ContentCase'] = 'camel';
    
    this.fsMessage.info('Starting download...');

    return request;
  }

  error(data, raw) {
    const message = data && data.message ? data.message : 'There was a problem with the download';
    this.fsMessage.error(message);
  }
}
