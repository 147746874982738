import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { FsMessage, MessageDialogConfig, MessageMode } from '@firestitch/message';

import { Observable, of, throwError } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class MessageService {

  constructor(
    private _message: FsMessage,
  ) {}

  public showApiErrorMessage(e: any): Observable<any> {
    let message;

    const config: MessageDialogConfig = {
      title: 'Attention',
      width: '400px',
      panelClass: 'system-error-message',
      buttons: [
        {
          click: () => {
            return of(true);
          },
          label: 'Ok',
        },
      ],
    };

    if (e.error && e.error.message) {
      message = e.error.message;

      if (!environment.production) {
        if (message.length > 100) {
          config.width = '80%';
        }

        if (e.error.exception) {
          config.buttons.push({
            click: () => {

              this._message.info(`<div>Url: ${e.url}</div><pre>${e.error.exception.trace.join('<br>')}</pre>`,
                {
                  title: 'Debug Message',
                  width: '90%',
                  mode: MessageMode.Dialog,
                });

              return throwError(null);
            },
            label: 'View Debug Message',
            color: 'warn',
          });
        }
      }
    } else if (e.error.text) {
      e.error.text = e.error.text.trim().replace(/(^<br[^>]*>|<br[^>]*>$)/ig, '');
      message = `<pre>${e.error.text}</pre>`;
      config.width = '90%';
    } else if (typeof e.error === 'string') {
      message = `<pre>${e.error}</pre>`;
      config.width = '90%';
    } else {
      message = 'Please check your network connection and try again.';
      config.title = 'Poor Connection';
    }

    return this._message.error(message, config);
  }

}
