import { FsAppMessageConfig } from '@firestitch/app-message';
import { FsFile } from '@firestitch/file';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  MessageData, MessageQueueData, MessageTemplateData, MessageWebhookData,
} from '../data';
import { AccountService, SessionService } from '../services';


export function appMessageConfig(
  messageData: MessageData,
  messageQueueData: MessageQueueData,
  messageTemplateData: MessageTemplateData,
  messageWebhookData: MessageWebhookData,
  accountService: AccountService,
  sessionService: SessionService,
): FsAppMessageConfig {

  const config: FsAppMessageConfig = {
    loadMessageQueues: (query) => {
      query = {
        ...query,
        emailMessageQueues: true,
        emailMessageQueueBodies: false,
        smsMessageQueues: true,
        messageQueueRecipients: true,
      };

      return messageQueueData.gets(query, { key: null })
        .pipe(
          map((response: any) => ({ data: response.messageQueues, paging: response.paging })),
        );
    },
    loadMessageQueue: (messageQueueId) => {
      const query = {
        emailMessageQueues: true,
        emailMessageQueueBodies: true,
        smsMessageQueues: true,
        smsMessageQueueBodies: true,
        messageQueueRecipients: true,
        messages: true,
      };

      return messageQueueData.get(messageQueueId, query);
    },
    loadLogs: (messageQueue, query) => {
      return messageQueueData.logsGets(messageQueue, query, { key: null })
        .pipe(
          map((response) => ({ data: response.messageLogs, paging: response.paging })),
        );
    },
    loadMessageQueueAttachments: (messageQueue, query) => {
      return messageQueueData.attachmentsGets(messageQueue, query, { key: null })
        .pipe(
          map((response) => ({ data: response.messageQueueAttachments, paging: response.paging })),
        );
    },
    loadMessageQueueEvents: (messageQueue, query) => {
      query = {
        ...query,
        messageQueueRecipients: true,
      };

      return messageQueueData.eventsGets(messageQueue, query, { key: null })
        .pipe(
          map((response) => ({ data: response.messageQueueEvents, paging: response.paging })),
        );
    },
    downloadMessageQueueAttachment: (messageQueueAttachment, messageQueue) => {
      messageQueueData.downloadAttachment(messageQueueAttachment, messageQueue);

      return of(true);
    },
    resendMessageQueue: (messageQueue) => {
      return messageQueueData.resend(messageQueue);
    },
    forwardMessageQueue: (messageQueue, email) => {
      return messageQueueData.forward(messageQueue, email);
    },
    bulkMessageQueues: (action, messageQueues) => {
      return messageQueueData.bulk(action, messageQueues
        .map((messageQueue) => messageQueue.id));
    },
    loadMessages: (query) => {
      query = {
        ...query,
        emailMessages: true,
        emailMessageMessageTemplates: true,
        smsMessages: true,
      };

      return messageData.gets(query, { key: null })
        .pipe(
          map((response) => ({ data: response.messages, paging: response.paging })),
        );
    },
    loadMessage: (message) => {
      const query = {
        emailMessages: true,
        smsMessages: true,
        variables: true,
      };

      return messageData.get(message.id, query);
    },
    testMessage: (message, recipient, type) => {
      return messageData.sendTest(message, recipient, type);
    },
    saveMessage: (message) => {
      return messageData.save(message);
    },
    loadMessageTemplates: (query) => {
      return messageTemplateData.gets(query, { key: null })
        .pipe(
          map((response) => ({ data: response.messageTemplates, paging: response.paging })),
        );
    },
    loadMessageTemplate: (messageTemplate) => {
      return messageTemplateData.get(messageTemplate.id);
    },
    saveMessageTemplate: (messageTemplate) => {
      return messageTemplateData.save(messageTemplate);
    },
    deleteMessageTemplate: (messageTemplate) => {
      return messageTemplateData.delete(messageTemplate);
    },
    getTestEmail: () => {
      return of('');
    },
    loadWebhooks: () => {
      return messageWebhookData.gets();
    },
    createWebhook: () => {
      return messageWebhookData.post();
    },
    deleteWebhook: (webhook) => {
      return messageWebhookData.delete(webhook);
    },
    loadMessageAttachments: (message, query) => {
      return messageData.messageAttachmentGets(message, query, { key: null })
        .pipe(
          map((response) => ({ data: response.messageAttachments, paging: response.paging })),
        );
    },
    uploadMessageAttachment: (message, fsFile: FsFile) => {
      return messageData.messageAttachmentPost(message, fsFile);
    },
    saveMessageAttachment: (messageAttachment) => {
      return messageData.messageAttachmentSave(messageAttachment);
    },
    deleteMessageAttachment: (messageAttachment) => {
      return messageData.messageAttachmentDelete(messageAttachment);
    },
    downloadMessageAttachment: (messageAttachment) => {
      messageData.messageAttachmentDownload(messageAttachment);
    },
  };

  return config;
}
