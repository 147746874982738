import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { filter } from 'rxjs/operators';

import { Angulartics2 } from 'angulartics2';

import { AccountService, AuthService } from '@app/core/services';
import { Item } from '@app/taster/modules/item/models';
import { Account } from '@app/shared/interfaces';
import { VerifyTastingConfirmedDialogComponent } from './confirmed-dialog/confirmed-dialog.component';


@Component({
  templateUrl: './verify-tasting-dialog.component.html'
})
export class VerifyTastingDialogComponent implements OnInit, OnDestroy {

  public code: string = null;

  public item: Item = null;

  private _loggedInAccount: Account = null;

  constructor(
    private _dialog: MatDialog,
    private _accountService: AccountService,
    private _authService: AuthService,
    private _dialogRef: MatDialogRef<VerifyTastingDialogComponent>,
    private _angulartics2: Angulartics2,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.item = data.item;
  }

  public ngOnInit() {
    this._authService.loggedInAccount$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(store => this._loggedInAccount = store.value);
  }

  public save() {
    this._accountService.verifiedtasting(this._loggedInAccount.id, { code: this.code, organizationId: this.item.id })
      .subscribe(() => {

        this._angulartics2.eventTrack.next({
          action: 'verify_tasting',
          properties: { objectId: this.item.id, wineryCode: this.code }
        });

        this.close();
        this._confirmedDialog();
      });
  }

  public close(data = null) {
    this._dialogRef.close(data);
  }

  public ngOnDestroy() { }

  private _confirmedDialog() {
    const dialogRef = this._dialog.open(VerifyTastingConfirmedDialogComponent);

    dialogRef.afterClosed()
      .pipe(
        filter(response => !!response)
      )
      .subscribe(response => {  });
  }

}
