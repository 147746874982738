/**
 * App can be named as RootModule, it has includes/imports of all project modules as a dependency.
 * Usually it doesn't contain any providers and providers are placed in Core
 */
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';

import { AskQuestionModule } from '@libs/ask-question';
import { ThemingService } from '@libs/theming/services';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { MainModule } from './main';
import { SharedModule } from './shared';

import { Router } from '@angular/router';
import { AppComponent } from './app.component';
import { IntercomService, SettingService, VisitService } from './core/services';
import { SearchModule } from './search/search.module';
import { AdminGuard, CanActivateWithoutConnection, LoggedInGuard, LoggedOutGuard, MobileWebGuard } from './shared/guards';


import { IconsFactory } from '@app/core/helpers/icons.factory';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { FsBuildService } from '@firestitch/build';
import { FsCordova } from '@firestitch/cordova';
import { FsProgressModule } from '@firestitch/progress';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Angulartics2Module } from 'angulartics2';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AppService } from './core/services/app.service';

export function provideConfig() {
  return new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientId)
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppId)
    }
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    MainModule,
    HttpClientModule,
    SearchModule,
    AskQuestionModule,
    SocialLoginModule,
    Angulartics2Module.forRoot(),
    FsProgressModule.forRoot({ barColor: '#6F2C3E', barHeight: 1 }),
  ],
  providers: [
    LoggedInGuard,
    LoggedOutGuard,
    AdminGuard,
    CanActivateWithoutConnection,
    MobileWebGuard,
    SplashScreen,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: {} },
    {
      provide: APP_INITIALIZER,      
      useFactory: (injector: Injector) => {
        return () => injector.get(ThemingService).load()
          .pipe(
            switchMap(() => injector.get(FsCordova).init()),
            catchError(() => of(null)),
            switchMap(() => injector.get(SettingService).load()),
            tap(() => injector.get(IconsFactory).init()),
            tap(() => injector.get(AppService).init()),
            tap(() => {
              if (!environment.production) {
                injector.get(FsBuildService).listen();
              }
            })
          );
      },
      multi: true,
      deps: [Injector]
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor(
    private intercomService: IntercomService,
    protected router: Router,
    private visitService: VisitService,
  ) {


    // // injecting GA main script asynchronously
    // const script = document.createElement('script');
    // script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsId;
    // script.async = true;
    // document.body.appendChild(script);

    // // preparing GA API to be usable even before the async script is loaded
    // (<any>window).dataLayer = (<any>window).dataLayer || [];
    // (<any>window).gtag = function() {
    //   (<any>window).dataLayer.push(arguments);
    // };
    // (<any>window).gtag('js', new Date());

    // // tracking current url at app bootstrap
    // (<any>window).gtag('config', environment.googleAnalyticsId);

    //this.angulartics2GoogleTagManager.startTracking();

    this.intercomService.start();

    // if there is a tracking code url param then track the visit
    const trackingCode = (new URL (window.location.href)).searchParams.get('tc');
    if (trackingCode) {
      this.visitService.trackVisit(trackingCode);
    }

  }

}
