import { Component, Input } from '@angular/core';

import { Item } from '../../models/item.model';


@Component({
  selector: 'app-item-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.scss'],
})
export class MapMarkerComponent {

  @Input()
  public item: Item;

  @Input()
  public clickable: boolean;

  @Input()
  public showName = true;

  constructor() { }

}
