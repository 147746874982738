<fs-dialog *fsSkeleton="!!assets.length" mobileMode="full">
  <h1 mat-dialog-title>Quick Start</h1>
  <mat-dialog-content>
    <div fxLayout="column" class="content">
      <img #image />
      <div class="overlay">
        <svg *ngIf="!imageLoaded" class="spinner" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      (click)="next()">Next</button>
    <button
      mat-button
      [disabled]="currentIndex <= 0"
      (click)="back()">Back</button>
    <button mat-button (click)="close()">Close</button>
  </mat-dialog-actions>
</fs-dialog>
