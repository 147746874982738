import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FsApi } from '@firestitch/api';

import { Category } from 'app/shared/interfaces';


@Injectable()
export class CategoryService {

  public tags = [
    { name: 'Featured', value: 'featured' }
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: Category = { id: null }): Category {
    return data;
  }

  public get(id, data = {}): Observable<any> {
    return this._fsApi.get(`categories/${id}`, data, { key: 'category' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'categories', data, Object.assign({ key: 'categories' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`categories/${data.id}`, data, Object.assign({ key: 'category' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('categories', data, { key: 'category' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`categories/${data.id}`, data, { key: 'category' });
  }

  public order(data, config = {}): Observable<any> {
    return this._fsApi.put(`categories/order`, data, Object.assign({ key: null }, config));
  }

  public image(data, file): Observable<any> {
    return this._fsApi.post(`categories/${data.id}/image`, { file: file }, { key: 'category' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public putCollections(categoryId, data, config = {}): Observable<any> {
    return this._fsApi.put(`categories/${categoryId}/collections`, data, Object.assign({ key: 'collections' }, config));
  }

  public orderCollections(categoryId, data, config = {}): Observable<any> {
    return this._fsApi.put(`categories/${categoryId}/collections/order`, data, Object.assign({ key: null }, config));
  }

  public deleteCollections(categoryId, data): Observable<any> {
    return this._fsApi.delete(`categories/${categoryId}/collections/${data.id}`, data, { key: null });
  }

}
