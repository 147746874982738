import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FsApi } from '@firestitch/api';

import { Award } from '../../shared/interfaces';


@Injectable()
export class AwardService {

  public ClassWinery = 'winery';
  public ClassWine = 'wine';

  public classes = [
    { name: 'Winery', value: this.ClassWinery },
    { name: 'Wine', value: this.ClassWine }
  ];

  constructor(private _fsApi: FsApi) { }

  public create(data: Award = { id: null }): Award {
    data.class = data.class || this.ClassWinery;
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`awards/${id}`, query, { key: 'award' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'awards', data, Object.assign({ key: 'awards' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`awards/${data.id}`, data, Object.assign({ key: 'award' }, config));
  }

  public post(data, config = {}): Observable<any> {
    return this._fsApi.post('awards', data, Object.assign({ key: 'award' }, config));
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`awards/${data.id}`, data, { key: 'award' });
  }

  public save(data, config = {}): Observable<any> {
    if (data.id) {
      return this.put(data, config);
    }
    return this.post(data, config);
  }
}
