import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FsStore } from '@firestitch/store';


@Component({
  selector: 'app-dialog-nav',
  templateUrl: './dialog-nav.component.html',
  styleUrls: ['./dialog-nav.component.scss']
})
export class DialogNavComponent {

  constructor(
    private _router: Router,
    private _fsStore: FsStore
  ) {
  }

}
