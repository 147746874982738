import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

import { FsPane } from '@firestitch/pane';

import { FooterComponent } from '@app/shared/components/footer';
import { Collection } from '@app/shared/interfaces';
import { Item, ItemOrganizationModel } from '@app/taster/modules/item/models';


@Injectable()
export class PaneControlService {

  constructor(
    private _pane: FsPane,
    private _deviceService: DeviceDetectorService
  ) { }

  public openBottomPane(component, data: any = {}) {
    this.hideFooter();
    this._pane.create('bottom', component, data);
  }

  public hideBottomPane() {
    this._pane.destroy('bottom');
    this.showFooter();
  }

  public openMapPane(component, data: any = {}) {
    this._pane.create('map', component, data);
  }

  public hideMapPane() {
    this._pane.destroy('map');
  }

  public createFooter() {
    const isMobile = this._isMobile();
    if (isMobile) {
      this._pane.create('footer', FooterComponent, {});
    }
  }

  public hideFooter() {
    this._pane.hide('footer');
  }

  public showFooter() {
    this._pane.show('footer');
  }

  public showOrganizationPane(item: Item, liteMode: boolean) {

    const primaryCollection: Collection = (<ItemOrganizationModel>item.data).collections.find((value) => !!value.primary);

    if (item.organization?.reservationUrl) {
      return true;
    }

    if (liteMode && !!item.data['experienceCount']) {
      return true;
    }

    if (item.type.actionButtonType === 'view_wine_list' && !primaryCollection) {
      return false;
    }

    if (item.type.actionButtonType === 'external_url' && !(<ItemOrganizationModel>item.data).actionButtonUrl) {
      return false;
    }

    return true;
  }

  private _isMobile() {
    return this._deviceService.isMobile();
  }

}
